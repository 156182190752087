import styled from 'styled-components';
import { FontFamily } from 'styles/style-variables/fonts';

import { SText } from 'components/styled/SText';

export const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 6px;
	padding: 8px;
	border-radius: 12px;
	background-color: ${({ theme }) => theme.components.transactionsCounter.bgColor.default};
	cursor: pointer;
`;

export const TransactionsBlock = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 2px;
`;

export const Value = styled(SText)<{ $isZero: boolean }>`
	font-family: ${FontFamily.Manrope};
	font-size: 12px;
	font-weight: 500;
	color: ${({ $isZero, theme }) =>
		$isZero
			? theme.components.transactionsCounter.fontColor.isZero
			: theme.components.transactionsCounter.fontColor.default};
`;
