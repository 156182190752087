import { Digits } from 'constants/digits/digits.const';
import fromExponential from 'from-exponential';

import { deleteDecimalTailZeros } from './delete-decimal-tail-zeros.util';

export const NBSP_JS = '\xa0';

//cuts digits after dot with specified number of fraction digits
export const cutFractionDigits = (value: string | number, fractionDigits: number): string => {
	let resultValue = String(fromExponential(value));

	if (resultValue.includes('.')) {
		const [beforeDot, afterDot] = resultValue.split('.');
		resultValue = `${beforeDot}.${afterDot.slice(0, fractionDigits)}`;
	}

	return resultValue;
};

// cuts a long string
export const minifyWalletString = (str: string | null, digits = 4): string => {
	if (!str) {
		return '';
	}
	if (str.length <= digits * 2) {
		return str;
	}
	return `${str.slice(0, digits + 2)}...${str.slice(-digits)}`;
};

//removes tail zeros from the end of the float
export const deleteTailZeros = (value: string): string => {
	return value.replace(/(\.0+)$/gi, '');
};

//adds spaces between digits groups
export const splitDigits = (value: string): string => {
	const isNegative = value.startsWith('-');
	const pureValue = isNegative ? value.slice(1) : value;

	if (pureValue.length <= 3) {
		return isNegative ? `-${pureValue}` : pureValue;
	}
	const firstElement = pureValue.slice(0, -3);
	const lastElement = pureValue.slice(-3);

	return isNegative
		? `-${splitDigits(firstElement)}${NBSP_JS}${lastElement}`
		: `${splitDigits(firstElement)}${NBSP_JS}${lastElement}`;
};

//adds spaces to numbers and cuts fraction digits
export const formatToNumberWithSpaces = (
	value: number | string,
	fractionDigits?: number,
): string => {
	const normalizedValue = Number(fromExponential(value))
		.toFixed(fractionDigits || 0)
		.replace(',', '.');
	const prunedValue = deleteTailZeros(normalizedValue);

	if (prunedValue.includes('.')) {
		const [beforeDot, afterDot] = prunedValue.split('.');

		return `${splitDigits(beforeDot)}.${afterDot}`;
	}

	return splitDigits(prunedValue);
};

//returns formatted USDT value
export const formatUsdtOutput = (value: number | string): string => {
	if (isNaN(Number(value))) {
		return '0';
	}

	const number = Number(fromExponential(value));
	const isNegative = number < 0;
	const absNumber = Math.abs(number);

	if (absNumber < 0.0001) {
		return '0';
	}
	if (absNumber < 0.01) {
		return isNegative
			? deleteDecimalTailZeros(formatToNumberWithSpaces(-1 * absNumber, 4))
			: deleteDecimalTailZeros(formatToNumberWithSpaces(absNumber, 4));
	}

	return isNegative
		? deleteDecimalTailZeros(formatToNumberWithSpaces(-1 * absNumber, Digits.USDT))
		: deleteDecimalTailZeros(formatToNumberWithSpaces(absNumber, Digits.USDT));
};

//returns number with four decimals and spaces between digits groups
export const formatBtcmtOutput = (value: number | string): string => {
	if (isNaN(Number(value))) {
		return '0';
	}
	return deleteDecimalTailZeros(formatToNumberWithSpaces(value, Digits.BTCMT));
};

//returns number with six decimals and spaces between digits groups
export const formatBnbOutput = (value: number | string): string => {
	if (isNaN(Number(value))) {
		return '0';
	}
	return deleteDecimalTailZeros(formatToNumberWithSpaces(value, Digits.BNB));
};

//returns number with eight decimals and spaces between digits groups
export const formatBtcOutput = (value: number | string): string => {
	if (isNaN(Number(value))) {
		return '0';
	}
	return deleteDecimalTailZeros(formatToNumberWithSpaces(value, Digits.BTCB));
};

export const floorBtcmtValue = (value: string | number): number => {
	return Number(cutFractionDigits(String(value), Digits.BTCMT));
};

export const floorBnbValue = (value: string | number): number => {
	return Number(cutFractionDigits(String(value), Digits.BNB));
};

export const floorUsdtValue = (value: string | number): number => {
	return Number(cutFractionDigits(String(value), Digits.USDT));
};

export const floorBtcValue = (value: string | number): number => {
	return Number(cutFractionDigits(String(value), Digits.BTCB));
};

//removes spaces from number
export const getBackToNumber = (stringWithSpaces: string): number => {
	const preparedString = stringWithSpaces.replace(/\s/g, '');
	const result = Number(fromExponential(preparedString));
	return isNaN(result) || !isFinite(result) ? 0 : result;
};

//returns number with four decimals and spaces between digits groups
export const formatBTCMTOutput = (value: number | string): string => {
	if (isNaN(Number(value))) {
		return '0';
	}
	return formatToNumberWithSpaces(value, Digits.BTCMT);
};
