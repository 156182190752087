import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { TRANSACTIONS_CHECK_INTERVAL_MS } from 'constants/intervals/transactions-check-interval-ms.const';
import { TFbStakingType } from 'store/api/fireblocks-api/types/staking/fb-staking-type.type';
import {
	setAutoFarmFlowStepAction,
	setStakingFlowStepAction,
} from 'store/staking-reducers/staking-flow-reducer/staking-flow.reducer';
import {
	selectFbStakingTransactionsCheckStatus,
	selectShouldCheckStakingTransactions,
} from 'store/staking-reducers/staking-flow-reducer/staking-flow.selectors';
import { checkPendingStakingTransactionsThunkAction } from 'store/staking-reducers/staking-flow-reducer/staking-flow.thunk-actions';
import { TAutofarmFlowStep } from 'store/staking-reducers/staking-flow-reducer/types/autofarm-flow.step';
import { TStakingFlowStep } from 'store/staking-reducers/staking-flow-reducer/types/staking-flow-step.type';
import { useAppDispatch } from 'store/store';

export const useCheckStakingTransactions = ({
	stakingFlowStep,
	autoFarmFlowStep,
	pendingStakeTransactions,
	pendingWithdrawTransactions,
	failedStakingTransactions,
	isCryptoUser,
}: {
	stakingFlowStep: TStakingFlowStep | null;
	autoFarmFlowStep: TAutofarmFlowStep | null;
	pendingStakeTransactions: TFbStakingType[];
	pendingWithdrawTransactions: TFbStakingType[];
	failedStakingTransactions: TFbStakingType[];
	isCryptoUser: boolean;
}): void => {
	const dispatch = useAppDispatch();
	const stakingTransactionsCheckStatus = useSelector(selectFbStakingTransactionsCheckStatus);
	const shouldCheckStakingTransactions = useSelector(selectShouldCheckStakingTransactions);

	const areTransactionsChecking = stakingTransactionsCheckStatus === 'pending';
	const isTransactionsCheckFulfilled = stakingTransactionsCheckStatus === 'fulfilled';

	const pendingManualStakingTransactions = pendingStakeTransactions.filter(
		(type) => type === 'Manual',
	);
	const pendingAutofarmStakingTransactions = pendingStakeTransactions.filter(
		(type) => type === 'Autofarm',
	);
	const pendingManualWithdrawTransactions = pendingWithdrawTransactions.filter(
		(type) => type === 'Manual',
	);
	const pendingAutofarmWithdrawTransactions = pendingWithdrawTransactions.filter(
		(type) => type === 'Autofarm',
	);
	const failedManualStakingTransactions = failedStakingTransactions.filter(
		(type) => type === 'Manual',
	);
	const failedAutofarmStakingTransactions = failedStakingTransactions.filter(
		(type) => type === 'Autofarm',
	);

	const intervalRef = useRef<ReturnType<typeof setInterval>>();

	useEffect(() => {
		if (!isCryptoUser) {
			void dispatch(checkPendingStakingTransactionsThunkAction());
		}
	}, [isCryptoUser, dispatch]);

	useEffect(() => {
		if (!isCryptoUser && shouldCheckStakingTransactions && !areTransactionsChecking) {
			intervalRef.current = setInterval(
				() => void dispatch(checkPendingStakingTransactionsThunkAction()),
				TRANSACTIONS_CHECK_INTERVAL_MS,
			);
		} else {
			clearInterval(intervalRef.current);
		}
		return () => {
			clearInterval(intervalRef.current);
		};
	}, [dispatch, shouldCheckStakingTransactions, areTransactionsChecking, isCryptoUser]);

	// Manual staking success transactions
	useEffect(() => {
		if (
			!isCryptoUser &&
			pendingManualStakingTransactions.length === 0 &&
			failedManualStakingTransactions.length === 0 &&
			isTransactionsCheckFulfilled
		) {
			switch (stakingFlowStep) {
				case 'stake-pending':
					dispatch(setStakingFlowStepAction('stake-success'));
					break;
				case 'stake-pending-background':
					dispatch(setStakingFlowStepAction('stake-notify-success'));
					break;
			}
		}
	}, [
		dispatch,
		pendingManualStakingTransactions,
		failedManualStakingTransactions,
		stakingFlowStep,
		isTransactionsCheckFulfilled,
		isCryptoUser,
	]);

	// Manual withdrawing success transactions
	useEffect(() => {
		if (
			!isCryptoUser &&
			pendingManualWithdrawTransactions.length === 0 &&
			failedManualStakingTransactions.length === 0 &&
			isTransactionsCheckFulfilled
		) {
			switch (stakingFlowStep) {
				case 'withdraw-pending':
					dispatch(setStakingFlowStepAction('withdraw-success'));
					break;
				case 'withdraw-pending-background':
					dispatch(setStakingFlowStepAction('withdraw-notify-success'));
					break;
			}
		}
	}, [
		dispatch,
		pendingManualWithdrawTransactions,
		failedManualStakingTransactions,
		isTransactionsCheckFulfilled,
		stakingFlowStep,
		shouldCheckStakingTransactions,
		isCryptoUser,
	]);

	// Autofarm staking success transactions
	useEffect(() => {
		if (
			!isCryptoUser &&
			pendingAutofarmStakingTransactions.length === 0 &&
			failedAutofarmStakingTransactions.length === 0 &&
			isTransactionsCheckFulfilled
		) {
			switch (autoFarmFlowStep) {
				case 'stake-pending':
					dispatch(setAutoFarmFlowStepAction('stake-success'));
					break;
				case 'stake-pending-background':
					dispatch(setAutoFarmFlowStepAction('stake-notify-success'));
					break;
			}
		}
	}, [
		dispatch,
		pendingAutofarmStakingTransactions,
		failedAutofarmStakingTransactions,
		autoFarmFlowStep,
		isTransactionsCheckFulfilled,
		isCryptoUser,
	]);

	// Autofarm withdrawing success transactions
	useEffect(() => {
		if (
			!isCryptoUser &&
			pendingAutofarmWithdrawTransactions.length === 0 &&
			failedAutofarmStakingTransactions.length === 0 &&
			isTransactionsCheckFulfilled
		) {
			switch (autoFarmFlowStep) {
				case 'withdraw-pending':
					dispatch(setAutoFarmFlowStepAction('withdraw-success'));
					break;
				case 'withdraw-pending-background':
					dispatch(setAutoFarmFlowStepAction('withdraw-notify-success'));
					break;
			}
		}
	}, [
		dispatch,
		pendingAutofarmWithdrawTransactions,
		failedAutofarmStakingTransactions,
		isTransactionsCheckFulfilled,
		autoFarmFlowStep,
		shouldCheckStakingTransactions,
		isCryptoUser,
	]);

	// Manual staking and withdrawing error transactions
	useEffect(() => {
		if (
			!isCryptoUser &&
			failedManualStakingTransactions.length > 0 &&
			isTransactionsCheckFulfilled
		) {
			switch (stakingFlowStep) {
				case 'stake-pending':
					dispatch(setStakingFlowStepAction('stake-error'));
					break;
				case 'stake-pending-background':
					dispatch(setStakingFlowStepAction('stake-notify-error'));
					break;
				case 'withdraw-pending':
					dispatch(setStakingFlowStepAction('withdraw-error'));
					break;
				case 'withdraw-pending-background':
					dispatch(setStakingFlowStepAction('withdraw-notify-error'));
					break;
			}
		}
	}, [
		failedManualStakingTransactions,
		isTransactionsCheckFulfilled,
		dispatch,
		stakingFlowStep,
		isCryptoUser,
	]);

	// Autofarm staking and withdrawing error transactions
	useEffect(() => {
		if (
			!isCryptoUser &&
			failedAutofarmStakingTransactions.length > 0 &&
			isTransactionsCheckFulfilled
		) {
			switch (autoFarmFlowStep) {
				case 'stake-pending':
					dispatch(setAutoFarmFlowStepAction('stake-error'));
					break;
				case 'stake-pending-background':
					dispatch(setAutoFarmFlowStepAction('stake-notify-error'));
					break;
				case 'withdraw-pending':
					dispatch(setAutoFarmFlowStepAction('withdraw-error'));
					break;
				case 'withdraw-pending-background':
					dispatch(setAutoFarmFlowStepAction('withdraw-notify-error'));
					break;
			}
		}
	}, [
		failedAutofarmStakingTransactions,
		isTransactionsCheckFulfilled,
		dispatch,
		autoFarmFlowStep,
		isCryptoUser,
	]);
};
