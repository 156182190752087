import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { fireblocksApi } from 'store/api/fireblocks-api/fireblocks.api';
import { FireblocksApiRoute } from 'store/api/fireblocks-api/fireblocks-api-route';
import { IUserProfile } from 'store/api/fireblocks-api/types/user/user-profile.interface';
import { IWalletAddress } from 'store/api/fireblocks-api/types/user/wallet-address.interface';

export const requestUserProfile = async (): Promise<IUserProfile> =>
	await fireblocksApi.get<IUserProfile>(FireblocksApiRoute.Profile()).then((res) => res.data);

export const createWallet = async (promoCode: string): Promise<IUserProfile> =>
	await fireblocksApi
		.patch<IUserProfile, AxiosResponse<IUserProfile>, { params: { promotion: string } }>(
			FireblocksApiRoute.CreateWallet(),
			undefined,
			{
				params: {
					promotion: promoCode,
				},
			},
		)
		.then((res) => res.data);

export const deleteWallet = async (address: string): Promise<void> =>
	await fireblocksApi
		.delete<Promise<void>, AxiosResponse<Promise<void>>, IWalletAddress>(
			FireblocksApiRoute.Profile(),
			{
				data: {
					address,
				},
			},
		)
		.then((res) => res.data);
