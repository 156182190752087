import { createSlice } from '@reduxjs/toolkit';
import { ITotalHistoryResponseItem } from 'store/api/stats-api/stats-api-response.types';
import { TFetchStatus } from 'types/api/fetch-status.type';

import {
	requestActiveWalletsThunkAction,
	requestStatsBlockInfoThunkAction,
	requestTotalHistoryThunkAction,
	requestTotalMinedThunkAction,
	requestTotalMultiThunkAction,
	requestTotalStakedThunkAction,
	requestTvlThunkAction,
} from './stats-api.thunk-actions';

export interface IStatsApiState {
	activeWallets?: number | null;
	activeWalletsStatus: TFetchStatus;
	totalHistory?: ITotalHistoryResponseItem[] | null;
	totalHistoryStatus: TFetchStatus;
	totalIssued?: number | null;
	totalIssuedStatus: TFetchStatus;
	totalMined?: number | null;
	totalMinedStatus: TFetchStatus;
	totalMulti?: number | null;
	totalMultiStatus: TFetchStatus;
	totalStaked?: number | null;
	totalStakedStatus: TFetchStatus;
	tvl?: number | null;
	tvlStatus: TFetchStatus;
	statsBlockInfoStatus: TFetchStatus;
	treasuryBalance?: number | null;
	treasuryBalanceStatus: TFetchStatus;
}

const initialState: IStatsApiState = {
	activeWallets: null,
	activeWalletsStatus: 'initial',
	totalHistory: null,
	totalHistoryStatus: 'initial',
	totalIssued: null,
	totalIssuedStatus: 'initial',
	totalMined: null,
	totalMinedStatus: 'initial',
	totalMulti: null,
	totalMultiStatus: 'initial',
	totalStaked: null,
	totalStakedStatus: 'initial',
	tvl: null,
	tvlStatus: 'initial',
	statsBlockInfoStatus: 'initial',
	treasuryBalance: null,
	treasuryBalanceStatus: 'initial',
};

const statsApiSlice = createSlice({
	name: 'statsApi',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(requestTotalMinedThunkAction.fulfilled, (state, { payload }) => {
				if (payload) {
					state.totalMined = payload;
				}
			})
			.addCase(requestTotalStakedThunkAction.pending, (state) => {
				state.totalStaked = null;
				state.totalStakedStatus = 'pending';
			})
			.addCase(requestTotalStakedThunkAction.fulfilled, (state, { payload }) => {
				state.totalStakedStatus = 'fulfilled';
				if (payload) {
					state.totalStaked = payload;
				}
			})
			.addCase(requestTotalStakedThunkAction.rejected, (state) => {
				state.totalStakedStatus = 'rejected';
			})
			.addCase(requestTotalMultiThunkAction.fulfilled, (state, { payload }) => {
				state.totalMultiStatus = 'fulfilled';
				if (payload) {
					state.totalMulti = payload;
				}
			})
			.addCase(requestTvlThunkAction.fulfilled, (state, { payload }) => {
				if (payload) {
					state.tvl = payload;
				}
			})
			.addCase(requestActiveWalletsThunkAction.fulfilled, (state, { payload }) => {
				if (payload) {
					state.activeWallets = payload;
				}
			})
			.addCase(requestTotalHistoryThunkAction.pending, (state) => {
				state.totalHistory = null;
				state.totalHistoryStatus = 'pending';
			})
			.addCase(requestTotalHistoryThunkAction.fulfilled, (state, { payload }) => {
				state.totalHistoryStatus = 'fulfilled';
				if (payload) {
					state.totalHistory = payload;
				}
			})
			.addCase(requestTotalHistoryThunkAction.rejected, (state) => {
				state.totalHistoryStatus = 'rejected';
			})
			.addCase(requestStatsBlockInfoThunkAction.pending, (state) => {
				state.statsBlockInfoStatus = 'pending';
				state.totalMined = null;
				state.totalStaked = null;
				state.totalMulti = null;
				state.tvl = null;
				state.activeWallets = null;
				state.treasuryBalance = null;
			})
			.addCase(requestStatsBlockInfoThunkAction.fulfilled, (state, { payload }) => {
				state.statsBlockInfoStatus = 'fulfilled';
				state.activeWalletsStatus = payload.activeWalletsStatus;
				state.totalMinedStatus = payload.totalMinedStatus;
				state.totalStakedStatus = payload.totalStakedStatus;
				state.totalMultiStatus = payload.totalMultiStatus;
				state.tvlStatus = payload.tvlStatus;
				state.treasuryBalanceStatus = payload.treasuryBalanceStatus;
				state.totalIssuedStatus = payload.totalIssuedStatus;

				state.totalMined = payload.totalMined;
				state.totalStaked = payload.totalStaked;
				state.totalMulti = payload.totalMulti;
				state.tvl = payload.tvl;
				state.activeWallets = payload.activeWallets;
				state.treasuryBalance = payload.treasuryBalance;
				state.totalIssued = payload.totalIssued;
			})
			.addCase(requestStatsBlockInfoThunkAction.rejected, (state) => {
				state.statsBlockInfoStatus = 'rejected';

				state.activeWalletsStatus = 'rejected';
				state.totalMinedStatus = 'rejected';
				state.totalStakedStatus = 'rejected';
				state.totalMultiStatus = 'rejected';
				state.tvlStatus = 'rejected';
				state.treasuryBalanceStatus = 'rejected';
				state.totalIssuedStatus = 'rejected';
			});
	},
});

export const statsReducer = statsApiSlice.reducer;
