import { createSlice } from '@reduxjs/toolkit';
import { IEstimateGasResponse } from 'store/api/fireblocks-api/types/gas/estimate-gas-response.interface';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { estimateGasThunkAction } from './gas.thunk-actions';

export interface IStakingFlowState {
	debugErrorMessage: string | null;
	gasEstimation: IEstimateGasResponse | null;
	gasEstimationStatus: TFetchStatus;
}

const initialState: IStakingFlowState = {
	debugErrorMessage: null,
	gasEstimation: null,
	gasEstimationStatus: 'initial',
};

const gasSlice = createSlice({
	name: 'gas-flow',
	initialState,
	reducers: {
		resetGasStateAction: (state) => {
			state.gasEstimation = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(estimateGasThunkAction.pending, (state) => {
				state.gasEstimation = null;
				state.gasEstimationStatus = 'pending';
			})
			.addCase(estimateGasThunkAction.fulfilled, (state, { payload }) => {
				state.gasEstimation = payload;
				state.gasEstimationStatus = 'fulfilled';
			})
			.addCase(estimateGasThunkAction.rejected, (state, { error }) => {
				state.gasEstimationStatus = 'rejected';
				if (error.message) {
					state.debugErrorMessage = error.message;
				}
			});
	},
});

export const { resetGasStateAction } = gasSlice.actions;
export const gasReducer = gasSlice.reducer;
