import { Auth, getAuth } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { isProductionFB } from 'store/api/env-config/is-production-fb.config';

import { firebaseDevConfig } from './config/firebase-dev-config';
import { firebaseProdConfig } from './config/firebase-prod.config';

export const firebaseConfig = isProductionFB ? firebaseProdConfig : firebaseDevConfig;

export const domainUrl = process.env.REACT_APP_DOMAIN_URL || '';

const firebaseApp = firebase.initializeApp(firebaseConfig);
let firebaseAuth: Auth;
try {
	firebaseAuth = getAuth(firebaseApp);
} catch {
	console.error('Cannot read Firebase config');
}

export { firebaseAuth };
