import styled from 'styled-components';
import { FontFamily } from 'styles/style-variables/fonts';

export const SText = styled.span`
  font-family: ${FontFamily.Manrope};
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  color: ${({ theme }) => theme.globals.textColor};
`;
