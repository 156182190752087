import { createAsyncThunk } from '@reduxjs/toolkit';
import { readContract } from '@wagmi/core';
import type { TRootState } from 'store/store';
import { ContractName, TAccountAddress, TNetworkName } from 'types/blockchain/contracts.types';
import { formatEther } from 'viem';

import { bscContractsConfig, bscTestnetContractsConfig } from 'config/web3/contracts.config';
import { floorBtcmtValue } from 'utils/formatters/formatters.utils';

export const requestUserAutoStakesV2ThunkAction = createAsyncThunk(
	'staking-balance/autofarm-v2',
	async (
		{ accountAddress, chainName }: { accountAddress: TAccountAddress; chainName: TNetworkName },
		{ getState },
	) => {
		const chainId = (getState() as TRootState).chainReducer.chain.id;

		if (!accountAddress || !chainId || !chainName) {
			return null;
		}

		let contractConfig;

		switch (chainName) {
			case 'bsc':
				contractConfig = bscContractsConfig[ContractName.AutofarmV2Bsc];
				break;
			case 'bsc-testnet':
				contractConfig = bscTestnetContractsConfig[ContractName.AutofarmV2Bsc];
				break;
		}

		return await readContract({
			address: contractConfig.address,
			abi: contractConfig.abi,
			functionName: 'userStake',
			args: [accountAddress],
			chainId: chainId,
		}).then((stake) => {
			return floorBtcmtValue(formatEther(stake));
		});
	},
);

export const requestUserStakesThunkAction = createAsyncThunk(
	'staking-balance/manual',
	async (
		{ accountAddress, chainName }: { accountAddress: TAccountAddress; chainName: TNetworkName },
		{ getState },
	) => {
		const chainId = (getState() as TRootState).chainReducer.chain.id;

		if (!accountAddress || !chainId || !chainName) {
			return null;
		}

		let contractConfig;

		switch (chainName) {
			case 'bsc':
				contractConfig = bscContractsConfig[ContractName.StakingBsc];
				break;
			case 'bsc-testnet':
				contractConfig = bscTestnetContractsConfig[ContractName.StakingBsc];
				break;
		}

		return await readContract({
			address: contractConfig.address,
			abi: contractConfig.abi,
			functionName: 'userStakes',
			args: [accountAddress],
			chainId: chainId,
		}).then(([_day, totalAmount, lockedAmount, unlockedAmount]) => {
			return {
				totalAmount: floorBtcmtValue(formatEther(totalAmount)),
				lockedAmount: floorBtcmtValue(formatEther(lockedAmount)),
				unlockedAmount: floorBtcmtValue(formatEther(unlockedAmount)),
			};
		});
	},
);

