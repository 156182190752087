import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { TRANSACTIONS_CHECK_INTERVAL_MS } from 'constants/intervals/transactions-check-interval-ms.const';
import { setBuyFromDexFlowStepAction } from 'store/purchase-reducers/web2-dex-reducer/web2-dex.reducer';
import {
	selectBuyFromDexFlowStep,
	selectDexTransactionsCheckStatus,
	selectIsLastDexTransactionFailed,
	selectIsLastDexTransactionFulfilled,
	selectShouldCheckDexTransactions,
} from 'store/purchase-reducers/web2-dex-reducer/web2-dex.selectors';
import { checkDexLastTransactionThunkAction } from 'store/purchase-reducers/web2-dex-reducer/web2-dex.thunk-actions';
import { useAppDispatch } from 'store/store';

export const useCheckDexTransactions = (isCryptoUser: boolean): void => {
	const dispatch = useAppDispatch();
	const dexTransactionsCheckStatus = useSelector(selectDexTransactionsCheckStatus);
	const shouldCheckDexTransactions = useSelector(selectShouldCheckDexTransactions);
	const isLastDexTransactionFulfilled = useSelector(selectIsLastDexTransactionFulfilled);
	const isLastDexTransactionFailed = useSelector(selectIsLastDexTransactionFailed);
	const buyFromDexFlowStep = useSelector(selectBuyFromDexFlowStep);

	const isTransactionChecked = dexTransactionsCheckStatus === 'fulfilled';
	const isTransactionChecking = dexTransactionsCheckStatus === 'pending';

	const intervalRef = useRef<ReturnType<typeof setInterval>>();

	useEffect(() => {
		if (!isCryptoUser) {
			void dispatch(checkDexLastTransactionThunkAction());
		}
	}, [isCryptoUser, dispatch]);

	useEffect(() => {
		if (!isCryptoUser && shouldCheckDexTransactions) {
			intervalRef.current = setInterval(() => {
				void dispatch(checkDexLastTransactionThunkAction());
			}, TRANSACTIONS_CHECK_INTERVAL_MS);
		} else {
			clearInterval(intervalRef.current);
		}

		return () => {
			clearInterval(intervalRef.current);
		};
	}, [dispatch, isCryptoUser, shouldCheckDexTransactions, isTransactionChecking]);

	useEffect(() => {
		if (
			!isCryptoUser &&
			isLastDexTransactionFulfilled &&
			isTransactionChecked &&
			buyFromDexFlowStep === 'purchase-pending'
		) {
			dispatch(setBuyFromDexFlowStepAction('purchase-success'));
		}
	}, [
		dispatch,
		isLastDexTransactionFulfilled,
		isTransactionChecked,
		buyFromDexFlowStep,
		isCryptoUser,
	]);

	useEffect(() => {
		if (
			!isCryptoUser &&
			isLastDexTransactionFailed &&
			isTransactionChecked &&
			buyFromDexFlowStep === 'purchase-pending'
		) {
			dispatch(setBuyFromDexFlowStepAction('purchase-error'));
		}
	}, [
		dispatch,
		isLastDexTransactionFailed,
		isTransactionChecked,
		buyFromDexFlowStep,
		isCryptoUser,
	]);
};
