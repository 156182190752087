import { AxiosResponse } from 'axios';
import { fireblocksApi } from 'store/api/fireblocks-api/fireblocks.api';
import { FireblocksApiRoute } from 'store/api/fireblocks-api/fireblocks-api-route';
import { TFbStakingDepositsResponse } from 'store/api/fireblocks-api/types/staking/fb-staking-deposits-response.type';
import { IFbStakingRequest } from 'store/api/fireblocks-api/types/staking/fb-staking-request.interface';

export const requestFbStakingDeposits = async (): Promise<TFbStakingDepositsResponse> =>
	await fireblocksApi
		.get<TFbStakingDepositsResponse>(FireblocksApiRoute.StakingDeposits())
		.then((res) => res?.data);

export const requestFbStaking = async ({
	type,
	amount,
}: IFbStakingRequest): Promise<IFbStakingRequest> =>
	await fireblocksApi
		.post<IFbStakingRequest, AxiosResponse<IFbStakingRequest>, IFbStakingRequest>(
			FireblocksApiRoute.StakingDeposits(),
			{
				type,
				amount,
			},
		)
		.then((res) => res?.data);

export const requestFbWithdraw = async ({
	type,
	amount,
}: IFbStakingRequest): Promise<IFbStakingRequest> =>
	await fireblocksApi
		.post<IFbStakingRequest, AxiosResponse<IFbStakingRequest>, IFbStakingRequest>(
			FireblocksApiRoute.StakingUnstake(),
			{
				type,
				amount,
			},
		)
		.then((res) => res?.data);
