import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import MintoLogo from 'components/ui/MintoLogo/MintoLogo';

import { IOuterLayoutProps } from './OuterLayout.types';

import * as S from './OuterLayout.styled';

const OuterLayout: React.FC<IOuterLayoutProps> = ({ hasBackground }) => {
	return (
		<>
			<S.Wrapper $hasBackground={hasBackground}>
				<S.Header>
					<MintoLogo type="full" width="auto" height="24px" />
				</S.Header>
				<S.Container>
					<Suspense fallback={<div></div>}>
						<Outlet />
					</Suspense>
				</S.Container>
			</S.Wrapper>
		</>
	);
};

export default OuterLayout;
