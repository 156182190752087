import { bscContractsConfig, bscTestnetContractsConfig } from 'config/web3/contracts.config';

export type TNetworkName = 'bsc' | 'bsc-testnet';

export type TAccountAddress = `0x${string}`;

export interface ITransactionResponse {
	hash: string;
	amount: string;
}

export enum ContractName {
	UsdtTokenBsc = 'USDTTOKENBSC',
	BusdTokenBsc = 'BUSDTOKENBSC',
	BnbTokenBsc = 'BNBTOKENBSC',
	PresaleBsc = 'PRESALEBSC',
	BtcmtTokenBsc = 'TOKENBSC',
	StakingBsc = 'STAKINGBSC',
	AutofarmV1Bsc = 'AUTOFARMV1BSC',
	AutofarmV2Bsc = 'AUTOFARMV2BSC',
	BtcbTokenBsc = 'BTCBTOKENBSC',
}

export type TBtcmtBscContractConfig =
	| (typeof bscContractsConfig)[ContractName.BtcmtTokenBsc]
	| (typeof bscTestnetContractsConfig)[ContractName.BtcmtTokenBsc];

export type TUsdtBscContractConfig =
	| (typeof bscContractsConfig)[ContractName.UsdtTokenBsc]
	| (typeof bscTestnetContractsConfig)[ContractName.UsdtTokenBsc]
	| (typeof bscTestnetContractsConfig)[ContractName.BusdTokenBsc];

export type TBtcbBscContractConfig =
	| (typeof bscContractsConfig)[ContractName.BtcbTokenBsc]
	| (typeof bscTestnetContractsConfig)[ContractName.BtcbTokenBsc];

export type TPurchaseBscContractConfig =
	| (typeof bscContractsConfig)[ContractName.PresaleBsc]
	| (typeof bscTestnetContractsConfig)[ContractName.PresaleBsc];

export type TContractsWithAllowanceConfig =
	| (typeof bscContractsConfig)[ContractName.UsdtTokenBsc]
	| (typeof bscTestnetContractsConfig)[ContractName.UsdtTokenBsc]
	| (typeof bscContractsConfig)[ContractName.BnbTokenBsc]
	| (typeof bscTestnetContractsConfig)[ContractName.BnbTokenBsc]
	| (typeof bscContractsConfig)[ContractName.BtcbTokenBsc]
	| (typeof bscTestnetContractsConfig)[ContractName.BtcbTokenBsc];

export type TAutofarmV2ContractConfig =
	| (typeof bscContractsConfig)[ContractName.AutofarmV2Bsc]
	| (typeof bscTestnetContractsConfig)[ContractName.AutofarmV2Bsc];

export type TStakingContractConfig =
	| (typeof bscContractsConfig)[ContractName.StakingBsc]
	| (typeof bscTestnetContractsConfig)[ContractName.StakingBsc];
