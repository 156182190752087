import { createSlice } from '@reduxjs/toolkit';
import { TransactionErrorMessage } from 'constants/message/transaction-error-message.const';
import { TFetchStatus } from 'types/api/fetch-status.type';
import { ITransactionResponse } from 'types/blockchain/contracts.types';

import {
	withdrawAutoFarmV2ThunkAction,
	withdrawManualThunkAction,
} from './web3-withdraw.thunk-actions';

export interface IWithdrawState {
	debugErrorMessage: string | null;
	withdrawErrorMessage: string | null;
	withdrawFetchStatus: TFetchStatus;
	withdrawResponse: ITransactionResponse | null;
	isWithdrawSucceed: boolean;
}

const initialState: IWithdrawState = {
	debugErrorMessage: null,
	withdrawErrorMessage: null,
	withdrawFetchStatus: 'initial',
	withdrawResponse: null,
	isWithdrawSucceed: false,
};

const withdrawSlice = createSlice({
	name: 'withdraw',
	initialState,
	reducers: {
		resetWithdrawStateAction: (state) => {
			state.debugErrorMessage = null;
			state.withdrawErrorMessage = null;
			state.withdrawFetchStatus = 'initial';
			state.withdrawResponse = null;
			state.isWithdrawSucceed = false;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(withdrawAutoFarmV2ThunkAction.pending, (state) => {
				state.withdrawFetchStatus = 'pending';
				state.withdrawResponse = null;
			})
			.addCase(withdrawAutoFarmV2ThunkAction.fulfilled, (state, { payload }) => {
				state.withdrawFetchStatus = 'fulfilled';
				state.withdrawResponse = payload;
				state.isWithdrawSucceed = true;
			})
			.addCase(withdrawAutoFarmV2ThunkAction.rejected, (state, { error }) => {
				state.withdrawFetchStatus = 'rejected';
				if (error?.message) {
					state.withdrawErrorMessage = error?.message;
				} else {
					state.withdrawErrorMessage = TransactionErrorMessage.Default;
				}
			})
			.addCase(withdrawManualThunkAction.pending, (state) => {
				state.withdrawFetchStatus = 'pending';
				state.withdrawResponse = null;
			})
			.addCase(withdrawManualThunkAction.fulfilled, (state, { payload }) => {
				state.withdrawFetchStatus = 'fulfilled';
				state.withdrawResponse = payload;
				state.isWithdrawSucceed = true;
			})
			.addCase(withdrawManualThunkAction.rejected, (state, { error }) => {
				state.withdrawFetchStatus = 'rejected';
				if (error?.message) {
					state.withdrawErrorMessage = error?.message;
				} else {
					state.withdrawErrorMessage = TransactionErrorMessage.Default;
				}
			});
	},
});

export const { resetWithdrawStateAction } = withdrawSlice.actions;
export const web3WithdrawReducer = withdrawSlice.reducer;
