import React from 'react';
import { Navigate } from 'react-router-dom';
import { StorageKey } from 'types/storage/storage-key.enum';

import { AppRoute } from 'utils/routes/app-route';

interface IRedirectToLastRouteProps {
	children: React.ReactNode;
}

const nonPersistingRoutes = [
	AppRoute.Login(),
	AppRoute.Registration(),
	AppRoute.Verification(),
	AppRoute.PasswordReset(),
	AppRoute.DeleteAccount(),
	AppRoute.NotFound(),
	AppRoute.More(),
];

const straightByPassPatterns = ['/dao/vote/'];

const RedirectToOuterRoute: React.FC<IRedirectToLastRouteProps> = ({ children }) => {
	const outerRoute = localStorage.getItem(StorageKey.OuterRoute);
	const outerRouteWithoutSlash = outerRoute?.replace(/\/$/, '');

	const appRoutes = Object.values(AppRoute)
		.map((route) => {
			if (route === AppRoute.StakingInner) {
				return [route('manual'), route('auto')];
			}
			return route('manual');
		})
		.flat();

	if (outerRoute && straightByPassPatterns.some((pattern) => outerRoute?.includes(pattern))) {
		return <Navigate to={outerRoute} replace />;
	}

	if (outerRouteWithoutSlash && !appRoutes.includes(outerRouteWithoutSlash)) {
		return <Navigate to={AppRoute.NotFound()} replace />;
	}

	if (
		outerRouteWithoutSlash &&
		appRoutes.includes(outerRouteWithoutSlash) &&
		!nonPersistingRoutes.includes(outerRouteWithoutSlash)
	) {
		return <Navigate to={outerRouteWithoutSlash} replace />;
	}

	return <>{children}</>;
};

export default RedirectToOuterRoute;
