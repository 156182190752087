import { createAsyncThunk } from '@reduxjs/toolkit';
import { readContract } from '@wagmi/core';
import type { TRootState } from 'store/store';
import { ContractName, TNetworkName } from 'types/blockchain/contracts.types';
import { formatEther } from 'viem';

import { bscContractsConfig, bscTestnetContractsConfig } from 'config/web3/contracts.config';

export const requestBtcmtContractPriceThunkAction = createAsyncThunk(
	'contracts/btcmtPrice',
	async (chainName: TNetworkName, { getState }) => {
		const chainId = (getState() as TRootState).chainReducer.chain.id;

		if (!chainId || !chainName) {
			return null;
		}

		let contractConfig;

		switch (chainName) {
			case 'bsc':
				contractConfig = bscContractsConfig[ContractName.PresaleBsc];
				break;
			case 'bsc-testnet':
				contractConfig = bscTestnetContractsConfig[ContractName.PresaleBsc];
				break;
		}

		return await readContract({
			address: contractConfig.address,
			abi: contractConfig.abi,
			functionName: 'price',
			chainId,
		}).then((res) => Number(formatEther(res)));
	},
);

export const requestTotalSoldThunkAction = createAsyncThunk(
	'contracts/totalSold',
	async (chainName: TNetworkName, { getState }) => {
		const chainId = (getState() as TRootState).chainReducer.chain.id;

		if (!chainId || !chainName) {
			return null;
		}

		let contractConfig;

		switch (chainName) {
			case 'bsc':
				contractConfig = bscContractsConfig[ContractName.PresaleBsc];
				break;
			case 'bsc-testnet':
				contractConfig = bscTestnetContractsConfig[ContractName.PresaleBsc];
				break;
		}

		return await readContract({
			address: contractConfig.address,
			abi: contractConfig.abi,
			functionName: 'totalSold',
			chainId: chainId,
		}).then((res) => Number(formatEther(res)));
	},
);

export const requestCapToCellThunkAction = createAsyncThunk(
	'contracts/capToCell',
	async (chainName: TNetworkName, { getState }) => {
		const chainId = (getState() as TRootState).chainReducer.chain.id;

		if (!chainId || !chainName) {
			return null;
		}

		let contractConfig;

		switch (chainName) {
			case 'bsc':
				contractConfig = bscContractsConfig[ContractName.PresaleBsc];
				break;
			case 'bsc-testnet':
				contractConfig = bscTestnetContractsConfig[ContractName.PresaleBsc];
				break;
		}

		return await readContract({
			address: contractConfig.address,
			abi: contractConfig.abi,
			functionName: 'capToSell',
			chainId: chainId,
		}).then((res) => Number(formatEther(res)));
	},
);
