import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { TAutofarmFlowStep } from './types/autofarm-flow.step';
import { TClaimFlowStep } from './types/claim-flow-step.type';
import { IClaimTransactionsStatuses } from './types/claim-transactions-statuses.interface';
import { TStakingFlowStep } from './types/staking-flow-step.type';
import { IStakingTransactionsStatuses } from './types/staking-transactions-statuses.interface';
import {
	checkPendingClaimTransactionsThunkAction,
	checkPendingStakingTransactionsThunkAction,
} from './staking-flow.thunk-actions';

export interface IStakingFlowState {
	debugErrorMessage: string | null;
	stakingFlowStep: TStakingFlowStep | null;
	autoFarmFlowStep: TAutofarmFlowStep | null;
	claimFlowStep: TClaimFlowStep | null;
	shouldCheckStakingTransactions: boolean;
	fbStakingTransactionsCheckStatus: TFetchStatus;
	shouldCheckClaimTransactions: boolean;
	fbClaimTransactionsCheckStatus: TFetchStatus;
	stakingTransactionsStatuses: IStakingTransactionsStatuses;
	claimTransactionsStatuses: IClaimTransactionsStatuses;
}

const initialState: IStakingFlowState = {
	debugErrorMessage: null,
	stakingFlowStep: null,
	autoFarmFlowStep: null,
	claimFlowStep: null,
	shouldCheckStakingTransactions: true,
	fbStakingTransactionsCheckStatus: 'initial',
	shouldCheckClaimTransactions: true,
	stakingTransactionsStatuses: {
		pendingStakeTransactions: [],
		pendingWithdrawTransactions: [],
		failedStakingTransactions: [],
	},
	fbClaimTransactionsCheckStatus: 'initial',
	claimTransactionsStatuses: {
		pendingClaimTransactions: [],
		failedClaimTransactions: [],
	},
};

const stakingFlowSlice = createSlice({
	name: 'staking-flow',
	initialState,
	reducers: {
		setStakingFlowStepAction: (state, action: PayloadAction<TStakingFlowStep | null>) => {
			state.stakingFlowStep = action.payload;
		},
		setAutoFarmFlowStepAction: (state, action: PayloadAction<TAutofarmFlowStep | null>) => {
			state.autoFarmFlowStep = action.payload;
		},
		setClaimFlowStepAction: (state, action: PayloadAction<TClaimFlowStep | null>) => {
			state.claimFlowStep = action.payload;
		},
		setShouldCheckStakingTransactionsAction: (state, action: PayloadAction<boolean>) => {
			state.shouldCheckStakingTransactions = action.payload;
			state.fbStakingTransactionsCheckStatus = 'initial';
		},
		setShouldCheckClaimTransactionsAction: (state, action: PayloadAction<boolean>) => {
			state.shouldCheckClaimTransactions = action.payload;
			state.fbClaimTransactionsCheckStatus = 'initial';
		},
		resetStakingFlowStateAction: (state) => {
			state.stakingFlowStep = null;
			state.fbStakingTransactionsCheckStatus = 'initial';
			state.shouldCheckStakingTransactions = true;
			state.stakingFlowStep = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(checkPendingStakingTransactionsThunkAction.pending, (state) => {
				state.fbStakingTransactionsCheckStatus = 'pending';
				state.shouldCheckStakingTransactions = true;
			})
			.addCase(checkPendingStakingTransactionsThunkAction.fulfilled, (state, { payload }) => {
				state.fbStakingTransactionsCheckStatus = 'fulfilled';
				state.stakingTransactionsStatuses = payload;
				if (
					payload.pendingStakeTransactions.length === 0 &&
					payload.pendingWithdrawTransactions.length === 0
				) {
					state.shouldCheckStakingTransactions = false;
				}
			})
			.addCase(checkPendingStakingTransactionsThunkAction.rejected, (state, { error }) => {
				state.fbStakingTransactionsCheckStatus = 'rejected';
				state.shouldCheckStakingTransactions = false;
				if (error.message) {
					state.debugErrorMessage = error.message;
				}
			})
			.addCase(checkPendingClaimTransactionsThunkAction.pending, (state) => {
				state.fbClaimTransactionsCheckStatus = 'pending';
				state.shouldCheckClaimTransactions = true;
			})
			.addCase(checkPendingClaimTransactionsThunkAction.fulfilled, (state, { payload }) => {
				state.fbClaimTransactionsCheckStatus = 'fulfilled';
				state.claimTransactionsStatuses = payload;
				if (payload.pendingClaimTransactions.length === 0) {
					state.shouldCheckClaimTransactions = false;
				}
			})
			.addCase(checkPendingClaimTransactionsThunkAction.rejected, (state, { error }) => {
				state.fbClaimTransactionsCheckStatus = 'rejected';
				state.shouldCheckClaimTransactions = false;
				if (error.message) {
					state.debugErrorMessage = error.message;
				}
			});
	},
});

export const {
	setStakingFlowStepAction,
	setAutoFarmFlowStepAction,
	setClaimFlowStepAction,
	setShouldCheckStakingTransactionsAction,
	setShouldCheckClaimTransactionsAction,
} = stakingFlowSlice.actions;
export const stakingFlowReducer = stakingFlowSlice.reducer;
