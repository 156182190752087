import { ratesApi } from './rates-api';
import { RatesApiRoute } from './rates-api.route';
import { IApiRatesResponse } from './rates-api-response.type';
import { TRatesPairSymbol } from './rates-api-symbols.type';

export const requestPairRate = async (pairSymbol: TRatesPairSymbol): Promise<string | undefined> =>
	await ratesApi
		.get<IApiRatesResponse>(RatesApiRoute.PairRate(), {
			params: {
				symbol: pairSymbol,
			},
		})
		.then((res) => res?.data?.rate);
