import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { estimateGasThunkAction } from 'store/gas-reducer/gas.thunk-actions';
import {
	selectAutoFarmFlowStep,
	selectClaimFlowStep,
	selectStakingFlowStep,
} from 'store/staking-reducers/staking-flow-reducer/staking-flow.selectors';
import { useAppDispatch } from 'store/store';

export const useStakingGasEstimation = (): void => {
	const dispatch = useAppDispatch();
	const stakingFlowStep = useSelector(selectStakingFlowStep);
	const autoFarmFlowStep = useSelector(selectAutoFarmFlowStep);
	const claimFlowStep = useSelector(selectClaimFlowStep);

	useLayoutEffect(() => {
		switch (stakingFlowStep) {
			case 'stake-simple-form':
			case 'stake-combined-form':
				void dispatch(estimateGasThunkAction('STAKE'));
				break;
			case 'withdraw-form':
				void dispatch(estimateGasThunkAction('WITHDRAW'));
				break;
		}
	}, [stakingFlowStep, dispatch]);

	useLayoutEffect(() => {
		switch (autoFarmFlowStep) {
			case 'stake-simple-form':
			case 'stake-combined-form':
				void dispatch(estimateGasThunkAction('STAKE'));
				break;
			case 'withdraw-form':
				void dispatch(estimateGasThunkAction('WITHDRAW'));
				break;
		}
	}, [autoFarmFlowStep, dispatch]);

	useLayoutEffect(() => {
		if (claimFlowStep === 'claim-form') {
			void dispatch(estimateGasThunkAction('CLAIM'));
		}
	}, [claimFlowStep, dispatch]);
};
