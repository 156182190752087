import { MutableRefObject } from 'react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { FirebaseErrorMessage } from 'constants/message/firebase-error-message.const';
import { AuthError, MultiFactorError } from 'firebase/auth';
import {
	handleMfaError,
	registerByPassword,
	requestSignOut,
	sendFirebasePasswordResetEmail,
	setGooglePersistence,
	setLoginWithPasswordPersistence,
} from 'store/api/firebase-api/firebase.service';
import { IFirebaseUser } from 'store/api/firebase-api/types/firebase-user.interface';
import { ILoginFormValues } from 'types/forms/login-form-values.interface';

import { isIOS } from 'utils/detectors/detectors.utils';
import { getFirebaseErrorMessage } from 'utils/error-message/get-firebase-error-message.util';
import { notifyError } from 'utils/notify/notify.utils';

import { setUserAuthStatusAction } from './auth.reducer';

export const registerWithEmailThunkAction = createAsyncThunk(
	'auth/registerByPassword',
	async (values: ILoginFormValues): Promise<IFirebaseUser> => {
		return await registerByPassword(values).catch((error: AuthError) => {
			notifyError(getFirebaseErrorMessage(error));
			throw error;
		});
	},
);

export const registerWithGoogleThunkAction = createAsyncThunk(
	'auth/singInWithGoogle',
	async (): Promise<void> => {
		return await setGooglePersistence().catch((error: AuthError) => {
			if (error?.code !== 'auth/popup-blocked' && isIOS()) {
				notifyError(getFirebaseErrorMessage(error));
			}
			throw error;
		});
	},
);

export const loginWithPasswordThunkAction = createAsyncThunk(
	'auth/loginByPassword',
	async (
		{
			values,
			errorHandlerRef,
		}: {
			values: ILoginFormValues;
			errorHandlerRef: MutableRefObject<AsyncGenerator<string | undefined, void, string> | null>;
		},
		{ dispatch },
	): Promise<void> => {
		await setLoginWithPasswordPersistence(values).catch(async (error: MultiFactorError) => {
			if (error.message === FirebaseErrorMessage.MfaRequired) {
				dispatch(setUserAuthStatusAction('otp-required'));
				errorHandlerRef.current = handleMfaError({
					error,
					onSuccessCb: () => dispatch(setUserAuthStatusAction('verified-no-wallet')),
					onErrorCb: () => dispatch(setUserAuthStatusAction('anonymous')),
				});
				await errorHandlerRef.current?.next();
			} else {
				notifyError(getFirebaseErrorMessage(error));
				throw error;
			}
		});
	},
);

export const signInWithGoogleThunkAction = createAsyncThunk(
	'auth/singInWithGoogle',
	async (
		{
			errorHandlerRef,
		}: {
			errorHandlerRef: MutableRefObject<AsyncGenerator<string | undefined, void, string> | null>;
		},
		{ dispatch },
	): Promise<void> => {
		return await setGooglePersistence().catch(async (error: MultiFactorError) => {
			if (error.message === FirebaseErrorMessage.MfaRequired) {
				dispatch(setUserAuthStatusAction('otp-required'));
				errorHandlerRef.current = handleMfaError({
					error,
					onSuccessCb: () => dispatch(setUserAuthStatusAction('verified-no-wallet')),
					onErrorCb: () => dispatch(setUserAuthStatusAction('anonymous')),
				});
				await errorHandlerRef.current?.next();
			} else {
				if (error?.code !== 'auth/popup-blocked' && isIOS()) {
					notifyError(getFirebaseErrorMessage(error));
				}
				throw error;
			}
		});
	},
);

export const requestPasswordResetThunkAction = createAsyncThunk(
	'auth/password-reset',
	async (email: string, { dispatch }): Promise<void> =>
		await sendFirebasePasswordResetEmail(email)
			.then(() => {
				dispatch(setUserAuthStatusAction('reset-email-sent'));
			})
			.catch((error: AuthError) => {
				notifyError(getFirebaseErrorMessage(error));
				throw error;
			}),
);

export const requestSignOutThunkAction = createAsyncThunk('auth/sign-out', async () =>
	requestSignOut().catch((error: AuthError) => {
		notifyError(getFirebaseErrorMessage(error));
		throw error;
	}),
);
