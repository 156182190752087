export const CopyTextMessage = {
	Success: 'Copied to clipboard',
	Error: 'Error, unsupported operation',
};

export const ShareTextMessage = {
	Success: 'Wallet address successfully shared',
	Cancel: 'Sharing canceled',
	Error: 'Error, unsupported operation',
};
