import React, { useCallback, useRef } from 'react';
import { ReactComponent as CopyIcon } from 'assets/icons/copy-small.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
import { useInnerWidth } from 'hooks/useInnerWidth';
import { useNavigatorCapabilitiesCheck } from 'hooks/useNavigatorCapabilitiesCheck';
import { useQrCode } from 'hooks/useQrCode';
import { BreakPoint } from 'styles/style-variables/breakpoints';
import { QrRectangleSize } from 'styles/style-variables/sizes';

import Button from 'components/ui/Button';
import { copyText } from 'utils/common/copy-text';
import { shareText } from 'utils/common/share-text';

import * as S from './QrPopupContent.styled';

interface IQrPopupContentProps {
	walletAddress: string;
}

const QrPopupContent: React.FC<IQrPopupContentProps> = ({ walletAddress }) => {
	const qrCodeRef = useRef(null);

	const innerWidth = useInnerWidth();

	const { isCopyingAvailable, isSharingAvailable } = useNavigatorCapabilitiesCheck();

	const handleCopyButtonClick = useCallback(() => copyText(walletAddress), [walletAddress]);

	const handleShareButtonClick = useCallback(() => shareText(walletAddress), [walletAddress]);

	useQrCode({
		qrCodeRef,
		qrCodeValue: walletAddress,
		qrRectangleSize:
			innerWidth <= parseInt(BreakPoint.MobileTop) ? QrRectangleSize.Medium : QrRectangleSize.Small,
	});

	return (
		<S.ContentWrapper>
			<S.QRCodeContainer ref={qrCodeRef} />
			<S.WalletAddress>{walletAddress}</S.WalletAddress>
			{(isCopyingAvailable || isSharingAvailable) && (
				<S.ButtonsWrapper>
					{isCopyingAvailable && (
						<Button
							variant="alter-light"
							text="Copy"
							icon={<CopyIcon />}
							onClick={handleCopyButtonClick}
						/>
					)}
					{isSharingAvailable && (
						<Button
							variant="alter-light"
							text="Share"
							icon={<ShareIcon />}
							onClick={handleShareButtonClick}
						/>
					)}
				</S.ButtonsWrapper>
			)}
		</S.ContentWrapper>
	);
};

export default QrPopupContent;
