import { AxiosResponse } from 'axios';
import { fireblocksApi } from 'store/api/fireblocks-api/fireblocks.api';
import { FireblocksApiRoute } from 'store/api/fireblocks-api/fireblocks-api-route';
import { TTransactionFilterType } from 'store/api/fireblocks-api/types/transactions/history/transaction-filter-type.type';
import { ITransactionHistoryResponse } from 'store/api/fireblocks-api/types/transactions/history/transaction-history-response.interface';

export const requestTransactionsHistory = async (
	filterType: TTransactionFilterType,
): Promise<ITransactionHistoryResponse> =>
	await fireblocksApi
		.get<
			ITransactionHistoryResponse,
			AxiosResponse<ITransactionHistoryResponse>
		>(FireblocksApiRoute.TransactionHistory(), { params: { filter_type: filterType } })
		.then((res) => res?.data);
