import React from 'react';
import { ReactComponent as MoonIcon } from 'assets/icons/moon.svg';
import { ReactComponent as SunIcon } from 'assets/icons/sun.svg';
import { TThemeMode } from 'types/theme-mode/theme-mode.type';

import * as S from './ThemeToggle.styled';

interface IThemeToggleProps {
	currentTheme: TThemeMode;
	handleThemeChange: (theme: TThemeMode) => void;
	className?: string;
}

export const ThemeToggle: React.FC<IThemeToggleProps> = ({
	currentTheme,
	handleThemeChange,
	className,
}) => {
	const isThemeLight = currentTheme === 'light';
	const isThemeDark = currentTheme === 'dark';
	const alterTheme = isThemeLight ? 'dark' : 'light';

	return (
		<S.ThemeToggle className={className}>
			<S.ThemeButton $isActive={isThemeLight} onClick={() => handleThemeChange('light')}>
				<SunIcon />
			</S.ThemeButton>
			<S.ToggleSwitchButton
				$isDarkMode={isThemeDark}
				onClick={() => handleThemeChange(alterTheme)}
			/>
			<S.ThemeButton $isActive={isThemeDark} onClick={() => handleThemeChange('dark')}>
				<MoonIcon />
			</S.ThemeButton>
		</S.ThemeToggle>
	);
};
