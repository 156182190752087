import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISendTransferResponse } from 'store/api/fireblocks-api/types/transfers/send/send-transfer-response.interface';
import { TFetchStatus } from 'types/api/fetch-status.type';
import { TSendStep } from 'types/transactions/send-step.type';

import { requestSendTransferThunkAction } from './transfers.thunk-actions';

export interface ITransfersState {
	debugError: string | null;
	sendTransferResponse: ISendTransferResponse | null;
	sendFlowStep: TSendStep;
	sendFetchStatus: TFetchStatus;
	shouldCheckForPendingTx: boolean;
}

const initialState: ITransfersState = {
	debugError: null,
	sendFetchStatus: 'initial',
	sendTransferResponse: null,
	sendFlowStep: 'initial',
	shouldCheckForPendingTx: false,
};

const transfersSlice = createSlice({
	name: 'transfers',
	initialState,
	reducers: {
		setSendFlowStepAction: (state, action: PayloadAction<TSendStep>) => {
			state.sendFlowStep = action.payload;
		},
		setShouldCheckForPendingTransfersAction: (state, action: PayloadAction<boolean>) => {
			state.shouldCheckForPendingTx = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(requestSendTransferThunkAction.pending, (state) => {
				state.sendFetchStatus = 'pending';
				state.debugError = null;
			})
			.addCase(requestSendTransferThunkAction.fulfilled, (state, { payload }) => {
				state.sendFetchStatus = 'fulfilled';
				if (payload) {
					state.sendTransferResponse = payload;
				}
			})
			.addCase(requestSendTransferThunkAction.rejected, (state, { error }) => {
				state.sendFetchStatus = 'rejected';
				state.debugError = JSON.stringify(error);
			});
	},
});

export const { setSendFlowStepAction, setShouldCheckForPendingTransfersAction } =
	transfersSlice.actions;
export const transfersReducer = transfersSlice.reducer;
