import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import { ReactComponent as BackIcon } from 'assets/icons/arrow-back.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';

import * as S from './HeaderWithButtons.styled';

interface IContentHeaderProps
	extends Partial<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>> {
	titleText?: string | null;
	handleBackClick?: (() => void) | null;
	handleCrossButtonClick?: (() => void) | null;
}

const HeaderWithButtons: React.FC<IContentHeaderProps> = ({
	titleText,
	handleBackClick,
	handleCrossButtonClick,
	className,
}) => {
	return (
		<S.HeaderWrapper className={className}>
			{handleBackClick && (
				<S.BackButton variant="borderless" icon={<BackIcon />} onClick={handleBackClick} />
			)}
			{titleText && <S.HeaderText as="h2">{titleText}</S.HeaderText>}
			{handleCrossButtonClick && (
				<S.CrossButton variant="borderless" icon={<CrossIcon />} onClick={handleCrossButtonClick} />
			)}
		</S.HeaderWrapper>
	);
};

export default HeaderWithButtons;
