import { Dispatch } from 'react';
import { Action } from '@reduxjs/toolkit';
import { prepareWriteContract } from '@wagmi/core';
import { FallbackString } from 'constants/fallback-string/fallback-string.const';
import { TransactionErrorMessage } from 'constants/message/transaction-error-message.const';
import fromExponential from 'from-exponential';
import { updateDebugInfoAction } from 'store/debug-reducer/debug.reducer';
import { TGetStateFn } from 'store/store';
import { handlePreparationError } from 'store/utils/error-report/error-report.utils';
import { TAccountAddress, TStakingContractConfig } from 'types/blockchain/contracts.types';

import { writeContractFromConfig } from 'utils/web3/web3.utils';

export const processClaimReward = async ({
	accountAddress,
	chainId,
	amount,
	stakingContractConfig,
	dispatch,
	getState,
}: {
	accountAddress: TAccountAddress;
	chainId: number;
	amount: string;
	stakingContractConfig: TStakingContractConfig;
	dispatch: Dispatch<Action>;
	getState: TGetStateFn;
}): Promise<{ hash: string; amount: string }> => {
	dispatch(
		updateDebugInfoAction({
			contractAddress: stakingContractConfig.address,
			contractName: stakingContractConfig.name,
			contractMethod: 'withdrawRewardAll',
			transactionAmount: fromExponential(amount),
			assetAvailableAmount: fromExponential(amount),
			assetName: 'BTCB',
			assetAllowance: FallbackString.NotRequired,
		}),
	);
	const config = await prepareWriteContract({
		address: stakingContractConfig.address,
		abi: stakingContractConfig.abi,
		functionName: 'withdrawRewardAll',
		chainId,
		account: accountAddress,
	}).catch((error: Error) => {
		return handlePreparationError({
			getState,
			dispatch,
			error,
			displayedMessage: TransactionErrorMessage.InternalError,
		});
	});

	return writeContractFromConfig({
		config,
		chainId,
		dispatch,
		errorMessage: TransactionErrorMessage.ClaimFailed,
	}).then((hash) => {
		return { hash, amount };
	});
};
