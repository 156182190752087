import React from 'react';
import { ReactComponent as Clock } from 'assets/icons/header/clock.svg';

import { SHeaderSpinnerDarkMode } from 'components/styled/SHeaderSpinnerDarkMode';
import { SHeaderSpinnerLightMode } from 'components/styled/SHeaderSpinnerLightMode';

import { ITransactionsCounterProps } from './TransactionsCounter.types';

import * as S from './TransactionsCounter.styled';

const TransactionsCounter: React.FC<ITransactionsCounterProps> = ({
	pendingTransactionsCount,
	pendingStakeTransactions,
	pendingWithdrawTransactions,
	pendingClaimTransactions,
	pendingDexTransactionsCount,
	currentTheme,
}) => {
	const hintText = `${pendingTransactionsCount ? `Pending transactions: ${pendingTransactionsCount}\n` : ''}${pendingStakeTransactions?.length ? `Staking: ${pendingStakeTransactions.join(', ')}\n` : ''}${pendingWithdrawTransactions?.length ? `Withdrawing: ${pendingWithdrawTransactions.join(', ')}\n` : ''}${pendingClaimTransactions?.length ? `Rewards: ${pendingClaimTransactions[0]}\n` : ''}${pendingDexTransactionsCount ? 'Dex purchase: 1' : ''}`;

	return (
		<S.Wrapper title={hintText}>
			{currentTheme === 'dark' ? <SHeaderSpinnerDarkMode /> : <SHeaderSpinnerLightMode />}
			<S.TransactionsBlock>
				<Clock />
				<S.Value $isZero={pendingTransactionsCount === 0}>{pendingTransactionsCount}</S.Value>
			</S.TransactionsBlock>
		</S.Wrapper>
	);
};

export default TransactionsCounter;
