import { createSlice } from '@reduxjs/toolkit';
import { IVotesResponseItem } from 'store/api/snapshot-api/types/response/votes-response-item.interface';
import { TFetchStatus } from 'types/api/fetch-status.type';
import { IDaoProposal } from 'types/dao/dao-vote.interface';

import {
	refreshProposalAndVotesThunkAction,
	requestProposalAndVotesThunkAction,
	requestProposalsAndVpThunkAction,
} from './dao-read.thunk-actions';
import { castWeb2VoteThunkAction, castWeb3VoteThunkAction } from './dao-vote.thunk-actions';

export interface IDaoState {
	outerDataStatus: TFetchStatus;
	innerDataStatus: TFetchStatus;
	voteCastingStatus: TFetchStatus;
	debugError: string | null;
	proposals: IDaoProposal[] | null;
	votingPower: number | null;
	currentProposal: IDaoProposal | null;
	currentVotes: IVotesResponseItem[] | null;
	isVotingPowerDelegated: boolean;
}

const initialState: IDaoState = {
	outerDataStatus: 'initial',
	innerDataStatus: 'initial',
	voteCastingStatus: 'initial',
	debugError: null,
	proposals: null,
	votingPower: null,
	currentProposal: null,
	currentVotes: null,
	isVotingPowerDelegated: false,
};

const daoSlice = createSlice({
	name: 'dao',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(requestProposalsAndVpThunkAction.pending, (state) => {
				state.outerDataStatus = 'pending';
				state.proposals = null;
				state.votingPower = null;
				state.debugError = null;
			})
			.addCase(requestProposalsAndVpThunkAction.fulfilled, (state, { payload }) => {
				state.outerDataStatus = 'fulfilled';
				if (payload) {
					state.proposals = payload.proposals;
					state.votingPower = payload.vp;
					state.isVotingPowerDelegated = payload.isVotingPowerDelegated;
				}
			})
			.addCase(requestProposalsAndVpThunkAction.rejected, (state, { error }) => {
				state.outerDataStatus = 'rejected';
				state.debugError = JSON.stringify(error);
			})
			.addCase(requestProposalAndVotesThunkAction.pending, (state) => {
				state.innerDataStatus = 'pending';
				state.currentProposal = null;
				state.currentVotes = null;
				state.debugError = null;
			})
			.addCase(requestProposalAndVotesThunkAction.fulfilled, (state, { payload }) => {
				state.innerDataStatus = 'fulfilled';
				if (payload) {
					state.currentProposal = payload.proposal;
					state.currentVotes = payload.votes;
				}
			})
			.addCase(requestProposalAndVotesThunkAction.rejected, (state, { error }) => {
				state.innerDataStatus = 'rejected';
				state.debugError = JSON.stringify(error);
			})
			.addCase(castWeb3VoteThunkAction.pending, (state) => {
				state.voteCastingStatus = 'pending';
			})
			.addCase(castWeb3VoteThunkAction.fulfilled, (state) => {
				state.voteCastingStatus = 'fulfilled';
			})
			.addCase(castWeb3VoteThunkAction.rejected, (state) => {
				state.voteCastingStatus = 'rejected';
			})
			.addCase(castWeb2VoteThunkAction.pending, (state) => {
				state.voteCastingStatus = 'pending';
			})
			.addCase(castWeb2VoteThunkAction.fulfilled, (state) => {
				state.voteCastingStatus = 'fulfilled';
			})
			.addCase(castWeb2VoteThunkAction.rejected, (state) => {
				state.voteCastingStatus = 'rejected';
			})
			.addCase(refreshProposalAndVotesThunkAction.pending, (state) => {
				state.debugError = null;
			})
			.addCase(refreshProposalAndVotesThunkAction.fulfilled, (state, { payload }) => {
				if (payload) {
					state.currentProposal = payload.proposal;
					state.currentVotes = payload.votes;
				}
			})
			.addCase(refreshProposalAndVotesThunkAction.rejected, (state, { error }) => {
				state.debugError = JSON.stringify(error);
			});
	},
});

export const daoReducer = daoSlice.reducer;
