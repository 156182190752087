import { DefaultTheme } from 'styled-components';
import { MintoLogoColor } from 'styles/style-variables/colors';

export const darkTheme: DefaultTheme = {
	name: 'dark',
	globals: {
		frameBgColor: '#1a2a2a',
		contentBgColor: '#1a2121',
		outerBgColor: '#1a2121',
		textColor: '#FFFFFF',
	},
	components: {
		votingForm: {
			title: {
				fontColor: '#ffffff',
			},
			choiceButton: {
				default: {
					fontColor: '#6ddac0',
					bgColor: '#1c342f',
				},
				hoverFocus: {
					fontColor: '#6ddac0',
					bgColor: '#2d4a44',
				},
				selected: {
					fontColor: '#6ddac0',
					bgColor: '#2d4a44',
					borderColor: '#6ddac0',
				},
			},
		},
		feeData: {
			iconsFill: '#BDE2D3',
			title: '#BDE2D3',
			usdAmount: '#BDE2D3',
		},
		button: {
			primary: {
				fontColor: {
					default: '#1A2121',
					active: '#1A2121',
					hoverFocus: '#1A2121',
					disabled: '#1A2121',
				},
				bgColor: {
					default: '#6DDAC0',
					hoverFocus: '#00DBA1',
					active: '#137A63',
					disabled: '#2D4A44',
				},
			},
			alter: {
				fontColor: {
					default: '#6DDAC0',
					hoverFocus: '#1A2121',
					active: '#1A2121',
					disabled: '#1A2121',
				},
				bgColor: {
					default: '#1A2121',
					hoverFocus: '#00DBA1',
					active: '#137A63',
					disabled: '#2D4A44',
				},
			},
			alterLight: {
				fontColor: {
					default: '#6DDAC0',
					hoverFocus: '#1A2121',
					active: '#1A2121',
					disabled: '#1A2121',
				},
				bgColor: {
					default: '#1C342F',
					hoverFocus: '#00DBA1',
					active: '#137A63',
					disabled: '#2D4A44',
				},
			},
			outlined: {
				fontColor: {
					default: '#137A63',
					active: '#137A63',
					hoverFocus: '#00DBA1',
					disabled: '#2D4A44',
				},
				borderColor: {
					default: '#6DDAC0',
					active: '#137A63',
					hoverFocus: '#00DBA1',
					disabled: '#2D4A44',
				},
			},
			borderless: {
				fontColor: {
					default: '#6DDAC0',
					active: '#137A63',
					hoverFocus: '#00DBA1',
					disabled: '#2D4A44',
				},
			},
			authOption: {
				fontColor: {
					default: '#ffffff',
					hoverFocus: '#ffffff',
					active: '#ffffff',
					disabled: '#1C342F',
				},
				bgColor: {
					default: '#1C342F',
					mobile: '#1A2A2A',
					hoverFocus: '#2D4A44',
					active: '#1C342F',
					disabled: '#2D4A44',
				},
			},
		},
		supportLink: {
			fontColor: {
				default: '#6DDAC0',
				hoverFocus: '#1A2121',
				active: '#1A2121',
				disabled: '#1A2121',
			},
			bgColor: {
				default: '#1C342F',
				hoverFocus: '#00DBA1',
				active: '#137A63',
				disabled: '#2D4A44',
			},
		},
		input: {
			fontColor: {
				mainText: {
					default: '#F3F8F6',
					disabled: '#BDE2D3',
				},
				placeholder: {
					default: '#BDE2D3',
					disabled: '#BDE2D3',
				},
				rightText: {
					default: '#6DDAC0',
					alter: '#137A63',
					disabled: '#BDE2D3',
				},
				leftText: {
					default: '#BDE2D3',
					disabled: '#BDE2D3',
				},
				errorText: '#EC494F',
				labelText: '#BDE2D3',
			},
			borderColor: {
				default: '#137A63',
				activeFocus: '#00DBA1',
				disabled: '#1C342F',
				invalid: '#EC494F',
			},
			bgColor: {
				default: '#1A2121',
				activeFocus: '#1A2121',
				disabled: '#1A2A2A',
			},
		},
		outerForegroundWrapper: {
			bgColor: {
				mobile: 'transparent',
				desktop: '#1A2A2A',
			},
			fontColor: {
				alter: '#BDE2D3',
			},
		},
		innerForegroundWrapper: {
			bgColor: {
				mobile: 'transparent',
				desktop: '#1A2A2A',
			},
			fontColor: {
				alter: '#BDE2D3',
			},
		},
		mfaForm: {
			fontColor: {
				labelText: '#bde2d3',
				titleText: '#ffffff',
			},
		},
		forgotPasswordForm: {
			fontColor: {
				labelText: '#bde2d3',
				errorMessage: '#EC494F',
			},
		},
		comparePasswordForm: {
			fontColor: {
				labelText: '#bde2d3',
				errorMessage: '#EC494F',
			},
		},
		mintoLogo: {
			full: {
				desktop: MintoLogoColor.FullLogoLight,
				mobile: MintoLogoColor.FullLogoLight,
			},
			icon: {
				desktop: MintoLogoColor.IconLogoLightDesktop,
				mobile: MintoLogoColor.IconLogoLightMobile,
			},
		},
		header: {
			balance: {
				bgColor: '#1C342F',
				iconColor: '#137A63',
			},
		},
		mainMenu: {
			bgColor: {
				primary: '#1A2A2A',
				alter: '#1A2A2A',
			},
			menuLink: {
				fontColor: {
					default: '#e1ebe7',
					active: '#6DDAC0',
				},
				iconFill: {
					default: '#BDE2D3',
					active: '#6DDAC0',
				},
			},
			themeToggle: {
				bgColor: '#137a63',
				fgColor: '#1a2a2a',
				valueColor: {
					default: '#2D4A44',
					active: '#137A63',
				},
			},
			shadow: '0 -16px 48px 0 rgb(0 0 0 / 30%)',
		},
		toast: {
			bgColor: {
				success: '#BDE2D3',
				warning: '#DAF770',
				error: '#EC494F',
			},
			fontColor: {
				success: '#1A2121',
				warning: '#1A2121',
				error: '#FFFFFF',
			},
		},
		switchButton: {
			bgColor: '#6ddac0',
			bgColorSlider: '#383838',
			fontColorSelected: '#6DDAC0',
			fontColorNotSelected: '#FFFFFF',
		},
		hint: {
			tooltipButton: {
				default: '#137A63',
				hoverFocus: '#00dba1',
				active: '#137a63',
				disabled: '#2d4a44',
			},
			tooltip: {
				boxShadow: '0px 16px 32px 0px rgba(0, 0, 0, 0.15)',
				bgColor: '#2d4a44',
				fontColor: '#FFFFFF',
			},
			closeButton: {
				default: '#6DDAC0',
				hoverFocus: '#00dba1',
				active: '#137a63',
			},
		},
		rewardsHistory: {
			bgColor: '#1a2a2a',
			fontColor: {
				rewardsDate: '#6ca095',
				rewardsTitle: '#ffffff',
				totalCount: '#ffffff',
				balance: '#6ca095',
			},
		},
		rewardsHistoryTitle: {
			fontColor: '#ffffff',
		},
		rewardsHistorySubTitle: {
			fontColor: {
				primary: '#bde2d3',
				secondary: '#ffffff',
			},
		},
		rewardsHistoryLink: {
			fontColor: {
				default: '#6ddac0',
				hoverFocus: '#1a2121',
				active: '#1a2121',
			},
			bgColor: {
				default: '#1a2121',
				hoverFocus: '#00dba1',
				active: '#137a63',
			},
		},
		walletAddressWithButtons: {
			bgColor: '#1a2121',
			fontColor: '#6ca095',
		},
		qrPopupContent: {
			bgColor: '#1a2a2a',
			qrContainerBgColor: '#bde2d3',
			walletAddress: {
				bgColor: '#2d4a44',
				fontColor: '#bde2d3',
			},
		},
		currencyWithChain: {
			chain: {
				fontColor: '#bde2d3',
				bgColorLight: '#2d4a44',
				bgColorDark: '#1C342F',
			},
		},
		datePicker: {
			bgColor: '#1A2121',
			shadow: 'rgba(0, 0, 0, 0.3)',
			components: {
				overlay: {
					bgColor: 'rgba(19, 21, 21, 0.8)',
				},
				header: {
					bgColor: '#1A2121',
					borderColor: '#1c342f',
				},
				changeMonthButtonIcon: {
					fill: '#1A2121',
				},
				weekDayName: {
					fontColor: '#2D4A44',
				},
				dayName: {
					fontColor: '#BDE2D34D',
				},
				dayNumber: {
					fontColor: '#F3F8F6',
				},
				currentMonth: {
					fontColor: '#BDE2D3',
				},
				dayOutsideMonth: {
					fontColor: '#BDE2D34D',
				},
				dayToday: {
					fontColor: '#6DDAC0',
				},
				dayInRange: {
					fontColor: '#ffffff',
					bgColor: '#1C342F',
				},
				dayRange: {
					fontColor: '#6ddac0',
					bgColor: {
						primary: '#137a63',
						secondary: '#1c342f',
					},
				},
				footer: {
					borderColor: '#1c342f',
				},
				closeButton: {
					iconFill: '#BDE2D3',
				},
			},
		},
		pagination: {
			components: {
				divider: {
					fontColor: '#137a63',
				},
				button: {
					bgColor: {
						default: '#1A2A2A',
						current: '#137A63',
						hoverFocus: '#137A63',
						active: '#1C342F',
						disabled: '#1A2A2A',
					},
					fontColor: {
						default: '#6DDAC0',
						current: '#1A2121',
						hoverFocus: '#1A2121',
						disabled: '#1A2A2A',
					},
				},
				icon: {
					fill: {
						default: '#6ddac0',
						disabled: '#2D4A44',
					},
				},
			},
		},
		loaderDot: {
			bgColor: '#BDE2D3',
		},
		errorElement: {
			bgColor: '#1a2a2a',
			fontColor: '#bde2d3',
		},
		select: {
			menu: {
				bgColor: '#1c342f',
				menuButton: {
					bgColor: {
						hoverFocus: '#2d4a44',
					},
					fontColor: {
						default: '#ffffff',
						active: '#6ddac0',
						disabled: '#2d4a44',
						chainDisabled: '#1a2121',
					},
				},
			},
		},
		transactionStatus: {
			completed: '#9df29a',
			pending: '#daf770',
			expired: '#ec494f',
		},
		transactionsCounter: {
			fontColor: {
				isZero: '#6CA095',
				default: '#ffffff',
			},
			bgColor: {
				default: '#1c342f',
			},
		},
		stakingForm: {
			bgColor: '#1a2a2a',
			iconsFill: '#BDE2D3',
			walletData: {
				bgColor: '#1c342f',
				addressColor: '#bde2d3',
				chain: {
					fontColor: '#bde2d3',
					bgColor: '#2d4a44',
				},
				labelColor: '#bde2d3',
			},
			radio: {
				bgColor: '#1c342f',
				borderColor: {
					default: '#1c342f',
					hoverFocus: '#6ddac0',
				},
				radioBoxColor: '#6ddac0',
				stakingTitleColor: '#bde2d3',
				stakingDataColor: '#ffffff',
			},
		},
		withdrawForm: {
			bgColor: '#1a2a2a',
			iconsFill: '#BDE2D3',
			stakingData: {
				bgColor: '#2d4a44',
				labelColor: '#bde2d3',
			},
			tokenStandard: {
				fontColor: '#BDE2D3',
				bgColor: '#1c342f',
			},
		},
		firstRewardsCongratsBanner: {
			bgColor: '#1a2a2a',
			fontColor: {
				title: '#bde2d3',
				subTitle: '#bde2d3',
				assetsTitle: '#ffffff',
				usdtAmount: '#ffffff',
				tokenAmount: '#ffffff',
			},
		},
		underConstruction: {
			iconFillColor: '#1A2121',
			bgColor: '#1a2a2a',
			fontColor: '#bde2d3',
		},
		networkWarning: {
			borderColor: '#ec494f',
			fontColor: '#ffffff',
		},
		transactionErrorContent: {
			bgColor: '#1a2a2a',
			fontColor: '#bde2d3',
			supportButton: {
				iconFill: {
					default: '#6ddac0',
					hoverFocus: '#1a2121',
					active: '#1a2121',
				},
			},
			retryButton: {
				iconStroke: {
					default: '#1A2121',
				},
			},
		},
		transactionPendingContent: {
			infoText: {
				fontColor: '#bde2d3',
			},
			spinnerBlock: {
				fontColor: '#bde2d3',
				bgColor: '#1a2a2a',
				alterBgColor: '#1a2121',
			},
			canCloseBlock: {
				fontColor: '#BDE2D3',
				bgColor: '#1C342F',
			},
		},
		transactionSuccessContent: {
			bgColor: '#1a2a2a',
		},
	},
	pages: {
		send: {
			sendForm: {
				bgColor: '#1A2A2A',
				labelColor: '#BDE2D3',
				balanceBlock: {
					bgColor: '#1C342F',
					walletIconFill: '#BDE2D3',
					balanceTitle: '#ffffff',
					walletAddress: '#bde2d3',
					balanceAndToken: '#ffffff',
					tokenStandard: {
						fontColor: '#BDE2D3',
						bgColor: '#2D4A44',
					},
				},
				currencyWithChain: {
					bgColor: '2d4a44',
				},
				amountInUsd: '#BDE2D3',
			},
			confirmationScreen: {
				bgColor: '#1A2A2A',
				tokenAndAmount: '#ffffff',
				tokenStandard: {
					fontColor: '#BDE2D3',
					bgColor: '#2D4A44',
				},
				amountInUsd: '#BDE2D3',
				toText: '#BDE2D3',
				wallet: {
					bgColor: '#2D4A44',
					fontColor: '#BDE2D3',
				},
				feeBlock: {
					feeTitle: '#bde2d3',
					feeValue: '#ffffff',
					feeUsdEquivalent: '#bde2d3',
				},
			},
			mfaNotification: {
				mainText: {
					fontColor: '#bde2d3',
				},
			},
		},
		outer: {
			components: {
				registrationMessage: {
					fontColor: {
						default: '#bde2d3',
					},
				},
				registrationLink: {
					fontColor: {
						default: '#6ddac0',
						hoverFocus: '#00dba1',
						active: '#137a63',
					},
				},
				separator: {
					bgColor: {
						default: '#137a63',
					},
				},
				separatorText: {
					fontColor: {
						default: '#137a63',
					},
					bgColor: {
						default: '#1A2A2A',
						mobile: '#1A2121',
					},
				},
				walletConnectIcon: {
					fill: {
						blue: '#3b99fd',
					},
				},
				accountSetupSuccess: {
					fontColor: '#BDE2D3',
				},
				backupCodesSection: {
					fontColor: {
						mainText: '#BDE2D3',
						codeText: '#BDE2D3',
					},
					bgColor: '#2D4A44',
				},
			},
		},
		dao: {
			card: {
				bgColor: {
					default: '#1A2A2A',
					hoverFocus: '#1c342f',
					progressBar: '#6DDAC0',
				},
				fontColor: {
					primary: '#ffffff',
					alter: '#bde2d3',
					metaInfo: '#6ca095',
				},
			},
			delegateLink: {
				bgColor: {
					default: '#6DDAC0',
					hoverFocus: '#00DBA1',
					active: '#137A63',
				},
				fontColor: {
					default: '#1A2121',
				},
			},
			currentVote: {
				bgColor: {
					default: '#6ddac0',
					noActiveVotes: '#1A2A2A',
				},
				fontColor: {
					metaInfo: '#2d4a44',
					voteTitle: '#1a2121',
				},
			},
			sectionTitle: {
				fontColor: '#ffffff',
			},
		},
		daoVote: {
			card: {
				bgColor: '#1A2A2A',
				fontColor: {
					primary: '#ffffff',
					alter: '#BDE2D3',
					link: '#BDE2D3',
				},
				gradient: 'linear-gradient(180deg, rgba(26, 42, 42, 0) 0%, #1a2a2a 100%)',
			},
			secondaryTitle: {
				fontColor: '#ffffff',
			},
			results: {
				choiceText: {
					fontColor: '#bde2d3',
				},
				resultsBar: {
					bgColor: '#6ddac0',
				},
			},
			votesList: {
				wallet: {
					fontColor: '#bde2d3',
				},
				choice: {
					fontColor: '#ffffff',
				},
				votingPower: {
					fontColor: '#ffffff',
				},
			},
			voteInfo: {
				listTitle: {
					fontColor: '#bde2d3',
				},
				listDescription: {
					fontColor: '#ffffff',
				},
				blockLink: {
					fontColor: '#6ddac0',
				},
			},
		},
		dashboard: {
			components: {
				manageButton: '#1c342f',
				assets: {
					bgColor: '#1c342f',
					fontColor: {
						mainText: '#ffffff',
						description: {
							title: '#6ca095',
							amount: '#ffffff',
							unit: '#bde2d3',
						},
					},
				},
				overviewCard: {
					bgColor: '#1a2a2a',
					fontColor: {
						title: '#6ca095',
						amount: '#ffffff',
						unit: '#bde2d3',
						diff: {
							increase: '#00ffc2',
							decrease: '#EC494F',
						},
					},
				},
				walletAssets: {
					bgColor: '#1a2a2a',
					title: '#6ca095',
					totalValue: '#bde2d3',
				},
				coinsList: {
					bgColor: '#1a2a2a',
					components: {
						tokenName: '#ffffff',
						networkName: {
							bgColor: '#2d4a44',
							fontColor: '#bde2d3',
						},
						rate: '#6ca095',
						totalCount: '#6ca095',
					},
				},
				firstBuy: {
					bgColor: '#1a2a2a',
					fontColor: '#bde2d3',
				},
				rewardsSubTitle: {
					primary: '#bde2d3',
					secondary: '#ffffff',
				},
				rewardsHistory: {
					button: {
						bgColor: '#1a2a2a',
					},
				},
				firstBuyBanner: {
					bgColor: 'rgb(26 42 42 / 50%)',
					fontColor: {
						title: '#bde2d3',
					},
				},
				firstRewardsBanner: {
					bgColor: '#1a2a2a',
					fontColor: {
						title: '#bde2d3',
						time: '#ffffff',
					},
				},
				stakeTokensBanner: {
					bgColor: 'rgb(26 42 42 / 50%)',
					fontColor: '#bde2d3',
					amountToStakeBg: '#2d4a44',
					iconColor: '#9DF29A',
					closeButtonColor: '#BDE2D3',
				},
				sectionTitle: {
					fontColor: '#ffffff',
					iconFill: '#BDE2D3',
					iconStroke: '#1A2A2A',
				},
			},
		},
		more: {
			components: {
				userPlaceholder: {
					fillColor: '#2D4A44',
				},
				web2AccountCard: {
					bgColor: '#1a2a2a',
					deleteButtonContentColor: '#ec494f',
					logoutButtonContentColor: '#6ddac0',
				},
				web3AccountCard: {
					walletFontColor: '#ffffff',
				},
				moreLink: {
					iconStroke: '#6DDAC0',
					fontColor: {
						default: '#FFFFFF',
						active: '#1A2121',
						hoverFocus: '#1A2121',
					},
					bgColor: {
						default: '#1A2A2A',
						active: '#137A63',
						hoverFocus: '#00DBA1',
					},
				},
			},
		},
		setupMfa: {
			content: {
				fontColor: '#BDE2D3',
				bgColor: '#1a2a2a',
			},
			alterButton: {
				mobileBgColor: '#1A2A2A',
			},
			appLink: {
				bgColor: {
					default: {
						desktop: '#1c342f',
						mobile: '#1a2a2a',
					},
					active: '#000000',
					hoverFocus: '#2d4a44',
				},
				fontColor: {
					default: '#ffffff',
					hoverFocus: '#ffffff',
				},
				iconFillColor: {
					default: '#ffffff',
					hoverFocus: '#ffffff',
				},
				arrowIconStroke: '#6DDAC0',
			},
			backupCodes: {
				bgColor: '#2D4A44',
			},
			qrCode: {
				bgColor: '#BDE2D3',
			},
		},
		verification: {
			components: {
				closeNotification: {
					fontColor: '#137a63',
					bgColor: '#1a2a2a',
				},
				walletCreationBadge: {
					bgColor: {
						mobile: '#1A2A2A',
						desktop: '#1A2121',
					},
				},
			},
		},
		wallet: {
			fontColor: {
				title: '#ffffff',
				amount: '#e1ebe7',
			},
			components: {
				tableContainer: {
					bgColor: '#1a2a2a',
				},
				table: {
					fontColor: '#ffffff',
				},
				tableTitle: '#6ca095',
				tabletAssetsValue: '#6ca095',
				tabletAssetsTitle: '#ffffff',
				tabletTotalAmountButton: {
					fontColor: '#ffffff',
				},
				tabletHiddenContent: {
					bgColor: '#2d4a44',
				},
				networkName: {
					bgColor: '#2d4a44',
					fontColor: '#bde2d3',
				},
				assetsTitle: '#6ca095',
				assetsOddBgColor: 'rgb(28 52 47 / 50%)',
				assetsValue: '#ffffff',
				stakingInfo: {
					titleFontColor: '#6ca095',
					bgColor: {
						default: 'rgb(255 255 255 / 2%)',
						mobile: '#2d4a44',
					},
				},
				showAllButton: {
					bgColor: '#1c342f',
				},
			},
		},
		staking: {
			fontColor: '#ffffff',
			components: {
				stakingCard: {
					bgColor: '#1a2a2a',
				},
				title: '#ffffff',
				assetsInfoAmount: {
					fontColor: '#ffffff',
				},
				assetsInfoUnit: {
					fontColor: '#bde2d3',
				},
				assetsCard: {
					fontColor: '#ffffff',
					bgColor: '#1c342f',
				},
				descriptionTitle: {
					fontColor: '#6ca095',
				},
				descriptionAmount: {
					fontColor: '#ffffff',
				},
				descriptionHashrate: {
					fontColor: '#6ca095',
				},
				descriptionAmountUnit: {
					fontColor: '#bde2d3',
				},
				styledLink: {
					fontColor: '#1A2121',
					bgColor: {
						default: '#6DDAC0',
						hoverFocus: '#00DBA1',
						active: '#137A63',
					},
				},
				stakingStart: {
					bgColor: '#1a2a2a',
					fontColor: '#bde2d3',
				},
				assetsContainer: {
					bgColor: {
						default: '#1c342f',
						mobile: '#1a2a2a',
					},
				},
				assetsTitle: {
					fontColor: '#bde2d3',
				},
			},
		},
		deleteAccount: {
			components: {
				firstConfirmation: {
					cancelButton: {
						bgColor: '#1A2A2A',
					},
				},
			},
		},
		stakingInner: {
			components: {
				rewardsHistoryButton: {
					bgColor: '#1a2a2a',
				},
				rewardsClaimCard: {
					fontColor: '#ffffff',
					bgColor: {
						default: '#1c342f',
						mobile: '#1a2a2a',
					},
				},
				rewardsClaimCardTitle: {
					fontColor: '#ffffff',
				},
				tokenAmount: {
					fontColor: '#ffffff',
				},
				USDAmount: {
					fontColor: '#6ca095',
				},
				assetsCardContainer: {
					bgColor: '#1a2a2a',
				},
				assetsCard: {
					buttonIconStroke: '#1A2121',
					fontColor: '#ffffff',
				},
				header: {
					bgColor: {
						default: '#1c342f',
						mobile: '#1a2a2a',
					},
				},
				headerTitle: {
					fontColor: '#bde2d3',
				},
				stakeAmount: {
					fontColor: '#ffffff',
				},
				tokenName: {
					fontColor: '#bde2d3',
				},
				footer: {
					bgColor: {
						default: '#1c342f',
						mobile: '#1a2a2a',
					},
				},
				footerTitle: {
					fontColor: '#ffffff',
				},
				statsTitle: {
					fontColor: '#bde2d3',
				},
				statsValue: {
					fontColor: '#ffffff',
				},
				statsUnit: {
					fontColor: '#bde2d3',
				},
				rewardsSection: {
					bgColor: '#1a2a2a',
				},
				rewardsSectionTitle: {
					fontColor: '#ffffff',
				},
				rewardsSectionSubtitle: {
					fontColor: {
						primary: '#bde2d3',
						default: '#ffffff',
					},
				},
			},
		},
		rewards: {
			components: {
				subtitle: {
					fontColor: {
						primary: '#bde2d3',
						secondary: '#ffffff',
					},
				},
				tableItemLine: {
					bgColor: '#1c342f',
				},
				startStakingBanner: {
					bgColor: '#1a2a2a',
					fontColor: '#bde2d3',
					stakingLink: {
						fontColor: '#1a2121',
						bgColor: {
							default: '#6ddac0',
							hoverFocus: '#00dba1',
							active: '#137a63',
						},
					},
				},
				firstRewardsBanner: {
					bgColor: '#1a2a2a',
					fontColor: {
						default: '#bde2d3',
						primary: '#6ddac0',
						assetsText: '#ffffff',
					},
				},
			},
		},
		stats: {
			components: {
				title: {
					fontColor: '#bde2d3',
				},
				assetsItem: {
					bgColor: '#1a2a2a',
				},
				assetsItemValue: {
					fontColor: '#ffffff',
				},
				tokenAmount: {
					bgColor: '#1a2a2a',
				},
				progressBar: {
					bgColor: {
						primary: '#9df29a',
						secondary: '#ffffff',
					},
					shadowColor: 'rgba(157, 242, 154, 0.4)',
				},
				descriptionValue: {
					fontColor: '#ffffff',
				},
				rewards: {
					bgColor: '#1a2a2a',
				},
				rewardsValue: {
					fontColor: '#ffffff',
				},
				rewardsUnit: {
					fontColor: '#ffffff',
				},
				rewardsTime: {
					fontColor: '#bde2d3',
				},

				hashrate: {
					bgColor: '#1a2a2a',
				},
				hashrateValue: {
					fontColor: '#ffffff',
				},
				hashrateUnit: {
					fontColor: '#BDE2D3',
				},
				energyEfficiency: {
					bgColor: '#1a2a2a',
				},
				energyItemTitle: {
					fontColor: '#ffffff',
				},
				energyAverageValue: {
					fontColor: '#daf770',
				},
				energyCustomerValue: {
					fontColor: '#9df29a',
				},
				energyItemValueUnit: {
					fontColor: '#bde2d3',
				},
				price: {
					bgColor: '#1a2a2a',
				},
				priceValue: {
					fontColor: '#ffffff',
				},
				treasury: {
					bgColor: '#1a2a2a',
				},
				treasuryAmount: {
					fontColor: '#ffffff',
				},
				treasuryUnit: {
					fontColor: '#bde2d3',
				},
				treasuryLink: {
					fontColor: '#1a2121',
					bgColor: {
						default: '#6ddac0',
						hoverFocus: '#00dba1',
						active: '#137a63',
					},
				},
				banner: {
					bgColor: '#1a2a2a',
				},
				bannerCurrentDate: {
					fontColor: '#6ca095',
				},
				bannerFinalDate: {
					fontColor: '#ffffff',
				},
				bannerLink: {
					fontColor: '#6ddac0',
				},
			},
		},
		buyBtcmt: {
			guideLink: {
				bgColor: {
					default: '#1c342f',
					hoverFocus: '#00dba1',
					active: '#137a63',
				},
				fontColor: {
					default: '#6ddac0',
					hoverFocus: '#1a2121',
					active: '#1a2121',
				},
			},
			buyingVariantCard: {
				bgColor: '#1a2a2a',
				fontColor: '#bde2d3',
				guideLinkColor: {
					default: '#6ddac0',
					hoverFocus: '#00dba1',
					active: '#137a63',
				},
				exchangeDetails: {
					iconFillColor: '#6DDAC0',
					fontColor: {
						chain: '#bde2d3',
						exchange: '#ffffff',
					},
				},
				buyLink: {
					bgColor: {
						default: '#6ddac0',
						hoverFocus: '#00dba1',
						active: '#137a63',
					},
					fontColor: '#1a2121',
				},
			},
			purchasePending: {
				bgColor: '#1a2a2a',
				fontColor: '#bde2d3',
				loader: {
					fontColor: '#bde2d3',
					bgColor: {
						desktop: '#1a2121',
						mobile: '#1a2a2a',
					},
				},
			},
			purchaseSuccess: {
				bgColor: '#1a2a2a',
				fontColor: '#bde2d3',
			},
			buyWithCard: {
				bgColor: '#1a2a2a',
				labelColor: '#bde2d3',
				result: {
					fontColor: '#bde2d3',
					bgColor: '#2d4a44',
				},
				paymentSystemButton: {
					bgColor: '#ffffff',
					iconColor: {
						hoverFocus: '#1a2121',
						active: '#1a2121',
						disabled: '#1a2121',
					},
				},
				clarification: {
					fontColor: '#BDE2D3',
					bgColor: '#1C342F',
				},
				paymentsList: {
					wrapper: {
						bgColor: '#1a2a2a',
					},
					listTitle: {
						iconFill: '#BDE2D3',
						fontColor: '#ffffff',
					},
					transactionId: {
						fontColor: '#bde2d3',
					},
					amount: {
						fontColor: '#ffffff',
					},
					dateTitle: {
						fontColor: '#6ca095',
					},
					status: {
						pending: {
							fontColor: '#DAF770',
						},
						completed: {
							fontColor: '#9DF29A',
						},
						failed: {
							fontColor: '#EC494F',
						},
					},
					arrowFill: '#6CA095',
				},
			},
			buyFromExchange: {
				bgColor: '#1a2a2a',
				labelColor: '#bde2d3',
				result: {
					bgColor: '#2d4a44',
					fontColor: '#bde2d3',
				},
				advancedSettings: {
					bgColor: '#1a2121',
					unitColor: '#6ddac0',
				},
				slippageButton: {
					fontColor: '#6DDAC0',
					bgColor: '#1A2A2A',
				},
			},
			buyForCrypto: {
				bgColor: '#1a2a2a',
				labelColor: '#bde2d3',
				priceInfo: {
					bgColor: '#1c342f',
					fontColor: {
						title: '#BDE2D3',
						value: {
							default: '#ffffff',
							soldOut: '#F0B90B',
						},
					},
				},
				soldOutBlock: {
					bgColor: '#2d4a44',
					fontColor: '#bde2d3',
				},
				result: {
					bgColor: '#1A2121',
					fontColor: '#bde2d3',
				},
				invoices: {
					titleIconFill: '#BDE2D3',
					bgColor: '#1a2a2a',
					dateColor: '#6ca095',
					invoiceButton: {
						desktopHover: '#1c342f',
						mobileHover: '#1a2a2a',
					},
					idColor: '#bde2d3',
					showMoreButton: {
						bgColorMobile: '#1a2a2a',
					},
				},
				invoiceContent: {
					bgColor: '#1a2a2a',
					titleColor: '#bde2d3',
					timeColor: '#6ddac0',
					qrBgColor: '#bde2d3',
					address: {
						bgColor: '#2d4a44',
						fontColor: '#bde2d3',
					},
				},
			},
			emptyList: {
				fontColor: '#BDE2D3',
				bgColor: '#1C342F',
				bgColorMobile: 'rgba(26, 42, 42, 0.50)',
				iconStrokeColor: '#6CA095',
			},
		},
		receive: {
			bgColor: '#1a2a2a',
			qrCodeBg: '#bde2d3',
			walletAddress: {
				bgColor: '#2d4a44',
				fontColor: '#bde2d3',
			},
			currencyButton: {
				iconStroke: '#6DDAC0',
				bgColor: {
					hoverFocusDesktop: '#1c342f',
					hoverFocusMobile: '#1a2a2a',
				},
			},
			chainName: {
				bgColor: '#2d4a44',
				fontColor: '#bde2d3',
			},
			priceUsdtColor: '#6ca095',
		},
		roi: {
			contentContainer: {
				bgColor: '#1a2a2a',
			},
			autoRestakingSwitch: {
				fontColor: {
					default: '#FFFFFF',
					active: '#BDE2D3',
				},
			},
			periodPickerTitle: {
				fontColor: '#BDE2D3',
			},
			periodSelect: {
				bgColor: '#1A2121',
				fontColor: '#FFFFFF',
				borderColor: '#137a63',
				iconStrokeColor: '#137a63',
			},
			inputBlockTitle: {
				fontColor: '#BDE2D3',
			},
			inputBtcmt: {
				fontColor: '#BDE2D3',
			},
			inputBtcmtBefore: {
				fontColor: '#2D4A44',
			},
			moneyButton: {
				bgColor: '#1C342F',
			},
			calculateBlock: {
				bgColor: '#1C342F',
				shadowColor: 'rgba(26, 33, 33, 0.1)',
			},
			priceItemContainerBefore: {
				fontColor: '#137A63',
			},
			calculateBlockTitle: {
				fontColor: '#BDE2D3',
			},
			calculateBlockEstimatedTitle: {
				fontColor: '#6CA095',
			},
			calculateBlockValue: {
				fontColor: '#FFFFFF',
			},
			usdtAmount: {
				fontColor: '#FFFFFF',
			},
			valuePercents: {
				fontColor: '#BDE2D3',
			},
			btcAmount: {
				fontColor: '#6CA095',
			},
			detailsTitle: {
				fontColor: '#BDE2D3',
			},
			detailsText: {
				fontColor: '#FFFFFF',
			},
		},
		notFound: {
			cardBgColor: '#1a2a2a',
			textFontColor: '#bde2d3',
			codeFontColor: '#2d4a44',
		},
	},
};

export const lightTheme: DefaultTheme = {
	name: 'light',
	globals: {
		frameBgColor: '#E1EBE7',
		contentBgColor: '#ffffff',
		outerBgColor: '#ffffff',
		textColor: '#1A2121',
	},
	components: {
		votingForm: {
			title: {
				fontColor: '#1A2121',
			},
			choiceButton: {
				default: {
					fontColor: '#137A63',
					bgColor: '#E1EBE7',
				},
				hoverFocus: {
					fontColor: '#137A63',
					bgColor: '#BDE2D3',
				},
				selected: {
					fontColor: '#137A63',
					bgColor: '#BDE2D3',
					borderColor: '#137A63',
				},
			},
		},
		feeData: {
			iconsFill: '#6CA095',
			title: '#2D4A44',
			usdAmount: '#2D4A44',
		},
		button: {
			primary: {
				fontColor: {
					default: '#BDE2D3',
					active: '#BDE2D3',
					hoverFocus: '#BDE2D3',
					disabled: '#ffffff',
				},
				bgColor: {
					default: '#137A63',
					active: '#1A2A2A',
					hoverFocus: '#2D4A44',
					disabled: 'rgba(19, 122, 99, 0.30)',
				},
			},
			alter: {
				fontColor: {
					default: '#137A63',
					disabled: '#BDE2D3',
					active: '#BDE2D3',
					hoverFocus: '#BDE2D3',
				},
				bgColor: {
					default: '#E1EBE7',
					hoverFocus: '#2D4A44',
					active: '#1A2A2A',
					disabled: 'rgba(19, 122, 99, 0.30)',
				},
			},
			alterLight: {
				fontColor: {
					default: '#137A63',
					hoverFocus: '#BDE2D3',
					active: '#BDE2D3',
					disabled: '#ffffff',
				},
				bgColor: {
					default: '#BDE2D3',
					hoverFocus: '#2D4A44',
					active: '#1A2A2A',
					disabled: 'rgba(19, 122, 99, 0.30)',
				},
			},
			outlined: {
				fontColor: {
					default: '#137A63',
					disabled: '#137A63',
					active: '#1A2A2A',
					hoverFocus: '#2D4A44',
				},
				borderColor: {
					default: 'rgba(19, 122, 99, 0.50)',
					active: '#1A2A2A',
					hoverFocus: '#2D4A44',
					disabled: 'rgba(19, 122, 99, 0.50)',
				},
			},
			borderless: {
				fontColor: {
					default: '#137A63',
					active: '#1A2121',
					hoverFocus: '#1C342F',
					disabled: '#137A63',
				},
			},
			authOption: {
				fontColor: {
					default: '#1C342F',
					hoverFocus: '#1C342F',
					active: '#1C342F',
					disabled: '#BDE2D3',
				},
				bgColor: {
					default: '#ffffff',
					mobile: '#F3F8F6',
					hoverFocus: '#E1EBE7',
					active: '#BDE2D3',
					disabled: '#E1EBE7',
				},
			},
		},
		supportLink: {
			fontColor: {
				default: '#137A63',
				hoverFocus: '#BDE2D3',
				active: '#BDE2D3',
				disabled: '#ffffff',
			},
			bgColor: {
				default: '#BDE2D3',
				hoverFocus: '#2D4A44',
				active: '#1A2A2A',
				disabled: 'rgba(19, 122, 99, 0.30)',
			},
		},
		input: {
			fontColor: {
				mainText: {
					default: '#1A2121',
					disabled: '#A1A1A1',
				},
				placeholder: {
					default: '#A1A1A1',
					disabled: '#A1A1A1',
				},
				rightText: {
					default: '#137A63',
					alter: '#137A63',
					disabled: '#A1A1A1',
				},
				leftText: {
					default: '#6CA095',
					disabled: '#6CA095',
				},
				errorText: '#EC494F',
				labelText: '#2D4A44',
			},
			borderColor: {
				default: '#6CA095',
				activeFocus: '#00DBA1',
				disabled: 'rgba(161, 161, 161, 0.30)',
				invalid: '#EC494F',
			},
			bgColor: {
				default: '#FFFFFF',
				activeFocus: '#FFFFFF',
				disabled: '#A1A1A1',
			},
		},
		outerForegroundWrapper: {
			bgColor: {
				mobile: 'transparent',
				desktop: '#F3F8F6',
			},
			fontColor: {
				alter: '#2D4A44',
			},
		},
		innerForegroundWrapper: {
			bgColor: {
				mobile: 'transparent',
				desktop: '#F3F8F6',
			},
			fontColor: {
				alter: '#BDE2D3',
			},
		},
		mfaForm: {
			fontColor: {
				labelText: '#2D4A44',
				titleText: '#2D4A44',
			},
		},
		forgotPasswordForm: {
			fontColor: {
				labelText: '#2D4A44',
				errorMessage: '#EC494F',
			},
		},
		comparePasswordForm: {
			fontColor: {
				labelText: '#2D4A44',
				errorMessage: '#EC494F',
			},
		},
		mintoLogo: {
			full: {
				desktop: '#2D4A44',
				mobile: '#2D4A44',
			},
			icon: {
				desktop: '#6CA095',
				mobile: '#6CA095',
			},
		},
		header: {
			balance: {
				bgColor: '#FFFFFF',
				iconColor: '#137A63',
			},
		},
		mainMenu: {
			bgColor: {
				primary: '#E1EBE7',
				alter: '#ffffff',
			},
			menuLink: {
				fontColor: {
					default: '#2D4A44',
					active: '#137A63',
				},
				iconFill: {
					default: '#2D4A44',
					active: '#137A63',
				},
			},
			themeToggle: {
				bgColor: '#137A63',
				fgColor: '#FFFFFF',
				valueColor: {
					default: '#6CA095',
					active: '#137A63',
				},
			},
			shadow: '0px -8px 24px 0px rgba(108, 160, 149, 0.15)',
		},
		toast: {
			bgColor: {
				success: '#BDE2D3',
				warning: '#DAF770',
				error: '#EC494F',
			},
			fontColor: {
				success: '#1A2121',
				warning: '#1A2121',
				error: '#ffffff',
			},
		},
		switchButton: {
			bgColor: '#137A63',
			bgColorSlider: '#FFFFFF',
			fontColorSelected: '#137A63',
			fontColorNotSelected: '#1A2121',
		},
		hint: {
			tooltipButton: {
				default: '#137A63',
				hoverFocus: '#2D4A44',
				active: '#1A2A2A',
				disabled: 'rgba(19, 122, 99, 0.30)',
			},
			tooltip: {
				boxShadow: '0px 16px 32px 0px rgba(0, 0, 0, 0.15)',
				bgColor: '#BDE2D3',
				fontColor: '#1A2121',
			},
			closeButton: {
				default: '#137A63',
				hoverFocus: '#2D4A44',
				active: '#1A2A2A',
			},
		},
		rewardsHistory: {
			bgColor: '#F3F8F6',
			fontColor: {
				rewardsDate: '#137A63',
				rewardsTitle: '#1A2121',
				totalCount: '#1A2121',
				balance: '#2D4A44',
			},
		},
		rewardsHistoryTitle: {
			fontColor: '#1A2121',
		},
		rewardsHistorySubTitle: {
			fontColor: {
				primary: '#2D4A44',
				secondary: '#1A2121',
			},
		},
		rewardsHistoryLink: {
			fontColor: {
				default: '#137A63',
				hoverFocus: '#BDE2D3',
				active: '#BDE2D3',
			},
			bgColor: {
				default: '#BDE2D3',
				hoverFocus: '#2D4A44',
				active: '#1A2A2A',
			},
		},
		walletAddressWithButtons: {
			bgColor: '#F3F8F6',
			fontColor: '#2D4A44',
		},
		qrPopupContent: {
			bgColor: '#F3F8F6',
			qrContainerBgColor: '#FFFFFF',
			walletAddress: {
				bgColor: '#E1EBE7',
				fontColor: '#1A2121',
			},
		},
		currencyWithChain: {
			chain: {
				fontColor: '#6CA095',
				bgColorLight: '#E1EBE7',
				bgColorDark: '#F0F5F3',
			},
		},
		datePicker: {
			bgColor: '#FFFFFF',
			shadow: 'rgba(0, 0, 0, 0.30)',
			components: {
				overlay: {
					bgColor: 'rgba(255, 255, 255, 0.80)',
				},
				header: {
					borderColor: '#E1EBE7',
					bgColor: '#FFFFFF',
				},
				changeMonthButtonIcon: {
					fill: '#137A63',
				},
				weekDayName: {
					fontColor: '#6CA095',
				},
				dayName: {
					fontColor: '#6CA095',
				},
				dayNumber: {
					fontColor: '#1A2121',
				},
				currentMonth: {
					fontColor: '#2D4A44',
				},
				dayOutsideMonth: {
					fontColor: '#BDE2D3',
				},
				dayToday: {
					fontColor: '#BDE2D3',
				},
				dayInRange: {
					fontColor: '#1A2121',
					bgColor: '#E1EBE7',
				},
				dayRange: {
					fontColor: '#BDE2D3',
					bgColor: {
						primary: '#6CA095',
						secondary: '#E1EBE7',
					},
				},
				footer: {
					borderColor: '#FFFFFF',
				},
				closeButton: {
					iconFill: '#137A63',
				},
			},
		},
		pagination: {
			components: {
				divider: {
					fontColor: '#137A63',
				},
				button: {
					bgColor: {
						default: '#F0F5F3',
						current: '#2D4A44',
						hoverFocus: '#E1EBE7',
						active: '#2D4A44',
						disabled: 'rgba(19, 122, 99, 0.30)',
					},
					fontColor: {
						default: '#137A63',
						current: '#BDE2D3',
						hoverFocus: '#BDE2D3',
						disabled: '#ffffff',
					},
				},
				icon: {
					fill: {
						default: '#137A63',
						disabled: 'string',
					},
				},
			},
		},
		loaderDot: {
			bgColor: '#2D4A44',
		},
		errorElement: {
			bgColor: '#F0F5F3',
			fontColor: '#2D4A44',
		},
		select: {
			menu: {
				bgColor: '#FFFFFF',
				menuButton: {
					bgColor: {
						hoverFocus: '#FFFFFF',
					},
					fontColor: {
						default: '#1A2121',
						active: '#1A2121',
						disabled: '#A1A1A1',
						chainDisabled: '#A1A1A1',
					},
				},
			},
		},
		transactionStatus: {
			completed: '#1AA815',
			pending: '#D3A206',
			expired: '#EC494F',
		},
		transactionsCounter: {
			fontColor: {
				isZero: '#6CA095',
				default: '#1A2121',
			},
			bgColor: {
				default: '#ffffff',
			},
		},
		stakingForm: {
			bgColor: '#F3F8F6',
			iconsFill: '#6CA095',
			walletData: {
				bgColor: '#ffffff',
				addressColor: '#2D4A44',
				chain: {
					fontColor: '#6CA095',
					bgColor: '#F0F5F3',
				},
				labelColor: '#2D4A44',
			},
			radio: {
				bgColor: '#ffffff',
				borderColor: {
					default: '#ffffff',
					hoverFocus: '#137A63',
				},
				radioBoxColor: '#137A63',
				stakingTitleColor: '#2D4A44',
				stakingDataColor: '#1A2121',
			},
		},
		withdrawForm: {
			bgColor: '#F3F8F6',
			iconsFill: '#6CA095',
			stakingData: {
				bgColor: '#E1EBE7',
				labelColor: '#2D4A44',
			},
			tokenStandard: {
				fontColor: '#6CA095',
				bgColor: '#F0F5F3',
			},
		},
		firstRewardsCongratsBanner: {
			bgColor: '#F0F5F3',
			fontColor: {
				title: '#2D4A44',
				subTitle: '#2D4A44',
				assetsTitle: '#2D4A44',
				usdtAmount: '#137A63',
				tokenAmount: '#137A63',
			},
		},
		underConstruction: {
			iconFillColor: '#BDE2D3',
			bgColor: '#F0F5F3',
			fontColor: '#2D4A44',
		},
		networkWarning: {
			borderColor: '#ec494f',
			fontColor: '#1A2121',
		},
		transactionErrorContent: {
			bgColor: '#F3F8F6',
			fontColor: '#2D4A44',
			retryButton: {
				iconStroke: {
					default: '#BDE2D3',
				},
			},
			supportButton: {
				iconFill: {
					default: '#137A63',
					hoverFocus: '#BDE2D3',
					active: '#BDE2D3',
				},
			},
		},
		transactionPendingContent: {
			infoText: {
				fontColor: '#2D4A44',
			},
			spinnerBlock: {
				fontColor: '#6CA095',
				bgColor: '#E1EBE7',
				alterBgColor: 'string',
			},
			canCloseBlock: {
				fontColor: '#1A2121',
				bgColor: '#ffffff',
			},
		},
		transactionSuccessContent: {
			bgColor: '#F3F8F6',
		},
	},
	pages: {
		send: {
			sendForm: {
				bgColor: '#F3F8F6',
				labelColor: '#2D4A44',
				balanceBlock: {
					bgColor: '#ffffff',
					walletIconFill: '#6ca095',
					balanceTitle: '#1A2121',
					walletAddress: '#6CA095',
					balanceAndToken: '#1A2121',
					tokenStandard: {
						fontColor: '#6CA095',
						bgColor: '#E1EBE7',
					},
				},
				currencyWithChain: {
					bgColor: '#E1EBE7',
				},
				amountInUsd: '#2D4A44',
			},
			confirmationScreen: {
				bgColor: '#F3F8F6',
				tokenAndAmount: '#1A2121',
				tokenStandard: {
					bgColor: '#E1EBE7',
					fontColor: '#6CA095',
				},
				amountInUsd: '#2D4A44',
				toText: '#2D4A44',
				wallet: {
					bgColor: '#ffffff',
					fontColor: '#1A2121',
				},
				feeBlock: {
					feeTitle: '#2D4A44',
					feeValue: '#1A2121',
					feeUsdEquivalent: '#2D4A44',
				},
			},
			mfaNotification: {
				mainText: {
					fontColor: '#2D4A44',
				},
			},
		},
		outer: {
			components: {
				registrationMessage: {
					fontColor: {
						default: '#2D4A44',
					},
				},
				registrationLink: {
					fontColor: {
						default: '#137A63',
						hoverFocus: '#1C342F',
						active: '#1A2121',
					},
				},
				separator: {
					bgColor: {
						default: '#6CA095',
					},
				},
				separatorText: {
					fontColor: {
						default: '#6CA095',
					},
					bgColor: {
						default: '#F3F8F6',
						mobile: '#ffffff',
					},
				},
				walletConnectIcon: {
					fill: {
						blue: '#3B99FD',
					},
				},
				accountSetupSuccess: {
					fontColor: '#1C342F',
				},
				backupCodesSection: {
					fontColor: {
						mainText: '#1C342F',
						codeText: '#1A2121',
					},
					bgColor: '#E1EBE7',
				},
			},
		},
		dao: {
			card: {
				bgColor: {
					default: '#F0F5F3',
					hoverFocus: '#E1EBE7',
					progressBar: '#137A63',
				},
				fontColor: {
					primary: '#1A2121',
					alter: '#2D4A44',
					metaInfo: '#2D4A44',
				},
			},
			delegateLink: {
				bgColor: {
					default: '#137A63',
					hoverFocus: '#2D4A44',
					active: '#1A2A2A',
				},
				fontColor: {
					default: '#BDE2D3',
				},
			},
			currentVote: {
				bgColor: {
					default: '#6DDAC0',
					noActiveVotes: '#F0F5F3',
				},
				fontColor: {
					metaInfo: '#2D4A44',
					voteTitle: '#1A2121',
				},
			},
			sectionTitle: {
				fontColor: '#1A2121',
			},
		},
		daoVote: {
			card: {
				bgColor: '#F0F5F3',
				fontColor: {
					primary: '#1A2121',
					alter: '#2D4A44',
					link: '#137A63',
				},
				gradient: 'linear-gradient(180deg, rgba(240, 245, 243, 0.00) 0%, #F0F5F3 100%)',
			},
			secondaryTitle: {
				fontColor: '#1A2121',
			},
			results: {
				choiceText: {
					fontColor: '#2D4A44',
				},
				resultsBar: {
					bgColor: '#137A63',
				},
			},
			votesList: {
				wallet: {
					fontColor: '#2D4A44',
				},
				choice: {
					fontColor: '#1A2121',
				},
				votingPower: {
					fontColor: '#1A2121',
				},
			},
			voteInfo: {
				listTitle: {
					fontColor: '#2D4A44',
				},
				listDescription: {
					fontColor: '#1A2121',
				},
				blockLink: {
					fontColor: '#137A63',
				},
			},
		},
		dashboard: {
			components: {
				manageButton: '#BDE2D3',
				assets: {
					bgColor: '#E1EBE7',
					fontColor: {
						mainText: '#1A2121',
						description: {
							title: '#6CA095',
							amount: '#1A2121',
							unit: '#2D4A44',
						},
					},
				},
				overviewCard: {
					bgColor: '#F3F8F6',
					fontColor: {
						title: '#6CA095',
						amount: '#1A2121',
						unit: '#2D4A44',
						diff: {
							increase: '#137A63',
							decrease: '#EC494F',
						},
					},
				},
				walletAssets: {
					bgColor: '#F3F8F6',
					title: '#6CA095',
					totalValue: '#1A2121',
				},
				coinsList: {
					bgColor: '#F3F8F6',
					components: {
						tokenName: '#1A2121',
						networkName: {
							bgColor: '##F0F5F3',
							fontColor: '#6CA095',
						},
						rate: '#6CA095',
						totalCount: '#1A2121',
					},
				},
				firstBuy: {
					bgColor: '#F3F8F6',
					fontColor: '#2D4A44',
				},
				rewardsSubTitle: {
					primary: '#2D4A44',
					secondary: '#1A2121',
				},
				rewardsHistory: {
					button: {
						bgColor: '#BDE2D3',
					},
				},
				firstBuyBanner: {
					bgColor: '#F3F8F6',
					fontColor: {
						title: '#2D4A44',
					},
				},
				firstRewardsBanner: {
					bgColor: '#F3F8F6',
					fontColor: {
						title: '#2D4A44',
						time: '#1A2121',
					},
				},
				stakeTokensBanner: {
					bgColor: '#E1EBE7',
					fontColor: '#2D4A44',
					amountToStakeBg: '#F3F8F6',
					iconColor: '#2AD124',
					closeButtonColor: '#137A63',
				},
				sectionTitle: {
					fontColor: '#1A2121',
					iconFill: '#6CA095',
					iconStroke: '#F0F5F3',
				},
			},
		},
		more: {
			components: {
				userPlaceholder: {
					fillColor: '#6CA095',
				},
				web2AccountCard: {
					bgColor: '#F3F8F6',
					deleteButtonContentColor: '#EC494F',
					logoutButtonContentColor: '#BDE2D3',
				},
				web3AccountCard: {
					walletFontColor: '#1A2121',
				},
				moreLink: {
					iconStroke: '#137A63',
					fontColor: {
						default: '#1A2121',
						active: '#1A2121',
						hoverFocus: '#1A2121',
					},
					bgColor: {
						default: '#F3F8F6',
						hoverFocus: '#E1EBE7',
						active: '#E1EBE7',
					},
				},
			},
		},
		setupMfa: {
			content: {
				fontColor: 'string',
				bgColor: 'string',
			},
			alterButton: {
				mobileBgColor: 'string',
			},
			appLink: {
				bgColor: {
					default: {
						desktop: '#ffffff',
						mobile: '#E1EBE7',
					},
					active: '#1A2A2A',
					hoverFocus: '#2D4A44',
				},
				fontColor: {
					default: '#1A2121',
					hoverFocus: '#BDE2D3',
				},
				iconFillColor: {
					default: '#1A2121',
					hoverFocus: '#BDE2D3',
				},
				arrowIconStroke: '#137A63',
			},
			backupCodes: {
				bgColor: 'string',
			},
			qrCode: {
				bgColor: 'string',
			},
		},
		verification: {
			components: {
				closeNotification: {
					fontColor: '#6CA095',
					bgColor: '#E1EBE7',
				},
				walletCreationBadge: {
					bgColor: {
						mobile: 'transparent',
						desktop: '#E1EBE7',
					},
				},
			},
		},
		wallet: {
			fontColor: {
				title: '#1A2121',
				amount: '#2D4A44',
			},
			components: {
				tableContainer: {
					bgColor: '#E1EBE7',
				},
				table: {
					fontColor: '#1A2121',
				},
				tableTitle: '#2D4A44',
				tabletAssetsValue: '#1A2121',
				tabletAssetsTitle: '#1A2121',
				tabletTotalAmountButton: {
					fontColor: '#1A2121',
				},
				tabletHiddenContent: {
					bgColor: '#F0F5F3',
				},
				networkName: {
					bgColor: '#F3F8F6',
					fontColor: '#2D4A44',
				},
				assetsTitle: '#2D4A44',
				assetsOddBgColor: 'rgba(255, 255, 255, 0.50)',
				assetsValue: '#1A2121',
				stakingInfo: {
					titleFontColor: '#2D4A44',
					bgColor: {
						default: '#ffffff',
						mobile: '#F3F8F6',
					},
				},
				showAllButton: {
					bgColor: 'rgba(255, 255, 255, 0.30)',
				},
			},
		},
		staking: {
			fontColor: '#1A2121',
			components: {
				stakingCard: {
					bgColor: '#F3F8F6',
				},
				title: '#1A2121',
				assetsInfoAmount: {
					fontColor: '#1A2121',
				},
				assetsInfoUnit: {
					fontColor: '#2D4A44',
				},
				assetsCard: {
					fontColor: '#1A2121',
					bgColor: '#ffffff',
				},
				descriptionTitle: {
					fontColor: '#2D4A44',
				},
				descriptionAmount: {
					fontColor: '#1A2121',
				},
				descriptionHashrate: {
					fontColor: '#6CA095',
				},
				descriptionAmountUnit: {
					fontColor: '#2D4A44',
				},
				styledLink: {
					fontColor: '#BDE2D3',
					bgColor: {
						default: '#137A63',
						hoverFocus: '#2D4A44',
						active: '#1A2A2A',
					},
				},
				stakingStart: {
					bgColor: '#FFFFFF',
					fontColor: '#2D4A44',
				},
				assetsContainer: {
					bgColor: {
						default: '#F3F8F6',
						mobile: '#FFFFFF',
					},
				},
				assetsTitle: {
					fontColor: '#2D4A44',
				},
			},
		},
		deleteAccount: {
			components: {
				firstConfirmation: {
					cancelButton: {
						bgColor: '#BDE2D3',
					},
				},
			},
		},
		stakingInner: {
			components: {
				rewardsHistoryButton: {
					bgColor: '#BDE2D3',
				},
				rewardsClaimCard: {
					fontColor: '#1A2121',
					bgColor: {
						default: '#FFFFFF',
						mobile: '#E1EBE7',
					},
				},
				rewardsClaimCardTitle: {
					fontColor: '#1A2121',
				},
				tokenAmount: {
					fontColor: '#1A2121',
				},
				USDAmount: {
					fontColor: '#2D4A44',
				},
				assetsCardContainer: {
					bgColor: '#F0F5F3',
				},
				assetsCard: {
					fontColor: '#2D4A44',
					buttonIconStroke: '#BDE2D3',
				},
				header: {
					bgColor: {
						default: '#FFFFFF',
						mobile: '#F3F8F6',
					},
				},
				headerTitle: {
					fontColor: '#2D4A44',
				},
				stakeAmount: {
					fontColor: '#1A2121',
				},
				tokenName: {
					fontColor: '#2D4A44',
				},
				footer: {
					bgColor: {
						default: '#FFFFFF',
						mobile: '#F3F8F6',
					},
				},
				footerTitle: {
					fontColor: '#1A2121',
				},
				statsTitle: {
					fontColor: '#2D4A44',
				},
				statsValue: {
					fontColor: '#1A2121',
				},
				statsUnit: {
					fontColor: '#2D4A44',
				},
				rewardsSection: {
					bgColor: '#F0F5F3',
				},
				rewardsSectionTitle: {
					fontColor: '#1A2121',
				},
				rewardsSectionSubtitle: {
					fontColor: {
						primary: '#6CA095',
						default: '#1A2121',
					},
				},
			},
		},
		buyBtcmt: {
			guideLink: {
				bgColor: {
					default: '#BDE2D3',
					hoverFocus: '#2D4A44',
					active: '#1A2A2A',
				},
				fontColor: {
					default: '#137A63',
					hoverFocus: '#BDE2D3',
					active: '#BDE2D3',
				},
			},
			buyingVariantCard: {
				bgColor: '#F3F8F6',
				fontColor: '#2D4A44',
				guideLinkColor: {
					default: '#137A63',
					hoverFocus: '#1C342F',
					active: '#1A2121',
				},
				exchangeDetails: {
					iconFillColor: '#137A63',
					fontColor: {
						chain: '#2D4A44',
						exchange: '#1A2121',
					},
				},
				buyLink: {
					bgColor: {
						default: '#137A63',
						hoverFocus: '#2D4A44',
						active: '#1A2A2A',
					},
					fontColor: '#BDE2D3',
				},
			},
			purchasePending: {
				bgColor: '#F3F8F6',
				fontColor: '#2D4A44',
				loader: {
					fontColor: '#6CA095',
					bgColor: {
						desktop: '#E1EBE7',
						mobile: '#E1EBE7',
					},
				},
			},
			purchaseSuccess: {
				bgColor: '#F3F8F6',
				fontColor: '#2D4A44',
			},
			buyWithCard: {
				bgColor: '#F3F8F6',
				labelColor: '#2D4A44',
				result: {
					fontColor: '#1A2121',
					bgColor: '#E1EBE7',
				},
				paymentSystemButton: {
					bgColor: 'string',
					iconColor: {
						hoverFocus: 'string',
						active: 'string',
						disabled: 'string',
					},
				},
				clarification: {
					fontColor: '#2D4A44',
					bgColor: '#ffffff',
				},
				paymentsList: {
					wrapper: {
						bgColor: '#F3F8F6',
					},
					listTitle: {
						iconFill: '#6CA095',
						fontColor: '#1A2121',
					},
					transactionId: {
						fontColor: '#6CA095',
					},
					amount: {
						fontColor: '#1A2121',
					},
					dateTitle: {
						fontColor: '#1C342F',
					},
					status: {
						pending: {
							fontColor: '#D3A206',
						},
						completed: {
							fontColor: '#2AD124',
						},
						failed: {
							fontColor: '#EC494F',
						},
					},
					arrowFill: '#1C342F',
				},
			},
			buyFromExchange: {
				bgColor: '#F3F8F6',
				labelColor: '#2D4A44',
				result: {
					bgColor: '#E1EBE7',
					fontColor: '#2D4A44',
				},
				advancedSettings: {
					bgColor: '#FFFFFF',
					unitColor: '#2D4A44',
				},
				slippageButton: {
					fontColor: '#137A63',
					bgColor: '#F0F5F3',
				},
			},
			buyForCrypto: {
				bgColor: '#F3F8F6',
				labelColor: '#2D4A44',
				priceInfo: {
					bgColor: '#E1EBE7',
					fontColor: {
						title: '#2D4A44',
						value: {
							default: '#1A2121',
							soldOut: '#D3A206',
						},
					},
				},
				soldOutBlock: {
					bgColor: '#E1EBE7',
					fontColor: '#2D4A44',
				},
				result: {
					bgColor: '#ffffff',
					fontColor: '#2D4A44',
				},
				invoices: {
					titleIconFill: '#6ca095',
					bgColor: '#F3F8F6',
					dateColor: '#137A63',
					invoiceButton: {
						desktopHover: '#E1EBE7',
						mobileHover: '#E1EBE7',
					},
					idColor: '#2D4A44',
					showMoreButton: {
						bgColorMobile: '#BDE2D3',
					},
				},
				invoiceContent: {
					bgColor: '#F3F8F6',
					titleColor: '#2D4A44',
					timeColor: '#137A63',
					qrBgColor: '#FFFFFF',
					address: {
						bgColor: '#E1EBE7',
						fontColor: '#1A2121',
					},
				},
			},
			emptyList: {
				fontColor: '#2D4A44',
				bgColor: '#E1EBE7',
				bgColorMobile: '#FFFFFF',
				iconStrokeColor: '#6CA095',
			},
		},
		rewards: {
			components: {
				subtitle: {
					fontColor: {
						primary: '#6CA095',
						secondary: '#1A2121',
					},
				},
				tableItemLine: {
					bgColor: '#BDE2D3',
				},
				startStakingBanner: {
					bgColor: '#F3F8F6',
					fontColor: '#2D4A44',
					stakingLink: {
						fontColor: '#BDE2D3',
						bgColor: {
							default: '#137A63',
							hoverFocus: '#2D4A44',
							active: '#1A2A2A',
						},
					},
				},
				firstRewardsBanner: {
					bgColor: 'string',
					fontColor: {
						default: 'string',
						primary: 'string',
						assetsText: 'string',
					},
				},
			},
		},
		stats: {
			components: {
				title: {
					fontColor: '#1A2121',
				},
				assetsItem: {
					bgColor: '#F3F8F6',
				},
				assetsItemValue: {
					fontColor: '#1A2121',
				},
				tokenAmount: {
					bgColor: '#F3F8F6',
				},
				progressBar: {
					bgColor: {
						primary: '#2AD124',
						secondary: '#FFFFFF',
					},
					shadowColor: 'rgba(157, 242, 154, 0.40)',
				},
				descriptionValue: {
					fontColor: '#1A2121',
				},
				rewards: {
					bgColor: '#F3F8F6',
				},
				rewardsValue: {
					fontColor: '#1A2121',
				},
				rewardsUnit: {
					fontColor: '#1A2121',
				},
				rewardsTime: {
					fontColor: '#2D4A44',
				},
				hashrate: {
					bgColor: '#F3F8F6',
				},
				hashrateValue: {
					fontColor: '#1A2121',
				},
				hashrateUnit: {
					fontColor: '#2D4A44',
				},
				energyEfficiency: {
					bgColor: '#F3F8F6',
				},
				energyItemTitle: {
					fontColor: '#1A2121',
				},
				energyAverageValue: {
					fontColor: '#F0B90B',
				},
				energyCustomerValue: {
					fontColor: '#2AD124',
				},
				energyItemValueUnit: {
					fontColor: '#2D4A44',
				},
				price: {
					bgColor: '#F3F8F6',
				},
				priceValue: {
					fontColor: '#1A2121',
				},
				treasury: {
					bgColor: '#F3F8F6',
				},
				treasuryAmount: {
					fontColor: '#1A2121',
				},
				treasuryUnit: {
					fontColor: '#2D4A44',
				},
				treasuryLink: {
					fontColor: '#BDE2D3',
					bgColor: {
						default: '#137A63',
						hoverFocus: '#2D4A44',
						active: '#1A2A2A',
					},
				},
				banner: {
					bgColor: '#F3F8F6',
				},
				bannerCurrentDate: {
					fontColor: '#6CA095',
				},
				bannerFinalDate: {
					fontColor: '#1A2121',
				},
				bannerLink: {
					fontColor: '#137A63',
				},
			},
		},
		receive: {
			bgColor: '#F3F8F6',
			qrCodeBg: '#ffffff',
			walletAddress: {
				bgColor: '#ffffff',
				fontColor: '#1A2121',
			},
			currencyButton: {
				iconStroke: '#137A63',
				bgColor: {
					hoverFocusDesktop: '#E1EBE7',
					hoverFocusMobile: '#E1EBE7',
				},
			},
			chainName: {
				bgColor: '#E1EBE7',
				fontColor: '#6CA095',
			},
			priceUsdtColor: '#6CA095',
		},
		roi: {
			contentContainer: {
				bgColor: '#F3F8F6',
			},
			autoRestakingSwitch: {
				fontColor: {
					default: '#1A2121',
					active: '#2D4A44',
				},
			},
			periodPickerTitle: {
				fontColor: '#2D4A44',
			},
			periodSelect: {
				bgColor: '#FFFFFF',
				fontColor: '#1A2121',
				borderColor: '#6CA095',
				iconStrokeColor: '#137A63',
			},
			inputBlockTitle: {
				fontColor: '#2D4A44',
			},
			inputBtcmt: {
				fontColor: '#1A2121',
			},
			inputBtcmtBefore: {
				fontColor: '#137A63',
			},
			moneyButton: {
				bgColor: '#E1EBE7',
			},
			calculateBlock: {
				bgColor: '#ffffff',
				shadowColor: 'transparent',
			},
			priceItemContainerBefore: {
				fontColor: '#137A63',
			},
			calculateBlockTitle: {
				fontColor: '#2D4A44',
			},
			calculateBlockEstimatedTitle: {
				fontColor: '#6CA095',
			},
			calculateBlockValue: {
				fontColor: '#1A2121',
			},
			usdtAmount: {
				fontColor: '#1A2121',
			},
			valuePercents: {
				fontColor: '#2D4A44',
			},
			btcAmount: {
				fontColor: '#6CA095',
			},
			detailsTitle: {
				fontColor: '#2D4A44',
			},
			detailsText: {
				fontColor: '#1A2121',
			},
		},
		notFound: {
			cardBgColor: '#F3F8F6',
			textFontColor: '#2D4A44',
			codeFontColor: '#6CA095',
		},
	},
};
