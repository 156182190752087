import React, { ReactNode } from 'react';
import { ToastContentProps } from 'react-toastify';
import { ReactComponent as CloseIcon } from 'assets/icons/toast-icons/close.svg';
import { ReactComponent as ErrorCloseIcon } from 'assets/icons/toast-icons/error-close.svg';

import * as S from './Toast.styled';

interface ToastProps {
	type: 'success' | 'warning' | 'error';
	children: ReactNode;
}

const Toast: React.FC<Partial<ToastContentProps> & ToastProps> = ({
	type,
	children,
	closeToast,
}) => {
	switch (type) {
		case 'success':
			return (
				<S.Success>
					{children}
					<S.CloseButton onClick={closeToast}>
						<CloseIcon />
					</S.CloseButton>
				</S.Success>
			);
		case 'warning':
			return (
				<S.Warning>
					{children}
					<S.CloseButton onClick={closeToast}>
						<CloseIcon />
					</S.CloseButton>
				</S.Warning>
			);
		case 'error':
			return (
				<S.Error>
					{children}
					<S.CloseButton onClick={closeToast}>
						<ErrorCloseIcon />
					</S.CloseButton>
				</S.Error>
			);
	}
};

export default React.memo(Toast);
