import { signTypedData } from '@wagmi/core';
import axios from 'axios';
import { MINTO_APP_NAME } from 'store/dao-reducer/snapshot/constants/minto-app-name.const';
import { SnapshotRawBaseUrl } from 'store/dao-reducer/snapshot/types/snapshot-base-url.enum';
import { TAccountAddress } from 'types/blockchain/contracts.types';

export const castWeb3Vote = async ({
	accountAddress,
	space,
	proposal,
	choice,
	snapshotClientAddress,
}: {
	accountAddress: TAccountAddress;
	space: string;
	proposal: string;
	choice: number;
	snapshotClientAddress: SnapshotRawBaseUrl;
}): Promise<void> => {
	const vote = {
		from: accountAddress,
		space,
		timestamp: Math.floor(Date.now() / 1000),
		proposal,
		choice,
		reason: '',
		app: MINTO_APP_NAME,
		metadata: '{}',
	};

	const signature = await signTypedData({
		account: accountAddress,
		domain: { name: 'snapshot', version: '0.1.4' },
		types: {
			Vote: [
				{ name: 'from', type: 'address' },
				{ name: 'space', type: 'string' },
				{ name: 'timestamp', type: 'uint64' },
				{ name: 'proposal', type: 'string' },
				{ name: 'choice', type: 'uint32' },
				{ name: 'reason', type: 'string' },
				{ name: 'app', type: 'string' },
				{ name: 'metadata', type: 'string' },
			],
		},
		primaryType: 'Vote',
		message: vote,
	});

	return await axios.post(snapshotClientAddress, {
		address: accountAddress,
		sig: signature,
		data: {
			domain: { name: 'snapshot', version: '0.1.4' },
			types: {
				Vote: [
					{ name: 'from', type: 'address' },
					{ name: 'space', type: 'string' },
					{ name: 'timestamp', type: 'uint64' },
					{ name: 'proposal', type: 'string' },
					{ name: 'choice', type: 'uint32' },
					{ name: 'reason', type: 'string' },
					{ name: 'app', type: 'string' },
					{ name: 'metadata', type: 'string' },
				],
			},
			message: vote,
		},
	});
};
