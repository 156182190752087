import React, { ReactNode, useRef } from 'react';
import { useCloseByEsc } from 'hooks/useCloseByEsc';
import { useOuterClick } from 'hooks/useOuterClick';

import HeaderWithButtons from 'components/ui/HeaderWithButtons';

import * as S from './Popup.styled';

interface IPopupProps {
	headerTitle: string;
	content: ReactNode;
	handlePopupClose: () => void;
}

const Popup: React.FC<IPopupProps> = ({ headerTitle, content, handlePopupClose }) => {
	const contentRef = useRef(null);

	useOuterClick({ excludedElements: [contentRef], cb: handlePopupClose });
	useCloseByEsc({ isShown: true, cb: handlePopupClose });

	return (
		<S.PopupWrapper>
			<S.PopupContent ref={contentRef}>
				<S.PopupContentWrapper>
					<HeaderWithButtons titleText={headerTitle} handleCrossButtonClick={handlePopupClose} />
					{content}
				</S.PopupContentWrapper>
			</S.PopupContent>
		</S.PopupWrapper>
	);
};

export default Popup;
