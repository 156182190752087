import { Dispatch } from 'react';
import { Action } from '@reduxjs/toolkit';
import {
	Connector,
	prepareWriteContract,
	readContract,
	waitForTransaction,
	writeContract,
} from '@wagmi/core';
import { TransactionErrorMessage } from 'constants/message/transaction-error-message.const';
import { NUMBER_OF_BLOCK_CONFIRMATIONS } from 'constants/web3/web3.const';
import { updateDebugInfoAction } from 'store/debug-reducer/debug.reducer';
import { TGetStateFn } from 'store/store';
import { handlePreparationError } from 'store/utils/error-report/error-report.utils';
import { TConnectorName } from 'types/blockchain/connectors.types';
import {
	TAccountAddress,
	TBtcmtBscContractConfig,
	TContractsWithAllowanceConfig,
} from 'types/blockchain/contracts.types';
import { formatEther, parseEther } from 'viem';

import { injectedConnector, walletConnectConnector } from 'config/web3/wagmi.config';

export const getConnector = (connectorName: TConnectorName): Connector => {
	switch (connectorName) {
		case 'WalletConnect':
			return walletConnectConnector;
		default:
			return injectedConnector;
	}
};

export const writeContractFromConfig = async ({
	config,
	chainId,
	dispatch,
	errorMessage,
}: {
	config: Awaited<ReturnType<typeof prepareWriteContract>>;
	chainId: number;
	dispatch: Dispatch<Action>;
	errorMessage: string;
}): Promise<string> => {
	const { request } = config;
	const { hash } = await writeContract(request).catch((error: Error) => {
		dispatch(updateDebugInfoAction({ errorDetails: error.message }));
		throw new Error(errorMessage);
	});

	return await waitForTransaction({
		chainId,
		hash,
		confirmations: NUMBER_OF_BLOCK_CONFIRMATIONS,
	})
		.then(({ transactionHash, status }) => {
			if (status === 'success') {
				return transactionHash;
			}

			throw new Error(
				`Unable to check the transaction with hash ${transactionHash}. Maybe it is not proceed by blockchain yet.`,
			);
		})
		.catch((error: Error) => {
			dispatch(updateDebugInfoAction({ errorDetails: error.message }));
			throw error;
		});
};

// Check allowance for BTCB, BNB and USDT contracts
export const checkAllowance = async ({
	accountAddress,
	tokenContractConfig,
	requiredValue,
	spenderAddress,
	chainId,
	assetBalance,
	dispatch,
}: {
	accountAddress: TAccountAddress;
	spenderAddress: TAccountAddress;
	tokenContractConfig: TContractsWithAllowanceConfig;
	requiredValue: string;
	chainId: number;
	assetBalance: string;
	dispatch: Dispatch<Action>;
}): Promise<boolean> => {
	dispatch(
		updateDebugInfoAction({
			contractAddress: tokenContractConfig.address,
			contractName: tokenContractConfig.name,
			contractMethod: 'allowance',
			assetAvailableAmount: assetBalance,
		}),
	);
	return readContract({
		address: tokenContractConfig.address,
		abi: tokenContractConfig.abi,
		functionName: 'allowance',
		args: [accountAddress, spenderAddress],
		chainId,
	})
		.then((value) => {
			dispatch(updateDebugInfoAction({ assetAllowance: formatEther(value).toString() }));
			return value >= parseEther(requiredValue);
		})
		.catch((error: Error) => {
			dispatch(updateDebugInfoAction({ errorDetails: error.message }));
			throw new Error(TransactionErrorMessage.CheckAllowanceFailed);
		});
};

// Check allowance for BTCMT contract
export const checkBtcmtAllowance = async ({
	accountAddress,
	btcmtContractConfig,
	requiredValue,
	spenderAddress,
	chainId,
	btcmtBalance,
	dispatch,
}: {
	accountAddress: TAccountAddress;
	spenderAddress: TAccountAddress;
	btcmtContractConfig: TBtcmtBscContractConfig;
	requiredValue: string;
	chainId: number;
	btcmtBalance: string | number;
	dispatch: Dispatch<Action>;
}): Promise<boolean> => {
	dispatch(
		updateDebugInfoAction({
			contractAddress: btcmtContractConfig.address,
			contractName: btcmtContractConfig.name,
			contractMethod: 'allowance',
			transactionAmount: requiredValue,
			assetName: 'BTCMT',
			assetAvailableAmount: String(btcmtBalance),
		}),
	);
	return readContract({
		address: btcmtContractConfig.address,
		abi: btcmtContractConfig.abi,
		functionName: 'allowance',
		args: [accountAddress, spenderAddress],
		chainId,
	})
		.then((value) => {
			dispatch(updateDebugInfoAction({ assetAllowance: formatEther(value).toString() }));
			return value >= parseEther(requiredValue);
		})
		.catch((error: Error) => {
			dispatch(updateDebugInfoAction({ errorDetails: error.message }));
			throw new Error(TransactionErrorMessage.CheckAllowanceFailed);
		});
};

// Approve for BTCB, BNB and USDT contracts
export const approveToken = async ({
	accountAddress,
	tokenContractConfig,
	requiredValue,
	spenderAddress,
	chainId,
	dispatch,
	getState,
}: {
	accountAddress: TAccountAddress;
	spenderAddress: TAccountAddress;
	tokenContractConfig: TContractsWithAllowanceConfig;
	requiredValue: string;
	chainId: number;
	dispatch: Dispatch<Action>;
	getState: TGetStateFn;
}): Promise<string> => {
	dispatch(
		updateDebugInfoAction({
			contractMethod: 'approve',
			transactionAmount: requiredValue,
		}),
	);
	const config = await prepareWriteContract({
		address: tokenContractConfig.address,
		abi: tokenContractConfig.abi,
		functionName: 'approve',
		args: [spenderAddress, parseEther(requiredValue)],
		account: accountAddress,
		chainId,
	}).catch((error: Error) => {
		return handlePreparationError({
			getState,
			dispatch,
			error,
			displayedMessage: TransactionErrorMessage.InternalError,
		});
	});
	return await writeContractFromConfig({
		dispatch,
		chainId,
		config,
		errorMessage: TransactionErrorMessage.ApproveFailed,
	});
};

// Approve for BTCMT contract
export const approveBtcmt = async ({
	accountAddress,
	btcmtContractConfig,
	requiredValue,
	spenderAddress,
	chainId,
	dispatch,
	getState,
}: {
	accountAddress: TAccountAddress;
	spenderAddress: TAccountAddress;
	btcmtContractConfig: TBtcmtBscContractConfig;
	requiredValue: string;
	chainId: number;
	dispatch: Dispatch<Action>;
	getState: TGetStateFn;
}): Promise<string> => {
	dispatch(
		updateDebugInfoAction({
			contractMethod: 'approve',
			transactionAmount: requiredValue,
		}),
	);
	const config = await prepareWriteContract({
		address: btcmtContractConfig.address,
		abi: btcmtContractConfig.abi,
		functionName: 'approve',
		args: [spenderAddress, parseEther(requiredValue)],
		account: accountAddress,
		chainId,
	}).catch((error: Error) => {
		return handlePreparationError({
			getState,
			dispatch,
			error,
			displayedMessage: TransactionErrorMessage.InternalError,
		});
	});
	return await writeContractFromConfig({
		chainId,
		dispatch,
		config,
		errorMessage: TransactionErrorMessage.ApproveFailed,
	});
};
