import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { isProductionFB } from 'store/api/env-config/is-production-fb.config';
import { refreshFirebaseToken } from 'store/api/firebase-api/firebase.service';

import { getAuthToken } from 'utils/storage/auth-token/get-auth-token.util';
import { setAuthToken } from 'utils/storage/auth-token/set-auth-token.util';

export const fireblocksApi = axios.create({
	baseURL: isProductionFB
		? 'https://fb-api.minto.finance/api/v1'
		: 'https://minto-fb.rocknblock.io/api/v1',
});

fireblocksApi.interceptors.request.use(async (req) => {
	const token = getAuthToken();
	if (token) {
		req.headers['Authorization'] = `Bearer ${token}`;
	} else {
		const updatedToken = await refreshFirebaseToken();
		setAuthToken(updatedToken);
		req.headers['Authorization'] = `Bearer ${updatedToken}`;
	}

	return Promise.resolve(req);
});

fireblocksApi.interceptors.response.use(
	(response): AxiosResponse => {
		return response;
	},
	async (error: AxiosError): Promise<AxiosRequestConfig> => {
		const originalRequest = error.config;

		// Check if the error is due to 401 Unauthorized
		if (error.response?.status === 401 && originalRequest) {
			try {
				// Refresh Firebase token
				const newToken = await refreshFirebaseToken();
				setAuthToken(newToken);

				// Retry the original request with the new token
				originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
				return fireblocksApi(originalRequest);
			} catch (refreshError) {
				// Handle error refreshing token
				return Promise.reject(refreshError);
			}
		}

		return Promise.reject(error);
	},
);
