import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoints';

export const ThemeToggle = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	height: 32px;
	margin-bottom: 24px;

	@media (max-width: ${BreakPoint.TabletTop}) {
		display: none;
	}
`;

export const ThemeButton = styled.button<{ $isActive: boolean }>`
	appearance: none;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 4px;
	border: none;
	background-color: transparent;
	cursor: pointer;

	& svg {
		flex-shrink: 0;
		width: 16px;
		height: 16px;

		path {
			fill: ${({ theme, $isActive }) =>
				$isActive
					? theme.components.mainMenu.themeToggle.valueColor.active
					: theme.components.mainMenu.themeToggle.valueColor.default};
		}
	}
`;

export const ToggleSwitchButton = styled.button<{ $isDarkMode: boolean }>`
	display: flex;
	justify-content: ${({ $isDarkMode }) => ($isDarkMode ? 'flex-end' : 'flex-start')};
	align-items: center;
	width: 22px;
	padding: 3px;
	border: none;
	border-radius: 8px;
	background: ${({ theme }) => theme.components.mainMenu.themeToggle.bgColor};
	cursor: pointer;

	&::before {
		content: '';
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: ${({ theme }) => theme.components.mainMenu.themeToggle.fgColor};
	}
`;
