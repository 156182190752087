import { createSlice } from '@reduxjs/toolkit';
import { TFetchStatus } from 'types/api/fetch-status.type';

import {
	requestBasicRatesThunkAction,
	requestBnbUsdtRateThunkAction,
	requestBtcbUsdtRateThunkAction,
	requestBtcmtUsdtRateThunkAction,
	requestBusdUsdtRateThunkAction,
	requestEtherToUsdtRateThunkAction,
} from './rates.thunk-actions';

export interface IRatesState {
	debugErrorMessage: string | null;
	bnbUsdtRate?: number | null;
	bnbUsdtRateStatus: TFetchStatus;
	busdUsdtRate?: number | null;
	busdUsdtRateStatus: TFetchStatus;
	btcmtUsdtRate?: number | null;
	btcmtUsdtRateStatus: TFetchStatus;
	btcbUsdtRate?: number | null;
	btcbUsdtRateStatus: TFetchStatus;
	basicRatesStatus: TFetchStatus;
	etherToUsdtRate: number | null | undefined;
	etherToUsdtRateStatus: TFetchStatus;
}

const initialState: IRatesState = {
	debugErrorMessage: null,
	bnbUsdtRate: null,
	bnbUsdtRateStatus: 'initial',
	busdUsdtRate: null,
	busdUsdtRateStatus: 'initial',
	btcmtUsdtRate: null,
	btcmtUsdtRateStatus: 'initial',
	btcbUsdtRate: null,
	btcbUsdtRateStatus: 'initial',
	basicRatesStatus: 'initial',
	etherToUsdtRate: null,
	etherToUsdtRateStatus: 'initial',
};

const ratesApiSlice = createSlice({
	name: 'ratesApi',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(requestBnbUsdtRateThunkAction.pending, (state) => {
				state.bnbUsdtRateStatus = 'pending';
				state.bnbUsdtRate = null;
			})
			.addCase(requestBnbUsdtRateThunkAction.fulfilled, (state, { payload }) => {
				state.bnbUsdtRateStatus = 'fulfilled';
				if (payload) {
					state.bnbUsdtRate = Number(payload) || undefined;
				}
			})
			.addCase(requestBnbUsdtRateThunkAction.rejected, (state) => {
				state.bnbUsdtRateStatus = 'rejected';
			})
			.addCase(requestBusdUsdtRateThunkAction.pending, (state) => {
				state.busdUsdtRateStatus = 'pending';
				state.busdUsdtRate = null;
			})
			.addCase(requestBusdUsdtRateThunkAction.fulfilled, (state, { payload }) => {
				state.busdUsdtRateStatus = 'fulfilled';
				if (payload) {
					state.busdUsdtRate = Number(payload) || undefined;
				}
			})
			.addCase(requestBusdUsdtRateThunkAction.rejected, (state) => {
				state.busdUsdtRateStatus = 'rejected';
			})
			.addCase(requestBtcmtUsdtRateThunkAction.pending, (state) => {
				state.btcmtUsdtRateStatus = 'pending';
				state.btcmtUsdtRate = null;
			})
			.addCase(requestBtcmtUsdtRateThunkAction.fulfilled, (state, { payload }) => {
				state.btcmtUsdtRateStatus = 'fulfilled';
				if (payload) {
					state.btcmtUsdtRate = Number(payload) || undefined;
				}
			})
			.addCase(requestBtcmtUsdtRateThunkAction.rejected, (state) => {
				state.btcmtUsdtRateStatus = 'rejected';
			})
			.addCase(requestBtcbUsdtRateThunkAction.pending, (state) => {
				state.btcbUsdtRateStatus = 'pending';
				state.btcbUsdtRate = null;
			})
			.addCase(requestBtcbUsdtRateThunkAction.fulfilled, (state, { payload }) => {
				state.btcbUsdtRateStatus = 'fulfilled';
				if (payload) {
					state.btcbUsdtRate = Number(payload) || undefined;
				}
			})
			.addCase(requestBtcbUsdtRateThunkAction.rejected, (state) => {
				state.btcbUsdtRateStatus = 'rejected';
			})
			.addCase(requestBasicRatesThunkAction.pending, (state) => {
				state.basicRatesStatus = 'pending';
				state.btcbUsdtRate = null;
				state.busdUsdtRate = null;
				state.bnbUsdtRate = null;
				state.btcmtUsdtRate = null;
			})
			.addCase(requestBasicRatesThunkAction.fulfilled, (state, { payload }) => {
				state.basicRatesStatus = 'fulfilled';
				state.btcbUsdtRate = payload.btcbUsdtRate;
				state.busdUsdtRate = payload.busdUsdtRate;
				state.bnbUsdtRate = payload.bnbUsdtRate;
				state.btcmtUsdtRate = payload.btcmtUsdtRate;
			})
			.addCase(requestBasicRatesThunkAction.rejected, (state) => {
				state.basicRatesStatus = 'rejected';
			})
			.addCase(requestEtherToUsdtRateThunkAction.pending, (state) => {
				state.etherToUsdtRateStatus = 'pending';
			})
			.addCase(requestEtherToUsdtRateThunkAction.fulfilled, (state, { payload }) => {
				state.etherToUsdtRateStatus = 'fulfilled';
				state.etherToUsdtRate = payload;
			})
			.addCase(requestEtherToUsdtRateThunkAction.rejected, (state) => {
				state.etherToUsdtRateStatus = 'rejected';
			});
	},
});

export const ratesReducer = ratesApiSlice.reducer;
