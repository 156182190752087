import { bsc, bscTestnet, mainnet } from '@wagmi/core/chains';
import { FontFamily } from 'styles/style-variables/fonts';
import { configureChains, createConfig, createStorage } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';

import { wcProjectId } from 'config/wallet-connect-v2/wc-project.config';
import { WalletId } from 'config/wallet-connect-v2/wc-wallets.config';
import { checkIsAutoConnectionRequired } from 'utils/date/date.utils';
import {
	getLastConnectionTime,
	getLastConnectorName,
} from 'utils/storage/connection-data/connection-data.utils';

const BINANCE_TESTNET_CHAIN_ID = 97;

const { publicClient, chains } = configureChains(
	[bsc, bscTestnet, mainnet],
	[
		jsonRpcProvider({
			rpc: ({ id }) => {
				switch (id) {
					case BINANCE_TESTNET_CHAIN_ID:
						return {
							http: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
						};
					default:
						return {
							http: 'https://bsc-dataseed.binance.org/',
						};
				}
			},
		}),
		jsonRpcProvider({
			rpc: ({ id }) => {
				switch (id) {
					case BINANCE_TESTNET_CHAIN_ID:
						return {
							http: 'https://data-seed-prebsc-2-s1.binance.org:8545/',
						};
					default:
						return {
							http: 'https://bsc-dataseed1.defibit.io/',
						};
				}
			},
		}),
		jsonRpcProvider({
			rpc: ({ id }) => {
				switch (id) {
					case BINANCE_TESTNET_CHAIN_ID:
						return {
							http: 'https://data-seed-prebsc-1-s2.binance.org:8545/',
						};
					default:
						return {
							http: 'https://bsc-dataseed1.ninicoin.io/',
						};
				}
			},
		}),
	],
);

export const injectedConnector = new InjectedConnector({ chains });
export const walletConnectConnector = new WalletConnectConnector({
	chains,
	options: {
		projectId: wcProjectId,
		qrModalOptions: {
			explorerRecommendedWalletIds: [
				WalletId.TokenPocket,
				WalletId.SafePal,
				WalletId.TrustWallet,
				WalletId.Metamask,
				WalletId.BitKeep,
			],
			themeMode: 'light',
			themeVariables: {
				'--wcm-font-family': FontFamily.Manrope,
				'--wcm-background-color': '#137A63',
				'--wcm-accent-color': '#137A63',
			},
		},
	},
});

export const wagmiConfig = createConfig({
	autoConnect: checkIsAutoConnectionRequired(getLastConnectionTime(), getLastConnectorName()),
	publicClient,
	connectors: [walletConnectConnector, injectedConnector],
	storage: createStorage({ storage: window.localStorage }),
});
