import { createSlice } from '@reduxjs/toolkit';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { requestWeb2ClaimThunkAction } from './web2-claim.thunk-actions';

export interface IWeb2StakingState {
	debugErrorMessage: string | null;
	claimFetchStatus: TFetchStatus;
}

const initialState: IWeb2StakingState = {
	debugErrorMessage: null,
	claimFetchStatus: 'initial',
};

const web2ClaimSlice = createSlice({
	name: 'web2-claim',
	initialState,
	reducers: {
		resetWeb2ClaimStateAction: (state) => {
			state.debugErrorMessage = null;
			state.claimFetchStatus = 'initial';
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(requestWeb2ClaimThunkAction.pending, (state) => {
				state.claimFetchStatus = 'pending';
			})
			.addCase(requestWeb2ClaimThunkAction.fulfilled, (state) => {
				state.claimFetchStatus = 'fulfilled';
			})
			.addCase(requestWeb2ClaimThunkAction.rejected, (state) => {
				state.claimFetchStatus = 'rejected';
			});
	},
});

export const { resetWeb2ClaimStateAction } = web2ClaimSlice.actions;
export const web2ClaimReducer = web2ClaimSlice.reducer;
