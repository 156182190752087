import styled from 'styled-components';
import { FontFamily } from 'styles/style-variables/fonts';

const Toast = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
	max-width: 300px;
	padding: 8px 8px 8px 16px;
	border-radius: 12px;
	font-family: ${FontFamily.Manrope};
	font-size: 14px;
	font-weight: 700;
	line-height: 22px;
	color: ${({ theme }) => theme.components.toast.fontColor.success};
`;

export const Success = styled(Toast)`
	padding-right: 16px;
	background-color: ${({ theme }) => theme.components.toast.bgColor.success};
`;

export const Warning = styled(Toast)`
	background-color: ${({ theme }) => theme.components.toast.bgColor.warning};
	color: ${({ theme }) => theme.components.toast.fontColor.warning};
`;

export const Error = styled(Toast)`
	background-color: ${({ theme }) => theme.components.toast.bgColor.error};
	color: ${({ theme }) => theme.components.toast.fontColor.error};
`;

export const CloseButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	padding: 0;
	border: none;
	background: transparent;
	cursor: pointer;
`;
