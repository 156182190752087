import { FirebaseErrorMessage } from 'constants/message/firebase-error-message.const';
import {
	AuthError,
	browserLocalPersistence,
	createUserWithEmailAndPassword,
	deleteUser,
	EmailAuthProvider,
	getMultiFactorResolver,
	GoogleAuthProvider,
	multiFactor,
	MultiFactorError,
	reauthenticateWithCredential,
	reauthenticateWithPopup,
	sendEmailVerification,
	sendPasswordResetEmail,
	setPersistence,
	signInWithEmailAndPassword,
	signInWithPopup,
	TotpMultiFactorGenerator,
	TotpSecret,
	UserCredential,
} from 'firebase/auth';
import { IAuthFormValues } from 'types/forms/auth-form-values.interface';
import { ILoginFormValues } from 'types/forms/login-form-values.interface';

import { getFirebaseErrorMessage } from 'utils/error-message/get-firebase-error-message.util';
import { ToastMessage } from 'utils/message/toast-message';
import { notifyError, notifySuccess } from 'utils/notify/notify.utils';
import { deleteEmailSendTime } from 'utils/storage/date-and-time/delete-email-send-time.util';
import { setEmailSendTime } from 'utils/storage/date-and-time/set-email-send-time.util';

import { MINTO_APP_NAME } from './constants/minto-app.constant';
import { IFirebaseUser } from './types/firebase-user.interface';
import { domainUrl, firebaseAuth } from './firebase.config';

export const sendFirebaseEmailVerification = async (): Promise<void> => {
	deleteEmailSendTime();
	if (firebaseAuth?.currentUser) {
		await sendEmailVerification(firebaseAuth.currentUser, {
			url: domainUrl,
		})
			.then(() => setEmailSendTime())
			.catch((error: AuthError) => {
				notifyError(getFirebaseErrorMessage(error));
			});
	}
};

export const sendFirebasePasswordResetEmail = async (email: string): Promise<void> =>
	await sendPasswordResetEmail(firebaseAuth, email, { url: domainUrl }).then(() => {
		setEmailSendTime();
	});

export const sendFirebasePasswordResetEmailWithNotification = async (
	email: string,
): Promise<void> =>
	await sendFirebasePasswordResetEmail(email)
		.then(() => {
			notifySuccess(ToastMessage.EmailSent(email));
		})
		.catch((error: AuthError) => {
			notifyError(getFirebaseErrorMessage(error));
		});

export const sendEmailVerificationWithNotification = async (): Promise<void> => {
	deleteEmailSendTime();
	if (firebaseAuth?.currentUser) {
		void sendEmailVerification(firebaseAuth.currentUser, {
			url: domainUrl,
		})
			.then(() => {
				const email = firebaseAuth.currentUser?.email;
				setEmailSendTime();
				notifySuccess(ToastMessage.EmailSent(email));
			})
			.catch((error: AuthError) => {
				notifyError(getFirebaseErrorMessage(error));
			});
	}
};

export const registerByPassword = async ({
	email,
	password,
}: ILoginFormValues): Promise<IFirebaseUser> =>
	await createUserWithEmailAndPassword(firebaseAuth, email, password).then(async (userData) => {
		await sendFirebaseEmailVerification();
		return { email: userData.user.email, isVerified: userData.user.emailVerified };
	});

export const loginWithPassword = async ({
	email,
	password,
}: ILoginFormValues): Promise<IFirebaseUser> =>
	await signInWithEmailAndPassword(firebaseAuth, email, password).then((userData) => {
		return { email: userData.user.email, isVerified: userData.user.emailVerified };
	});

export const setLoginWithPasswordPersistence = async ({
	email,
	password,
}: ILoginFormValues): Promise<void> => {
	await setPersistence(firebaseAuth, browserLocalPersistence).then(() => {
		return loginWithPassword({ email, password });
	});
};

export const signInWithGoogle = async (): Promise<void> => {
	const provider = new GoogleAuthProvider();
	firebaseAuth?.useDeviceLanguage();
	provider?.addScope('email');
	await signInWithPopup(firebaseAuth, provider);
};

export const setGooglePersistence = async (): Promise<void> => {
	await setPersistence(firebaseAuth, browserLocalPersistence).then(async () => {
		return signInWithGoogle();
	});
};

export const requestSignOut = async (): Promise<void> =>
	await firebaseAuth.signOut().then(() => {
		window.location.reload();
	});

export const requestPasswordReAuthentication = async ({
	email,
	password,
}: IAuthFormValues): Promise<UserCredential | void> => {
	const currentUser = firebaseAuth?.currentUser;
	if (currentUser) {
		const credentials = EmailAuthProvider.credential(email, password);
		return await reauthenticateWithCredential(currentUser, credentials);
	}
};

export const requestGoogleReAuthentication = async (): Promise<UserCredential | void> => {
	const currentUser = firebaseAuth?.currentUser;
	if (currentUser) {
		return await reauthenticateWithPopup(currentUser, new GoogleAuthProvider());
	}
};

export const requestDeleteUser = async (): Promise<void> => {
	const currentUser = firebaseAuth?.currentUser;
	if (currentUser) {
		return await deleteUser(currentUser).catch((error: AuthError) => {
			notifyError(getFirebaseErrorMessage(error));
			throw error;
		});
	}
};

export const generateMfaSecret = async (): Promise<TotpSecret | void> => {
	const currentUser = firebaseAuth.currentUser;
	if (!currentUser) {
		return;
	}

	const multiFactorSession = await multiFactor(currentUser).getSession();
	return await TotpMultiFactorGenerator.generateSecret(multiFactorSession);
};

export const generateMfaSecretKey = (totpSecret: TotpSecret): string => totpSecret.secretKey;

export const generateMfaQrCodeUrl = (totpSecret: TotpSecret): string | void => {
	const currentUser = firebaseAuth.currentUser;
	const userAccountEmail = currentUser?.email;
	if (!currentUser || !userAccountEmail) {
		return;
	}

	return totpSecret.generateQrCodeUrl(userAccountEmail, MINTO_APP_NAME);
};

export const finalizeMfaEnrollment = async ({
	mfaSecret,
	verificationCode,
}: {
	mfaSecret: TotpSecret;
	verificationCode: string;
}): Promise<void> => {
	const currentUser = firebaseAuth.currentUser;
	const userAccountEmail = currentUser?.email;
	if (!currentUser || !userAccountEmail) {
		return;
	}

	const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(
		mfaSecret,
		verificationCode,
	);

	return multiFactor(currentUser).enroll(multiFactorAssertion, MINTO_APP_NAME);
};

export const unEnrollFromMfa = async (): Promise<void> => {
	const currentUser = firebaseAuth.currentUser;
	const mfaInfo = currentUser?.reloadUserInfo?.mfaInfo;
	let mfaEnrollmentId;
	if (mfaInfo) {
		mfaEnrollmentId = mfaInfo[0]?.mfaEnrollmentId;
	}

	if (!currentUser || !mfaEnrollmentId) {
		return;
	}

	return await multiFactor(currentUser).unenroll(mfaEnrollmentId);
};

export const checkOneTimePassword = async (
	otpFromAuthenticator: string,
	error: MultiFactorError,
): Promise<UserCredential | void> => {
	const mfaResolver = getMultiFactorResolver(firebaseAuth, error);
	const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
		mfaResolver.hints[0].uid,
		otpFromAuthenticator,
	);

	return await mfaResolver.resolveSignIn(multiFactorAssertion);
};

export const handleMfaError = async function* ({
	error,
	onErrorCb,
	onSuccessCb,
}: {
	error: MultiFactorError;
	onSuccessCb: () => void;
	onErrorCb: () => void;
}): AsyncGenerator<string | undefined, void, string> {
	const otpCode = yield;

	if (error.message === FirebaseErrorMessage.MfaRequired && otpCode) {
		await checkOneTimePassword(otpCode ?? '', error)
			.then(() => onSuccessCb())
			.catch((error: MultiFactorError) => {
				notifyError(getFirebaseErrorMessage(error));
				onErrorCb();
			});
	}

	if (error.message === FirebaseErrorMessage.MfaRequired && !otpCode) {
		notifyError("One time password wasn't provided");
		onErrorCb();
	}
};

export const refreshFirebaseToken = async (): Promise<string> => {
	const user = firebaseAuth.currentUser;
	if (user) {
		return await user.getIdToken();
	}

	return '';
};
