import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'store/store';

import { IModalsState } from './modals.reducer';

const getMenusState = (state: TRootState): IModalsState => state.modalsReducer;

export const selectIsQrPopupOpened = createSelector(
	[getMenusState],
	({ popupMode }) => popupMode === 'qr',
);

export const selectIsAnyModalOpened = createSelector(
	[getMenusState],
	({ popupMode }) => popupMode !== 'disabled',
);
