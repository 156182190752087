export const StatsApiRoute = {
	ActiveWallets: (): string => '/general/active-wallets',
	TotalHistory: (): string => 'general/total-history',
	TotalTreasury: (): string => 'general/total-treasury',
	TotalMined: (): string => '/general/total-mined',
	TotalStaked: (): string => 'general/total-staked',
	TotalMultiplier: (): string => 'general/total-multiplier',
	Tvl: (): string => '/general/tapp-tvl',
	TotalIssued: (): string => '/general/total-supply',
};
