import axios from 'axios';
import { TAccountAddress, TNetworkName } from 'types/blockchain/contracts.types';

import { getSnapshotGqlBaseUrl } from 'utils/dao/get-snapshot-gql-base-url.util';
import { getSnapshotSpace } from 'utils/dao/get-snapshot-space.util';

import { proposalByIdQuery } from './queries/proposal-by-id.query';
import { proposalsBySpaceQuery } from './queries/proposals-by-space.query';
import { votesByProposalIdQuery } from './queries/votes-by-proposal-id.query';
import { votingPowerBySpaceAndVoterQuery } from './queries/voting-power-by-space-and-voter.query';
import { IProposalByIdResponse } from './types/response/proposal-by-id-response.interface';
import { IProposalsResponse } from './types/response/proposals-response.interface';
import { IVotesResponse } from './types/response/votes-response.interface';
import { IVotingPowerResponse } from './types/response/voting-power-response.interface';

export const requestProposals = async (chainName: TNetworkName): Promise<IProposalsResponse> =>
	axios
		.post(getSnapshotGqlBaseUrl(chainName), {
			query: proposalsBySpaceQuery,
			variables: { snapshotSpace: getSnapshotSpace(chainName) },
		})
		.then((response) => response?.data);

export const requestProposalById = async ({
	id,
	chainName,
}: {
	id: string;
	chainName: TNetworkName;
}): Promise<IProposalByIdResponse> =>
	await axios
		.post(getSnapshotGqlBaseUrl(chainName), {
			query: proposalByIdQuery,
			variables: { id },
		})
		.then((response) => response?.data);

export const requestVotesByProposalId = async ({
	proposalId,
	chainName,
}: {
	proposalId: string;
	chainName: TNetworkName;
}): Promise<IVotesResponse> =>
	await axios
		.post(getSnapshotGqlBaseUrl(chainName), {
			query: votesByProposalIdQuery,
			variables: { proposal: proposalId },
		})
		.then((response) => response?.data);

export const requestVotingPower = async ({
	voter,
	chainName,
}: {
	voter: TAccountAddress;
	chainName: TNetworkName;
}): Promise<IVotingPowerResponse> =>
	await axios
		.post(getSnapshotGqlBaseUrl(chainName), {
			query: votingPowerBySpaceAndVoterQuery,
			variables: { snapshotSpace: getSnapshotSpace(chainName), voter },
		})
		.then((response) => response?.data);
