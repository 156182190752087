import { createSlice } from '@reduxjs/toolkit';

import {
	requestBnbBalanceThunkAction,
	requestBtcbBalanceThunkAction,
	requestBtcmtBalanceThunkAction,
	requestFbBalancesThunkAction,
	requestUsdtBalanceThunkAction,
} from './balance.thunk-actions';
import { IBalanceState } from './balance-reducer.types';

const initialState: IBalanceState = {
	btcmtBalance: null,
	btcmtBalanceStatus: 'initial',
	bnbBalance: null,
	bnbBalanceStatus: 'initial',
	usdtBalance: null,
	usdtBalanceStatus: 'initial',
	btcbBalance: null,
	btcbBalanceStatus: 'initial',
};

const balanceSlice = createSlice({
	name: 'balance',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(requestBtcmtBalanceThunkAction.pending, (state) => {
				state.btcmtBalance = null;
				state.btcmtBalanceStatus = 'pending';
			})
			.addCase(requestBtcmtBalanceThunkAction.fulfilled, (state, { payload }) => {
				state.btcmtBalanceStatus = 'fulfilled';

				if (payload) {
					state.btcmtBalance = payload;
				}
			})
			.addCase(requestBtcmtBalanceThunkAction.rejected, (state) => {
				state.btcmtBalanceStatus = 'rejected';
			})
			.addCase(requestBnbBalanceThunkAction.pending, (state) => {
				state.bnbBalance = null;
				state.bnbBalanceStatus = 'pending';
			})
			.addCase(requestBnbBalanceThunkAction.fulfilled, (state, { payload }) => {
				state.bnbBalanceStatus = 'fulfilled';

				if (payload) {
					state.bnbBalance = payload;
				}
			})
			.addCase(requestBnbBalanceThunkAction.rejected, (state) => {
				state.bnbBalanceStatus = 'rejected';
			})
			.addCase(requestUsdtBalanceThunkAction.pending, (state) => {
				state.usdtBalance = null;
				state.usdtBalanceStatus = 'pending';
			})
			.addCase(requestUsdtBalanceThunkAction.fulfilled, (state, { payload }) => {
				state.usdtBalanceStatus = 'fulfilled';

				if (payload) {
					state.usdtBalance = payload;
				}
			})
			.addCase(requestUsdtBalanceThunkAction.rejected, (state) => {
				state.usdtBalanceStatus = 'rejected';
			})
			.addCase(requestBtcbBalanceThunkAction.pending, (state) => {
				state.btcbBalance = null;
				state.btcbBalanceStatus = 'pending';
			})
			.addCase(requestBtcbBalanceThunkAction.fulfilled, (state, { payload }) => {
				state.btcbBalanceStatus = 'fulfilled';

				if (payload) {
					state.btcbBalance = payload;
				}
			})
			.addCase(requestBtcbBalanceThunkAction.rejected, (state) => {
				state.btcbBalanceStatus = 'rejected';
			})
			.addCase(requestFbBalancesThunkAction.pending, (state) => {
				state.btcbBalance = null;
				state.btcmtBalance = null;
				state.bnbBalance = null;
				state.usdtBalance = null;
			})
			.addCase(requestFbBalancesThunkAction.fulfilled, (state, { payload }) => {
				if (payload) {
					state.btcbBalance = payload.btcbBalance;
					state.btcmtBalance = payload.btcmtBalance;
					state.bnbBalance = payload.bnbBalance;
					state.usdtBalance = payload.usdtBalance;
				}
			});
	},
});

export const balanceReducer = balanceSlice.reducer;
