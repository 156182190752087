import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { watchAccount, watchNetwork } from '@wagmi/core';
import { bsc } from '@wagmi/core/chains';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { WagmiConfig } from 'wagmi';

import App from './components/logic/App';
import { wagmiConfig } from './config/web3/wagmi.config';
import { firebaseAuth } from './store/api/firebase-api/firebase.config';
import {
	setIsAuthStateReadyAction,
	setUserAuthStatusAction,
} from './store/auth-reducer/auth.reducer';
import { changeChainAction, IRawChain } from './store/chain-reducer/chain.reducer';
import { updateDebugInfoAction } from './store/debug-reducer/debug.reducer';
import { store } from './store/store';
import { StorageKey } from './types/storage/storage-key.enum';
import { minifyWalletString } from './utils/formatters/formatters.utils';
import { notifySuccess, notifyWarning } from './utils/notify/notify.utils';
import { clearAllUpdateItems } from './utils/storage/connection-data/connection-data.utils';
import reportWebVitals from './reportWebVitals';

import 'react-toastify/dist/ReactToastify.css';

dayjs.extend(isBetween);

clearAllUpdateItems();

localStorage.removeItem(StorageKey.OuterRoute);
const outerRoute = window.location.pathname;
localStorage.setItem(StorageKey.OuterRoute, outerRoute);

void firebaseAuth?.authStateReady().then(() => {
	store.dispatch(setIsAuthStateReadyAction(true));
	if (store.getState().authReducer.userAuthStatus !== 'crypto-wallet-connected') {
		store.dispatch(setUserAuthStatusAction('anonymous'));
	}
});

watchAccount(({ address, connector }) => {
	clearAllUpdateItems();
	if (connector) {
		store.dispatch(updateDebugInfoAction({ connectorName: connector.name }));
	} else {
		store.dispatch(updateDebugInfoAction({ connectorName: null }));
	}
	if (address) {
		store.dispatch(setUserAuthStatusAction('crypto-wallet-connected'));
		notifySuccess(`Account connected: ${minifyWalletString(address)}`);
	} else if (store.getState().authReducer.userAuthStatus !== 'anonymous') {
		store.dispatch(setUserAuthStatusAction(null));
	}
});

watchNetwork((network) => {
	const currentChainName = store.getState().chainReducer.chain.name;
	const newChainName = network.chain?.name;
	if (newChainName && newChainName !== currentChainName) {
		clearAllUpdateItems();
		store.dispatch(changeChainAction((network.chain || bsc) as IRawChain));
		notifyWarning(`Switched to ${network.chain?.name}`);
	}
});

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				<WagmiConfig config={wagmiConfig}>
					<App />
				</WagmiConfig>
			</BrowserRouter>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root') as HTMLElement,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
