import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoints';

export const MenuWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	flex-grow: 1;
	gap: 40px;
	width: 100%;
	padding: 0;
	background-color: ${({ theme }) => theme.components.mainMenu.bgColor.primary};
	overflow: auto;

	@media (max-width: ${BreakPoint.TabletTop}) {
		background-color: ${({ theme }) => theme.components.mainMenu.bgColor.alter};
		box-shadow: ${({ theme }) => theme.components.mainMenu.shadow};
	}
`;

export const MenuList = styled.ul`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 40px;
	margin: auto 0;
	padding: 8px;

	@media (max-width: ${BreakPoint.TabletTop}) {
		flex-direction: row;
		justify-content: space-around;
		gap: 16px;
		padding: 8px 17px 32px;
	}

	@media (max-width: ${BreakPoint.MobileTop}) {
		gap: 6px;
		padding: 8px 8px 32px;
	}
`;
