import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	requestActiveWallets,
	requestTotalHistory,
	requestTotalIssued,
	requestTotalMined,
	requestTotalMulti,
	requestTotalStaked,
	requestTotalTreasury,
	requestTvl,
} from 'store/api/stats-api/stats-api.service';
import { TFetchStatus } from 'types/api/fetch-status.type';

export const requestTotalMinedThunkAction = createAsyncThunk('statsApi/totalMined', async () =>
	requestTotalMined().then((data) => data?.value),
);

export const requestTotalStakedThunkAction = createAsyncThunk('statsApi/totalStaked', async () =>
	requestTotalStaked().then((data) => data?.value),
);

export const requestTotalMultiThunkAction = createAsyncThunk('statsApi/totalMulti', async () =>
	requestTotalMulti().then((data) => data?.value),
);

export const requestTvlThunkAction = createAsyncThunk('statsApi/Tvl', async () =>
	requestTvl().then((data) => data?.value),
);

export const requestActiveWalletsThunkAction = createAsyncThunk(
	'statsApi/activeWallets',
	async () => requestActiveWallets().then((data) => data?.value),
);

export const requestTotalHistoryThunkAction = createAsyncThunk('statsApi/totalHistory', async () =>
	requestTotalHistory().then((data) => {
		return data?.data?.filter((item) => item.amount > 0);
	}),
);

export const requestStatsBlockInfoThunkAction = createAsyncThunk(
	'statsApi/statsInfo',
	async (): Promise<{
		totalMined: number | undefined;
		totalMinedStatus: TFetchStatus;
		totalStaked: number | undefined;
		totalStakedStatus: TFetchStatus;
		totalMulti: number | undefined;
		totalMultiStatus: TFetchStatus;
		tvl: number | undefined;
		tvlStatus: TFetchStatus;
		activeWallets: number | undefined;
		activeWalletsStatus: TFetchStatus;
		treasuryBalance: number | undefined;
		treasuryBalanceStatus: TFetchStatus;
		totalIssued: number | undefined;
		totalIssuedStatus: TFetchStatus;
	}> => {
		const totalMinedPromise = requestTotalMined().then((data) => data?.value);
		const totalStakedPromise = requestTotalStaked().then((data) => data?.value);
		const totalMultiPromise = requestTotalMulti().then((data) => data?.value);
		const tvlPromise = requestTvl().then((data) => data?.value);
		const activeWalletsPromise = requestActiveWallets().then((data) => data?.value);
		const treasuryBalancePromise = requestTotalTreasury().then((data) =>
			Number(data?.total_treasury_amount),
		);
		const totalIssuedPromise = requestTotalIssued().then((data) => data?.value);

		const [totalMined, totalStaked, totalMulti, tvl, activeWallets, treasuryBalance, totalIssued] =
			await Promise.allSettled([
				totalMinedPromise,
				totalStakedPromise,
				totalMultiPromise,
				tvlPromise,
				activeWalletsPromise,
				treasuryBalancePromise,
				totalIssuedPromise,
			]);

		return {
			totalMined: totalMined.status === 'fulfilled' ? totalMined.value : undefined,
			totalMinedStatus: totalMined.status,
			totalStaked: totalStaked.status === 'fulfilled' ? totalStaked.value : undefined,
			totalStakedStatus: totalStaked.status,
			totalMulti: totalMulti.status === 'fulfilled' ? totalMulti.value : undefined,
			totalMultiStatus: totalMulti.status,
			tvl: tvl.status === 'fulfilled' ? tvl.value : undefined,
			tvlStatus: tvl.status,
			activeWallets: activeWallets.status === 'fulfilled' ? activeWallets.value : undefined,
			activeWalletsStatus: activeWallets.status,
			treasuryBalance: treasuryBalance.status === 'fulfilled' ? treasuryBalance.value : undefined,
			treasuryBalanceStatus: treasuryBalance.status,
			totalIssued: totalIssued.status === 'fulfilled' ? totalIssued.value : undefined,
			totalIssuedStatus: totalIssued.status,
		};
	},
);
