import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { requestBtcmtBalanceThunkAction } from 'store/balance-reducer/balance.thunk-actions';
import { selectChainName } from 'store/chain-reducer/chain.selectors';
import {
	requestUserAutoStakesV2ThunkAction,
	requestUserStakesThunkAction,
} from 'store/staking-reducers/staking-balances-reducer/staking-balance.thunk-actions';
import { useAppDispatch } from 'store/store';
import { TAccountAddress } from 'types/blockchain/contracts.types';

export const useGlobalDispatches = (walletAddress: TAccountAddress | null | undefined): void => {
	const dispatch = useAppDispatch();
	const chainName = useSelector(selectChainName);

	useLayoutEffect(() => {
		if (walletAddress && chainName) {
			void dispatch(requestBtcmtBalanceThunkAction({ accountAddress: walletAddress, chainName }));
			void dispatch(requestUserStakesThunkAction({ accountAddress: walletAddress, chainName }));
			void dispatch(
				requestUserAutoStakesV2ThunkAction({ accountAddress: walletAddress, chainName }),
			);
		}
	}, [dispatch, walletAddress, chainName]);
};
