import { TAccountAddress } from 'types/blockchain/contracts.types';

// TODO: replace enum with as const object
export enum FbGoalName {
  WalletConnected = 'Lead',
  StartPurchase = 'InitiateCheckout',
  PurchaseSuccess = 'Purchase',
}

export type TFbEvent = 'track';
type TFbEventId =
  | 'BTCMT'
  | 'BTCMT_staking_auto'
  | 'BTCMT_unstaking_auto'
  | 'BTCMT_staking_manual'
  | 'BTCMT_unstaking_manual'
  | 'BTCMT_claim'
  | 'none';

export interface IWalletConnectedOptions {
  content_name: string;
}

export interface IPurchaseOptions {
  event_ids: [TFbEventId];
  eventRef: TAccountAddress; // account address
  currency?: 'USD';
  num_items: number; // number of BTCMT
  value: number; // total value in USD
}

export type TFbGoalOptions = IWalletConnectedOptions | IPurchaseOptions;
export type TWindowWithFb = {
  gtag: (event: TFbEvent, goal: FbGoalName, options?: TFbGoalOptions) => void;
};
