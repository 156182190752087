import { TNetworkName } from 'types/blockchain/contracts.types';

const snapshotApiKey = process.env?.REACT_APP_SNAPSHOT_API_KEY || '';

export const getSnapshotGqlBaseUrl = (chainName: TNetworkName): string => {
	switch (chainName) {
		case 'bsc':
			return `https://hub.snapshot.org/graphql/?apiKey=${snapshotApiKey}`;
		case 'bsc-testnet':
			return `https://testnet.hub.snapshot.org/graphql/?apiKey=${snapshotApiKey}`;
	}
};
