import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	requestDexEstimation,
	requestDexExchange,
	requestDexTransactions,
} from 'store/api/fireblocks-api/services/fireblocks-dex.sevice';
import { IDexLastTransaction } from 'store/api/fireblocks-api/types/dex/dex-last-transaction.interface';
import { IDexPurchaseRequestBody } from 'store/api/fireblocks-api/types/dex/dex-purchase-request-body.interface';
import {
	requestBnbBalanceThunkAction,
	requestBtcbBalanceThunkAction,
	requestBtcmtBalanceThunkAction,
	requestUsdtBalanceThunkAction,
} from 'store/balance-reducer/balance.thunk-actions';
import { TRootState } from 'store/store';

import { notifyError } from 'utils/notify/notify.utils';

import {
	resetLastDexTransactionAction,
	setBuyFromDexFlowStepAction,
	setShouldCheckDexTransactionsAction,
} from './web2-dex.reducer';

export const requestDexEstimationThunkAction = createAsyncThunk(
	'purchase/dex-estimation',
	async (body: IDexPurchaseRequestBody) => {
		return requestDexEstimation(body).catch((error) => {
			notifyError('Failed to get estimation');
			throw error;
		});
	},
);

export const requestDexExchangeThunkAction = createAsyncThunk(
	'purchase/dex-purchase',
	async (body: IDexPurchaseRequestBody, { dispatch }) => {
		dispatch(setBuyFromDexFlowStepAction('purchase-requested'));
		dispatch(resetLastDexTransactionAction());

		return requestDexExchange(body)
			.then(() => {
				dispatch(setShouldCheckDexTransactionsAction(true));
				dispatch(setBuyFromDexFlowStepAction('purchase-pending'));
				void dispatch(checkDexLastTransactionThunkAction());
			})
			.catch((error) => {
				dispatch(setBuyFromDexFlowStepAction('purchase-error'));
				throw error;
			});
	},
);

export const checkDexLastTransactionThunkAction = createAsyncThunk(
	'purchase/check-transaction',
	async (_, { dispatch, getState }): Promise<IDexLastTransaction> => {
		return await requestDexTransactions()
			.then((transactions) => {
				const accountAddress = (getState() as TRootState).authReducer.userWalletData?.address;
				const chainName = (getState() as TRootState).chainReducer.chain.network;

				const result: IDexLastTransaction = {
					isFailed: null,
					isPending: null,
					isFulfilled: null,
					data: null,
				};

				if (!transactions || transactions.length === 0) {
					return result;
				}

				const lastTransaction = transactions[0];
				result.data = lastTransaction;

				switch (lastTransaction.status) {
					case 'SWAPED':
						result.isFulfilled = true;
						if (accountAddress && chainName) {
							void dispatch(requestBtcmtBalanceThunkAction({ accountAddress, chainName }));
							void dispatch(requestBnbBalanceThunkAction({ accountAddress, chainName }));
							void dispatch(requestUsdtBalanceThunkAction({ accountAddress, chainName }));
							void dispatch(requestBtcbBalanceThunkAction({ accountAddress, chainName }));
						}
						break;
					case 'ERROR':
						result.isFailed = true;
						if (accountAddress && chainName) {
							void dispatch(requestBtcmtBalanceThunkAction({ accountAddress, chainName }));
							void dispatch(requestBnbBalanceThunkAction({ accountAddress, chainName }));
							void dispatch(requestUsdtBalanceThunkAction({ accountAddress, chainName }));
							void dispatch(requestBtcbBalanceThunkAction({ accountAddress, chainName }));
						}
						break;
					default:
						result.isPending = true;
						break;
				}

				return result;
			})
			.catch((error) => {
				notifyError('Failed to get transactions info');
				throw error;
			});
	},
);
