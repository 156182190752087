import { AxiosResponse } from 'axios';
import { fireblocksApi } from 'store/api/fireblocks-api/fireblocks.api';
import { FireblocksApiRoute } from 'store/api/fireblocks-api/fireblocks-api-route';
import { IDexEstimationResponse } from 'store/api/fireblocks-api/types/dex/dex-estimation-response.interface';
import { IDexPurchaseRequestBody } from 'store/api/fireblocks-api/types/dex/dex-purchase-request-body.interface';
import { IDexTransactionItem } from 'store/api/fireblocks-api/types/dex/dex-transaction-item.interface';

export const requestDexExchange = (
	body: IDexPurchaseRequestBody,
): Promise<IDexPurchaseRequestBody> =>
	fireblocksApi
		.post<
			IDexPurchaseRequestBody,
			AxiosResponse<IDexPurchaseRequestBody>,
			IDexPurchaseRequestBody
		>(FireblocksApiRoute.Dex(), body)
		.then((res) => res.data);

export const requestDexEstimation = (
	params: IDexPurchaseRequestBody,
): Promise<IDexEstimationResponse> =>
	fireblocksApi
		.get<
			IDexEstimationResponse,
			AxiosResponse<IDexEstimationResponse>,
			{
				params: IDexPurchaseRequestBody;
			}
		>(FireblocksApiRoute.DexInfo(), {
			params,
		})
		.then((res) => res.data);

export const requestDexTransactions = (): Promise<IDexTransactionItem[]> =>
	fireblocksApi.get<IDexTransactionItem[]>(FireblocksApiRoute.Dex()).then((res) => res.data);
