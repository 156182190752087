import { createAsyncThunk } from '@reduxjs/toolkit';
import { readContract } from '@wagmi/core';
import { TransactionErrorMessage } from 'constants/message/transaction-error-message.const';
import { requestBtcbBalanceThunkAction } from 'store/balance-reducer/balance.thunk-actions';
import { setClaimFlowStepAction } from 'store/staking-reducers/staking-flow-reducer/staking-flow.reducer';
import { TGetStateFn, TRootState } from 'store/store';
import { processClaimReward } from 'store/utils/claim/claim.utils';
import { populateErrorReport, sendErrorReport } from 'store/utils/error-report/error-report.utils';
import {
	ContractName,
	ITransactionResponse,
	TAccountAddress,
	TNetworkName,
	TStakingContractConfig,
} from 'types/blockchain/contracts.types';
import { formatEther } from 'viem';

import { bscContractsConfig, bscTestnetContractsConfig } from 'config/web3/contracts.config';
import { floorBtcValue } from 'utils/formatters/formatters.utils';
import { FbGoalName } from 'utils/metrics/fb/fb.types';
import { sendFbGoal } from 'utils/metrics/fb/fb.utils';
import { sendGtagGoalPurchase } from 'utils/metrics/gtag/gtag.utils';
import { YMGoal } from 'utils/metrics/ym/ym.types';
import { reportEcommerce, sendYmGoal } from 'utils/metrics/ym/ym.utils';

export const requestAvailableToClaimThunkAction = createAsyncThunk(
	'contracts/availableToClaim',
	async (
		{ accountAddress, chainName }: { accountAddress: TAccountAddress; chainName: TNetworkName },
		{ getState },
	) => {
		const chainId = (getState() as TRootState).chainReducer.chain.id;

		if (!accountAddress || !chainName || !chainId) {
			return null;
		}

		let contractConfig;

		switch (chainName) {
			case 'bsc':
				contractConfig = bscContractsConfig[ContractName.StakingBsc];
				break;
			case 'bsc-testnet':
				contractConfig = bscTestnetContractsConfig[ContractName.StakingBsc];
				break;
		}

		return await readContract({
			address: contractConfig.address,
			abi: contractConfig.abi,
			functionName: 'getCurrentUserReward',
			args: [accountAddress],
			chainId,
		}).then((res) => floorBtcValue(formatEther(res)));
	},
);

export const claimManualRewardThunkAction = createAsyncThunk(
	'claim/manualReward',
	async (
		{
			accountAddress,
			chainName,
			amount,
		}: {
			accountAddress: TAccountAddress | null | undefined;
			chainName: TNetworkName;
			amount: string;
		},
		{ getState, dispatch },
	): Promise<ITransactionResponse> => {
		dispatch(setClaimFlowStepAction('claim-pending'));
		const state = getState() as TRootState;
		const chainId = state.chainReducer.chain.id;
		const { availableToClaim } = state.web3ClaimReducer;
		const btcmtMarketPrice = state.ratesReducer.btcmtUsdtRate || 0;

		if (!accountAddress || !availableToClaim || !chainId || !chainName) {
			throw new Error(TransactionErrorMessage.IncorrectOperation);
		}

		let stakingContractConfig: TStakingContractConfig;

		switch (chainName) {
			case 'bsc':
				stakingContractConfig = bscContractsConfig[ContractName.StakingBsc];
				break;
			case 'bsc-testnet':
				stakingContractConfig = bscTestnetContractsConfig[ContractName.StakingBsc];
				break;
		}

		return processClaimReward({
			getState: getState as TGetStateFn,
			dispatch,
			accountAddress,
			chainId,
			amount,
			stakingContractConfig,
		})
			.then(({ hash }) => {
				dispatch(setClaimFlowStepAction('claim-success'));

				try {
					reportEcommerce('purchase', {
						coupon: accountAddress,
						price: btcmtMarketPrice,
						quantity: Number(availableToClaim),
						name: 'BTCMT_claim',
						txId: hash,
					});
				} catch (error) {
					console.error(error);
				}

				try {
					sendYmGoal(YMGoal.ClaimReward);
				} catch (error) {
					console.error(error);
				}

				try {
					sendGtagGoalPurchase({
						transaction_id: hash,
						coupon: accountAddress,
						value: Number(availableToClaim) * btcmtMarketPrice,
						items: [
							{
								item_name: 'BTCMT_claim',
								price: btcmtMarketPrice,
								quantity: Number(availableToClaim),
							},
						],
					});
				} catch (error) {
					console.error(error);
				}

				try {
					sendFbGoal(FbGoalName.PurchaseSuccess, {
						currency: 'USD',
						value: Number(availableToClaim) * btcmtMarketPrice,
						event_ids: ['BTCMT_claim'],
						num_items: Number(availableToClaim),
						eventRef: accountAddress,
					});
				} catch (error) {
					console.error(error);
				}

				return { hash, amount: String(availableToClaim || 0) };
			})
			.catch((error: Error) => {
				dispatch(setClaimFlowStepAction('claim-error'));
				const state = getState() as TRootState;
				const errorReportData = populateErrorReport({
					state,
					accountAddress,
				});
				sendErrorReport(errorReportData);
				throw error;
			})
			.finally(() => {
				void dispatch(requestAvailableToClaimThunkAction({ accountAddress, chainName }));
				void dispatch(requestBtcbBalanceThunkAction({ accountAddress, chainName }));
			});
	},
);
