import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestFbClaimHistory } from 'store/api/fireblocks-api/services/fireblocks-claim.service';
import { requestFbStakingDeposits } from 'store/api/fireblocks-api/services/fireblocks-staking.service';
import { TRootState } from 'store/store';
import { categorizeClaimTransaction } from 'store/utils/transactions/categorize-claim-transaction.util';
import { categorizeStakingTransaction } from 'store/utils/transactions/categorize-staking-transaction.util';

import { IClaimTransactionsStatuses } from './types/claim-transactions-statuses.interface';
import { IStakingTransactionsStatuses } from './types/staking-transactions-statuses.interface';

export const checkPendingStakingTransactionsThunkAction = createAsyncThunk(
	'staking-flow/staking-transactions',
	async (_, { getState }): Promise<IStakingTransactionsStatuses> => {
		const userAuthStatus = (getState() as TRootState).authReducer.userAuthStatus;
		const isCryptoUser = userAuthStatus === 'crypto-wallet-connected';

		const result: IStakingTransactionsStatuses = {
			pendingStakeTransactions: [],
			pendingWithdrawTransactions: [],
			failedStakingTransactions: [],
		};

		if (isCryptoUser) {
			return result;
		}

		const stakingTransactions = await requestFbStakingDeposits();

		stakingTransactions.forEach((item) => {
			if (item) {
				const transactionStatus = categorizeStakingTransaction(item.status);
				switch (transactionStatus) {
					case 'staking':
						result.pendingStakeTransactions.push(item.type);
						break;
					case 'withdrawing':
						result.pendingWithdrawTransactions.push(item.type);
						break;
					case 'error':
						result.failedStakingTransactions.push(item.type);
				}
			}
		});

		return result;
	},
);

export const checkPendingClaimTransactionsThunkAction = createAsyncThunk(
	'staking-flow/claim-transactions',
	async (_, { getState }): Promise<IClaimTransactionsStatuses> => {
		const userAuthStatus = (getState() as TRootState).authReducer.userAuthStatus;
		const isCryptoUser = userAuthStatus === 'crypto-wallet-connected';

		const result: IClaimTransactionsStatuses = {
			pendingClaimTransactions: [],
			failedClaimTransactions: [],
		};

		if (isCryptoUser) {
			return result;
		}

		const claimTransactions = await requestFbClaimHistory();
		const [lastTransaction] = claimTransactions.results;

		if (lastTransaction) {
			const transactionStatus = categorizeClaimTransaction(lastTransaction?.status);
			switch (transactionStatus) {
				case 'claiming':
					result.pendingClaimTransactions.push('Manual');
					break;
				case 'error':
					result.failedClaimTransactions.push('Manual');
			}
		}

		return result;
	},
);
