import { fetchBalance, readContract } from '@wagmi/core';
import {
  TAccountAddress,
  TBtcbBscContractConfig,
  TBtcmtBscContractConfig,
  TUsdtBscContractConfig,
} from 'types/blockchain/contracts.types';
import { formatEther } from 'viem';

export const getBtcmtBalance = async ({
  accountAddress,
  chainId,
  contractConfig,
}: {
  accountAddress: TAccountAddress;
  chainId: number;
  contractConfig: TBtcmtBscContractConfig;
}): Promise<number | undefined> => {
  return readContract({
    address: contractConfig.address,
    abi: contractConfig.abi,
    functionName: 'balanceOf',
    args: [accountAddress],
    chainId,
  }).then((res) => Number(formatEther(res)));
};

export const getBnbBalance = async ({
  accountAddress,
  chainId,
}: {
  accountAddress: TAccountAddress;
  chainId: number;
}): Promise<number | undefined> => {
  return fetchBalance({
    address: accountAddress,
    chainId,
  }).then((res) => Number(res.formatted));
};

export const getUsdtBalance = async ({
  accountAddress,
  chainId,
  contractConfig,
}: {
  accountAddress: TAccountAddress;
  chainId: number;
  contractConfig: TUsdtBscContractConfig;
}): Promise<number | undefined> => {
  return readContract({
    address: contractConfig.address,
    abi: contractConfig.abi,
    functionName: 'balanceOf',
    args: [accountAddress],
    chainId,
  }).then((res) => Number(formatEther(res)));
};

export const getBtcbBalance = async ({
  accountAddress,
  chainId,
  contractConfig,
}: {
  accountAddress: TAccountAddress;
  chainId: number;
  contractConfig: TBtcbBscContractConfig;
}): Promise<number | undefined> => {
  return readContract({
    address: contractConfig.address,
    abi: contractConfig.abi,
    functionName: 'balanceOf',
    args: [accountAddress],
    chainId,
  }).then((res) => Number(formatEther(res)));
};
