import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import {
	requestBnbBalanceThunkAction,
	requestBtcmtBalanceThunkAction,
} from 'store/balance-reducer/balance.thunk-actions';
import { selectChainName } from 'store/chain-reducer/chain.selectors';
import {
	requestUserAutoStakesV2ThunkAction,
	requestUserStakesThunkAction,
} from 'store/staking-reducers/staking-balances-reducer/staking-balance.thunk-actions';
import {
	selectAutoFarmFlowStep,
	selectClaimFlowStep,
	selectStakingFlowStep,
} from 'store/staking-reducers/staking-flow-reducer/staking-flow.selectors';
import { requestAvailableToClaimThunkAction } from 'store/staking-reducers/web3-claim-reducer/web3-claim.thunk-actions';
import { useAppDispatch } from 'store/store';
import { TAccountAddress } from 'types/blockchain/contracts.types';

export const useUpdateStakingBalances = ({
	accountAddress,
}: {
	accountAddress: TAccountAddress | null | undefined;
}): void => {
	const dispatch = useAppDispatch();
	const chainName = useSelector(selectChainName);
	const stakingFlowStep = useSelector(selectStakingFlowStep);
	const autofarmFlowStep = useSelector(selectAutoFarmFlowStep);
	const claimFlowStep = useSelector(selectClaimFlowStep);

	const isTransactionComplete =
		stakingFlowStep === 'stake-success' ||
		stakingFlowStep === 'stake-notify-success' ||
		stakingFlowStep === 'stake-error' ||
		stakingFlowStep === 'stake-notify-error' ||
		stakingFlowStep === 'withdraw-success' ||
		stakingFlowStep === 'withdraw-error' ||
		stakingFlowStep === 'withdraw-notify-success' ||
		stakingFlowStep === 'withdraw-notify-error' ||
		autofarmFlowStep === 'stake-success' ||
		autofarmFlowStep === 'stake-notify-success' ||
		autofarmFlowStep === 'stake-error' ||
		autofarmFlowStep === 'stake-notify-error' ||
		autofarmFlowStep === 'withdraw-success' ||
		autofarmFlowStep === 'withdraw-error' ||
		autofarmFlowStep === 'withdraw-notify-success' ||
		autofarmFlowStep === 'withdraw-notify-error' ||
		claimFlowStep === 'claim-success' ||
		claimFlowStep === 'claim-notify-success' ||
		claimFlowStep === 'claim-error' ||
		claimFlowStep === 'claim-notify-error';

	useLayoutEffect(() => {
		if (isTransactionComplete && accountAddress && chainName) {
			void dispatch(requestBtcmtBalanceThunkAction({ accountAddress, chainName }));
			void dispatch(requestBnbBalanceThunkAction({ accountAddress, chainName }));
			void dispatch(requestUserAutoStakesV2ThunkAction({ accountAddress, chainName }));
			void dispatch(requestUserStakesThunkAction({ accountAddress, chainName }));
			void dispatch(requestAvailableToClaimThunkAction({ accountAddress, chainName }));
		}
	}, [dispatch, accountAddress, chainName, isTransactionComplete]);
};
