import { useEffect } from 'react';
import { setUserAuthStatusAction } from 'store/auth-reducer/auth.reducer';
import { useAppDispatch } from 'store/store';
import { useAccount } from 'wagmi';

export const useWalletAutoConnection = (): void => {
	const dispatch = useAppDispatch();
	const { address } = useAccount();

	useEffect(() => {
		if (address) {
			dispatch(setUserAuthStatusAction('crypto-wallet-connected'));
		}
	}, [address, dispatch]);
};
