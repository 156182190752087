import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	requestProposalById,
	requestProposals,
	requestVotesByProposalId,
	requestVotingPower,
} from 'store/api/snapshot-api/snapshot-gql.service';
import { requestDelegations } from 'store/api/snapshot-api/subgrapher-gql.service';
import { IVotesResponseItem } from 'store/api/snapshot-api/types/response/votes-response-item.interface';
import { TAccountAddress, TNetworkName } from 'types/blockchain/contracts.types';
import { IDaoProposal } from 'types/dao/dao-vote.interface';

import { notifyError } from 'utils/notify/notify.utils';

export const requestProposalsAndVpThunkAction = createAsyncThunk(
	'dao/proposalsAndVp',
	async ({
		voter,
		chainName,
	}: {
		voter: TAccountAddress;
		chainName: TNetworkName;
	}): Promise<{
		proposals: IDaoProposal[] | null;
		vp: number | null;
		isVotingPowerDelegated: boolean;
	}> => {
		const proposalsPromise = requestProposals(chainName).then((data) => data?.data.proposals);

		const vpPromise = requestVotingPower({ voter, chainName }).then((data) => data?.data.vp.vp);

		const isDelegatedPromise = requestDelegations({ address: voter, chainName }).then((data) => {
			const delegations = data?.data?.delegations;
			return delegations && delegations.length > 0 && !!delegations[0].delegate;
		});

		return Promise.all([proposalsPromise, vpPromise, isDelegatedPromise]).then((data) => {
			const [proposals, vp, isVotingPowerDelegated] = data;
			return { proposals, vp, isVotingPowerDelegated };
		});
	},
);

export const requestProposalAndVotesThunkAction = createAsyncThunk(
	'dao/proposalAndVotes',
	async ({
		proposalId,
		chainName,
	}: {
		proposalId: string;
		chainName: TNetworkName;
	}): Promise<{
		proposal: IDaoProposal | null;
		votes: IVotesResponseItem[] | null;
	}> => {
		const proposalPromise = requestProposalById({ id: proposalId, chainName }).then(
			(data) => data?.data?.proposal,
		);
		const votesPromise = requestVotesByProposalId({ proposalId, chainName }).then(
			(data) => data?.data?.votes,
		);

		return Promise.all([proposalPromise, votesPromise])
			.then((data) => {
				const [proposal, votes] = data;
				return { proposal, votes };
			})
			.catch((error) => {
				notifyError('Failed to load data...');

				throw error;
			});
	},
);

export const refreshProposalAndVotesThunkAction = createAsyncThunk(
	'dao/refreshProposalAndVotes',
	async ({
		proposalId,
		chainName,
	}: {
		proposalId: string;
		chainName: TNetworkName;
	}): Promise<{
		proposal: IDaoProposal | null;
		votes: IVotesResponseItem[] | null;
	}> => {
		const proposalPromise = requestProposalById({ id: proposalId, chainName }).then(
			(data) => data?.data?.proposal,
		);
		const votesPromise = requestVotesByProposalId({ proposalId, chainName }).then(
			(data) => data?.data?.votes,
		);

		return Promise.all([proposalPromise, votesPromise]).then((data) => {
			const [proposal, votes] = data;
			return { proposal, votes };
		});
	},
);
