import { createSlice } from '@reduxjs/toolkit';
import { IGetInvoicesResponse } from 'store/api/fireblocks-api/types/invoices/get-invoices-response.interface';
import { IPurchaseInvoiceResponse } from 'store/api/fireblocks-api/types/invoices/purchase-invoice-response.interface';
import { TFetchStatus } from 'types/api/fetch-status.type';

import {
	getInvoicesThunkAction,
	postPurchaseInvoiceThunkAction,
} from './web2-invoice.thunk-actions';

export interface IWeb2InvoiceState {
	invoiceErrorMessage: string | null;
	postInvoiceStatus: TFetchStatus;
	postedInvoice: IPurchaseInvoiceResponse | null;
	invoicesListStatus: TFetchStatus;
	invoices: IGetInvoicesResponse | null;
}

const initialState: IWeb2InvoiceState = {
	invoiceErrorMessage: null,
	postInvoiceStatus: 'initial',
	invoicesListStatus: 'initial',
	invoices: null,
	postedInvoice: null,
};

const web2PurchaseSlice = createSlice({
	name: 'web2-invoices',
	initialState,
	reducers: {
		resetPostedInvoiceAction: (state) => {
			state.invoiceErrorMessage = null;
			state.postInvoiceStatus = 'initial';
			state.postedInvoice = null;
			state.invoices = null;
			state.postedInvoice = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(postPurchaseInvoiceThunkAction.pending, (state) => {
				state.postInvoiceStatus = 'pending';
			})
			.addCase(postPurchaseInvoiceThunkAction.fulfilled, (state, { payload }) => {
				state.postInvoiceStatus = 'fulfilled';
				if (payload) {
					state.postedInvoice = payload;
				}
			})
			.addCase(postPurchaseInvoiceThunkAction.rejected, (state, { error }) => {
				state.postInvoiceStatus = 'rejected';
				if (error) {
					state.invoiceErrorMessage = JSON.stringify(error);
				}
			})
			.addCase(getInvoicesThunkAction.pending, (state) => {
				state.invoicesListStatus = 'pending';
			})
			.addCase(getInvoicesThunkAction.fulfilled, (state, { payload }) => {
				state.invoicesListStatus = 'fulfilled';
				if (payload) {
					state.invoices = payload;
				}
			})
			.addCase(getInvoicesThunkAction.rejected, (state, { error }) => {
				state.invoicesListStatus = 'rejected';
				if (error) {
					state.invoiceErrorMessage = JSON.stringify(error);
				}
			})
	},
});

export const { resetPostedInvoiceAction } = web2PurchaseSlice.actions;
export const web2InvoiceReducer = web2PurchaseSlice.reducer;
