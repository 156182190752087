import { IEcommerceReportParams, TEcommerceActionKey, TWindowWithYm, YMGoal } from './ym.types';

export const YM_COUNTER = 85288888;

export const sendYmGoal = (goal: YMGoal): void => {
	if ((window as unknown as TWindowWithYm).ym) {
		(window as unknown as TWindowWithYm).ym(YM_COUNTER, 'reachGoal', goal);
	}
};

export const reportEcommerce = (
	action: TEcommerceActionKey,
	params: IEcommerceReportParams,
): void => {
	(window as unknown as TWindowWithYm).dataLayer?.push({
		ecommerce: {
			currencyCode: 'USD',
			[action]: {
				actionField: {
					id: params.txId,
					coupon: params.coupon,
					revenue: params.quantity,
				},
				products: [params],
			},
		},
	});
};
