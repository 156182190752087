import { fireblocksApi } from 'store/api/fireblocks-api/fireblocks.api';
import { FireblocksApiRoute } from 'store/api/fireblocks-api/fireblocks-api-route';
import { IEstimateGasResponse } from 'store/api/fireblocks-api/types/gas/estimate-gas-response.interface';
import { TTransactionGasType } from 'store/api/fireblocks-api/types/gas/transaction-gas-type.type';

export const requestGasEstimation = async (
	transactionType: TTransactionGasType,
): Promise<IEstimateGasResponse> =>
	await fireblocksApi
		.get<IEstimateGasResponse>(FireblocksApiRoute.EstimateGas(transactionType))
		.then((res) => res?.data);
