import React, { ReactElement } from 'react';

import { DOTS_BOUNCE_TIME_DELAYS } from './DotsLoader.config';

import * as S from './DotsLoader.styled';

const DotsLoader = (): ReactElement => (
	<S.LoaderContainer data-testid="loader-container">
		{DOTS_BOUNCE_TIME_DELAYS.map((timeDelay, index) => (
			<S.LoaderDot $animationDelayInSeconds={timeDelay} key={index} data-testid="loader-dot" />
		))}
	</S.LoaderContainer>
);

export default React.memo(DotsLoader);
