import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDexEstimationResponse } from 'store/api/fireblocks-api/types/dex/dex-estimation-response.interface';
import { IDexLastTransaction } from 'store/api/fireblocks-api/types/dex/dex-last-transaction.interface';
import {
	checkDexLastTransactionThunkAction,
	requestDexEstimationThunkAction,
	requestDexExchangeThunkAction,
} from 'store/purchase-reducers/web2-dex-reducer/web2-dex.thunk-actions';
import { TFetchStatus } from 'types/api/fetch-status.type';
import { TBuyFromDexFlowStep } from 'types/dex/buy-from-dex-flow-step.type';

export interface IWeb2DexState {
	buyFromDexFlowStep: TBuyFromDexFlowStep;
	dexErrorMessage: string | null;
	dexEstimationStatus: TFetchStatus;
	dexEstimation: IDexEstimationResponse | null;
	dexExchangeStatus: TFetchStatus;
	shouldCheckDexTransactions: boolean;
	pendingDexTransactionsCount: number;
	transactionsCheckStatus: TFetchStatus;
	dexLastTransaction: IDexLastTransaction;
}

const initialState: IWeb2DexState = {
	buyFromDexFlowStep: null,
	dexErrorMessage: null,
	dexEstimationStatus: 'initial',
	dexEstimation: null,
	dexExchangeStatus: 'initial',
	shouldCheckDexTransactions: true,
	pendingDexTransactionsCount: 0,
	transactionsCheckStatus: 'initial',
	dexLastTransaction: {
		isFailed: null,
		isPending: null,
		isFulfilled: null,
		data: null,
	},
};

const web2DexSlice = createSlice({
	name: 'web2-dex',
	initialState,
	reducers: {
		setShouldCheckDexTransactionsAction: (state, { payload }) => {
			state.shouldCheckDexTransactions = payload;
		},
		setBuyFromDexFlowStepAction: (state, action: PayloadAction<TBuyFromDexFlowStep>) => {
			state.buyFromDexFlowStep = action.payload;
		},
		resetLastDexTransactionAction: (state) => {
			state.dexLastTransaction = {
				isFailed: null,
				isPending: null,
				isFulfilled: null,
				data: null,
			};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(requestDexEstimationThunkAction.pending, (state) => {
				state.dexEstimationStatus = 'pending';
			})
			.addCase(requestDexEstimationThunkAction.fulfilled, (state, { payload }) => {
				state.dexEstimationStatus = 'fulfilled';
				if (payload) {
					state.dexEstimation = payload;
				}
			})
			.addCase(requestDexEstimationThunkAction.rejected, (state, { error }) => {
				state.dexEstimationStatus = 'rejected';
				if (error) {
					state.dexErrorMessage = JSON.stringify(error);
				}
			})
			.addCase(requestDexExchangeThunkAction.pending, (state) => {
				state.dexExchangeStatus = 'pending';
			})
			.addCase(requestDexExchangeThunkAction.fulfilled, (state) => {
				state.dexExchangeStatus = 'fulfilled';
			})
			.addCase(requestDexExchangeThunkAction.rejected, (state, { error }) => {
				state.dexExchangeStatus = 'rejected';
				if (error) {
					state.dexErrorMessage = JSON.stringify(error);
				}
			})
			.addCase(checkDexLastTransactionThunkAction.pending, (state) => {
				state.transactionsCheckStatus = 'pending';
			})
			.addCase(checkDexLastTransactionThunkAction.fulfilled, (state, { payload }) => {
				state.transactionsCheckStatus = 'fulfilled';
				state.dexLastTransaction = payload;
				if ((payload.isFulfilled || payload.isFailed || !payload.data) && !payload.isPending) {
					state.shouldCheckDexTransactions = false;
				}
			})
			.addCase(checkDexLastTransactionThunkAction.rejected, (state, { error }) => {
				state.transactionsCheckStatus = 'rejected';
				state.shouldCheckDexTransactions = false;
				state.dexErrorMessage = JSON.stringify(error);
			});
	},
});

export const {
	setShouldCheckDexTransactionsAction,
	setBuyFromDexFlowStepAction,
	resetLastDexTransactionAction,
} = web2DexSlice.actions;
export const web2DexReducer = web2DexSlice.reducer;
