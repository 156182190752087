import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TThemeMode } from 'types/theme-mode/theme-mode.type';

import { setThemeToLocalStorage } from 'utils/storage/theme/set-theme-to-local-storage.util';

export interface IThemeState {
	theme: TThemeMode;
}

const initialState: IThemeState = {
	theme: 'dark',
};

const themeSlice = createSlice({
	name: 'theme',
	initialState,
	reducers: {
		changeThemeAction: (state, action: PayloadAction<TThemeMode>) => {
			state.theme = action.payload;
			setThemeToLocalStorage(action.payload);
		},
	},
});

export const { changeThemeAction } = themeSlice.actions;
export const themeReducer = themeSlice.reducer;
