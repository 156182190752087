import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	requestFiatPaymentData,
	requestFiatPaymentRequests,
} from 'store/api/fireblocks-api/services/fireblocks-fiat.service';
import { requestFireblocksRates } from 'store/api/fireblocks-api/services/fireblocks-rates.service';
import { IFiatPaymentResponse } from 'store/api/fireblocks-api/types/fiat/fiat-payment-response.interface';
import { TRootState } from 'store/store';
import { IFiatPaymentItem } from 'types/fiat-payments/fiat-payment-info-item.interface';

import { notifyError } from 'utils/notify/notify.utils';

import { FIAT_PAYMENTS_DEFAULT_PAGE_NUMBER } from './constants/fiat-payments-default-page-number';
import { FIAT_PAYMENTS_ITEMS_PER_PAGE } from './constants/fiat-payments-items-per-page';

export const requestFireblocksBtcmtRateThunkAction = createAsyncThunk(
	'rates/fireblocks-rates',
	async (): Promise<number | undefined> => {
		return requestFireblocksRates('bsc').then((data) => {
			const btcmtRate = data.find((rate) => rate.symbol === 'BTCMT')?.rate;
			return Number(btcmtRate) || undefined;
		});
	},
);

export const requestFiatPaymentDataThunkAction = createAsyncThunk(
	'fiat/request-payment',
	async (
		amountToPay: string,
		{ dispatch, getState },
	): Promise<IFiatPaymentResponse | undefined> => {
		await dispatch(requestFireblocksBtcmtRateThunkAction());
		const btcmtUsdtRate = (getState() as TRootState).web2FiatReducer.btcmtUsdtRate;
		const numberAmountToPay = Number(amountToPay);

		if (!btcmtUsdtRate || btcmtUsdtRate === 0 || isNaN(numberAmountToPay)) {
			return;
		}

		const btcmtAmountToReceive = numberAmountToPay / btcmtUsdtRate;

		return requestFiatPaymentData({
			usd_amount_to_pay: numberAmountToPay,
			btcmt_amount_to_recieve: btcmtAmountToReceive,
		}).catch((error) => {
			notifyError('Failed to request payment data due to an server error. Please try again later.');
			throw error;
		});
	},
);

export const requestFiatPaymentsThunkAction = createAsyncThunk(
	'fiat/request-payments',
	async (): Promise<IFiatPaymentItem[]> => {
		return requestFiatPaymentRequests({
			page_number: FIAT_PAYMENTS_DEFAULT_PAGE_NUMBER,
			items_per_page: FIAT_PAYMENTS_ITEMS_PER_PAGE,
		}).then((data) => data.results);
	},
);
