import { Id, toast, ToastOptions } from 'react-toastify';

import Toast from 'components/ui/Toast/Toast';

const toastOptions = {
	autoClose: 5000,
	hideProgressBar: true,
	closeOnClick: false,
	pauseOnHover: true,
	draggable: false,
	progress: undefined,
	dataTouch: true,
} as ToastOptions;

const ERROR_AUTO_CLOSE_TIME = 10000;

export const notifySuccess = (text: string): Id =>
	toast(<Toast type="success">{text}</Toast>, toastOptions);

export const notifyWarning = (text: string): Id =>
	toast(<Toast type="warning">{text}</Toast>, toastOptions);

export const notifyError = (text: string): Id =>
	toast(<Toast type="error">{text}</Toast>, { ...toastOptions, autoClose: ERROR_AUTO_CLOSE_TIME });
