import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectIsCryptoUser } from 'store/auth-reducer/auth.selectors';
import {
	setAutoFarmFlowStepAction,
	setClaimFlowStepAction,
	setStakingFlowStepAction,
} from 'store/staking-reducers/staking-flow-reducer/staking-flow.reducer';
import {
	selectAutoFarmFlowStep,
	selectClaimFlowStep,
	selectStakingFlowStep,
} from 'store/staking-reducers/staking-flow-reducer/staking-flow.selectors';
import { useAppDispatch } from 'store/store';

import { notifyError, notifySuccess } from 'utils/notify/notify.utils';

export const useStakingCompleteNotification = (): void => {
	const dispatch = useAppDispatch();
	const stakingFlowStep = useSelector(selectStakingFlowStep);
	const autoFarmFlowStep = useSelector(selectAutoFarmFlowStep);
	const claimFlowStep = useSelector(selectClaimFlowStep);
	const isCryptoUser = useSelector(selectIsCryptoUser);

	useEffect(() => {
		if (!isCryptoUser) {
			switch (stakingFlowStep) {
				case 'stake-notify-success':
					notifySuccess('Manual staking complete');
					dispatch(setStakingFlowStepAction(null));
					break;
				case 'stake-success':
					notifySuccess('Manual staking complete');
					break;
				case 'withdraw-notify-success':
					notifySuccess('Manual staking withdrawal complete');
					dispatch(setStakingFlowStepAction(null));
					break;
				case 'withdraw-success':
					notifySuccess('Manual staking withdrawal complete');
					break;
				case 'stake-notify-error':
					notifyError('Manual staking failed');
					dispatch(setStakingFlowStepAction(null));
					break;
				case 'stake-error':
					notifyError('Manual staking failed');
					break;
				case 'withdraw-notify-error':
					notifyError('Manual staking withdrawal failed');
					dispatch(setStakingFlowStepAction(null));
					break;
				case 'withdraw-error':
					notifyError('Manual staking withdrawal failed');
					break;
			}
		}
	}, [stakingFlowStep, dispatch, isCryptoUser]);

	useEffect(() => {
		if (!isCryptoUser) {
			switch (autoFarmFlowStep) {
				case 'stake-notify-success':
					notifySuccess('Autofarm staking complete');
					dispatch(setAutoFarmFlowStepAction(null));
					break;
				case 'stake-success':
					notifySuccess('Autofarm staking complete');
					break;
				case 'withdraw-notify-success':
					notifySuccess('Autofarm withdrawal complete');
					dispatch(setAutoFarmFlowStepAction(null));
					break;
				case 'withdraw-success':
					notifySuccess('Autofarm withdrawal complete');
					break;
				case 'stake-notify-error':
					notifyError('Autofarm staking failed');
					dispatch(setAutoFarmFlowStepAction(null));
					break;
				case 'stake-error':
					notifyError('Autofarm staking failed');
					break;
				case 'withdraw-notify-error':
					notifyError('Autofarm withdrawal failed');
					dispatch(setAutoFarmFlowStepAction(null));
					break;
				case 'withdraw-error':
					notifyError('Autofarm withdrawal failed');
					break;
			}
		}
	}, [autoFarmFlowStep, dispatch, isCryptoUser]);

	useEffect(() => {
		if (!isCryptoUser) {
			switch (claimFlowStep) {
				case 'claim-notify-success':
					notifySuccess('Claim complete');
					dispatch(setClaimFlowStepAction(null));
					break;
				case 'claim-success':
					notifySuccess('Claim complete');
					break;
				case 'claim-notify-error':
					notifyError('Claim failed');
					dispatch(setClaimFlowStepAction(null));
					break;
				case 'claim-error':
					notifyError('Claim failed');
					break;
			}
		}
	}, [dispatch, claimFlowStep, isCryptoUser]);
};
