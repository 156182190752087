import styled, { keyframes } from 'styled-components';

const bounce = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }`;

export const LoaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: end;
	flex-shrink: 0;
	width: 20px;
	height: 16px;
	padding: 1px;
`;

export const LoaderDot = styled.div<{ $animationDelayInSeconds: number }>`
	width: 2px;
	height: 2px;
	border-radius: 50%;
	background-color: ${({ theme }) => theme.components.loaderDot.bgColor};
	animation: ${bounce} 0.8s infinite ease;
	animation-delay: ${({ $animationDelayInSeconds }) => `${$animationDelayInSeconds}s`};
`;
