import dayjs from 'dayjs';
import { TConnectorName } from 'types/blockchain/connectors.types';
import {
	StorageKey,
	StorageUpdateTime,
	TStorageUpdateItemValue,
} from 'types/storage/storage-key.enum';

export const updateLastConnectionTime = (): void => {
	localStorage.setItem(StorageKey.LastConnection, dayjs().toString());
};

export const storeLastConnectionData = (connectorName: TConnectorName): void => {
	localStorage.setItem(StorageKey.ConnectorName, connectorName);
	updateLastConnectionTime();
};

export const getLastConnectorName = (): string | null =>
	localStorage.getItem(StorageKey.ConnectorName);

export const getLastConnectionTime = (): string | null => {
	return localStorage.getItem(StorageKey.LastConnection);
};

export const storeLastUpdateTime = (storageKey: TStorageUpdateItemValue): void => {
	localStorage.setItem(storageKey, dayjs().toString());
};

export const getLastUpdateTime = (storageKey: TStorageUpdateItemValue): string | null => {
	return localStorage.getItem(storageKey);
};

export const clearAllUpdateItems = (): void => {
	const keys = Object.values(StorageUpdateTime) as TStorageUpdateItemValue[];
	keys.forEach((key) => {
		localStorage.removeItem(key);
	});
};
