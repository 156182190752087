import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoints';
import { HeaderHeight, MenuSize, ZIndex } from 'styles/style-variables/sizes';

export const LayoutWrapper = styled.div`
	display: grid;
	grid-template: auto 1fr / auto 1fr;
	grid-template-areas:
		'header header'
		'menu content';
	width: 100%;
	background-color: ${({ theme }) => theme.globals.frameBgColor};

	@media (max-width: ${BreakPoint.TabletTop}) {
		grid-template: auto 1fr auto / 1fr;
		grid-template-areas:
			'header'
			'content'
			'menu';
	}
`;

export const HeaderWrapper = styled.header`
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: ${ZIndex.Header};
	grid-area: header;
	max-width: ${BreakPoint.DesktopTop};
	height: ${HeaderHeight.Desktop};

	@media (min-width: ${BreakPoint.DesktopTop}) {
		right: calc((100vw - ${BreakPoint.DesktopTop}) / 2);
		left: calc((100vw - ${BreakPoint.DesktopTop}) / 2);
		width: ${BreakPoint.DesktopTop};
	}

	@media (max-width: ${BreakPoint.TabletTop}) {
		height: ${HeaderHeight.Mobile};
	}

	@media print {
		display: none;
	}
`;

export const MenuWrapper = styled.nav`
	position: fixed;
	top: ${HeaderHeight.Desktop};
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	grid-area: menu;
	width: ${MenuSize.DesktopWidth};

	@media (min-width: ${BreakPoint.DesktopTop}) {
		left: calc((100vw - ${BreakPoint.DesktopTop}) / 2);
	}

	@media (max-width: ${BreakPoint.TabletTop}) {
		position: fixed;
		top: unset;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: ${ZIndex.Menu};
		flex-direction: row;
		align-items: center;
		width: unset;
		min-height: ${MenuSize.MobileHeight};
	}

	@media print {
		display: none;
	}
`;

export const ContentWrapper = styled.main`
	grid-area: content;
	max-width: 100vw;
	min-height: calc(100vh - ${HeaderHeight.Desktop} - 16px);
	margin: ${HeaderHeight.Desktop} 16px 16px ${MenuSize.DesktopWidth};
	padding: 32px;
	border-radius: 32px;
	background-color: ${({ theme }) => theme.globals.contentBgColor};
	overflow: hidden;

	@media (min-width: ${BreakPoint.DesktopTop}) {
		max-width: calc(${BreakPoint.DesktopTop} - ${MenuSize.DesktopWidth});
		margin: ${HeaderHeight.Desktop} 16px 16px
			calc((100vw - ${BreakPoint.DesktopTop}) / 2 + ${MenuSize.DesktopWidth});
		padding-bottom: 48px;
	}

	@media (max-width: ${BreakPoint.TabletTop}) {
		min-height: calc(100vh - ${HeaderHeight.Mobile} - ${MenuSize.MobileHeight});
		margin: ${HeaderHeight.Mobile} 0 ${MenuSize.MobileHeight} 0;
		padding: 16px 16px 64px;
		border-radius: 0;
	}
`;
