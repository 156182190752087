import styled from 'styled-components';
import { popupContentReveal, popupReveal } from 'styles/keyframes/popup-reveal';
import { BreakPoint } from 'styles/style-variables/breakpoints';
import { AnimationDuration, HeaderHeight, MenuSize } from 'styles/style-variables/sizes';

export const PopupWrapper = styled.div`
	position: fixed;
	inset: ${HeaderHeight.Desktop} 16px 0 ${MenuSize.DesktopWidth};
	min-height: calc(100vh - ${HeaderHeight.Desktop});
	padding-bottom: 16px;
	background-color: ${({ theme }) => theme.globals.contentBgColor};
	animation: ${popupReveal} ${AnimationDuration.PopupReveal} ease;

	@media (max-width: ${BreakPoint.TabletTop}) {
		inset: ${HeaderHeight.Mobile} 0 ${MenuSize.MobileHeight};
		min-height: calc(100vh - ${HeaderHeight.Mobile} - ${MenuSize.MobileHeight});
		padding-bottom: 0;
	}

	@media (min-width: ${BreakPoint.DesktopTop}) {
		max-width: calc(${BreakPoint.DesktopTop} - ${MenuSize.DesktopWidth});
		margin-left: calc((100vw - ${BreakPoint.DesktopTop}) / 2);
	}
`;

export const PopupContent = styled.div`
	height: 100%;
	padding: 32px;
	border-radius: 32px;
	background-color: ${({ theme }) => theme.globals.contentBgColor};
	overflow-y: auto;

	@media (max-width: ${BreakPoint.TabletTop}) {
		padding: 18px 16px 64px;
		border-radius: 24px 24px 0 0;
	}

	@media (max-width: ${BreakPoint.MobileTop}) {
		padding: 16px 16px 48px;
	}
`;

export const PopupContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	width: 100%;
	animation: ${popupContentReveal} ${AnimationDuration.PopupReveal} ease;

	@media (max-width: ${BreakPoint.TabletTop}) {
		gap: 16px;
	}
`;
