import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { changeThemeAction } from 'store/theme-reducer/theme.reducer';
import { selectCurrentTheme } from 'store/theme-reducer/theme.selectors';
import { DefaultTheme } from 'styled-components';
import { darkTheme, lightTheme } from 'styles/themes';
import { TThemeMode } from 'types/theme-mode/theme-mode.type';

import { getThemeFromLocalStorage } from 'utils/storage/theme/get-theme-from-local-storage.util';

export const useTheme = (): DefaultTheme => {
	const dispatch = useAppDispatch();
	const themeFromStore = useSelector(selectCurrentTheme);
	const themeFromLocalStorage = getThemeFromLocalStorage();

	const currentThemeMode: TThemeMode = themeFromLocalStorage
		? (themeFromLocalStorage as TThemeMode)
		: themeFromStore;

	useEffect(() => {
		if (themeFromStore !== themeFromLocalStorage) {
			dispatch(changeThemeAction(currentThemeMode));
		}
	}, [dispatch, currentThemeMode, themeFromLocalStorage, themeFromStore]);

	return currentThemeMode === 'dark' ? darkTheme : lightTheme;
};
