import React from 'react';

import * as S from './MintoLogo.styled';

interface ILogoProps {
	type: 'icon' | 'full';
	width: string;
	height: string;
}

const MintoLogo = ({ type = 'icon', width = 'auto', height = 'auto' }: ILogoProps) => {
	switch (type) {
		case 'full':
			return (
				<S.LogoWrapper $height={height} $width={width}>
					<S.FullLogo />
				</S.LogoWrapper>
			);
		default:
			return (
				<S.LogoWrapper $height={height} $width={width}>
					<S.IconLogo />
				</S.LogoWrapper>
			);
	}
};

export default React.memo(MintoLogo);
