import {
	AUTOCONNECT_LIFE_TIME_MIN,
	DATA_UPDATE_INTERVAL_MIN,
} from 'constants/session/session.const';
import dayjs from 'dayjs';

import { updateLastConnectionTime } from 'utils/storage/connection-data/connection-data.utils';

export const checkIsItemOutdated = (startTime: string | null, intervalMinutes: number): boolean => {
	return dayjs().subtract(intervalMinutes, 'minutes').isAfter(startTime);
};

export const checkIsDataOutdated = (startTime: string | null): boolean => {
	if (!startTime) {
		return true;
	}
	return checkIsItemOutdated(startTime, DATA_UPDATE_INTERVAL_MIN);
};

export const checkIsAutoConnectionRequired = (
	startTime: string | null,
	lastConnectorName: string | null,
): boolean => {
	if (!startTime || lastConnectorName === 'WalletConnect') {
		return false;
	}

	const isAutoConnectionRequired = !checkIsItemOutdated(startTime, AUTOCONNECT_LIFE_TIME_MIN);
	if (isAutoConnectionRequired) {
		updateLastConnectionTime();
	}

	return isAutoConnectionRequired;
};
