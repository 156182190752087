type TGtagEvent = 'event';
type TGtagItemName =
  | 'BTCMT'
  | 'BTCMT_staking_auto'
  | 'BTCMT_unstaking_auto'
  | 'BTCMT_staking_manual'
  | 'BTCMT_unstaking_manual'
  | 'BTCMT_claim'
  | 'none';

interface IGTagItem {
  item_name: TGtagItemName;
  price?: number;
  quantity?: number;
}

interface IAddPaymentInfoParams {
  currency: 'USD';
  value: 0;
  coupon: string;
  payment_type: 'Wallet';
  items: IGTagItem[];
}

export interface IBeginCheckoutParams {
  currency?: 'USD';
  value: number;
  coupon: string;
  items: IGTagItem[];
}

export interface IPurchaseParams {
  currency?: 'USD';
  transaction_id: string;
  value: number;
  coupon: string;
  items: IGTagItem[];
}

export type TGtagGoalOptions = IAddPaymentInfoParams | IBeginCheckoutParams | IPurchaseParams;

// TODO: replace enum with as const object
export enum GtagGoalName {
  WalletConnected = 'add_payment_info',
  StartPurchase = 'begin_checkout',
  PurchaseSuccess = 'purchase',
}

export type TWindowWithGtag = {
  gtag: (event: TGtagEvent, goal: GtagGoalName, options?: TGtagGoalOptions) => void;
};
