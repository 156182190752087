import { ShareTextMessage } from 'constants/message/toast-message.const';

import { notifyError, notifySuccess } from 'utils/notify/notify.utils';

export const shareText = (value: string): void => {
	const data = {
		title: 'My wallet address',
		text: value,
	};

	if (navigator.canShare(data)) {
		navigator
			?.share(data)
			.then(() => {
				notifySuccess(ShareTextMessage.Success);
			})
			.catch(() => {
				notifyError(ShareTextMessage.Cancel);
			});
	} else {
		notifyError(ShareTextMessage.Error);
	}
};
