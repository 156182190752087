import React, { useEffect, useState } from 'react';
import { TFbStakingType } from 'store/api/fireblocks-api/types/staking/fb-staking-type.type';
import { TFetchStatus } from 'types/api/fetch-status.type';
import { TThemeMode } from 'types/theme-mode/theme-mode.type';

import MintoLogo from 'components/ui/MintoLogo';
import { TransactionsCounter } from 'components/ui/TransactionsCounter';
import { formatToNumberWithSpaces } from 'utils/formatters/formatters.utils';
import { AppRoute } from 'utils/routes/app-route';

import * as S from './Header.styled';

interface IHeaderProps {
	btcmtBalance: number;
	hasMobileBackground?: boolean;
	pendingStakeTransactions: TFbStakingType[];
	pendingClaimTransactions: TFbStakingType[];
	pendingWithdrawTransactions: TFbStakingType[];
	isDexTransactionPending: boolean;
	btcmtBalanceStatus: TFetchStatus;
	currentTheme: TThemeMode;
}

const Header: React.FC<IHeaderProps> = ({
	btcmtBalance,
	hasMobileBackground = false,
	pendingStakeTransactions,
	pendingWithdrawTransactions,
	pendingClaimTransactions,
	isDexTransactionPending,
	btcmtBalanceStatus,
	currentTheme,
}) => {
	const [isContentReady, setIsContentReady] = useState(false);
	const [totalBalance, setTotalBalance] = useState(0);

	const isTotalBalanceFetching = btcmtBalanceStatus === 'pending';
	const isTotalBalanceFetched = btcmtBalanceStatus === 'fulfilled';

	const pendingDexTransactionsCount = isDexTransactionPending ? 1 : 0;
	const pendingTransactionsCount =
		pendingStakeTransactions?.length +
		pendingWithdrawTransactions?.length +
		pendingClaimTransactions?.length +
		pendingDexTransactionsCount;

	useEffect(() => {
		if (isTotalBalanceFetched && btcmtBalance !== totalBalance) {
			setTotalBalance(btcmtBalance);
		}
	}, [isTotalBalanceFetched, btcmtBalance, totalBalance]);

	return (
		<S.HeaderWrapper $hasMobileBackground={hasMobileBackground}>
			<S.LogoLink to={AppRoute.Dashboard()}>
				<MintoLogo type="icon" width="48px" height="32px" />
			</S.LogoLink>
			<S.ButtonsWrapper>
				{pendingTransactionsCount > 0 && (
					<TransactionsCounter
						pendingTransactionsCount={pendingTransactionsCount}
						pendingStakeTransactions={pendingStakeTransactions}
						pendingWithdrawTransactions={pendingWithdrawTransactions}
						pendingClaimTransactions={pendingClaimTransactions}
						pendingDexTransactionsCount={pendingDexTransactionsCount}
						currentTheme={currentTheme}
					/>
				)}
				<S.Balance>
					{!isContentReady ? (
						<S.BalanceLoader
							dependencies={[isTotalBalanceFetching]}
							setIsContentReady={setIsContentReady}
						/>
					) : (
						<>
							<MintoLogo type="icon" width="16px" height="11px" />
							<S.BalanceValue>{`${formatToNumberWithSpaces(totalBalance)}`}</S.BalanceValue>
						</>
					)}
				</S.Balance>
			</S.ButtonsWrapper>
		</S.HeaderWrapper>
	);
};

export default Header;
