import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { useSwitchToTestnet } from 'hooks/useSwitchToTestnet';
import { useWalletAddress } from 'hooks/useWalletAddress';
import { selectIsCryptoUser } from 'store/auth-reducer/auth.selectors';
import {
	selectTotalBtcmtOwned,
	selectTotalBtcmtOwnedStatus,
} from 'store/computed-selectors/balance-and-rates.selectors';
import { closeQrPopupAction } from 'store/modals-reducer/modals.reducer';
import {
	selectIsAnyModalOpened,
	selectIsQrPopupOpened,
} from 'store/modals-reducer/modals.selectors';
import { selectIsLastDexTransactionPending } from 'store/purchase-reducers/web2-dex-reducer/web2-dex.selectors';
import {
	selectAutoFarmFlowStep,
	selectClaimFlowTransactionsStatuses,
	selectStakingFlowStep,
	selectStakingFlowTransactionsStatuses,
} from 'store/staking-reducers/staking-flow-reducer/staking-flow.selectors';
import { useAppDispatch } from 'store/store';
import { changeThemeAction } from 'store/theme-reducer/theme.reducer';
import { selectCurrentTheme } from 'store/theme-reducer/theme.selectors';
import { TThemeMode } from 'types/theme-mode/theme-mode.type';

import { useGlobalDispatches } from 'components/logic/InnerLayout/hooks/useGlobalDispatches';
import { SText } from 'components/styled/SText';
import DotsLoader from 'components/ui/DotsLoader';
import Popup from 'components/ui/Popup';

import Header from './components/Header';
import MainMenu from './components/MainMenu';
import QrPopupContent from './components/QrPopupContent';
import { useCheckClaimTransactions } from './hooks/useCheckClaimTransactions';
import { useCheckDexTransactions } from './hooks/useCheckDexTransactions';
import { useCheckFiatPayments } from './hooks/useCheckFiatPayments';
import { useCheckStakingTransactions } from './hooks/useCheckStakingTransactions';
import { useDexCompleteNotifications } from './hooks/useDexCompleteNotifications';
import { useStakingCompleteNotification } from './hooks/useStakingCompleteNotification';
import { useStakingGasEstimation } from './hooks/useStakingGasEstimation';
import { useUpdateStakingBalances } from './hooks/useUpdateStakingBalances';

import * as S from './InnerLayout.styled';

const InnerLayout: React.FC = () => {
	const { pathname } = useLocation();
	const dispatch = useAppDispatch();
	const isQrPopupOpened = useSelector(selectIsQrPopupOpened);
	const isAnyModalOpened = useSelector(selectIsAnyModalOpened);
	const totalBtcmtOwned = useSelector(selectTotalBtcmtOwned);
	const totalBtcmtOwnedStatus = useSelector(selectTotalBtcmtOwnedStatus);
	const walletAddress = useWalletAddress();
	const stakingFlowStep = useSelector(selectStakingFlowStep);
	const autoFarmFlowStep = useSelector(selectAutoFarmFlowStep);
	const isCryptoUser = useSelector(selectIsCryptoUser);
	const currentTheme = useSelector(selectCurrentTheme);
	const isDexTransactionPending = useSelector(selectIsLastDexTransactionPending);
	const { pendingStakeTransactions, pendingWithdrawTransactions, failedStakingTransactions } =
		useSelector(selectStakingFlowTransactionsStatuses);
	const { pendingClaimTransactions, failedClaimTransactions } = useSelector(
		selectClaimFlowTransactionsStatuses,
	);

	const closeQrPopup = () => isQrPopupOpened && dispatch(closeQrPopupAction());

	const handleThemeChange = (theme: TThemeMode) => {
		dispatch(changeThemeAction(theme));
	};

	useSwitchToTestnet();
	useGlobalDispatches(walletAddress);
	useStakingGasEstimation();
	useCheckStakingTransactions({
		pendingStakeTransactions,
		pendingWithdrawTransactions,
		failedStakingTransactions,
		stakingFlowStep,
		autoFarmFlowStep,
		isCryptoUser,
	});
	useCheckClaimTransactions({
		pendingClaimTransactions,
		failedClaimTransactions,
		isCryptoUser,
	});
	useUpdateStakingBalances({ accountAddress: walletAddress });
	useStakingCompleteNotification();
	useCheckDexTransactions(isCryptoUser);
	useDexCompleteNotifications();
	useCheckFiatPayments();
	useScrollToTop(pathname);

	return (
		<>
			<S.LayoutWrapper>
				<S.HeaderWrapper>
					<Header
						btcmtBalance={totalBtcmtOwned}
						hasMobileBackground={isAnyModalOpened}
						pendingStakeTransactions={pendingStakeTransactions}
						pendingWithdrawTransactions={pendingWithdrawTransactions}
						pendingClaimTransactions={pendingClaimTransactions}
						isDexTransactionPending={isDexTransactionPending}
						btcmtBalanceStatus={totalBtcmtOwnedStatus}
						currentTheme={currentTheme}
					/>
				</S.HeaderWrapper>
				<S.MenuWrapper>
					<MainMenu
						pathName={pathname}
						currentTheme={currentTheme}
						handleThemeChange={handleThemeChange}
					/>
				</S.MenuWrapper>
				<S.ContentWrapper>
					<Suspense
						fallback={
							<SText style={{ display: 'flex', columnGap: '4px' }}>Loading {<DotsLoader />}</SText>
						}
					>
						<Outlet />
					</Suspense>
				</S.ContentWrapper>
			</S.LayoutWrapper>

			{isQrPopupOpened && walletAddress && (
				<Popup
					headerTitle="My wallet"
					content={<QrPopupContent walletAddress={walletAddress ?? ''} />}
					handlePopupClose={closeQrPopup}
				/>
			)}
		</>
	);
};

export default InnerLayout;
