import { keyframes } from 'styled-components';

export const popupReveal = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const popupContentReveal = keyframes`
  0% {
    transform: translateY(8px);
  }

  100% {
    transform: translateY(0);
  }
`;
