import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectIsCryptoUser } from 'store/auth-reducer/auth.selectors';
import { resetLastDexTransactionAction } from 'store/purchase-reducers/web2-dex-reducer/web2-dex.reducer';
import {
	selectBuyFromDexFlowStep,
	selectDexTransactionsCheckStatus,
	selectIsLastDexTransactionFailed,
	selectIsLastDexTransactionFulfilled,
	selectReceivedBtcmtAmount,
} from 'store/purchase-reducers/web2-dex-reducer/web2-dex.selectors';
import { useAppDispatch } from 'store/store';

import { formatBtcmtOutput } from 'utils/formatters/formatters.utils';
import { notifyError, notifySuccess } from 'utils/notify/notify.utils';

export const useDexCompleteNotifications = (): void => {
	const dispatch = useAppDispatch();
	const isLastTransactionFulfilled = useSelector(selectIsLastDexTransactionFulfilled);
	const isLastTransactionFailed = useSelector(selectIsLastDexTransactionFailed);
	const dexFlowStep = useSelector(selectBuyFromDexFlowStep);
	const isCryptoUser = useSelector(selectIsCryptoUser);
	const transactionsCheckStatus = useSelector(selectDexTransactionsCheckStatus);
	const receivedBtcmtAmount = useSelector(selectReceivedBtcmtAmount);

	const isLastTransactionChecked = transactionsCheckStatus === 'fulfilled';

	useEffect(() => {
		if (!isCryptoUser && isLastTransactionFulfilled && isLastTransactionChecked) {
			switch (dexFlowStep) {
				case 'purchase-success':
					notifySuccess(`You have received ${formatBtcmtOutput(receivedBtcmtAmount)} BTCMT`);
					break;
			}
		}
	}, [
		dispatch,
		isCryptoUser,
		isLastTransactionChecked,
		isLastTransactionFulfilled,
		dexFlowStep,
		receivedBtcmtAmount,
	]);

	useEffect(() => {
		if (!isCryptoUser && isLastTransactionChecked) {
			switch (dexFlowStep) {
				case 'purchase-error':
					notifyError('Purchase failed');
					dispatch(resetLastDexTransactionAction());
					break;
			}
		}
	}, [dispatch, isCryptoUser, isLastTransactionChecked, isLastTransactionFailed, dexFlowStep]);
};
