import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestFbBalances } from 'store/api/fireblocks-api/services/fireblocks-balances.service';
import { TRootState } from 'store/store';
import {
	getBnbBalance,
	getBtcbBalance,
	getBtcmtBalance,
	getUsdtBalance,
} from 'store/utils/balance/balance.utils';
import { TBasicAssetId } from 'types/assets/basic-asset-id.type';
import { ContractName, TAccountAddress, TNetworkName } from 'types/blockchain/contracts.types';

import { bscContractsConfig, bscTestnetContractsConfig } from 'config/web3/contracts.config';

import { IBalanceState } from './balance-reducer.types';

export const requestBtcmtBalanceThunkAction = createAsyncThunk(
	'balance/btcmtBalance',
	async (
		{ accountAddress, chainName }: { accountAddress: TAccountAddress; chainName: TNetworkName },
		{ getState },
	): Promise<number | void> => {
		const chainId = (getState() as TRootState).chainReducer.chain.id;

		if (!accountAddress || !chainName || !chainId) {
			return;
		}

		let contractConfig;

		switch (chainName) {
			case 'bsc':
				contractConfig = bscContractsConfig[ContractName.BtcmtTokenBsc];
				break;
			case 'bsc-testnet':
				contractConfig = bscTestnetContractsConfig[ContractName.BtcmtTokenBsc];
				break;
		}

		return await getBtcmtBalance({ accountAddress, chainId, contractConfig });
	},
);

export const requestBnbBalanceThunkAction = createAsyncThunk(
	'balance/bnbBalance',
	async (
		{ accountAddress, chainName }: { accountAddress: TAccountAddress; chainName: TNetworkName },
		{ getState },
	): Promise<number | void> => {
		const chainId = (getState() as TRootState).chainReducer.chain.id;

		if (!accountAddress || !chainName || !chainId) {
			return;
		}

		return await getBnbBalance({ accountAddress, chainId });
	},
);

export const requestUsdtBalanceThunkAction = createAsyncThunk(
	'balance/usdtBalance',
	async (
		{ accountAddress, chainName }: { accountAddress: TAccountAddress; chainName: TNetworkName },
		{ getState },
	): Promise<number | void> => {
		const chainId = (getState() as TRootState).chainReducer.chain.id;
		const userAuthStatus = (getState() as TRootState).authReducer.userAuthStatus;

		const isWeb3User = userAuthStatus === 'crypto-wallet-connected';

		if (!accountAddress || !chainName || !chainId) {
			return;
		}

		let contractConfig;

		switch (chainName) {
			case 'bsc':
				contractConfig = bscContractsConfig[ContractName.UsdtTokenBsc];
				break;
			case 'bsc-testnet':
				contractConfig = isWeb3User
					? bscTestnetContractsConfig[ContractName.UsdtTokenBsc]
					: bscTestnetContractsConfig[ContractName.BusdTokenBsc];
				break;
		}

		return await getUsdtBalance({ accountAddress, chainId, contractConfig });
	},
);

export const requestBtcbBalanceThunkAction = createAsyncThunk(
	'balance/btcbBalance',
	async (
		{ accountAddress, chainName }: { accountAddress: TAccountAddress; chainName: TNetworkName },
		{ getState },
	): Promise<number | void> => {
		const chainId = (getState() as TRootState).chainReducer.chain.id;

		if (!accountAddress || !chainName || !chainId) {
			return;
		}

		let contractConfig;

		switch (chainName) {
			case 'bsc':
				contractConfig = bscContractsConfig[ContractName.BtcbTokenBsc];
				break;
			case 'bsc-testnet':
				contractConfig = bscTestnetContractsConfig[ContractName.BtcbTokenBsc];
				break;
		}

		return await getBtcbBalance({ accountAddress, chainId, contractConfig });
	},
);

export const requestFbBalancesThunkAction = createAsyncThunk(
	'balance/fb-balances',
	async (): Promise<
		Pick<IBalanceState, 'btcmtBalance' | 'usdtBalance' | 'bnbBalance' | 'btcbBalance'>
	> => {
		const rawResponse = await requestFbBalances();
		const initialBalances: Record<TBasicAssetId, string | null> = {
			BTCMT: null,
			BNB: null,
			USDT: null,
			BTCB: null,
			BUSD: null,
		};

		const parsedBalances = rawResponse.reduce((acc, cur) => {
			return Object.assign(acc, { [cur.id]: cur.total });
		}, initialBalances);

		return {
			btcmtBalance: Number(parsedBalances.BTCMT) || undefined,
			usdtBalance: Number(parsedBalances.USDT) || Number(parsedBalances.BUSD) || undefined,
			bnbBalance: Number(parsedBalances.BNB) || undefined,
			btcbBalance: Number(parsedBalances.BTCB) || undefined,
		};
	},
);
