import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFirebaseUser } from 'store/api/firebase-api/types/firebase-user.interface';
import { IUserProfile } from 'store/api/fireblocks-api/types/user/user-profile.interface';
import { TFetchStatus } from 'types/api/fetch-status.type';
import { TUserAuthStatus } from 'types/user/user-status.types';

import { deleteAuthToken } from 'utils/storage/auth-token/delete-auth-token.util';
import { deleteEmailSendTime } from 'utils/storage/date-and-time/delete-email-send-time.util';

import {
	loginWithPasswordThunkAction,
	registerWithEmailThunkAction,
	requestPasswordResetThunkAction,
	requestSignOutThunkAction,
	signInWithGoogleThunkAction,
} from './firebase-auth.thunk-actions';
import {
	createWalletThunkAction,
	deleteAccountThunkAction,
	requestUserProfileThunkAction,
} from './fireblocks-auth.thunk-actions';

export interface IAuthState {
	isAuthStateReady: boolean;
	profileStatus: TFetchStatus;
	deleteWalletStatus: TFetchStatus;
	createWalletStatus: TFetchStatus;
	signInStatus: TFetchStatus;
	signOutStatus: TFetchStatus;
	passwordResetStatus: TFetchStatus;
	error: string | null;
	userAuthData: IFirebaseUser | null;
	userWalletData: IUserProfile | null | undefined;
	userAuthStatus: TUserAuthStatus;
}

const initialState: IAuthState = {
	isAuthStateReady: false,
	profileStatus: 'initial',
	deleteWalletStatus: 'initial',
	createWalletStatus: 'initial',
	signInStatus: 'initial',
	signOutStatus: 'initial',
	passwordResetStatus: 'initial',
	error: null,
	userAuthData: null,
	userWalletData: null,
	userAuthStatus: null,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		resetAuthStateAction: (state) => {
			state.profileStatus = 'initial';
			state.deleteWalletStatus = 'initial';
			state.createWalletStatus = 'initial';
			state.signInStatus = 'initial';
			state.signOutStatus = 'initial';
			state.error = null;
			state.userAuthData = null;
			state.userWalletData = null;
			state.userAuthStatus = 'anonymous';
		},
		setAuthUserDataAction: (state, action: PayloadAction<Partial<IFirebaseUser>>) => {
			state.userAuthData = action.payload;
		},
		setUserAuthStatusAction: (state, action: PayloadAction<TUserAuthStatus>) => {
			state.userAuthStatus = action.payload;
		},
		setIsAuthStateReadyAction: (state, action: PayloadAction<boolean>) => {
			state.isAuthStateReady = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(registerWithEmailThunkAction.pending, (state) => {
				state.error = null;
				state.signInStatus = 'pending';
			})
			.addCase(registerWithEmailThunkAction.fulfilled, (state, { payload }) => {
				state.signInStatus = 'fulfilled';
				if (payload) {
					state.userAuthData = payload;
				}
			})
			.addCase(registerWithEmailThunkAction.rejected, (state, { error }) => {
				state.signInStatus = 'rejected';
				state.userAuthData = null;
				if (error.message) {
					state.error = error.message;
				}
			})
			.addCase(loginWithPasswordThunkAction.pending, (state) => {
				state.error = null;
				state.signInStatus = 'pending';
			})
			.addCase(loginWithPasswordThunkAction.fulfilled, (state) => {
				state.signInStatus = 'fulfilled';
			})
			.addCase(loginWithPasswordThunkAction.rejected, (state, { error }) => {
				state.userAuthData = null;
				state.userAuthStatus = 'anonymous';
				state.signInStatus = 'rejected';
				if (error.message) {
					state.error = error.message;
				}
			})
			.addCase(signInWithGoogleThunkAction.pending, (state) => {
				state.error = null;
				state.signInStatus = 'pending';
			})
			.addCase(signInWithGoogleThunkAction.fulfilled, (state) => {
				state.signInStatus = 'fulfilled';
			})
			.addCase(signInWithGoogleThunkAction.rejected, (state, { error }) => {
				state.signInStatus = 'rejected';
				state.userAuthData = null;
				state.userAuthStatus = 'anonymous';
				if (error.message) {
					state.error = error.message;
				}
			})
			.addCase(requestUserProfileThunkAction.pending, (state) => {
				state.profileStatus = 'pending';
				state.error = null;
			})
			.addCase(requestUserProfileThunkAction.fulfilled, (state, { payload }) => {
				state.profileStatus = 'fulfilled';
				if (payload) {
					state.userWalletData = payload;
				}
				if (payload?.address) {
					state.userAuthStatus = 'verified-has-wallet';
					deleteEmailSendTime();
				}
			})
			.addCase(requestUserProfileThunkAction.rejected, (state, { error }) => {
				state.profileStatus = 'rejected';
				state.userWalletData = null;
				state.userAuthStatus = 'anonymous';
				if (error.message) {
					state.error = error.message;
				}
			})
			.addCase(requestSignOutThunkAction.pending, (state) => {
				state.signOutStatus = 'pending';
			})
			.addCase(requestSignOutThunkAction.fulfilled, (state) => {
				state.userWalletData = null;
				state.userAuthData = null;
				state.userAuthStatus = 'anonymous';
				state.signOutStatus = 'fulfilled';
			})
			.addCase(requestSignOutThunkAction.rejected, (state, { error }) => {
				state.signOutStatus = 'rejected';
				if (error.message) {
					state.error = error.message;
				}
			})
			.addCase(createWalletThunkAction.pending, (state) => {
				state.createWalletStatus = 'pending';
				state.error = null;
			})
			.addCase(createWalletThunkAction.fulfilled, (state, action: PayloadAction<IUserProfile>) => {
				state.createWalletStatus = 'fulfilled';
				state.userWalletData = action.payload;
				if (action.payload.address) {
					state.userAuthStatus = 'wallet-just-created';
				}
			})
			.addCase(createWalletThunkAction.rejected, (state, { error }) => {
				state.createWalletStatus = 'rejected';
				if (error?.message) {
					state.error = error.message;
				}
			})
			.addCase(deleteAccountThunkAction.pending, (state) => {
				state.deleteWalletStatus = 'pending';
			})
			.addCase(deleteAccountThunkAction.fulfilled, (state) => {
				state.deleteWalletStatus = 'fulfilled';
				state.userAuthStatus = 'account-just-deleted';
				deleteAuthToken();
			})
			.addCase(deleteAccountThunkAction.rejected, (state, { error }) => {
				state.deleteWalletStatus = 'rejected';
				if (error?.message) {
					state.error = error.message;
				}
			})
			.addCase(requestPasswordResetThunkAction.pending, (state) => {
				state.passwordResetStatus = 'pending';
			})
			.addCase(requestPasswordResetThunkAction.fulfilled, (state) => {
				state.passwordResetStatus = 'fulfilled';
			})
			.addCase(requestPasswordResetThunkAction.rejected, (state, { error }) => {
				state.passwordResetStatus = 'rejected';
				if (error?.message) {
					state.error = error.message;
				}
			});
	},
});

export const {
	setAuthUserDataAction,
	setUserAuthStatusAction,
	setIsAuthStateReadyAction,
	resetAuthStateAction,
} = authSlice.actions;
export const authReducer = authSlice.reducer;
