import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	requestFbStaking,
	requestFbWithdraw,
} from 'store/api/fireblocks-api/services/fireblocks-staking.service';
import { IFbStakingRequest } from 'store/api/fireblocks-api/types/staking/fb-staking-request.interface';
import {
	setAutoFarmFlowStepAction,
	setShouldCheckStakingTransactionsAction,
	setStakingFlowStepAction,
} from 'store/staking-reducers/staking-flow-reducer/staking-flow.reducer';
import { checkPendingStakingTransactionsThunkAction } from 'store/staking-reducers/staking-flow-reducer/staking-flow.thunk-actions';
import { TRootState } from 'store/store';
import { parseEther } from 'viem';

export const requestWeb2StakeThunkAction = createAsyncThunk(
	'web2-staking/stake',
	async ({ type, amount }: IFbStakingRequest, { dispatch, getState }): Promise<void> => {
		const isAutofarm = type === 'Autofarm';
		isAutofarm
			? dispatch(setAutoFarmFlowStepAction('stake-requested'))
			: dispatch(setStakingFlowStepAction('stake-requested'));

		requestFbStaking({
			type,
			amount: parseEther(amount).toString(),
		})
			.then(() => {
				dispatch(setShouldCheckStakingTransactionsAction(true));
				isAutofarm
					? dispatch(setAutoFarmFlowStepAction('stake-pending'))
					: dispatch(setStakingFlowStepAction('stake-pending'));
				void dispatch(checkPendingStakingTransactionsThunkAction());
			})
			.catch(() => {
				const autoFarmFlowStep = (getState() as TRootState).stakingFlowReducer.autoFarmFlowStep;
				const stakingFlowStep = (getState() as TRootState).stakingFlowReducer.stakingFlowStep;
				if (isAutofarm && autoFarmFlowStep === 'stake-requested') {
					dispatch(setAutoFarmFlowStepAction('stake-error'));
				} else if (!isAutofarm && stakingFlowStep === 'stake-requested') {
					dispatch(setStakingFlowStepAction('stake-error'));
				}
			});
	},
);

export const requestWeb2WithdrawThunkAction = createAsyncThunk(
	'web2-staking/withdraw',
	async ({ type, amount }: IFbStakingRequest, { dispatch, getState }): Promise<void> => {
		const isAutofarm = type === 'Autofarm';
		isAutofarm
			? dispatch(setAutoFarmFlowStepAction('withdraw-requested'))
			: dispatch(setStakingFlowStepAction('withdraw-requested'));

		dispatch(setShouldCheckStakingTransactionsAction(true));

		requestFbWithdraw({
			type,
			amount: parseEther(amount).toString(),
		})
			.then(() => {
				isAutofarm
					? dispatch(setAutoFarmFlowStepAction('withdraw-pending'))
					: dispatch(setStakingFlowStepAction('withdraw-pending'));
				void dispatch(checkPendingStakingTransactionsThunkAction());
			})
			.catch(() => {
				const stakingFlowStep = (getState() as TRootState).stakingFlowReducer.stakingFlowStep;
				const autoFarmFlowStep = (getState() as TRootState).stakingFlowReducer.autoFarmFlowStep;

				if (isAutofarm && autoFarmFlowStep === 'withdraw-requested') {
					dispatch(setAutoFarmFlowStepAction('withdraw-error'));
				} else if (!isAutofarm && stakingFlowStep === 'withdraw-requested') {
					dispatch(setStakingFlowStepAction('withdraw-error'));
				}
			});
	},
);
