import { fireblocksApi } from 'store/api/fireblocks-api/fireblocks.api';
import { FireblocksApiRoute } from 'store/api/fireblocks-api/fireblocks-api-route';
import { IFireblocksRateItem } from 'store/api/fireblocks-api/types/rates/fireblocks-rate-item.interface';
import { TFireblocksNetwork } from 'types/fireblocks/fireblocks-network.type';

export const requestFireblocksRates = async (
	network: TFireblocksNetwork,
): Promise<IFireblocksRateItem[]> =>
	fireblocksApi
		.get<IFireblocksRateItem[]>(FireblocksApiRoute.Rates(network))
		.then((res) => res.data);
