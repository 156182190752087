import styled, { css } from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoints';
import { FontFamily } from 'styles/style-variables/fonts';
import { TransitionTime } from 'styles/style-variables/sizes';

export const MenuLink = styled.li<{ $isActive: boolean }>`
	width: 100%;

	& a {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 4px;
		font-size: 12px;
		line-height: 18px;
		text-decoration: none;
		color: ${({ theme, $isActive }) =>
			$isActive
				? theme.components.mainMenu.menuLink.fontColor.active
				: theme.components.mainMenu.menuLink.fontColor.default};
		transition: color ${TransitionTime.Hover};
		cursor: ${({ $isActive }) => ($isActive ? 'default' : 'pointer')};
		outline: none;

		& span {
			font-family: ${FontFamily.Manrope};
		}

		& svg {
			flex-shrink: 0;
			width: 32px;
			height: 32px;

			& path {
				fill: ${({ theme, $isActive }) =>
					$isActive
						? theme.components.mainMenu.menuLink.iconFill.active
						: theme.components.mainMenu.menuLink.iconFill.default};
				transition: fill ${TransitionTime.Hover};
			}
		}

		&:hover,
		&:focus {
			color: ${({ theme }) => theme.components.mainMenu.menuLink.fontColor.active};

			& svg path {
				fill: ${({ theme }) => theme.components.mainMenu.menuLink.iconFill.active};
			}
		}
	}

	@media (max-width: ${BreakPoint.TabletTop}) {

		& a {
			font-size: 10px;
			font-weight: 500;
			line-height: 14px;

			& svg {
				width: 24px;
				height: 24px;
			}
		}
	}
`;

export const ListItem = styled.li`
	width: 100%;
`;

export const MenuButton = styled.button`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4px;
	width: 100%;
	padding: 0;
	border: none;
	background: none;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.components.mainMenu.menuLink.fontColor.default};
	transition: color ${TransitionTime.Hover};
	cursor: pointer;
	outline: none;

	& span {
		font-family: ${FontFamily.Manrope};
	}

	& svg {
		flex-shrink: 0;
		width: 32px;
		height: 32px;

		& path {
			fill: ${({ theme }) => theme.components.mainMenu.menuLink.iconFill.default};
			transition: fill ${TransitionTime.Hover};
		}
	}

	&:hover,
	&:focus {
		color: ${({ theme }) => theme.components.mainMenu.menuLink.fontColor.active};

		& svg path {
			fill: ${({ theme }) => theme.components.mainMenu.menuLink.iconFill.active};
		}
	}

	@media (max-width: ${BreakPoint.TabletTop}) {
		font-size: 10px;
		font-weight: 500;
		line-height: 14px;

		& svg {
			width: 24px;
			height: 24px;
		}
	}
`;
