import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoints';
import { FontFamily } from 'styles/style-variables/fonts';
import { TransitionTime } from 'styles/style-variables/sizes';

const BasicButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 6px;
	width: auto;
	height: auto;
	padding: 8px 15px;
	border: none;
	border-radius: 12px;
	background-color: transparent;
	font-family: ${FontFamily.Manrope};
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px;
	letter-spacing: 0.5px;
	text-align: center;
	text-transform: uppercase;
	transition: all ${TransitionTime.Hover};
	cursor: pointer;
	outline: none;

	& svg {
		flex-grow: 0;
		flex-shrink: 0;

		path,
		circle {
			transition: all ${TransitionTime.Hover};
		}
	}

	&:hover,
	&:focus {
		transition: all ${TransitionTime.Hover};

		& svg path,
		circle {
			transition: all ${TransitionTime.Hover};
		}
	}

	&:active {
		transition: all ${TransitionTime.Active};

		& svg path,
		circle {
			transition: all ${TransitionTime.Active};
		}
	}

	&:disabled {
		transition: all ${TransitionTime.Disabled};
		cursor: auto;

		& svg path,
		circle {
			transition: all ${TransitionTime.Disabled};
		}
	}
`;

export const PrimaryButton = styled(BasicButton)`
	background-color: ${({ theme }) => theme.components.button.primary.bgColor.default};
	color: ${({ theme }) => theme.components.button.primary.fontColor.default};

	&:hover,
	&:focus {
		background-color: ${({ theme }) => theme.components.button.primary.bgColor.hoverFocus};
	}

	&:active {
		background-color: ${({ theme }) => theme.components.button.primary.bgColor.active};
	}

	&:disabled {
		background-color: ${({ theme }) => theme.components.button.primary.bgColor.disabled};
		color: ${({ theme }) => theme.components.button.primary.fontColor.disabled};
	}
`;

export const AlterButton = styled(PrimaryButton)`
	background-color: ${({ theme }) => theme.components.button.alter.bgColor.default};
	color: ${({ theme }) => theme.components.button.alter.fontColor.default};

	& svg path,
	circle {
		stroke: ${({ theme }) => theme.components.button.alter.fontColor.default};
	}

	&:hover,
	&:focus {
		background-color: ${({ theme }) => theme.components.button.alter.bgColor.hoverFocus};
		color: ${({ theme }) => theme.components.button.alter.fontColor.hoverFocus};

		& path,
		circle {
			stroke: ${({ theme }) => theme.components.button.alter.fontColor.hoverFocus};
		}
	}

	&:active {
		background-color: ${({ theme }) => theme.components.button.alter.bgColor.active};
		color: ${({ theme }) => theme.components.button.alter.fontColor.active};

		& path,
		circle {
			stroke: ${({ theme }) => theme.components.button.alter.fontColor.active};
		}
	}

	&:disabled {
		background-color: ${({ theme }) => theme.components.button.alter.bgColor.disabled};
		color: ${({ theme }) => theme.components.button.alter.fontColor.disabled};

		& path,
		circle {
			stroke: ${({ theme }) => theme.components.button.alter.fontColor.disabled};
		}
	}
`;

export const AlterLightButton = styled(AlterButton)`
	background-color: ${({ theme }) => theme.components.button.alterLight.bgColor.default};
	color: ${({ theme }) => theme.components.button.alterLight.fontColor.default};

	& svg path,
	circle {
		stroke: ${({ theme }) => theme.components.button.alterLight.fontColor.default};
	}

	&:hover,
	&:focus {
		background-color: ${({ theme }) => theme.components.button.alterLight.bgColor.hoverFocus};
		color: ${({ theme }) => theme.components.button.alterLight.fontColor.hoverFocus};

		& path,
		circle {
			stroke: ${({ theme }) => theme.components.button.alterLight.fontColor.hoverFocus};
		}
	}

	&:active {
		background-color: ${({ theme }) => theme.components.button.alterLight.bgColor.active};
		color: ${({ theme }) => theme.components.button.alterLight.fontColor.active};

		& path,
		circle {
			stroke: ${({ theme }) => theme.components.button.alterLight.fontColor.active};
		}
	}

	&:disabled {
		background-color: ${({ theme }) => theme.components.button.alterLight.bgColor.disabled};
		color: ${({ theme }) => theme.components.button.alterLight.fontColor.disabled};

		& path,
		circle {
			stroke: ${({ theme }) => theme.components.button.alterLight.fontColor.disabled};
		}
	}
`;

export const BorderlessButton = styled(BasicButton)`
	padding: 0;
	background-color: transparent;
	font-size: 14px;
	font-weight: 600;
	line-height: 22px;
	letter-spacing: 0.5px;
	text-align: center;
	text-transform: uppercase;
	color: ${({ theme }) => theme.components.button.borderless.fontColor.default};

	& svg path,
	circle {
		stroke: ${({ theme }) => theme.components.button.borderless.fontColor.default};
	}

	&:hover,
	&:focus {
		color: ${({ theme }) => theme.components.button.borderless.fontColor.hoverFocus};

		& svg path,
		circle {
			stroke: ${({ theme }) => theme.components.button.borderless.fontColor.hoverFocus};
		}
	}

	&:active {
		color: ${({ theme }) => theme.components.button.borderless.fontColor.active};

		& svg path,
		circle {
			stroke: ${({ theme }) => theme.components.button.borderless.fontColor.active};
		}
	}

	&:disabled {
		color: ${({ theme }) => theme.components.button.borderless.fontColor.disabled};

		& svg path,
		circle {
			stroke: ${({ theme }) => theme.components.button.borderless.fontColor.disabled};
		}
	}
`;

export const OutlinedButton = styled(BasicButton)`
	border: 1px solid ${({ theme }) => theme.components.button.outlined.borderColor.default};
	border-radius: 16px;
	background-color: transparent;
	color: ${({ theme }) => theme.components.button.outlined.fontColor.default};

	& svg path,
	circle {
		stroke: ${({ theme }) => theme.components.button.outlined.fontColor.default};
	}

	&:hover,
	&:focus {
		border: 1px solid ${({ theme }) => theme.components.button.outlined.borderColor.hoverFocus};
		color: ${({ theme }) => theme.components.button.outlined.fontColor.hoverFocus};

		& svg path,
		circle {
			stroke: ${({ theme }) => theme.components.button.outlined.borderColor.hoverFocus};
		}
	}

	&:active {
		border: 1px solid ${({ theme }) => theme.components.button.outlined.borderColor.active};
		color: ${({ theme }) => theme.components.button.outlined.fontColor.active};

		& svg path,
		circle {
			stroke: ${({ theme }) => theme.components.button.outlined.borderColor.active};
		}
	}

	&:disabled {
		border: 1px solid ${({ theme }) => theme.components.button.outlined.borderColor.disabled};
		color: ${({ theme }) => theme.components.button.outlined.fontColor.disabled};

		& svg path,
		circle {
			stroke: ${({ theme }) => theme.components.button.outlined.borderColor.disabled};
		}
	}
`;

export const BorderedIcon = styled(OutlinedButton)`
	width: 58px;
	height: 58px;
	border-radius: 50%;

	& svg {
		min-width: 24px;
		min-height: 24px;
	}

	& svg path,
	circle {
		stroke: ${({ theme }) => theme.components.button.outlined.borderColor.default};
		fill: ${({ theme }) => theme.components.button.outlined.borderColor.default};
	}

	&:hover,
	&:focus {
		border: 1px solid ${({ theme }) => theme.components.button.outlined.borderColor.hoverFocus};
		color: ${({ theme }) => theme.components.button.outlined.fontColor.hoverFocus};

		& svg path,
		circle {
			fill: ${({ theme }) => theme.components.button.outlined.borderColor.hoverFocus};
		}
	}

	&:active {
		color: ${({ theme }) => theme.components.button.outlined.fontColor.active};

		& svg path,
		circle {
			fill: ${({ theme }) => theme.components.button.outlined.borderColor.active};
		}
	}

	&:disabled {
		border: 1px solid ${({ theme }) => theme.components.button.outlined.borderColor.disabled};
		color: ${({ theme }) => theme.components.button.outlined.fontColor.disabled};

		& svg path,
		circle {
			fill: ${({ theme }) => theme.components.button.outlined.borderColor.disabled};
		}
	}
`;

export const AuthOption = styled(BasicButton)`
	background-color: ${({ theme }) => theme.components.button.authOption.bgColor.default};
	color: ${({ theme }) => theme.components.button.authOption.fontColor.default};

	& svg path,
	circle {
		stroke: ${({ theme }) => theme.components.button.authOption.fontColor.default};
	}

	&:hover,
	&:focus {
		background-color: ${({ theme }) => theme.components.button.authOption.bgColor.hoverFocus};
		color: ${({ theme }) => theme.components.button.authOption.fontColor.hoverFocus};

		& path,
		circle {
			stroke: ${({ theme }) => theme.components.button.authOption.fontColor.hoverFocus};
		}
	}

	&:active {
		background-color: ${({ theme }) => theme.components.button.authOption.bgColor.active};
		color: ${({ theme }) => theme.components.button.authOption.fontColor.active};

		& path,
		circle {
			stroke: ${({ theme }) => theme.components.button.authOption.fontColor.active};
		}
	}

	&:disabled {
		background-color: ${({ theme }) => theme.components.button.authOption.bgColor.disabled};
		color: ${({ theme }) => theme.components.button.authOption.fontColor.disabled};

		& path,
		circle {
			stroke: ${({ theme }) => theme.components.button.authOption.fontColor.disabled};
		}
	}

	@media (max-width: ${BreakPoint.MobileTop}) {
		background-color: ${({ theme }) => theme.components.button.authOption.bgColor.mobile};
	}
`;
