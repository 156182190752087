import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	FiatPaymentsCheckIntervalMilliSec,
	TFiatPaymentsCheckInterval,
} from 'constants/intervals/fiat-payments-check-interval';
import { useWalletAddress } from 'hooks/useWalletAddress';
import { selectIsCryptoUser } from 'store/auth-reducer/auth.selectors';
import { requestBtcmtBalanceThunkAction } from 'store/balance-reducer/balance.thunk-actions';
import { selectChainName } from 'store/chain-reducer/chain.selectors';
import {
	selectArePendingTransactionsInFiatPayments,
	selectFiatPaymentsStatus,
} from 'store/purchase-reducers/web2-fiat-reducer/web2-fiat.selectors';
import { requestFiatPaymentsThunkAction } from 'store/purchase-reducers/web2-fiat-reducer/web2-fiat.thunk-actions';
import { useAppDispatch } from 'store/store';

export const useCheckFiatPayments = (): void => {
	const dispatch = useAppDispatch();
	const fiatPaymentsStatus = useSelector(selectFiatPaymentsStatus);
	const isCryptoUser = useSelector(selectIsCryptoUser);
	const chainName = useSelector(selectChainName);
	const accountAddress = useWalletAddress();
	const arePendingTransactionsInFiatPayments = useSelector(
		selectArePendingTransactionsInFiatPayments,
	);

	const [checkInterval, setCheckInterval] = useState<TFiatPaymentsCheckInterval>(
		FiatPaymentsCheckIntervalMilliSec.Lazy,
	);
	const [shouldUpdateBtcmtBalance, setShouldUpdateBtcmtBalance] = useState(false);

	const checkIntervalRef = useRef<ReturnType<typeof setInterval>>();

	useLayoutEffect(() => {
		if (isCryptoUser) return;
		if (fiatPaymentsStatus === 'initial') {
			void dispatch(requestFiatPaymentsThunkAction());
		}
	}, [dispatch, fiatPaymentsStatus, isCryptoUser]);

	useLayoutEffect(() => {
		if (isCryptoUser) return;
		const callback = () => void dispatch(requestFiatPaymentsThunkAction());
		clearInterval(checkIntervalRef.current);
		checkIntervalRef.current = setInterval(callback, checkInterval);

		return () => clearInterval(checkIntervalRef.current);
	}, [dispatch, checkInterval, isCryptoUser]);

	useEffect(() => {
		if (isCryptoUser) return;
		if (arePendingTransactionsInFiatPayments) {
			setShouldUpdateBtcmtBalance(true);
			setCheckInterval(FiatPaymentsCheckIntervalMilliSec.Active);
		} else {
			setCheckInterval(FiatPaymentsCheckIntervalMilliSec.Lazy);

			if (shouldUpdateBtcmtBalance && accountAddress && chainName) {
				void dispatch(requestBtcmtBalanceThunkAction({ accountAddress, chainName }));
				setShouldUpdateBtcmtBalance(false);
			}
		}
	}, [
		arePendingTransactionsInFiatPayments,
		accountAddress,
		chainName,
		dispatch,
		isCryptoUser,
		shouldUpdateBtcmtBalance,
	]);
};
