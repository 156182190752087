import { createSelector } from '@reduxjs/toolkit';
import { IStakingBalanceReducer } from 'store/staking-reducers/staking-balances-reducer/staking-balances.reducer';
import { TRootState } from 'store/store';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { floorBtcmtValue } from 'utils/formatters/formatters.utils';

const getStakingBalancesState = (state: TRootState): IStakingBalanceReducer =>
	state.stakingBalancesReducer;

export const selectWeb3AutofarmV2BalanceFloored = createSelector(
	[getStakingBalancesState],
	(state): number => {
		return floorBtcmtValue(state.cryptoAutoFarmV2Balance || 0);
	},
);

export const selectWeb3ManualStakingBalanceFloored = createSelector(
	[getStakingBalancesState],
	(state): number => {
		return floorBtcmtValue(state.cryptoStakingBalances?.unlockedAmount || 0);
	},
);

export const selectWeb3TotalStakingBalance = createSelector(
	[selectWeb3AutofarmV2BalanceFloored, selectWeb3ManualStakingBalanceFloored],
	(autofarmBalance, manualStakingBalance): number => autofarmBalance + manualStakingBalance,
);

export const selectWeb3TotalStakingBalanceStatus = createSelector(
	[getStakingBalancesState],
	(state): TFetchStatus => {
		const { cryptoAutoFarmV2Status, cryptoStakingStatus } = state;

		if (cryptoAutoFarmV2Status === 'initial' && cryptoStakingStatus === 'initial') {
			return 'initial';
		}
		if (cryptoAutoFarmV2Status === 'fulfilled' && cryptoStakingStatus === 'fulfilled') {
			return 'fulfilled';
		}
		if (cryptoAutoFarmV2Status === 'rejected' || cryptoStakingStatus === 'rejected') {
			return 'rejected';
		}
		if (cryptoAutoFarmV2Status === 'pending' || cryptoStakingStatus === 'pending') {
			return 'pending';
		}

		return 'pending';
	},
);
