import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'store/store';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { IBasicRates } from './types/default-rates.interface';
import { IRatesState } from './rates.reducer';

const getRatesApiState = (state: TRootState): IRatesState => state.ratesReducer;

export const selectBnbUsdtRateExact = createSelector(
	[getRatesApiState],
	(state): number => state.bnbUsdtRate ?? 0,
);

export const selectBtcmtUsdtRateExact = createSelector(
	[getRatesApiState],
	(state): number => state.btcmtUsdtRate ?? 0,
);

export const selectBtcbUsdtRateExact = createSelector(
	[getRatesApiState],
	(state): number => state.btcbUsdtRate ?? 0,
);

export const selectBusdUsdtRateExact = createSelector(
	[getRatesApiState],
	(state): number => state.busdUsdtRate ?? 0,
);

export const selectBasicRatesExact = createSelector(
	[
		selectBnbUsdtRateExact,
		selectBtcbUsdtRateExact,
		selectBtcmtUsdtRateExact,
		selectBusdUsdtRateExact,
	],
	(bnbUsdtRate, btcbUsdtRate, btcmtUsdtRate, busdUsdtRate): Required<IBasicRates> => ({
		bnbUsdtRate,
		btcbUsdtRate,
		btcmtUsdtRate,
		busdUsdtRate,
	}),
);

export const selectBtcmtUsdtRateStatus = createSelector(
	[getRatesApiState],
	(state): TFetchStatus => state.btcmtUsdtRateStatus,
);

export const selectBtcbUsdtRateStatus = createSelector(
	[getRatesApiState],
	(state): TFetchStatus => state.btcbUsdtRateStatus,
);

export const selectBnbUsdtRateStatus = createSelector(
	[getRatesApiState],
	(state): TFetchStatus => state.bnbUsdtRateStatus,
);

export const selectBasicRatesStatus = createSelector(
	[getRatesApiState],
	(state): TFetchStatus => state.basicRatesStatus,
);

export const selectEtherToUsdtRateExact = createSelector(
	[getRatesApiState],
	(state): number => state.etherToUsdtRate ?? 0,
);
