import { createAsyncThunk } from '@reduxjs/toolkit';
import { prepareWriteContract } from '@wagmi/core';
import { FallbackString } from 'constants/fallback-string/fallback-string.const';
import { TransactionErrorMessage } from 'constants/message/transaction-error-message.const';
import { postInvoice } from 'store/api/bsc-api/bsc.service';
import { requestBtcmtBalanceThunkAction } from 'store/balance-reducer/balance.thunk-actions';
import { updateDebugInfoAction } from 'store/debug-reducer/debug.reducer';
import {
	requestUserAutoStakesV2ThunkAction,
	requestUserStakesThunkAction,
} from 'store/staking-reducers/staking-balances-reducer/staking-balance.thunk-actions';
import {
	setAutoFarmFlowStepAction,
	setStakingFlowStepAction,
} from 'store/staking-reducers/staking-flow-reducer/staking-flow.reducer';
import { TGetStateFn, TRootState } from 'store/store';
import {
	handlePreparationError,
	populateErrorReport,
	sendErrorReport,
} from 'store/utils/error-report/error-report.utils';
import {
	ContractName,
	ITransactionResponse,
	TAccountAddress,
	TAutofarmV2ContractConfig,
	TBtcmtBscContractConfig,
	TNetworkName,
	TStakingContractConfig,
} from 'types/blockchain/contracts.types';
import { parseEther } from 'viem';

import { bscContractsConfig, bscTestnetContractsConfig } from 'config/web3/contracts.config';
import { FbGoalName } from 'utils/metrics/fb/fb.types';
import { sendFbGoal } from 'utils/metrics/fb/fb.utils';
import { sendGtagGoalPurchase } from 'utils/metrics/gtag/gtag.utils';
import { YMGoal } from 'utils/metrics/ym/ym.types';
import { reportEcommerce, sendYmGoal } from 'utils/metrics/ym/ym.utils';
import { approveBtcmt, checkBtcmtAllowance, writeContractFromConfig } from 'utils/web3/web3.utils';

export const startAutoFarmStakeThunkAction = createAsyncThunk(
	'web3-stake/startAutoFarmStake',
	async (
		{
			accountAddress,
			chainName,
			stakeAmount,
		}: {
			accountAddress: TAccountAddress | null | undefined;
			chainName: TNetworkName;
			stakeAmount: string;
		},
		{ dispatch, getState },
	): Promise<ITransactionResponse | null> => {
		dispatch(setAutoFarmFlowStepAction('stake-pending'));

		const state = getState() as TRootState;
		const chainId = state.chainReducer.chain.id;
		const btcmtMarketPrice = state.ratesReducer.btcmtUsdtRate || 0;
		const btcmtBalance = state.balanceReducer.btcmtBalance ?? FallbackString.NotSet;

		if (!accountAddress || !chainName || !chainId) {
			throw new Error(TransactionErrorMessage.IncorrectOperation);
		}

		let btcmtContractConfig: TBtcmtBscContractConfig;
		let autofarmContractConfig: TAutofarmV2ContractConfig;
		let spenderAddress: TAccountAddress;

		switch (chainName) {
			case 'bsc':
				btcmtContractConfig = bscContractsConfig[ContractName.BtcmtTokenBsc];
				autofarmContractConfig = bscContractsConfig[ContractName.AutofarmV2Bsc];
				spenderAddress = bscContractsConfig[ContractName.AutofarmV2Bsc].address;
				break;
			case 'bsc-testnet':
				btcmtContractConfig = bscTestnetContractsConfig[ContractName.BtcmtTokenBsc];
				autofarmContractConfig = bscTestnetContractsConfig[ContractName.AutofarmV2Bsc];
				spenderAddress = bscTestnetContractsConfig[ContractName.AutofarmV2Bsc].address;
				break;
		}

		const checkAutoFarmAllowance = () => {
			return checkBtcmtAllowance({
				accountAddress,
				chainId,
				btcmtContractConfig,
				spenderAddress,
				requiredValue: stakeAmount,
				btcmtBalance,
				dispatch,
			});
		};

		const approveAutoFarm = () =>
			approveBtcmt({
				getState: getState as TGetStateFn,
				accountAddress,
				chainId,
				spenderAddress,
				btcmtContractConfig,
				requiredValue: stakeAmount,
				dispatch,
			});

		const sendToAutoFarmStaking = async (): Promise<string> => {
			dispatch(
				updateDebugInfoAction({
					contractName: autofarmContractConfig.name,
					contractAddress: autofarmContractConfig.address,
					contractMethod: 'stake',
				}),
			);
			const config = await prepareWriteContract({
				address: autofarmContractConfig.address,
				abi: autofarmContractConfig.abi,
				functionName: 'stake',
				args: [parseEther(stakeAmount)],
				chainId,
				account: accountAddress,
			}).catch((error: Error) => {
				return handlePreparationError({
					getState: getState as TGetStateFn,
					dispatch,
					error,
					displayedMessage: TransactionErrorMessage.InternalError,
				});
			});

			return writeContractFromConfig({
				dispatch,
				config,
				chainId,
				errorMessage: TransactionErrorMessage.TransactionFailed,
			});
		};

		return await checkAutoFarmAllowance()
			.then((isAllowed) => {
				return isAllowed
					? sendToAutoFarmStaking()
					: approveAutoFarm().then(() => sendToAutoFarmStaking());
			})
			.then((hash) => {
				try {
					reportEcommerce('purchase', {
						coupon: accountAddress,
						price: btcmtMarketPrice,
						quantity: Number(stakeAmount),
						name: 'BTCMT_staking_auto',
						txId: hash,
					});
				} catch (error) {
					console.error(error);
				}

				try {
					sendYmGoal(YMGoal.StakeAutoSuccess);
				} catch (error) {
					console.error(error);
				}

				try {
					sendGtagGoalPurchase({
						transaction_id: hash,
						coupon: accountAddress,
						value: Number(stakeAmount) * btcmtMarketPrice,
						items: [
							{
								item_name: 'BTCMT_staking_auto',
								price: btcmtMarketPrice || 0,
								quantity: Number(stakeAmount),
							},
						],
					});
				} catch (error) {
					console.error(error);
				}

				try {
					sendFbGoal(FbGoalName.PurchaseSuccess, {
						currency: 'USD',
						value: Number(stakeAmount) * btcmtMarketPrice,
						event_ids: ['BTCMT_staking_auto'],
						num_items: Number(stakeAmount),
						eventRef: accountAddress,
					});
				} catch (error) {
					console.error(error);
				}

				void postInvoice(chainName, { type: 'STAKE', txid: hash, walletAddress: accountAddress });

				dispatch(setAutoFarmFlowStepAction('stake-success'));
				return { hash, amount: stakeAmount };
			})
			.catch((error: Error) => {
				const errorReportData = populateErrorReport({
					state: getState() as TRootState,
					accountAddress,
				});
				sendErrorReport(errorReportData);
				dispatch(setAutoFarmFlowStepAction('stake-error'));
				throw error;
			})
			.finally(() => {
				void dispatch(requestBtcmtBalanceThunkAction({ accountAddress, chainName }));
				void dispatch(requestUserStakesThunkAction({ accountAddress, chainName }));
				void dispatch(requestUserAutoStakesV2ThunkAction({ accountAddress, chainName }));
			});
	},
);

export const startStakeThunkAction = createAsyncThunk(
	'web3-stake/startStake',
	async (
		{
			accountAddress,
			chainName,
			unlockedValue,
			lockedValue = '0',
		}: {
			accountAddress: TAccountAddress | null | undefined;
			chainName: TNetworkName;
			unlockedValue: string;
			lockedValue?: string;
		},
		{ dispatch, getState },
	): Promise<ITransactionResponse> => {
		const state = getState() as TRootState;
		const chainId = state.chainReducer.chain.id;
		const btcmtMarketPrice = state.ratesReducer.btcmtUsdtRate || 0;
		const btcmtBalance = state.balanceReducer.btcmtBalance ?? FallbackString.NotSet;

		if (!accountAddress || !chainName || !chainId) {
			throw new Error(TransactionErrorMessage.IncorrectOperation);
		}

		dispatch(setStakingFlowStepAction('stake-pending'));

		let btcmtContractConfig: TBtcmtBscContractConfig;
		let stakingContractConfig: TStakingContractConfig;
		let spenderAddress: TAccountAddress;

		switch (chainName) {
			case 'bsc':
				btcmtContractConfig = bscContractsConfig[ContractName.BtcmtTokenBsc];
				stakingContractConfig = bscContractsConfig[ContractName.StakingBsc];
				spenderAddress = bscContractsConfig[ContractName.StakingBsc].address;
				break;
			case 'bsc-testnet':
				btcmtContractConfig = bscTestnetContractsConfig[ContractName.BtcmtTokenBsc];
				stakingContractConfig = bscTestnetContractsConfig[ContractName.StakingBsc];
				spenderAddress = bscTestnetContractsConfig[ContractName.StakingBsc].address;
				break;
		}

		const checkAllowanceUnlocked = () =>
			checkBtcmtAllowance({
				accountAddress,
				chainId,
				spenderAddress,
				btcmtContractConfig,
				requiredValue: unlockedValue,
				btcmtBalance,
				dispatch,
			});

		const checkAllowanceLocked = () =>
			checkBtcmtAllowance({
				accountAddress,
				chainId,
				spenderAddress,
				btcmtContractConfig,
				requiredValue: lockedValue,
				btcmtBalance,
				dispatch,
			});

		const approveUnlockedAmount = () =>
			approveBtcmt({
				getState: getState as TGetStateFn,
				accountAddress,
				chainId,
				spenderAddress,
				btcmtContractConfig,
				requiredValue: unlockedValue,
				dispatch,
			});

		const approveLockedAmount = () =>
			approveBtcmt({
				getState: getState as TGetStateFn,
				accountAddress,
				chainId,
				spenderAddress,
				btcmtContractConfig,
				requiredValue: lockedValue,
				dispatch,
			});

		const sendToStaking = async (): Promise<string> => {
			dispatch(
				updateDebugInfoAction({
					contractName: stakingContractConfig.name,
					contractAddress: stakingContractConfig.address,
					contractMethod: 'stakeStart',
				}),
			);

			const config = await prepareWriteContract({
				address: stakingContractConfig.address,
				abi: stakingContractConfig.abi,
				functionName: 'stakeStart',
				args: [parseEther(unlockedValue), parseEther(lockedValue)],
				account: accountAddress,
				chainId,
			}).catch((error: Error) => {
				return handlePreparationError({
					getState: getState as TGetStateFn,
					dispatch,
					error,
					displayedMessage: TransactionErrorMessage.InternalError,
				});
			});
			return writeContractFromConfig({
				config,
				chainId,
				dispatch,
				errorMessage: TransactionErrorMessage.TransactionFailed,
			});
		};

		const startStake = async (): Promise<string> => {
			if (unlockedValue === '0' && lockedValue !== '0') {
				return checkAllowanceLocked().then((isAllowed) =>
					isAllowed ? sendToStaking() : approveLockedAmount().then(() => sendToStaking()),
				);
			}
			return checkAllowanceUnlocked().then((isAllowed) =>
				isAllowed ? sendToStaking() : approveUnlockedAmount().then(() => sendToStaking()),
			);
		};

		return startStake()
			.then((hash) => {
				try {
					reportEcommerce('purchase', {
						coupon: accountAddress,
						price: btcmtMarketPrice,
						quantity: Number(unlockedValue),
						name: 'BTCMT_staking_manual',
						txId: hash,
					});
				} catch (error) {
					console.error(error);
				}

				try {
					sendYmGoal(YMGoal.StakeManualSuccess);
				} catch (error) {
					console.error(error);
				}

				try {
					sendGtagGoalPurchase({
						transaction_id: hash,
						coupon: accountAddress,
						value: Number(unlockedValue) * btcmtMarketPrice,
						items: [
							{
								item_name: 'BTCMT_staking_manual',
								price: btcmtMarketPrice,
								quantity: Number(unlockedValue),
							},
						],
					});
				} catch (error) {
					console.error(error);
				}

				try {
					sendFbGoal(FbGoalName.PurchaseSuccess, {
						currency: 'USD',
						value: Number(unlockedValue) * btcmtMarketPrice,
						event_ids: ['BTCMT_staking_manual'],
						num_items: Number(unlockedValue),
						eventRef: accountAddress,
					});
				} catch (error) {
					console.error(error);
				}

				void postInvoice(chainName, { type: 'STAKE', txid: hash, walletAddress: accountAddress });

				dispatch(setStakingFlowStepAction('stake-success'));
				return { hash, amount: unlockedValue };
			})
			.catch((error: Error) => {
				const errorReportData = populateErrorReport({
					state: getState() as TRootState,
					accountAddress,
				});
				sendErrorReport(errorReportData);
				dispatch(setStakingFlowStepAction('stake-error'));
				throw error;
			})
			.finally(() => {
				void dispatch(requestBtcmtBalanceThunkAction({ accountAddress, chainName }));
				void dispatch(requestUserStakesThunkAction({ accountAddress, chainName }));
				void dispatch(requestUserAutoStakesV2ThunkAction({ accountAddress, chainName }));
			});
	},
);
