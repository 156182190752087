import { createSlice } from '@reduxjs/toolkit';
import { ITransactionHistoryResponse } from 'store/api/fireblocks-api/types/transactions/history/transaction-history-response.interface';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { requestTransactionsHistoryThunkAction } from './transactions.thunk-actions';

export interface ITransactionsState {
	debugError: string | null;
	historyFetchStatus: TFetchStatus;
	historyData: ITransactionHistoryResponse | null;
	shouldCheckForPendingTx: boolean;
}

const initialState: ITransactionsState = {
	debugError: null,
	historyFetchStatus: 'initial',
	historyData: null,
	shouldCheckForPendingTx: false,
};

const transactionsSlice = createSlice({
	name: 'transactions',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(requestTransactionsHistoryThunkAction.pending, (state) => {
				state.historyFetchStatus = 'pending';
				state.debugError = null;
			})
			.addCase(requestTransactionsHistoryThunkAction.fulfilled, (state, { payload }) => {
				state.historyFetchStatus = 'fulfilled';
				state.historyData = payload;
			})
			.addCase(requestTransactionsHistoryThunkAction.rejected, (state, { error }) => {
				state.historyFetchStatus = 'rejected';
				state.debugError = JSON.stringify(error);
			});
	},
});

export const transactionsReducer = transactionsSlice.reducer;
