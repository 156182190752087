import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { switchNetwork } from '@wagmi/core';
import { bsc, bscTestnet } from '@wagmi/core/chains';
import { selectIsCryptoUser } from 'store/auth-reducer/auth.selectors';
import { selectChainName } from 'store/chain-reducer/chain.selectors';
import { useAppDispatch } from 'store/store';
import { useAccount } from 'wagmi';

import { notifyError } from 'utils/notify/notify.utils';

export const useSwitchToTestnet = (): void => {
	const dispatch = useAppDispatch();
	const currentChainName = useSelector(selectChainName);
	const isCryptoUser = useSelector(selectIsCryptoUser);
	const { address: accountAddress } = useAccount();
	let targetChainId: number = bsc.id;

	switch (currentChainName) {
		case 'bsc':
			targetChainId = bscTestnet.id;
			break;
		case 'bsc-testnet':
			targetChainId = bsc.id;
			break;
	}

	useEffect(() => {
		if (process.env.REACT_APP_SWITCH_NETWORK_ON_TEST_SERVER !== 'true' || !isCryptoUser) {
			return;
		}

		const switchTestnet = async (evt: KeyboardEvent) => {
			if (evt.ctrlKey && evt.altKey && evt.key === '1') {
				if (!accountAddress) {
					notifyError('Please connect wallet to switch testnet');
					return;
				}

				return await switchNetwork({
					chainId: targetChainId,
				});
			}
		};

		document.addEventListener('keydown', switchTestnet);

		return () => {
			document.removeEventListener('keydown', switchTestnet);
		};
	}, [dispatch, currentChainName, targetChainId, accountAddress, isCryptoUser]);
};
