import { TAccountAddress } from 'types/blockchain/contracts.types';

import {
	GtagGoalName,
	IBeginCheckoutParams,
	IPurchaseParams,
	TGtagGoalOptions,
	TWindowWithGtag,
} from './gtag.types';

export const cutHashForGa = (walletAddress: string): string => walletAddress.replace(/^0x/i, '');

export const sendGtagGoal = (goal: GtagGoalName, options?: TGtagGoalOptions): void => {
	(window as unknown as TWindowWithGtag)?.gtag('event', goal, options);
};

export const reachGtagGoalAddPaymentInfo = (accountAddress: TAccountAddress): void => {
	sendGtagGoal(GtagGoalName.WalletConnected, {
		coupon: cutHashForGa(accountAddress),
		payment_type: 'Wallet',
		value: 0,
		currency: 'USD',
		items: [
			{
				item_name: 'none',
				quantity: 0,
				price: 0,
			},
		],
	});
};

export const sendGtagGoalBeginCheckout = ({ coupon, value, items }: IBeginCheckoutParams): void => {
	sendGtagGoal(GtagGoalName.StartPurchase, {
		coupon: cutHashForGa(coupon),
		currency: 'USD',
		value,
		items,
	});
};

export const sendGtagGoalPurchase = ({
	transaction_id,
	coupon,
	value,
	items,
}: IPurchaseParams): void => {
	sendGtagGoal(GtagGoalName.PurchaseSuccess, {
		coupon: cutHashForGa(coupon),
		transaction_id: cutHashForGa(transaction_id),
		currency: 'USD',
		value,
		items,
	});
};
