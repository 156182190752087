import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	getInvoices,
	postPurchaseInvoice,
} from 'store/api/fireblocks-api/services/fireblocks-invoice.service';
import { IPurchaseInvoiceRequestBody } from 'store/api/fireblocks-api/types/invoices/purchase-invoice-request-body.interface';
import { INVOICES_DEFAULT_PAGE } from 'store/purchase-reducers/web2-invoice-reducer/constants/invoices-default-page.const';
import { INVOICES_ITEMS_PER_PAGE } from 'store/purchase-reducers/web2-invoice-reducer/constants/invoices-items-per-page.const';

import { notifyError } from 'utils/notify/notify.utils';

export const postPurchaseInvoiceThunkAction = createAsyncThunk(
	'purchase/send-invoice',
	async (body: IPurchaseInvoiceRequestBody, { dispatch }) =>
		postPurchaseInvoice(body)
			.catch((error) => {
				notifyError('Failed to send invoice');
				throw error;
			})
			.finally(() => {
				void dispatch(getInvoicesThunkAction());
			}),
);

export const getInvoicesThunkAction = createAsyncThunk(
	'purchase/get-invoices',
	async ({
		page_number = INVOICES_DEFAULT_PAGE,
		items_per_page = INVOICES_ITEMS_PER_PAGE,
	}: {
		page_number?: number;
		items_per_page?: number;
	} = {}) =>
		getInvoices({ page_number, items_per_page }).catch(() => {
			notifyError('Failed to get invoices');
		}),
);
