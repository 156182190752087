import { createSlice } from '@reduxjs/toolkit';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { IUserWeb3Stakes } from './types/staking-balances.interface';
import {
	requestUserAutoStakesV2ThunkAction,
	requestUserStakesThunkAction,
} from './staking-balance.thunk-actions';

export interface IStakingBalanceReducer {
	debugErrorMessage: string | null;
	cryptoAutoFarmV2Balance?: number | null;
	cryptoAutoFarmV2Status: TFetchStatus;
	cryptoStakingBalances?: IUserWeb3Stakes | null;
	cryptoStakingStatus: TFetchStatus;
}

const initialState: IStakingBalanceReducer = {
	debugErrorMessage: null,
	cryptoAutoFarmV2Balance: null,
	cryptoAutoFarmV2Status: 'initial',
	cryptoStakingBalances: null,
	cryptoStakingStatus: 'initial',
};

const stakingBalancesSlice = createSlice({
	name: 'staking-balances',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(requestUserAutoStakesV2ThunkAction.pending, (state) => {
				state.cryptoAutoFarmV2Status = 'pending';
			})
			.addCase(requestUserAutoStakesV2ThunkAction.fulfilled, (state, { payload }) => {
				state.cryptoAutoFarmV2Status = 'fulfilled';
				state.cryptoAutoFarmV2Balance = payload;
			})
			.addCase(requestUserAutoStakesV2ThunkAction.rejected, (state, { error }) => {
				state.cryptoAutoFarmV2Status = 'rejected';
				if (error.message) {
					state.debugErrorMessage = error.message;
				}
			})
			.addCase(requestUserStakesThunkAction.pending, (state) => {
				state.cryptoStakingStatus = 'pending';
			})
			.addCase(requestUserStakesThunkAction.fulfilled, (state, { payload }) => {
				state.cryptoStakingStatus = 'fulfilled';
				state.cryptoStakingBalances = payload;
			})
			.addCase(requestUserStakesThunkAction.rejected, (state, { error }) => {
				state.cryptoStakingStatus = 'rejected';
				if (error.message) {
					state.debugErrorMessage = error.message;
				}
			});
	},
});

export const stakingBalancesReducer = stakingBalancesSlice.reducer;
