import { statsApi, statsApiDevV1, statsApiV2 } from './stats-api';
import { StatsApiRoute } from './stats-api.route';
import {
	IStatsCommonResponse,
	ITotalTreasuryResponse,
	TTotalHistoryResponse,
} from './stats-api-response.types';

export const requestTotalMined = async (): Promise<IStatsCommonResponse> =>
	await statsApi.get<IStatsCommonResponse>(StatsApiRoute.TotalMined()).then((res) => res?.data);

export const requestTotalStaked = async (): Promise<IStatsCommonResponse> =>
	await statsApi.get<IStatsCommonResponse>(StatsApiRoute.TotalStaked()).then((res) => res?.data);

export const requestTotalMulti = async (): Promise<IStatsCommonResponse> =>
	await statsApi
		.get<IStatsCommonResponse>(StatsApiRoute.TotalMultiplier())
		.then((res) => res?.data);

export const requestTvl = async (): Promise<IStatsCommonResponse> =>
	await statsApi.get<IStatsCommonResponse>(StatsApiRoute.Tvl()).then((res) => res?.data);

export const requestActiveWallets = async (): Promise<IStatsCommonResponse> =>
	await statsApi.get<IStatsCommonResponse>(StatsApiRoute.ActiveWallets()).then((res) => res?.data);

export const requestTotalHistory = async (): Promise<TTotalHistoryResponse> =>
	await statsApi
		.get<TTotalHistoryResponse>(StatsApiRoute.TotalHistory())
		.then((res) => res?.data);

export const requestTotalTreasury = async (): Promise<ITotalTreasuryResponse> =>
	await statsApiV2
		.get<ITotalTreasuryResponse>(StatsApiRoute.TotalTreasury())
		.then((res) => res?.data);

export const requestTotalIssued = async (): Promise<IStatsCommonResponse> =>
	await statsApi.get<IStatsCommonResponse>(StatsApiRoute.TotalIssued()).then((res) => res?.data);
