import { AxiosResponse } from 'axios';
import { fireblocksApi } from 'store/api/fireblocks-api/fireblocks.api';
import { FireblocksApiRoute } from 'store/api/fireblocks-api/fireblocks-api-route';
import { IFiatPaymentRequestBody } from 'store/api/fireblocks-api/types/fiat/fiat-payment-request-body.interface';
import { IFiatPaymentResponse } from 'store/api/fireblocks-api/types/fiat/fiat-payment-response.interface';
import { IFiatPaymentsRequestQuery } from 'store/api/fireblocks-api/types/fiat/fiat-payments-request-query.interface';
import { IFiatPaymentsResponse } from 'store/api/fireblocks-api/types/fiat/fiat-payments-response.interface';

export const requestFiatPaymentData = async (
	body: IFiatPaymentRequestBody,
): Promise<IFiatPaymentResponse> =>
	fireblocksApi
		.post<
			IFiatPaymentResponse,
			AxiosResponse<IFiatPaymentResponse>,
			IFiatPaymentRequestBody
		>(FireblocksApiRoute.Fiat(), body)
		.then((res) => res.data);

export const requestFiatPaymentRequests = async (
	query: IFiatPaymentsRequestQuery,
): Promise<IFiatPaymentsResponse> =>
	fireblocksApi
		.get<
			IFiatPaymentsResponse,
			AxiosResponse<IFiatPaymentsResponse>
		>(FireblocksApiRoute.FiatPayments(query))
		.then((res) => res.data);
