import { TFireblocksNetwork } from 'types/fireblocks/fireblocks-network.type';

import { IFiatPaymentsRequestQuery } from './types/fiat/fiat-payments-request-query.interface';
import { TTransactionGasType } from './types/gas/transaction-gas-type.type';
import { IGetInvoicesRequestQuery } from './types/invoices/get-invoices-request-query.interface';

export const FireblocksApiRoute = {
	Balances: (): string => '/accounts/wallets/balances',
	EstimateGas: (transactionType: TTransactionGasType): string =>
		`/transactions/gas/?transaction_type=${transactionType}`,
	Profile: (): string => '/accounts/profile/',
	CreateWallet: (): string => '/accounts/create-wallet/',
	StakingDeposits: (): string => '/staking/deposits/',
	StakingUnstake: (): string => '/staking/unstake/',
	Claim: (): string => '/staking/claim/',
	InvoicePost: (): string => '/sales/payments/invoice/',
	InvoiceGet: ({ page_number, items_per_page }: IGetInvoicesRequestQuery): string =>
		`/sales/payments/invoice/?page_number=${page_number}&items_per_page=${items_per_page}`,
	Dex: (): string => '/dex/payments/exchanges/',
	DexInfo: (): string => '/dex/payments/exchanges/info/',
	Fiat: (): string => '/sales/fiat/',
	FiatPayments: ({ page_number, items_per_page }: IFiatPaymentsRequestQuery): string =>
		`/sales/fiat/requests/?page_number=${page_number}&items_per_page=${items_per_page}`,
	Rates: (network: TFireblocksNetwork): string => `/rates/?network=${network}`,
	TransactionSend: (): string => '/transactions/send/',
	TransactionHistory: (): string => '/transactions/history/',
	DaoVote: (): string => '/dao/vote/',
};
