import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { bsc } from '@wagmi/core/chains';
import { TFetchStatus } from 'types/api/fetch-status.type';
import { TNetworkName } from 'types/blockchain/contracts.types';

export interface IRawChain {
  id: number;
  network: TNetworkName;
  name: string;
}

export interface IChainState {
  error: string | null;
  fetchStatus: TFetchStatus;
  chain: IRawChain;
}

const initialState: IChainState = {
  error: null,
  fetchStatus: 'initial',
  chain: bsc,
};

const chainSlice = createSlice({
  name: 'chain',
  initialState,
  reducers: {
    changeChainAction: (state, action: PayloadAction<IRawChain>) => {
      state.chain = action.payload;
    },
  },
});

export const { changeChainAction } = chainSlice.actions;
export const chainReducer = chainSlice.reducer;
