export const TransactionErrorMessage = {
	Default: 'Something went wrong',
	IncorrectOperation: 'Incorrect operation',
	TransactionCancelled: 'Transaction cancelled.',
	TransactionFailed: 'Transaction failed',
	InternalError:
		'Internal application error. Trying to call contract method with incorrect arguments.',
	NetworkError: 'Network error. Please reload the page and try again.',
	ApproveFailed: 'Failed to approve allowance.',
	CheckAllowanceFailed: 'Failed to check allowance.',
	ClaimFailed: 'Failed to claim rewards',
} as const;
