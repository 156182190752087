import { createAsyncThunk } from '@reduxjs/toolkit';
import { prepareWriteContract } from '@wagmi/core';
import { FallbackString } from 'constants/fallback-string/fallback-string.const';
import { TransactionErrorMessage } from 'constants/message/transaction-error-message.const';
import { postInvoice } from 'store/api/bsc-api/bsc.service';
import {
	requestBtcbBalanceThunkAction,
	requestBtcmtBalanceThunkAction,
} from 'store/balance-reducer/balance.thunk-actions';
import { updateDebugInfoAction } from 'store/debug-reducer/debug.reducer';
import {
	requestUserAutoStakesV2ThunkAction,
	requestUserStakesThunkAction,
} from 'store/staking-reducers/staking-balances-reducer/staking-balance.thunk-actions';
import {
	setAutoFarmFlowStepAction,
	setStakingFlowStepAction,
} from 'store/staking-reducers/staking-flow-reducer/staking-flow.reducer';
import { requestAvailableToClaimThunkAction } from 'store/staking-reducers/web3-claim-reducer/web3-claim.thunk-actions';
import { TGetStateFn, TRootState } from 'store/store';
import { processClaimReward } from 'store/utils/claim/claim.utils';
import {
	handlePreparationError,
	populateErrorReport,
	sendErrorReport,
} from 'store/utils/error-report/error-report.utils';
import {
	ContractName,
	ITransactionResponse,
	TAccountAddress,
	TAutofarmV2ContractConfig,
	TNetworkName,
	TStakingContractConfig,
} from 'types/blockchain/contracts.types';
import { parseEther } from 'viem';

import { bscContractsConfig, bscTestnetContractsConfig } from 'config/web3/contracts.config';
import { FbGoalName } from 'utils/metrics/fb/fb.types';
import { sendFbGoal } from 'utils/metrics/fb/fb.utils';
import { sendGtagGoalPurchase } from 'utils/metrics/gtag/gtag.utils';
import { YMGoal } from 'utils/metrics/ym/ym.types';
import { reportEcommerce, sendYmGoal } from 'utils/metrics/ym/ym.utils';
import { writeContractFromConfig } from 'utils/web3/web3.utils';

export const withdrawAutoFarmV2ThunkAction = createAsyncThunk(
	'withdraw/withdrawAutoFarm',
	async (
		{
			accountAddress,
			chainName,
			withdrawAmount,
		}: {
			accountAddress: TAccountAddress | null | undefined;
			chainName: TNetworkName;
			withdrawAmount: string;
		},
		{ dispatch, getState },
	): Promise<ITransactionResponse> => {
		dispatch(setAutoFarmFlowStepAction('withdraw-pending'));

		const state = getState() as TRootState;
		const chainId = state.chainReducer.chain.id;
		const btcmtMarketPrice = state.ratesReducer.btcmtUsdtRate || 0;
		const totalStaked =
			state.stakingBalancesReducer.cryptoAutoFarmV2Balance ?? FallbackString.NotSet;

		if (!accountAddress || !chainName || !chainId) {
			throw new Error(TransactionErrorMessage.IncorrectOperation);
		}

		let autofarmContractConfig: TAutofarmV2ContractConfig;

		switch (chainName) {
			case 'bsc':
				autofarmContractConfig = bscContractsConfig[ContractName.AutofarmV2Bsc];
				break;
			case 'bsc-testnet':
				autofarmContractConfig = bscTestnetContractsConfig[ContractName.AutofarmV2Bsc];
				break;
		}

		dispatch(
			updateDebugInfoAction({
				contractAddress: autofarmContractConfig.address,
				contractName: autofarmContractConfig.name,
				contractMethod: 'unstake',
				transactionAmount: withdrawAmount,
				assetName: 'BTCMT',
				assetAvailableAmount: String(totalStaked),
				assetAllowance: FallbackString.NotRequired,
			}),
		);

		const config = await prepareWriteContract({
			address: autofarmContractConfig.address,
			abi: autofarmContractConfig.abi,
			functionName: 'unstake',
			args: [parseEther(withdrawAmount)],
			chainId,
			account: accountAddress,
		}).catch((error: Error) => {
			return handlePreparationError({
				getState: getState as TGetStateFn,
				dispatch,
				error,
				displayedMessage: TransactionErrorMessage.InternalError,
			});
		});

		return writeContractFromConfig({
			config,
			chainId,
			dispatch,
			errorMessage: TransactionErrorMessage.TransactionFailed,
		})
			.then((hash) => {
				try {
					reportEcommerce('purchase', {
						coupon: accountAddress,
						price: btcmtMarketPrice,
						quantity: Number(withdrawAmount),
						name: 'BTCMT_unstaking_auto',
						txId: hash,
					});
				} catch (error) {
					console.error(error);
				}

				try {
					sendYmGoal(YMGoal.WithdrawAutoSuccess);
				} catch (error) {
					console.error(error);
				}

				try {
					sendGtagGoalPurchase({
						transaction_id: hash,
						coupon: accountAddress,
						value: Number(withdrawAmount) * btcmtMarketPrice,
						items: [
							{
								item_name: 'BTCMT_unstaking_auto',
								price: btcmtMarketPrice,
								quantity: Number(withdrawAmount),
							},
						],
					});
				} catch (error) {
					console.error(error);
				}

				try {
					sendFbGoal(FbGoalName.PurchaseSuccess, {
						currency: 'USD',
						value: Number(withdrawAmount) * btcmtMarketPrice,
						event_ids: ['BTCMT_unstaking_auto'],
						num_items: Number(withdrawAmount),
						eventRef: accountAddress,
					});
				} catch (error) {
					console.error(error);
				}

				void postInvoice(chainName, { type: 'UNSTAKE', txid: hash, walletAddress: accountAddress });

				dispatch(setAutoFarmFlowStepAction('withdraw-success'));
				return { hash, amount: withdrawAmount };
			})
			.catch((error: Error) => {
				dispatch(setAutoFarmFlowStepAction('withdraw-error'));
				const errorReportData = populateErrorReport({
					state: getState() as TRootState,
					accountAddress,
				});
				sendErrorReport(errorReportData);
				throw error;
			})
			.finally(() => {
				void dispatch(requestBtcmtBalanceThunkAction({ accountAddress, chainName }));
				void dispatch(requestUserStakesThunkAction({ accountAddress, chainName }));
				void dispatch(requestUserAutoStakesV2ThunkAction({ accountAddress, chainName }));
			});
	},
);

export const withdrawManualThunkAction = createAsyncThunk(
	'withdraw/withdrawManual',
	async (
		{
			accountAddress,
			chainName,
			availableToClaim,
			lockedAmount = '0',
			unlockedAmount,
		}: {
			accountAddress: TAccountAddress | null | undefined;
			chainName: TNetworkName;
			availableToClaim: number;
			lockedAmount?: string;
			unlockedAmount: string;
		},
		{ dispatch, getState },
	): Promise<ITransactionResponse> => {
		dispatch(setStakingFlowStepAction('withdraw-pending'));

		const state = getState() as TRootState;
		const chainId = state.chainReducer.chain.id;
		const btcmtMarketPrice = state.ratesReducer.btcmtUsdtRate || 0;
		const totalStaked =
			state.stakingBalancesReducer.cryptoStakingBalances?.unlockedAmount ?? FallbackString.NotSet;

		if (!accountAddress || !chainName || !chainId) {
			throw new Error(TransactionErrorMessage.IncorrectOperation);
		}

		let stakingContractConfig: TStakingContractConfig;

		switch (chainName) {
			case 'bsc':
				stakingContractConfig = bscContractsConfig[ContractName.StakingBsc];
				break;
			case 'bsc-testnet':
				stakingContractConfig = bscTestnetContractsConfig[ContractName.StakingBsc];
				break;
		}

		dispatch(
			updateDebugInfoAction({
				contractAddress: stakingContractConfig.address,
				contractName: stakingContractConfig.name,
				contractMethod: 'stakeEndPartially',
				transactionAmount: unlockedAmount,
				assetName: 'BTCMT',
				assetAvailableAmount: String(totalStaked),
				assetAllowance: FallbackString.NotRequired,
			}),
		);

		const config = await prepareWriteContract({
			address: stakingContractConfig.address,
			abi: stakingContractConfig.abi,
			functionName: 'stakeEndPartially',
			args: [parseEther(lockedAmount), parseEther(unlockedAmount)],
			chainId,
			account: accountAddress,
		}).catch((error: Error) => {
			return handlePreparationError({
				getState: getState as TGetStateFn,
				dispatch,
				error,
				displayedMessage: TransactionErrorMessage.InternalError,
			});
		});

		const processWithdraw = () =>
			writeContractFromConfig({
				config,
				chainId,
				dispatch,
				errorMessage: TransactionErrorMessage.TransactionFailed,
			})
				.then((hash) => {
					try {
						reportEcommerce('purchase', {
							coupon: accountAddress,
							price: btcmtMarketPrice,
							quantity: Number(unlockedAmount),
							name: 'BTCMT_unstaking_manual',
							txId: hash,
						});
					} catch (error) {
						console.error(error);
					}

					try {
						sendYmGoal(YMGoal.WithdrawManualSuccess);
					} catch (error) {
						console.error(error);
					}

					try {
						sendGtagGoalPurchase({
							transaction_id: hash,
							coupon: accountAddress,
							value: Number(unlockedAmount) * btcmtMarketPrice,
							items: [
								{
									item_name: 'BTCMT_unstaking_manual',
									price: btcmtMarketPrice,
									quantity: Number(unlockedAmount),
								},
							],
						});
					} catch (error) {
						console.error(error);
					}

					try {
						sendFbGoal(FbGoalName.PurchaseSuccess, {
							currency: 'USD',
							value: Number(unlockedAmount) * btcmtMarketPrice,
							event_ids: ['BTCMT_unstaking_manual'],
							num_items: Number(unlockedAmount),
							eventRef: accountAddress,
						});
					} catch (error) {
						console.error(error);
					}

					void postInvoice(chainName, {
						type: 'UNSTAKE',
						txid: hash,
						walletAddress: accountAddress,
					});

					dispatch(setStakingFlowStepAction('withdraw-success'));
					return { hash, amount: unlockedAmount };
				})
				.catch((error: Error) => {
					dispatch(setStakingFlowStepAction('withdraw-error'));
					const errorReportData = populateErrorReport({
						state: getState() as TRootState,
						accountAddress,
					});
					sendErrorReport(errorReportData);
					throw error;
				})
				.finally(() => {
					void dispatch(requestBtcmtBalanceThunkAction({ accountAddress, chainName }));
					void dispatch(requestUserStakesThunkAction({ accountAddress, chainName }));
					void dispatch(requestUserAutoStakesV2ThunkAction({ accountAddress, chainName }));
					void dispatch(requestAvailableToClaimThunkAction({ accountAddress, chainName }));
					void dispatch(requestBtcbBalanceThunkAction({ accountAddress, chainName }));
				});

		return availableToClaim > 0
			? processClaimReward({
					getState: getState as TGetStateFn,
					dispatch,
					accountAddress,
					chainId,
					amount: String(availableToClaim),
					stakingContractConfig,
				}).then(() => processWithdraw())
			: processWithdraw();
	},
);
