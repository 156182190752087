import { FALLBACK_AUTH_ERROR_MESSAGE } from './constants/fallback-auth-error-message';
import { IFirebaseAuthError } from './types/firebase-auth-error.interface';
import { parseFirebaseAuthErrorCode } from './parse-firebase-error-code.util';

export const getFirebaseErrorMessage = (error: IFirebaseAuthError): string => {
	if (!error?.code) {
		return FALLBACK_AUTH_ERROR_MESSAGE;
	}

	switch (error.code) {
		case 'auth/admin-restricted-operation':
			return 'Admin restricted operation';
		case 'auth/argument-error':
			return 'Argument error';
		case 'auth/app-not-authorized':
			return 'App not authorized';
		case 'auth/app-not-installed':
			return 'App not installed';
		case 'auth/captcha-check-failed':
			return 'Captcha check failed';
		case 'auth/code-expired':
			return 'Code expired';
		case 'auth/cordova-not-ready':
			return 'Cordova not ready';
		case 'auth/cors-unsupported':
			return 'Cors unsupported';
		case 'auth/credential-already-in-use':
			return 'Credential already in use';
		case 'auth/custom-token-mismatch':
			return 'Custom token mismatch';
		case 'auth/requires-recent-login':
			return 'Requires recent login';
		case 'auth/dependent-sdk-initialized-before-auth':
			return 'Dependent sdk initialized before auth';
		case 'auth/dynamic-link-not-activated':
			return 'Dynamic link not activated';
		case 'auth/email-change-needs-verification':
			return 'Email change needs verification';
		case 'auth/email-already-in-use':
			return 'Email already in use. Please log in';
		case 'auth/emulator-config-failed':
			return 'Emulator config failed';
		case 'auth/expired-action-code':
			return 'Expired action code';
		case 'auth/cancelled-popup-request':
			return 'Cancelled popup request';
		case 'auth/internal-error':
			return 'Internal error';
		case 'auth/invalid-api-key':
			return 'Invalid api key';
		case 'auth/invalid-app-credential':
			return 'Invalid app credential';
		case 'auth/invalid-app-id':
			return 'Invalid app id';
		case 'auth/invalid-user-token':
			return 'Invalid user token';
		case 'auth/invalid-auth-event':
			return 'Invalid auth event';
		case 'auth/invalid-cert-hash':
			return 'Invalid cert hash';
		case 'auth/invalid-verification-code':
			return 'Invalid verification code';
		case 'auth/invalid-continue-uri':
			return 'Invalid continue uri';
		case 'auth/invalid-cordova-configuration':
			return 'Invalid cordova configuration';
		case 'auth/invalid-custom-token':
			return 'Invalid custom token';
		case 'auth/invalid-dynamic-link-domain':
			return 'Invalid dynamic link domain';
		case 'auth/invalid-email':
			return 'Invalid email';
		case 'auth/invalid-emulator-scheme':
			return 'Invalid emulator scheme';
		case 'auth/invalid-credential':
			return 'Invalid credential';
		case 'auth/invalid-oauth-client-id':
			return 'Invalid oauth client id';
		case 'auth/invalid-oauth-provider':
			return 'Invalid oauth provider';
		case 'auth/invalid-action-code':
			return 'Invalid action code';
		case 'auth/unauthorized-domain':
			return 'Unauthorized domain';
		case 'auth/wrong-password':
			return 'Wrong password';
		case 'auth/invalid-persistence-type':
			return 'Invalid persistence type';
		case 'auth/invalid-phone-number':
			return 'Invalid phone number';
		case 'auth/invalid-provider-id':
			return 'Invalid provider id';
		case 'auth/invalid-recipient-email':
			return 'Invalid recipient email';
		case 'auth/invalid-sender':
			return 'Invalid sender';
		case 'auth/invalid-verification-id':
			return 'Invalid verification id';
		case 'auth/invalid-tenant-id':
			return 'Invalid tenant id';
		case 'auth/multi-factor-info-not-found':
			return 'Multi factor info not found';
		case 'auth/multi-factor-auth-required':
			return 'Multi factor auth required';
		case 'auth/missing-android-pkg-name':
			return 'Missing android pkg name';
		case 'auth/missing-app-credential':
			return 'Missing app credential';
		case 'auth/auth-domain-config-required':
			return 'Auth domain config required';
		case 'auth/missing-verification-code':
			return 'Missing verification code';
		case 'auth/missing-continue-uri':
			return 'Missing continue uri';
		case 'auth/missing-iframe-start':
			return 'Missing iframe start';
		case 'auth/missing-ios-bundle-id':
			return 'Missing ios bundle id';
		case 'auth/missing-or-invalid-nonce':
			return 'Missing or invalid nonce';
		case 'auth/missing-multi-factor-info':
			return 'Missing multi factor info';
		case 'auth/missing-multi-factor-session':
			return 'Missing multi factor session';
		case 'auth/missing-phone-number':
			return 'Missing phone number';
		case 'auth/missing-verification-id':
			return 'Missing verification id';
		case 'auth/app-deleted':
			return 'App deleted';
		case 'auth/account-exists-with-different-credential':
			return 'Account exists with different credential';
		case 'auth/network-request-failed':
			return 'Network request failed';
		case 'auth/null-user':
			return 'Null user';
		case 'auth/no-auth-event':
			return 'No auth event';
		case 'auth/no-such-provider':
			return 'No such provider';
		case 'auth/operation-not-allowed':
			return 'Operation not allowed';
		case 'auth/operation-not-supported-in-this-environment':
			return 'Operation not supported in this environment';
		case 'auth/popup-blocked':
			return 'Popup blocked';
		case 'auth/popup-closed-by-user':
			return 'Popup closed by user';
		case 'auth/provider-already-linked':
			return 'Provider already linked';
		case 'auth/quota-exceeded':
			return 'Quota exceeded';
		case 'auth/redirect-cancelled-by-user':
			return 'Redirect cancelled by user';
		case 'auth/redirect-operation-pending':
			return 'Redirect operation pending';
		case 'auth/rejected-credential':
			return 'Rejected credential';
		case 'auth/second-factor-already-in-use':
			return 'Second factor already in use';
		case 'auth/maximum-second-factor-count-exceeded':
			return 'Maximum second factor count exceeded';
		case 'auth/tenant-id-mismatch':
			return 'Tenant id mismatch';
		case 'auth/timeout':
			return 'Timeout';
		case 'auth/user-token-expired':
			return 'User token expired';
		case 'auth/too-many-requests':
			return 'Too many requests';
		case 'auth/unauthorized-continue-uri':
			return 'Unauthorized continue uri';
		case 'auth/unsupported-first-factor':
			return 'Unsupported first factor';
		case 'auth/unsupported-persistence-type':
			return 'Unsupported persistence type';
		case 'auth/unsupported-tenant-operation':
			return 'Unsupported tenant operation';
		case 'auth/unverified-email':
			return 'Unverified email';
		case 'auth/user-cancelled':
			return 'User cancelled';
		case 'auth/user-not-found':
			return 'User not found';
		case 'auth/user-disabled':
			return 'User disabled';
		case 'auth/user-mismatch':
			return 'User mismatch';
		case 'auth/user-signed-out':
			return 'User signed out';
		case 'auth/weak-password':
			return 'Weak password';
		case 'auth/web-storage-unsupported':
			return 'Web storage unsupported';
		case 'auth/already-initialized':
			return 'Already initialized';
		case 'auth/recaptcha-not-enabled':
			return 'Recaptcha not enabled';
		case 'auth/missing-recaptcha-token':
			return 'Missing recaptcha token';
		case 'auth/invalid-recaptcha-token':
			return 'Invalid recaptcha token';
		case 'auth/invalid-recaptcha-action':
			return 'Invalid recaptcha action';
		case 'auth/missing-client-type':
			return 'Missing client type';
		case 'auth/missing-recaptcha-version':
			return 'Missing recaptcha version';
		case 'auth/invalid-recaptcha-version':
			return 'Invalid recaptcha version';
		case 'auth/invalid-req-type':
			return 'Invalid request type';
		default:
			return parseFirebaseAuthErrorCode(error.code);
	}
};
