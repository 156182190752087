import { createSelector } from '@reduxjs/toolkit';
import type { TRootState } from 'store/store';
import { TFetchStatus } from 'types/api/fetch-status.type';
import { TAccountAddress } from 'types/blockchain/contracts.types';
import type { TUserAuthStatus } from 'types/user/user-status.types';

import type { IAuthState } from './auth.reducer';

const getAuthState = (state: TRootState): IAuthState => state.authReducer;

export const selectIsAuthStateReady = createSelector(
	[getAuthState],
	(state): boolean => state.isAuthStateReady,
);

export const selectCurrentUserEmail = createSelector(
	[getAuthState],
	(state): string | null => state.userAuthData?.email || null,
);

export const selectUserAuthStatus = createSelector(
	[getAuthState],
	(state): TUserAuthStatus => state.userAuthStatus,
);

export const selectIsCryptoUser = createSelector(
	[getAuthState],
	(state): boolean => state.userAuthStatus === 'crypto-wallet-connected',
);

export const selectProfileFetchStatus = createSelector(
	[getAuthState],
	(state): TFetchStatus => state.profileStatus,
);

export const selectWalletCreationStatus = createSelector(
	[getAuthState],
	(state): TFetchStatus => state.createWalletStatus,
);

export const selectWalletDeletionStatus = createSelector(
	[getAuthState],
	(state): TFetchStatus => state.deleteWalletStatus,
);

export const selectSignInStatus = createSelector([getAuthState], (state) => state.signInStatus);

export const selectSignOutStatus = createSelector(
	[getAuthState],
	(state): TFetchStatus => state.signOutStatus,
);

export const selectPasswordResetStatus = createSelector(
	[getAuthState],
	(state): TFetchStatus => state.passwordResetStatus,
);

export const selectWeb2WalletAddress = createSelector(
	[getAuthState],
	(state): TAccountAddress | null | undefined => state.userWalletData?.address,
);

export const selectIsMfaEnabled = createSelector(
	[getAuthState],
	(state): boolean => state.userAuthData?.isMfaEnabled ?? false,
);
