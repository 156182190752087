import { RefObject, useEffect, useMemo } from 'react';
import QRCodeStyling, { Options } from 'qr-code-styling';
import { QrRectangleSize } from 'styles/style-variables/sizes';

export const useQrCode = ({
	qrCodeRef,
	qrCodeValue,
	qrRectangleSize = QrRectangleSize.Big,
	isMonochrome,
	isContentReady = true,
}: {
	qrCodeRef: RefObject<HTMLElement>;
	qrCodeValue: string;
	qrRectangleSize?: number;
	isMonochrome?: boolean;
	isContentReady?: boolean;
}): void => {
	const qrCodeOptions = useMemo<Options>(
		() => ({
			width: qrRectangleSize,
			height: qrRectangleSize,
			type: 'svg',
			image: './minto-logo.svg',
			data: qrCodeValue,
			dotsOptions: {
				color: isMonochrome ? '#000000' : '#1C342F',
				type: 'dots',
			},
			backgroundOptions: {
				color: isMonochrome ? '#ffffff' : 'none',
			},
			cornersSquareOptions: {
				type: 'extra-rounded',
			},
			cornersDotOptions: {
				type: 'dot',
			},
			imageOptions: {
				crossOrigin: 'anonymous',
				imageSize: 0.4,
				margin: 4,
			},
		}),
		[qrCodeValue, qrRectangleSize, isMonochrome],
	);

	const qrCode = useMemo(() => new QRCodeStyling(qrCodeOptions), [qrCodeOptions]);

	useEffect(() => {
		if (qrCode && qrCodeRef.current && isContentReady) {
			qrCode.append(qrCodeRef.current);
		}
	}, [qrCode, qrCodeRef, isContentReady]);

	useEffect(
		() => qrCode.update({ data: qrCodeValue, width: qrRectangleSize, height: qrRectangleSize }),
		[qrCode, qrCodeValue, qrRectangleSize],
	);
};
