import { AxiosResponse } from 'axios';
import { fireblocksApi } from 'store/api/fireblocks-api/fireblocks.api';
import { FireblocksApiRoute } from 'store/api/fireblocks-api/fireblocks-api-route';
import { IGetInvoicesRequestQuery } from 'store/api/fireblocks-api/types/invoices/get-invoices-request-query.interface';
import { IGetInvoicesResponse } from 'store/api/fireblocks-api/types/invoices/get-invoices-response.interface';
import { IPurchaseInvoiceRequestBody } from 'store/api/fireblocks-api/types/invoices/purchase-invoice-request-body.interface';
import { IPurchaseInvoiceResponse } from 'store/api/fireblocks-api/types/invoices/purchase-invoice-response.interface';

export const postPurchaseInvoice = async ({
	symbol,
	amount_to_pay,
	network,
}: IPurchaseInvoiceRequestBody): Promise<IPurchaseInvoiceResponse> =>
	await fireblocksApi
		.post<
			IPurchaseInvoiceResponse,
			AxiosResponse<IPurchaseInvoiceResponse>,
			IPurchaseInvoiceRequestBody
		>(FireblocksApiRoute.InvoicePost(), {
			symbol,
			network,
			amount_to_pay,
		})
		.then((res) => res?.data);

export const getInvoices = async (query: IGetInvoicesRequestQuery): Promise<IGetInvoicesResponse> =>
	await fireblocksApi
		.get<
			IGetInvoicesResponse,
			AxiosResponse<IGetInvoicesResponse>
		>(FireblocksApiRoute.InvoiceGet(query))
		.then((res) => res?.data);
