import { useDispatch } from 'react-redux';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { authReducer } from './auth-reducer/auth.reducer';
import { balanceReducer } from './balance-reducer/balance.reducer';
import { bscReducer } from './bsc-reducer/bsc.reducer';
import { chainReducer } from './chain-reducer/chain.reducer';
import { contractsReducer } from './contracts-reducer/contracts.reducer';
import { daoReducer } from './dao-reducer/dao.reducer';
import { debugReducer } from './debug-reducer/debug.reducer';
import { gasReducer } from './gas-reducer/gas.reducer';
import { modalsReducer } from './modals-reducer/modals.reducer';
import { web2DexReducer } from './purchase-reducers/web2-dex-reducer/web2-dex.reducer';
import { web2FiatReducer } from './purchase-reducers/web2-fiat-reducer/web2-fiat.reducer';
import { web2InvoiceReducer } from './purchase-reducers/web2-invoice-reducer/web2-invoice.reducer';
import { web3PurchaseReducer } from './purchase-reducers/web3-purchase-reducer/web3-purchase.reducer';
import { ratesReducer } from './rates-reducer/rates.reducer';
import { stakingBalancesReducer } from './staking-reducers/staking-balances-reducer/staking-balances.reducer';
import { stakingFlowReducer } from './staking-reducers/staking-flow-reducer/staking-flow.reducer';
import { web2ClaimReducer } from './staking-reducers/web2-claim-reducer/web2-claim-reducer';
import { web2StakingReducer } from './staking-reducers/web2-staking-reducer/web2-staking-reducer';
import { web3ClaimReducer } from './staking-reducers/web3-claim-reducer/web3-claim.reducer';
import { web3StakingReducer } from './staking-reducers/web3-staking-reducer/web3-staking.reducer';
import { web3WithdrawReducer } from './staking-reducers/web3-withdraw-reducer/web3-withdraw.reducer';
import { statsReducer } from './stats-reducer/stats-api.reducer';
import { themeReducer } from './theme-reducer/theme.reducer';
import { transactionsReducer } from './transactions-reducer/transactions.reducer';
import { transfersReducer } from './transfers-reducer/transfers.reducer';

const rootReducer = combineReducers({
	authReducer,
	balanceReducer,
	gasReducer,
	modalsReducer,
	chainReducer,
	debugReducer,
	bscReducer,
	ratesReducer,
	web2StakingReducer,
	web3StakingReducer,
	contractsReducer,
	web3ClaimReducer,
	web2ClaimReducer,
	web3WithdrawReducer,
	stakingFlowReducer,
	stakingBalancesReducer,
	statsReducer,
	web2InvoiceReducer,
	web2DexReducer,
	web3PurchaseReducer,
	web2FiatReducer,
	themeReducer,
	transfersReducer,
	transactionsReducer,
	daoReducer,
});

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type TRootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export type TGetStateFn = () => TRootState;
