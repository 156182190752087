import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'store/store';
import { TNetworkName } from 'types/blockchain/contracts.types';

import { IChainState } from './chain.reducer';

const getChainState = (state: TRootState): IChainState => state.chainReducer;

export const selectChainId = createSelector([getChainState], (state): number => state.chain.id);

export const selectChainName = createSelector(
	[getChainState],
	(state): TNetworkName => state.chain.network,
);
