import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestGasEstimation } from 'store/api/fireblocks-api/services/fireblocks-gas.service';
import { TTransactionGasType } from 'store/api/fireblocks-api/types/gas/transaction-gas-type.type';

export const estimateGasThunkAction = createAsyncThunk(
	'staking/estimate-gas',
	async (transactionType: TTransactionGasType) => {
		return await requestGasEstimation(transactionType);
	},
);
