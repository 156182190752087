export const proposalByIdQuery = `
	query Proposal($id: String!) {
		proposal(id: $id) {
			id
			title
			start
			end
			choices
			scores
			scores_total
			state
			snapshot
			body
			space {
				id
			}
		}
	}
`;
