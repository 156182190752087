import { createSlice } from '@reduxjs/toolkit';
import { TFetchStatus } from 'types/api/fetch-status.type';

import {
	requestBtcmtContractPriceThunkAction,
	requestCapToCellThunkAction,
	requestTotalSoldThunkAction,
} from './contracts.thunk-actions';

export interface IContractsState {
	btcmtContractPrice?: number | null;
	btcmtContractPriceStatus: TFetchStatus;
	totalSupply?: number | null;
	totalSold?: number | null;
	totalSoldStatus: TFetchStatus;
	capToCell?: number | null;
	capToCellStatus: TFetchStatus;
}

const initialState: IContractsState = {
	btcmtContractPrice: null,
	btcmtContractPriceStatus: 'initial',
	totalSupply: null,
	totalSold: null,
	totalSoldStatus: 'initial',
	capToCell: null,
	capToCellStatus: 'initial',
};

const contractsSlice = createSlice({
	name: 'contracts',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(requestBtcmtContractPriceThunkAction.pending, (state) => {
				state.btcmtContractPrice = null;
				state.btcmtContractPriceStatus = 'pending';
			})
			.addCase(requestBtcmtContractPriceThunkAction.fulfilled, (state, { payload }) => {
				state.btcmtContractPrice = payload;
				state.btcmtContractPriceStatus = 'fulfilled';
			})
			.addCase(requestBtcmtContractPriceThunkAction.rejected, (state) => {
				state.btcmtContractPriceStatus = 'rejected';
			})
			.addCase(requestTotalSoldThunkAction.pending, (state) => {
				state.totalSoldStatus = 'pending';
			})
			.addCase(requestTotalSoldThunkAction.fulfilled, (state, { payload }) => {
				state.totalSoldStatus = 'fulfilled';
				state.totalSold = payload;
			})
			.addCase(requestTotalSoldThunkAction.rejected, (state) => {
				state.totalSoldStatus = 'rejected';
			})
			.addCase(requestCapToCellThunkAction.pending, (state) => {
				state.capToCellStatus = 'pending';
			})
			.addCase(requestCapToCellThunkAction.fulfilled, (state, { payload }) => {
				state.capToCellStatus = 'fulfilled';
				state.capToCell = payload;
			})
			.addCase(requestCapToCellThunkAction.rejected, (state) => {
				state.capToCellStatus = 'rejected';
			})
	},
});

export const contractsReducer = contractsSlice.reducer;
