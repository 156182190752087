import { createAsyncThunk } from '@reduxjs/toolkit';
import { prepareWriteContract } from '@wagmi/core';
import { FallbackString } from 'constants/fallback-string/fallback-string.const';
import { TransactionErrorMessage } from 'constants/message/transaction-error-message.const';
import dayjs from 'dayjs';
import { postInvoice } from 'store/api/bsc-api/bsc.service';
import {
	requestBtcmtBalanceThunkAction,
	requestUsdtBalanceThunkAction,
} from 'store/balance-reducer/balance.thunk-actions';
import { requestTokenBSCThunkAction } from 'store/bsc-reducer/bsc.thunk-actions';
import {
	requestBtcmtContractPriceThunkAction,
	requestCapToCellThunkAction,
	requestTotalSoldThunkAction,
} from 'store/contracts-reducer/contracts.thunk-actions';
import { updateDebugInfoAction } from 'store/debug-reducer/debug.reducer';
import { TGetStateFn, TRootState } from 'store/store';
import { populateErrorReport, sendErrorReport } from 'store/utils/error-report/error-report.utils';
import {
	ContractName,
	TAccountAddress,
	TContractsWithAllowanceConfig,
	TNetworkName,
	TPurchaseBscContractConfig,
} from 'types/blockchain/contracts.types';
import { parseEther } from 'viem';

import { bscContractsConfig, bscTestnetContractsConfig } from 'config/web3/contracts.config';
import { FbGoalName } from 'utils/metrics/fb/fb.types';
import { sendFbGoal } from 'utils/metrics/fb/fb.utils';
import { sendGtagGoalBeginCheckout, sendGtagGoalPurchase } from 'utils/metrics/gtag/gtag.utils';
import { YMGoal } from 'utils/metrics/ym/ym.types';
import { reportEcommerce, sendYmGoal } from 'utils/metrics/ym/ym.utils';
import { approveToken, checkAllowance, writeContractFromConfig } from 'utils/web3/web3.utils';

import { setWeb3PurchaseFlowStepAction } from './web3-purchase.reducer';

export const purchaseWeb3ThunkAction = createAsyncThunk(
	'purchase/purchase-web3',
	async (
		{
			accountAddress,
			chainName,
			btcmtAmount,
			usdtAmount,
		}: {
			accountAddress: TAccountAddress | null | undefined;
			chainName: TNetworkName;
			btcmtAmount: string;
			usdtAmount: string;
		},
		{ dispatch, getState },
	) => {
		dispatch(setWeb3PurchaseFlowStepAction('web3-pending'));

		const state = getState() as TRootState;
		const chainId = state.chainReducer.chain.id;
		const btcmtContractPrice = state.contractsReducer.btcmtContractPrice;
		const usdtBalance = state.balanceReducer.usdtBalance ?? FallbackString.NotSet;

		if (!accountAddress || !chainName || !chainId) {
			throw new Error(TransactionErrorMessage.IncorrectOperation);
		}

		try {
			const startPurchaseId = String(dayjs().valueOf());

			reportEcommerce('add', {
				coupon: accountAddress,
				price: btcmtContractPrice || 0,
				quantity: Number(btcmtAmount),
				name: 'BTCMT',
				txId: startPurchaseId,
			});
		} catch (error) {
			console.error(error);
		}

		try {
			sendYmGoal(YMGoal.PurchaseStart);
		} catch (error) {
			console.error(error);
		}

		try {
			sendGtagGoalBeginCheckout({
				coupon: accountAddress,
				value: Number(usdtAmount),
				items: [
					{
						item_name: 'BTCMT',
						price: btcmtContractPrice || 0,
						quantity: Number(btcmtAmount),
					},
				],
			});
		} catch (error) {
			console.error(error);
		}

		try {
			sendFbGoal(FbGoalName.StartPurchase, {
				currency: 'USD',
				value: Number(usdtAmount),
				event_ids: ['BTCMT'],
				num_items: Number(btcmtAmount),
				eventRef: accountAddress,
			});
		} catch (error) {
			console.error(error);
		}

		let usdtContractConfig: TContractsWithAllowanceConfig;
		let spenderAddress: TAccountAddress;
		let purchaseContractConfig: TPurchaseBscContractConfig;

		switch (chainName) {
			case 'bsc':
				usdtContractConfig = bscContractsConfig[ContractName.UsdtTokenBsc];
				purchaseContractConfig = bscContractsConfig[ContractName.PresaleBsc];
				spenderAddress = bscContractsConfig[ContractName.PresaleBsc].address;
				break;
			case 'bsc-testnet':
				usdtContractConfig = bscTestnetContractsConfig[ContractName.UsdtTokenBsc];
				purchaseContractConfig = bscTestnetContractsConfig[ContractName.PresaleBsc];
				spenderAddress = bscTestnetContractsConfig[ContractName.PresaleBsc].address;
				break;
		}

		dispatch(
			updateDebugInfoAction({
				assetName: 'USDT',
			}),
		);

		const checkUsdtAllowance = () =>
			checkAllowance({
				dispatch,
				accountAddress,
				requiredValue: usdtAmount,
				tokenContractConfig: usdtContractConfig,
				spenderAddress,
				chainId,
				assetBalance: String(usdtBalance),
			});

		const approveUsdt = () =>
			approveToken({
				getState: getState as TGetStateFn,
				dispatch,
				accountAddress,
				requiredValue: usdtAmount,
				tokenContractConfig: usdtContractConfig,
				spenderAddress,
				chainId,
			});

		const buyTokens = async (): Promise<string> => {
			const config = await prepareWriteContract({
				address: purchaseContractConfig.address,
				abi: purchaseContractConfig.abi,
				functionName: 'buy',
				args: [parseEther(btcmtAmount), parseEther('0')],
				account: accountAddress,
				chainId,
			});

			return writeContractFromConfig({
				config,
				chainId,
				dispatch,
				errorMessage: TransactionErrorMessage.TransactionFailed,
			});
		};

		return checkUsdtAllowance()
			.then((isAllowed) => {
				return isAllowed ? buyTokens() : approveUsdt().then(() => buyTokens());
			})
			.then((hash) => {
				try {
					reportEcommerce('purchase', {
						coupon: accountAddress,
						price: btcmtContractPrice || 0,
						quantity: Number(btcmtAmount),
						name: 'BTCMT',
						txId: hash,
					});
				} catch (error) {
					console.error(error);
				}

				try {
					sendYmGoal(YMGoal.PurchaseSuccess);
				} catch (error) {
					console.error(error);
				}

				try {
					sendGtagGoalPurchase({
						transaction_id: hash,
						coupon: accountAddress,
						value: Number(usdtAmount),
						items: [
							{
								item_name: 'BTCMT',
								price: btcmtContractPrice || 0,
								quantity: Number(btcmtAmount),
							},
						],
					});
				} catch (error) {
					console.error(error);
				}

				try {
					sendFbGoal(FbGoalName.PurchaseSuccess, {
						currency: 'USD',
						value: Number(usdtAmount),
						event_ids: ['BTCMT'],
						num_items: Number(btcmtAmount),
						eventRef: accountAddress,
					});
				} catch (error) {
					console.error(error);
				}

				try {
					if (chainName === 'bsc') {
						void postInvoice(chainName, { type: 'BUY', txid: hash, walletAddress: accountAddress });
					}
				} catch (error) {
					console.error(error);
				}

				dispatch(setWeb3PurchaseFlowStepAction('web3-success'));
				return { hash, amount: btcmtAmount };
			})
			.catch((error: Error) => {
				const errorReportData = populateErrorReport({
					state: getState() as TRootState,
					accountAddress,
				});
				sendErrorReport(errorReportData);
				dispatch(setWeb3PurchaseFlowStepAction('web3-error'));
				throw error;
			})
			.finally(() => {
				void dispatch(requestTokenBSCThunkAction());
				void dispatch(requestTotalSoldThunkAction(chainName));
				void dispatch(requestCapToCellThunkAction(chainName));
				void dispatch(requestBtcmtContractPriceThunkAction(chainName));
				void dispatch(requestUsdtBalanceThunkAction({ accountAddress, chainName }));
				void dispatch(requestBtcmtBalanceThunkAction({ accountAddress, chainName }));
			});
	},
);
