import { useLayoutEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { bscTestnet } from '@wagmi/core/chains';
import { isProductionFB } from 'store/api/env-config/is-production-fb.config';
import { firebaseAuth } from 'store/api/firebase-api/firebase.config';
import { setAuthUserDataAction, setUserAuthStatusAction } from 'store/auth-reducer/auth.reducer';
import {
	selectCurrentUserEmail,
	selectIsAuthStateReady,
	selectProfileFetchStatus,
} from 'store/auth-reducer/auth.selectors';
import { requestUserProfileThunkAction } from 'store/auth-reducer/fireblocks-auth.thunk-actions';
import { changeChainAction } from 'store/chain-reducer/chain.reducer';
import { selectChainName } from 'store/chain-reducer/chain.selectors';
import { setShouldCheckDexTransactionsAction } from 'store/purchase-reducers/web2-dex-reducer/web2-dex.reducer';
import {
	setShouldCheckClaimTransactionsAction,
	setShouldCheckStakingTransactionsAction,
} from 'store/staking-reducers/staking-flow-reducer/staking-flow.reducer';
import { useAppDispatch } from 'store/store';
import { TUserAuthStatus } from 'types/user/user-status.types';

import { deleteAuthToken } from 'utils/storage/auth-token/delete-auth-token.util';
import { setAuthToken } from 'utils/storage/auth-token/set-auth-token.util';

export const useOnAuthStateChange = (userAuthStatus: TUserAuthStatus): void => {
	const dispatch = useAppDispatch();
	const currentUserEmail = useSelector(selectCurrentUserEmail);
	const profileFetchStatus = useSelector(selectProfileFetchStatus);
	const isAuthStateReady = useSelector(selectIsAuthStateReady);
	const chainName = useSelector(selectChainName);

	const isProfileFetching = useMemo(() => profileFetchStatus === 'pending', [profileFetchStatus]);

	useLayoutEffect(() => {
		const unSubscribe = firebaseAuth?.onAuthStateChanged(async (user) => {
			if (user) {
				if (!isProductionFB && chainName !== 'bsc-testnet') {
					dispatch(changeChainAction(bscTestnet));
				}

				const token = await user.getIdToken();
				const newUserEmail = user.email;
				const isUserVerified = user.emailVerified;
				const isMfaEnabled = !!user.reloadUserInfo?.mfaInfo;

				setAuthToken(token);

				if (isAuthStateReady && currentUserEmail !== newUserEmail) {
					dispatch(setAuthUserDataAction({ email: newUserEmail, isMfaEnabled }));

					if (userAuthStatus === 'anonymous') {
						isUserVerified
							? dispatch(setUserAuthStatusAction('verified-no-wallet'))
							: dispatch(setUserAuthStatusAction('not-verified'));
					}

					if (userAuthStatus !== 'verified-has-wallet' && isUserVerified && !isProfileFetching) {
						void dispatch(requestUserProfileThunkAction());
					}

					if (userAuthStatus === 'crypto-wallet-connected') {
						void dispatch(setShouldCheckClaimTransactionsAction(false));
						void dispatch(setShouldCheckStakingTransactionsAction(false));
						void dispatch(setShouldCheckDexTransactionsAction(false));
					}
				}
			} else {
				setUserAuthStatusAction('anonymous');
				deleteAuthToken();
			}
		});

		return () => unSubscribe();
	}, [dispatch, currentUserEmail, userAuthStatus, isProfileFetching, isAuthStateReady, chainName]);
};
