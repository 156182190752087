import { TAccountAddress } from 'types/blockchain/contracts.types';

export enum YMGoal {
  WalletConnected = 'wallet_connected',
  PurchaseStart = 'btcmt_buy_start',
  PurchaseSuccess = 'btcmt_buy',
  StakeAutoSuccess = 'btcmt_staking_auto',
  WithdrawAutoSuccess = 'btcmt_unstaking_auto',
  StakeManualSuccess = 'btcmt_staking_manual',
  WithdrawManualSuccess = 'btcmt_unstaking_manual',
  ClaimReward = 'claim_rewards',
}

export type TYmEvent = 'reachGoal';

export type TWindowWithYm = {
  ym: (counter: number, event: TYmEvent, goal: YMGoal) => void;
  dataLayer: {
    push: (ecommerceItem: TEcommerceItem) => void;
  };
};

export type TEcommerceItemType =
  | 'BTCMT'
  | 'BTCMT_staking_auto'
  | 'BTCMT_unstaking_auto'
  | 'BTCMT_staking_manual'
  | 'BTCMT_unstaking_manual'
  | 'BTCMT_claim';

export interface IEcommerceReportParams {
  coupon: TAccountAddress;
  price: number;
  quantity: number;
  name: TEcommerceItemType;
  txId: string;
}

export type TEcommerceActionKey = 'add' | 'purchase';

export type TEcommerceItem = {
  ecommerce: {
    [x in TEcommerceActionKey]?: {
      actionField: {
        id: string;
        coupon: TAccountAddress;
        revenue: number;
      };
      products: IEcommerceReportParams[];
    };
  } & { currencyCode: 'USD' };
};
