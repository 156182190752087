import { TNetworkName } from 'types/blockchain/contracts.types';

const SNAPSHOT_BSC_SPACE_NAME = 'mintotestnet.eth';
const SNAPSHOT_BSC_TESTNET_SPACE = 'mintotest.eth';

export const getSnapshotSpace = (chainName: TNetworkName): string => {
	switch (chainName) {
		case 'bsc':
			return SNAPSHOT_BSC_SPACE_NAME;
		case 'bsc-testnet':
			return SNAPSHOT_BSC_TESTNET_SPACE;
	}
};
