import { Dispatch } from 'react';
import { Action } from '@reduxjs/toolkit';
import { FallbackString } from 'constants/fallback-string/fallback-string.const';
import { reportError } from 'store/debug-reducer/api-debug/api-debug.service';
import { updateDebugInfoAction } from 'store/debug-reducer/debug.reducer';
import { store, TGetStateFn } from 'store/store';
import { TAccountAddress } from 'types/blockchain/contracts.types';
import { IErrorReport } from 'types/errors/error-report-body.interface';

const parseUserWallet = (): string => {
	const ethereum = (window as unknown as { ethereum: Record<string, unknown> }).ethereum;
	return Object.keys(ethereum).reduce((acc, key) => {
		if (typeof ethereum[key] === 'boolean' && key.startsWith('is')) {
			return `${acc} ${key}: ${ethereum[key]};`;
		}
		return acc;
	}, '');
};

export const populateErrorReport = ({
	state,
	accountAddress,
}: {
	state: ReturnType<typeof store.getState>;
	accountAddress?: TAccountAddress;
}): IErrorReport => {
	const bnbBalance = state.balanceReducer.bnbBalance;

	return {
		accountAddress: accountAddress || FallbackString.NotSet,
		contractAddress: state.debugReducer.contractAddress || FallbackString.NotSet,
		connectorType: state.debugReducer.connectorName || FallbackString.NotSet,
		contractName: state.debugReducer.contractName || FallbackString.NotSet,
		errorDetails: state.debugReducer.errorDetails || FallbackString.NotSet,
		contractMethod: state.debugReducer.contractMethod || FallbackString.NotSet,
		walletApp: parseUserWallet(),
		userDevice: navigator.userAgent,
		networkName: state.chainReducer.chain.name,
		assetName: state.debugReducer.assetName || FallbackString.NotSet,
		transactionAmount: state.debugReducer.transactionAmount || FallbackString.NotSet,
		assetAllowance: state.debugReducer.assetAllowance || FallbackString.NotSet,
		assetAvailableAmount: state.debugReducer.assetAvailableAmount || FallbackString.NotSet,
		gasAvailableAmount: bnbBalance ? String(bnbBalance) : FallbackString.NotSet,
	};
};

export const handlePreparationError = ({
	dispatch,
	error,
	displayedMessage,
	getState,
}: {
	dispatch: Dispatch<Action>;
	error: Error;
	displayedMessage: string;
	getState: TGetStateFn;
}): never => {
	dispatch(
		updateDebugInfoAction({
			errorDetails: error.message,
		}),
	);
	const errorData = populateErrorReport({ state: getState() });
	sendErrorReport(errorData);
	throw new Error(displayedMessage);
};

export const getIsErrorActual = (errorData: IErrorReport): boolean => {
	const { errorDetails } = errorData;
	const isUserRejectedError =
		/user reject(ed)?|reject(ed)? by user|user cancel(led)?|User denied|Вы отмен(или)?/gi.test(
			errorDetails,
		);

	return !isUserRejectedError;
};

export const sendErrorReport = (data: IErrorReport): void => {
	if (getIsErrorActual(data)) {
		void reportError(data);
	}
};
