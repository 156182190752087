import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestFireblocksRates } from 'store/api/fireblocks-api/services/fireblocks-rates.service';
import { requestPairRate } from 'store/api/rates-api/rates-api.service';

import { IBasicRates } from './types/default-rates.interface';

export const requestBnbUsdtRateThunkAction = createAsyncThunk('ratesApi/bnbUsdtRate', async () => {
	return requestPairRate('BNBBUSD');
});

export const requestBusdUsdtRateThunkAction = createAsyncThunk('rates/busdUsdt', async () => {
	return requestPairRate('BUSDUSDT');
});

export const requestBtcmtUsdtRateThunkAction = createAsyncThunk('rates/btcmtUsdt', async () => {
	return requestPairRate('BTCMTUSD');
});

export const requestBtcbUsdtRateThunkAction = createAsyncThunk('rates/btcbUsdt', async () => {
	return requestPairRate('BTCBUSD');
});

export const requestBasicRatesThunkAction = createAsyncThunk(
	'rates/basic-rates',
	async (): Promise<IBasicRates> => {
		const bnbUsdtPromise = requestPairRate('BNBBUSD');
		const btcbUsdtPromise = requestPairRate('BTCBUSD');
		const btcmtUsdtPromise = requestPairRate('BTCMTUSD');
		const busdUsdtPromise = requestPairRate('BUSDUSDT');

		const [bnbUsdtRate, btcbUsdtRate, btcmtUsdtRate, busdUsdtRate] = await Promise.allSettled([
			bnbUsdtPromise,
			btcbUsdtPromise,
			btcmtUsdtPromise,
			busdUsdtPromise,
		]).then((result) =>
			result.map((item) => (item.status === 'fulfilled' ? Number(item.value) : undefined)),
		);

		return {
			bnbUsdtRate,
			btcbUsdtRate,
			btcmtUsdtRate,
			busdUsdtRate,
		};
	},
);

export const requestEtherToUsdtRateThunkAction = createAsyncThunk('rates/etherToUsdt', async () => {
	return requestFireblocksRates('ethereum').then((data) => {
		const etherRate = data.find((rate) => rate.symbol === 'ETH')?.rate;
		return Number(etherRate) || undefined;
	});
});
