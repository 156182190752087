import { AxiosResponse } from 'axios';
import { fireblocksApi } from 'store/api/fireblocks-api/fireblocks.api';
import { FireblocksApiRoute } from 'store/api/fireblocks-api/fireblocks-api-route';
import { IDaoVoteRequestBody } from 'store/api/fireblocks-api/types/dao/dao-vote-request-body.interface';

export const castWeb2Vote = async ({
	choice,
	space,
	proposal,
}: IDaoVoteRequestBody): Promise<void> =>
	fireblocksApi
		.post<void, AxiosResponse<void>, IDaoVoteRequestBody>(FireblocksApiRoute.DaoVote(), {
			choice,
			space,
			proposal,
		})
		.then((res) => res?.data);
