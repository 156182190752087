import { createSlice } from '@reduxjs/toolkit';
import { TransactionErrorMessage } from 'constants/message/transaction-error-message.const';
import { TFetchStatus } from 'types/api/fetch-status.type';
import { ITransactionResponse } from 'types/blockchain/contracts.types';

import {
	startAutoFarmStakeThunkAction,
	startStakeThunkAction,
} from './web3-stake.thunk-actions';

export interface IStakingState {
	debugErrorMessage: string | null;
	stakeErrorMessage: string | null;
	stakeFetchStatus: TFetchStatus;
	stakeResponse: ITransactionResponse | null;
	isStakeSucceed: boolean;
}

const initialState: IStakingState = {
	debugErrorMessage: null,
	stakeErrorMessage: null,
	stakeFetchStatus: 'initial',
	stakeResponse: null,
	isStakeSucceed: false,
};

const web3StakingSlice = createSlice({
	name: 'web3-staking',
	initialState,
	reducers: {
		resetWeb3StakingStateAction: (state) => {
			state.debugErrorMessage = null;
			state.stakeErrorMessage = null;
			state.stakeFetchStatus = 'initial';
			state.stakeResponse = null;
			state.isStakeSucceed = false;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(startAutoFarmStakeThunkAction.pending, (state) => {
				state.isStakeSucceed = false;
				state.stakeFetchStatus = 'pending';
				state.stakeResponse = null;
			})
			.addCase(startAutoFarmStakeThunkAction.fulfilled, (state, { payload }) => {
				state.stakeFetchStatus = 'fulfilled';
				state.stakeResponse = payload;
				state.isStakeSucceed = true;
			})
			.addCase(startAutoFarmStakeThunkAction.rejected, (state, { error }) => {
				state.stakeFetchStatus = 'rejected';
				if (error?.message) {
					state.stakeErrorMessage = error?.message;
				} else {
					state.stakeErrorMessage = TransactionErrorMessage.Default;
				}
			})
			.addCase(startStakeThunkAction.pending, (state) => {
				state.stakeFetchStatus = 'pending';
				state.stakeResponse = null;
				state.isStakeSucceed = false;
			})
			.addCase(startStakeThunkAction.fulfilled, (state, { payload }) => {
				state.stakeFetchStatus = 'fulfilled';
				state.stakeResponse = payload;
				state.isStakeSucceed = true;
			})
			.addCase(startStakeThunkAction.rejected, (state, { error }) => {
				state.stakeFetchStatus = 'rejected';
				if (error?.message) {
					state.stakeErrorMessage = error?.message;
				} else {
					state.stakeErrorMessage = TransactionErrorMessage.Default;
				}
			});
	},
});

export const { resetWeb3StakingStateAction } = web3StakingSlice.actions;
export const web3StakingReducer = web3StakingSlice.reducer;
