import React from 'react';
import { Link } from 'react-router-dom';

import { IMainMenuItemProps } from './types/main-menu-item-props.interface';

import * as S from './MainMenuItem.styled';

const MainMenuItem: React.FC<IMainMenuItemProps> = ({
	title,
	linkTo,
	icon,
	type,
	handleButtonClick,
	isActive,
}) => {
	switch (type) {
		case 'outerLink':
			return (
				<S.MenuLink $isActive={isActive}>
					<a href={linkTo} target="_blank" rel="noreferrer">
						{icon}
						<span>{title}</span>
					</a>
				</S.MenuLink>
			);
		case 'innerLink':
			return (
				<S.MenuLink $isActive={isActive}>
					<Link to={linkTo ? linkTo : ''}>
						{icon}
						<span>{title}</span>
					</Link>
				</S.MenuLink>
			);
		case 'button':
			return (
				<S.ListItem>
					<S.MenuButton onClick={handleButtonClick ? handleButtonClick : () => null}>
						{icon}
						<span>{title}</span>
					</S.MenuButton>
				</S.ListItem>
			);
	}
};

export default MainMenuItem;
