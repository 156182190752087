import React from 'react';
import { ReactComponent as DashboardIcon } from 'assets/icons/menu-dashboard.svg';
import { ReactComponent as MoreIcon } from 'assets/icons/menu-more.svg';
import { ReactComponent as StakingIcon } from 'assets/icons/menu-staking.svg';
import { ReactComponent as SupportIcon } from 'assets/icons/menu-support.svg';
import { ReactComponent as WalletIconDark } from 'assets/icons/menu-wallet-dark.svg';
import { ReactComponent as WalletIconLight } from 'assets/icons/menu-wallet-light.svg';
import { TThemeMode } from 'types/theme-mode/theme-mode.type';

import { ThemeToggle } from 'components/ui/ThemeToggle';
import { openChat } from 'utils/chat/open-chat.util';
import { AppRoute } from 'utils/routes/app-route';

import { MainMenuItem } from './MainMenuItem';

import * as S from './MainMenu.styled';

interface IMainMenuProps {
	pathName: string;
	currentTheme: TThemeMode;
	handleThemeChange: (theme: TThemeMode) => void;
}

const MainMenu: React.FC<IMainMenuProps> = ({ pathName, currentTheme, handleThemeChange }) => {
	return (
		<S.MenuWrapper>
			<S.MenuList>
				<MainMenuItem
					title="Dashboard"
					linkTo={AppRoute.Dashboard()}
					icon={<DashboardIcon />}
					type="innerLink"
					isActive={pathName === AppRoute.Dashboard()}
				/>
				<MainMenuItem
					title="Wallet"
					linkTo={AppRoute.Wallet()}
					icon={currentTheme === 'dark' ? <WalletIconDark /> : <WalletIconLight />}
					type="innerLink"
					isActive={pathName === AppRoute.Wallet()}
				/>
				<MainMenuItem
					title="Staking"
					linkTo={AppRoute.Staking()}
					icon={<StakingIcon />}
					type="innerLink"
					isActive={pathName === AppRoute.Staking()}
				/>
				<MainMenuItem
					title="Support"
					icon={<SupportIcon />}
					type="button"
					handleButtonClick={openChat}
					isActive={false}
				/>
				<MainMenuItem
					title="More"
					linkTo={AppRoute.More()}
					icon={<MoreIcon />}
					type="innerLink"
					isActive={pathName === AppRoute.More()}
				/>
			</S.MenuList>
			<ThemeToggle currentTheme={currentTheme} handleThemeChange={handleThemeChange} />
		</S.MenuWrapper>
	);
};

export default MainMenu;
