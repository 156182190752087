import { createSlice } from '@reduxjs/toolkit';
import {
	IRewardsResponse,
	ITokenResponse,
	TUserRewardsHistoryNormalized,
} from 'store/api/bsc-api/types/bsc-response.types';
import { TFetchStatus } from 'types/api/fetch-status.type';

import {
	requestRewardsThunkAction,
	requestTokenBSCThunkAction,
	requestUserTotalRewardsHistoryThunkAction,
} from './bsc.thunk-actions';

export interface IBscState {
	tokenBSCData?: ITokenResponse | null;
	tokenBscStatus: TFetchStatus;
	userRewardsHistory?: TUserRewardsHistoryNormalized | null;
	userAutoRewardsHistory?: TUserRewardsHistoryNormalized | null;
	userRewardsHistoryStatus: TFetchStatus;
	rewards?: IRewardsResponse | null;
	rewardsStatus: TFetchStatus;
}

const initialState: IBscState = {
	tokenBSCData: null,
	tokenBscStatus: 'initial',
	userRewardsHistory: null,
	userAutoRewardsHistory: null,
	userRewardsHistoryStatus: 'initial',
	rewards: null,
	rewardsStatus: 'initial',
};

const bscSlice = createSlice({
	name: 'bsc',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(requestTokenBSCThunkAction.pending, (state) => {
				state.tokenBscStatus = 'pending';
				state.tokenBSCData = null;
			})
			.addCase(requestTokenBSCThunkAction.fulfilled, (state, { payload }) => {
				state.tokenBscStatus = 'fulfilled';
				if (payload) {
					state.tokenBSCData = payload;
				}
			})
			.addCase(requestTokenBSCThunkAction.rejected, (state) => {
				state.tokenBscStatus = 'rejected';
			})
			.addCase(requestUserTotalRewardsHistoryThunkAction.pending, (state) => {
				state.userRewardsHistoryStatus = 'pending';
				state.userAutoRewardsHistory = null;
				state.userRewardsHistory = null;
			})
			.addCase(requestUserTotalRewardsHistoryThunkAction.fulfilled, (state, { payload }) => {
				state.userRewardsHistoryStatus = 'fulfilled';
				if (payload) {
					state.userAutoRewardsHistory = payload.autoHistory;
					state.userRewardsHistory = payload.history;
				}
			})
			.addCase(requestUserTotalRewardsHistoryThunkAction.rejected, (state) => {
				state.userRewardsHistoryStatus = 'rejected';
			})
			.addCase(requestRewardsThunkAction.pending, (state) => {
				state.rewards = null;
				state.rewardsStatus = 'pending';
			})
			.addCase(requestRewardsThunkAction.fulfilled, (state, { payload }) => {
				state.rewardsStatus = 'fulfilled';
				if (payload) {
					state.rewards = payload;
				}
			})
			.addCase(requestRewardsThunkAction.rejected, (state) => {
				state.rewardsStatus = 'rejected';
			});
	},
});

export const bscReducer = bscSlice.reducer;
