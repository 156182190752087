import axios from 'axios';
import { isProductionMinto } from 'store/api/env-config/is-production-minto.config';

export const statsApi = axios.create({
	baseURL: isProductionMinto ? 'https://stats-prod.minto.org/v1' : 'https://stats-dev.minto.org/v1',
	responseType: 'json',
});

export const statsApiV2 = axios.create({
	baseURL: isProductionMinto ? 'https://stats-prod.minto.org/v2' : 'https://stats-dev.minto.org/v2',
	responseType: 'json',
});

export const statsApiDevV1 = axios.create({
	baseURL: `https://stats-dev.minto.org/v1`,
	responseType: 'json',
});

export const statsApiDevV2 = axios.create({
	baseURL: `https://stats-dev.minto.org/v2`,
	responseType: 'json',
});
