import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { TRANSACTIONS_CHECK_INTERVAL_MS } from 'constants/intervals/transactions-check-interval-ms.const';
import { TFbStakingType } from 'store/api/fireblocks-api/types/staking/fb-staking-type.type';
import { setClaimFlowStepAction } from 'store/staking-reducers/staking-flow-reducer/staking-flow.reducer';
import {
	selectClaimFlowStep,
	selectFbClaimTransactionsCheckStatus,
	selectShouldCheckClaimTransactions,
} from 'store/staking-reducers/staking-flow-reducer/staking-flow.selectors';
import { checkPendingClaimTransactionsThunkAction } from 'store/staking-reducers/staking-flow-reducer/staking-flow.thunk-actions';
import { useAppDispatch } from 'store/store';

export const useCheckClaimTransactions = ({
	pendingClaimTransactions,
	failedClaimTransactions,
	isCryptoUser,
}: {
	pendingClaimTransactions: TFbStakingType[];
	failedClaimTransactions: TFbStakingType[];
	isCryptoUser: boolean;
}): void => {
	const dispatch = useAppDispatch();
	const shouldCheckClaimTransactions = useSelector(selectShouldCheckClaimTransactions);
	const claimTransactionsCheckStatus = useSelector(selectFbClaimTransactionsCheckStatus);
	const claimFlowStep = useSelector(selectClaimFlowStep);

	const areClaimTransactionsChecking = claimTransactionsCheckStatus === 'pending';
	const isClaimTransactionsCheckFulfilled = claimTransactionsCheckStatus === 'fulfilled';

	const checkIntervalRef = useRef<ReturnType<typeof setInterval>>();

	useEffect(() => {
		if (!isCryptoUser) {
			void dispatch(checkPendingClaimTransactionsThunkAction());
		}
	}, [isCryptoUser, dispatch]);

	useEffect(() => {
		if (!isCryptoUser && shouldCheckClaimTransactions && !areClaimTransactionsChecking) {
			checkIntervalRef.current = setInterval(() => {
				void dispatch(checkPendingClaimTransactionsThunkAction());
			}, TRANSACTIONS_CHECK_INTERVAL_MS);
		} else {
			clearInterval(checkIntervalRef.current);
		}

		return () => {
			clearInterval(checkIntervalRef.current);
		};
	}, [dispatch, shouldCheckClaimTransactions, areClaimTransactionsChecking, isCryptoUser]);

	useEffect(() => {
		if (
			!isCryptoUser &&
			pendingClaimTransactions.length === 0 &&
			isClaimTransactionsCheckFulfilled
		) {
			switch (claimFlowStep) {
				case 'claim-pending':
					dispatch(setClaimFlowStepAction('claim-success'));
					break;
				case 'claim-pending-background':
					dispatch(setClaimFlowStepAction('claim-notify-success'));
					break;
			}
		}
	}, [
		dispatch,
		pendingClaimTransactions,
		claimFlowStep,
		isClaimTransactionsCheckFulfilled,
		isCryptoUser,
	]);

	useEffect(() => {
		if (!isCryptoUser && failedClaimTransactions.length > 0 && isClaimTransactionsCheckFulfilled) {
			switch (claimFlowStep) {
				case 'claim-pending':
					dispatch(setClaimFlowStepAction('claim-error'));
					break;
				case 'claim-pending-background':
					dispatch(setClaimFlowStepAction('claim-notify-error'));
					break;
			}
		}
	}, [
		dispatch,
		failedClaimTransactions,
		claimFlowStep,
		isClaimTransactionsCheckFulfilled,
		isCryptoUser,
	]);
};
