import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoints';

import { SText } from 'components/styled/SText';

export const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
	width: 100%;
	max-width: 348px;
	padding: 24px;
	border-radius: 24px;
	background-color: ${({ theme }) => theme.components.qrPopupContent.bgColor};
	text-align: center;

	@media (max-width: ${BreakPoint.MobileTop}) {
		max-width: 288px;
		padding: 12px;
		border-radius: 16px;
	}
`;

export const QRCodeContainer = styled.div`
	width: 220px;
	height: 220px;
	padding: 8px;
	border-radius: 16px;
	background-color: ${({ theme }) => theme.components.qrPopupContent.qrContainerBgColor};

	@media (max-width: ${BreakPoint.MobileTop}) {
		width: 264px;
		height: 264px;
	}
`;

export const WalletAddress = styled(SText)`
	display: inline-block;
	width: 100%;
	padding: 8px 12px;
	border-radius: 12px;
	background-color: ${({ theme }) => theme.components.qrPopupContent.walletAddress.bgColor};
	font-size: 14px;
	line-height: 22px;
	text-align: center;
	word-break: break-all;
	color: ${({ theme }) => theme.components.qrPopupContent.walletAddress.fontColor};
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	gap: 16px;
	width: 100%;

	& button {
		flex-grow: 1;
		gap: 4px;
		padding: 8px 16px;
		line-height: 22px;
	}
`;
