import { createGlobalStyle } from 'styled-components';

import { BreakPoint } from './style-variables/breakpoints';
import { FontFamily } from './style-variables/fonts';

export const GlobalStyles = createGlobalStyle`
    body {
        position: relative;
        display: flex;
        flex-direction: column;
        max-width: 100%;
        min-height: calc(100vh + 1px);
        margin: 0;
        background-color: ${({ theme }) => theme.globals.outerBgColor};
        font-family: ${FontFamily.Manrope};
				
				@media print {
						min-height: 100vh;
        }
    }

    body * {
        box-sizing: border-box;
				
        ::-ms-reveal {
            display: none;
        }
    }

    #root {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: 100%;

        @media (min-width: ${BreakPoint.DesktopTop}) {
            margin: 0 auto;
        };
    }

    .Toastify__toast-container, .Toastify__toast, .Toastify__toast-body {
        padding: 0;
				border: none;
        border-radius: 12px;
				background: transparent;
    }

    .Toastify__toast-container {
        width: max-content;
    }

    .Toastify__toast {
        min-height: auto;
    }

    .Toastify__toast-body {
        width: 100%;
        height: 100%;
        margin: 0;

        & > div:last-child {
            width: 100%;
            height: 100%;
        }
    }

    .Toastify__close-button, .Toastify__toast-icon {
        display: none;
    }

    @media only screen and (width <= 480px) {

        .Toastify__toast {
            margin-bottom: 1rem;
        }

        .Toastify__toast-container--bottom-center {
            bottom: 1em;
            left: 50%;
            transform: translateX(-50%);
        }
    }
`;
