import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'store/store';
import { TFetchStatus } from 'types/api/fetch-status.type';
import { IFiatPaymentItem } from 'types/fiat-payments/fiat-payment-info-item.interface';
import { formatEther } from 'viem';

import { getFiatPaymentStatus } from 'utils/fiat-payments/get-fiat-payment-status.util';

const getWeb2FiatState = (state: TRootState) => state.web2FiatReducer;

export const selectFireblocksBtcmtUsdtRate = (state: TRootState): number =>
	getWeb2FiatState(state).btcmtUsdtRate ?? 0;

export const selectFiatPaymentLinkAndRate = createSelector(
	[getWeb2FiatState],
	(
		state,
	): {
		checkoutLink: string | undefined;
		btcmtAmountToGet: string | undefined;
	} => {
		const btcmtAmount = state.fiatPaymentData?.btcmt_amount;

		return {
			checkoutLink: state.fiatPaymentData?.checkout_url || undefined,
			btcmtAmountToGet: btcmtAmount ? formatEther(BigInt(btcmtAmount)) : undefined,
		};
	},
);

export const selectFiatPaymentDataStatus = createSelector(
	[getWeb2FiatState],
	(state): TFetchStatus => state.fiatPaymentDataStatus,
);

export const selectFiatPaymentRequests = createSelector(
	[getWeb2FiatState],
	(state): IFiatPaymentItem[] => {
		if (!state.fiatPaymentRequests) {
			return [];
		}

		return state.fiatPaymentRequests.map(
			({
				fiat_amount,
				fiat_currency,
				btcmt_amount,
				status,
				transfer_status,
				merchant_transaction_id,
				currency,
				created_at,
			}) => ({
				fiat_amount,
				fiat_currency,
				btcmt_amount: formatEther(BigInt(btcmt_amount || 0)),
				status,
				transfer_status,
				merchant_transaction_id,
				currency,
				created_at,
			}),
		);
	},
);

export const selectArePendingTransactionsInFiatPayments = createSelector(
	[selectFiatPaymentRequests],
	(fiatPaymentRequests) =>
		fiatPaymentRequests.some(
			({ status, transfer_status }) =>
				getFiatPaymentStatus({
					mercuryoStatus: status || 'pending',
					fireblocksStatus: transfer_status,
				}) === 'pending',
		),
);

export const selectFiatPaymentsStatus = createSelector(
	[getWeb2FiatState],
	(state): TFetchStatus => state.fiatPaymentsStatus,
);

export const selectFireblocksBtcmtUsdtRateStatus = createSelector(
	[getWeb2FiatState],
	(state): TFetchStatus => state.btcmtUsdtRateStatus,
);
