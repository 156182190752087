import { TAccountAddress } from 'types/blockchain/contracts.types';

import {
	IRewardsResponse,
	ITokenResponse,
	TInvoiceType,
	TNetworkName,
	TUserAutoRewardsHistoryResponse,
	TUserRewardsHistoryResponse,
} from './types/bsc-response.types';
import { BscApi } from './bsc.api';
import { BscApiRoute } from './bsc.route';

export const requestTokenStats = async (): Promise<ITokenResponse> =>
	await BscApi.get<ITokenResponse>(BscApiRoute.StatsToken()).then((res) => res?.data);

export const requestUserRewardsHistory = async (
	accountAddress: TAccountAddress,
): Promise<TUserRewardsHistoryResponse> => {
	return await BscApi.get<TUserRewardsHistoryResponse>(BscApiRoute.UserRewardsHistory(), {
		params: {
			bsc_wallet: accountAddress,
		},
	}).then((res) => res?.data);
};

export const requestUserAutoRewardsHistory = async (
	accountAddress: TAccountAddress,
): Promise<TUserAutoRewardsHistoryResponse> => {
	return await BscApi.get<TUserAutoRewardsHistoryResponse>(BscApiRoute.UserAutoFarmHistoryV2(), {
		params: {
			bsc_wallet: accountAddress,
		},
	}).then((res) => res?.data);
};

export const requestRewards = async (): Promise<IRewardsResponse> =>
	await BscApi.get<IRewardsResponse>(BscApiRoute.StatsRewards()).then((res) => res?.data);

export const postInvoice = async (
	chainName: TNetworkName,
	payload: { type: TInvoiceType; txid: string; walletAddress: string },
): Promise<void> => {
	const { type, txid, walletAddress } = payload;
	const walletKey = chainName === 'bsc' ? 'bsc_wallet' : 'bsc_testnet_wallet';
	return await BscApi.post(BscApiRoute.Invoice(), {
		[walletKey]: walletAddress,
		type,
		txid,
	});
};

export const reportUserSignIn = async (accountAddress: string): Promise<void> =>
	await BscApi.post(BscApiRoute.ReportSignIn(), {
		bsc_wallet: accountAddress,
	});
