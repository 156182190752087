import { createSlice } from '@reduxjs/toolkit';
import { TFetchStatus } from 'types/api/fetch-status.type';
import { ITransactionResponse } from 'types/blockchain/contracts.types';

import {
	claimManualRewardThunkAction,
	requestAvailableToClaimThunkAction,
} from './web3-claim.thunk-actions';

export interface IClaimState {
	claimErrorMessage?: string | null;
	claimFetchStatus: TFetchStatus;
	claimResponse: ITransactionResponse | null;
	isClaimSucceed: boolean | null;
	availableToClaim?: number | null;
	availableToClaimStatus: TFetchStatus;
}

const initialState: IClaimState = {
	claimErrorMessage: null,
	claimFetchStatus: 'initial',
	claimResponse: null,
	isClaimSucceed: null,
	availableToClaim: null,
	availableToClaimStatus: 'initial',
};

const claimRewardsSlice = createSlice({
	name: 'blockchain',
	initialState,
	reducers: {
		resetClaimStateAction: (state) => {
			state.claimErrorMessage = null;
			state.claimFetchStatus = 'initial';
			state.claimResponse = null;
			state.isClaimSucceed = null;
			state.availableToClaim = null;
			state.availableToClaimStatus = 'initial';
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(claimManualRewardThunkAction.pending, (state) => {
				state.claimErrorMessage = null;
				state.claimFetchStatus = 'pending';
				state.claimResponse = null;
				state.isClaimSucceed = false;
			})
			.addCase(claimManualRewardThunkAction.fulfilled, (state, { payload }) => {
				state.claimFetchStatus = 'fulfilled';
				state.claimResponse = payload;
				state.isClaimSucceed = true;
			})
			.addCase(claimManualRewardThunkAction.rejected, (state, { error }) => {
				state.claimErrorMessage = error.message;
				state.claimFetchStatus = 'rejected';
			})
			.addCase(requestAvailableToClaimThunkAction.pending, (state) => {
				state.availableToClaimStatus = 'pending';
				state.availableToClaim = null;
			})
			.addCase(requestAvailableToClaimThunkAction.fulfilled, (state, { payload }) => {
				state.availableToClaimStatus = 'fulfilled';
				state.availableToClaim = payload;
			})
			.addCase(requestAvailableToClaimThunkAction.rejected, (state) => {
				state.availableToClaimStatus = 'rejected';
			});
	},
});

export const { resetClaimStateAction } = claimRewardsSlice.actions;
export const web3ClaimReducer = claimRewardsSlice.reducer;
