import { createSlice } from '@reduxjs/toolkit';
import { TAccountAddress } from 'types/blockchain/contracts.types';

export interface IDebugState {
  connectorName: string | null;
  errorDetails: string | null;
  contractName: string | null;
  contractAddress: TAccountAddress | null;
  contractMethod: string | null;
  assetName: string | null;
  assetAvailableAmount: string | null;
  assetAllowance: string | null;
  transactionAmount: string | null;
  walletApp: string | null;
  gasAvailableAmount: string | null;
}

const initialState: IDebugState = {
  connectorName: null,
  errorDetails: null,
  contractName: null,
  contractAddress: null,
  contractMethod: null,
  assetName: null,
  assetAvailableAmount: null,
  assetAllowance: null,
  transactionAmount: null,
  walletApp: null,
  gasAvailableAmount: null,
};

const debugSlice = createSlice({
  name: 'debug',
  initialState,
  reducers: {
    updateDebugInfoAction: (state, { payload }: { payload: Partial<IDebugState> }) => {
      Object.assign(state, payload);
    },
    resetDebugStateAction: (state) => {
      state.errorDetails = null;
      state.contractName = null;
      state.contractAddress = null;
      state.contractMethod = null;
      state.assetName = null;
      state.assetAvailableAmount = null;
      state.assetAllowance = null;
      state.transactionAmount = null;
      state.walletApp = null;
      state.gasAvailableAmount = null;
    },
  },
});

export const { updateDebugInfoAction, resetDebugStateAction } = debugSlice.actions;
export const debugReducer = debugSlice.reducer;
