import { createSlice } from '@reduxjs/toolkit';
import { IFiatPaymentResponse } from 'store/api/fireblocks-api/types/fiat/fiat-payment-response.interface';
import { TFetchStatus } from 'types/api/fetch-status.type';
import { IFiatPaymentItem } from 'types/fiat-payments/fiat-payment-info-item.interface';

import {
	requestFiatPaymentDataThunkAction,
	requestFiatPaymentsThunkAction,
	requestFireblocksBtcmtRateThunkAction,
} from './web2-fiat.thunk-actions';

export interface IWeb2FiatState {
	btcmtRateDebugErrorMessage: string | null;
	fiatPaymentDataDebugErrorMessage: string | null;
	paymentRequestsDebugErrorMessage: string | null;
	btcmtUsdtRate?: number | null;
	btcmtUsdtRateStatus: TFetchStatus;
	fiatPaymentData: IFiatPaymentResponse | undefined | null;
	fiatPaymentDataStatus: TFetchStatus;
	fiatPaymentRequests: IFiatPaymentItem[] | null;
	fiatPaymentsStatus: TFetchStatus;
}

const initialState: IWeb2FiatState = {
	btcmtRateDebugErrorMessage: null,
	fiatPaymentDataDebugErrorMessage: null,
	paymentRequestsDebugErrorMessage: null,
	btcmtUsdtRate: null,
	btcmtUsdtRateStatus: 'initial',
	fiatPaymentData: null,
	fiatPaymentDataStatus: 'initial',
	fiatPaymentRequests: null,
	fiatPaymentsStatus: 'initial',
};

const Web2FiatSlice = createSlice({
	name: 'web2Fiat',
	initialState,
	reducers: {
		resetFiatPaymentDataAction: (state) => {
			state.fiatPaymentData = null;
			state.fiatPaymentDataStatus = 'initial';
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(requestFireblocksBtcmtRateThunkAction.pending, (state) => {
				state.btcmtUsdtRateStatus = 'pending';
				state.btcmtRateDebugErrorMessage = null;
			})
			.addCase(requestFireblocksBtcmtRateThunkAction.fulfilled, (state, action) => {
				state.btcmtUsdtRate = action.payload;
				state.btcmtUsdtRateStatus = 'fulfilled';
			})
			.addCase(requestFireblocksBtcmtRateThunkAction.rejected, (state, { error }) => {
				state.btcmtUsdtRateStatus = 'rejected';
				if (error?.message) {
					state.btcmtRateDebugErrorMessage = error.message;
				}
			})
			.addCase(requestFiatPaymentDataThunkAction.pending, (state) => {
				state.fiatPaymentDataStatus = 'pending';
				state.fiatPaymentData = null;
				state.btcmtUsdtRate = null;
				state.fiatPaymentDataDebugErrorMessage = null;
			})
			.addCase(requestFiatPaymentDataThunkAction.fulfilled, (state, action) => {
				state.fiatPaymentData = action.payload;
				state.fiatPaymentDataStatus = 'fulfilled';
			})
			.addCase(requestFiatPaymentDataThunkAction.rejected, (state, { error }) => {
				state.fiatPaymentDataStatus = 'rejected';
				if (error?.message) {
					state.fiatPaymentDataDebugErrorMessage = error.message;
				}
			})
			.addCase(requestFiatPaymentsThunkAction.pending, (state) => {
				state.fiatPaymentsStatus = 'pending';
				state.paymentRequestsDebugErrorMessage = null;
			})
			.addCase(requestFiatPaymentsThunkAction.fulfilled, (state, action) => {
				state.fiatPaymentRequests = action.payload;
				state.fiatPaymentsStatus = 'fulfilled';
			})
			.addCase(requestFiatPaymentsThunkAction.rejected, (state, { error }) => {
				state.fiatPaymentsStatus = 'rejected';
				if (error?.message) {
					state.paymentRequestsDebugErrorMessage = error.message;
				}
			});
	},
});

export const { resetFiatPaymentDataAction } = Web2FiatSlice.actions;
export const web2FiatReducer = Web2FiatSlice.reducer;
