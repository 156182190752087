import styled from 'styled-components';
import { FontFamily } from 'styles/style-variables/fonts';

import { SText } from 'components/styled/SText';
import Button from 'components/ui/Button';

export const HeaderWrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	width: 100%;
	padding-right: 40px;
	padding-left: 40px;

	@media print {
		display: none;
	}
`;

export const HeaderText = styled(SText)`
	font-family: ${FontFamily.Manrope};
	font-size: 24px;
	line-height: 34px;
	text-align: center;
`;

export const HeaderButton = styled(Button)`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);

	& svg path,
	circle {
		stroke: none;
		fill: ${({ theme }) => theme.components.button.borderless.fontColor.default};
	}

	&:hover,
	&:focus {

		& svg path,
		circle {
			fill: ${({ theme }) => theme.components.button.borderless.fontColor.hoverFocus};
		}
	}

	&:active {

		& svg path,
		circle {
			fill: ${({ theme }) => theme.components.button.borderless.fontColor.active};
		}
	}

	&:disabled {

		& svg path,
		circle {
			fill: ${({ theme }) => theme.components.button.borderless.fontColor.disabled};
		}
	}
`;

export const BackButton = styled(HeaderButton)`
	left: 0;
`;

export const CrossButton = styled(HeaderButton)`
	right: 0;
`;
