import { createSelector } from '@reduxjs/toolkit';
import { IStakingFlowState } from 'store/staking-reducers/staking-flow-reducer/staking-flow.reducer';
import { TStakingFlowStep } from 'store/staking-reducers/staking-flow-reducer/types/staking-flow-step.type';
import { TRootState } from 'store/store';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { IClaimTransactionsStatuses } from './types/claim-transactions-statuses.interface';
import { IStakingTransactionsStatuses } from './types/staking-transactions-statuses.interface';

const getStakingFlowState = (state: TRootState): IStakingFlowState => state.stakingFlowReducer;

export const selectStakingFlowStep = createSelector(
	[getStakingFlowState],
	(state): TStakingFlowStep | null => state.stakingFlowStep,
);

export const selectAutoFarmFlowStep = createSelector(
	[getStakingFlowState],
	(state) => state.autoFarmFlowStep,
);

export const selectClaimFlowStep = createSelector(
	[getStakingFlowState],
	(state) => state.claimFlowStep,
);

export const selectShouldCheckStakingTransactions = createSelector(
	[getStakingFlowState],
	(state): boolean => state.shouldCheckStakingTransactions,
);

export const selectShouldCheckClaimTransactions = createSelector(
	[getStakingFlowState],
	(state): boolean => state.shouldCheckClaimTransactions,
);

export const selectStakingFlowTransactionsStatuses = createSelector(
	[getStakingFlowState],
	(state): IStakingTransactionsStatuses => state.stakingTransactionsStatuses,
);

export const selectClaimFlowTransactionsStatuses = createSelector(
	[getStakingFlowState],
	(state): IClaimTransactionsStatuses => state.claimTransactionsStatuses,
);

export const selectFbStakingTransactionsCheckStatus = createSelector(
	[getStakingFlowState],
	(state): TFetchStatus => state.fbStakingTransactionsCheckStatus,
);

export const selectFbClaimTransactionsCheckStatus = createSelector(
	[getStakingFlowState],
	(state): TFetchStatus => state.fbClaimTransactionsCheckStatus,
);

export const selectIsAutofarmBusy = createSelector([getStakingFlowState], (state): boolean => {
	const stakingTransactionsCount =
		state.stakingTransactionsStatuses.pendingStakeTransactions.filter(
			(type) => type === 'Autofarm',
		).length;
	const withdrawTransactionsCount =
		state.stakingTransactionsStatuses.pendingWithdrawTransactions.filter(
			(type) => type === 'Autofarm',
		).length;

	return stakingTransactionsCount + withdrawTransactionsCount > 0;
});

export const selectIsManualStakingBusy = createSelector([getStakingFlowState], (state): boolean => {
	const stakingTransactionsCount =
		state.stakingTransactionsStatuses.pendingStakeTransactions.filter(
			(type) => type === 'Manual',
		).length;
	const withdrawTransactionsCount =
		state.stakingTransactionsStatuses.pendingWithdrawTransactions.filter(
			(type) => type === 'Manual',
		).length;

	return stakingTransactionsCount + withdrawTransactionsCount > 0;
});
