import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoints';

import { SText } from 'components/styled/SText';
import DotsLoaderWithDelay from 'components/ui/DotsLoaderWithDelay';

export const HeaderWrapper = styled.div<{ $hasMobileBackground: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px 16px 8px;
	background-color: ${({ theme }) => theme.globals.frameBgColor};

	@media (max-width: ${BreakPoint.TabletTop}) {
		padding-bottom: 16px;
		background-color: ${({ theme, $hasMobileBackground }) =>
			$hasMobileBackground ? theme.globals.frameBgColor : theme.globals.contentBgColor};
	}
`;

export const LogoLink = styled(Link)`
	display: flex;
	align-items: center;
	margin-right: 8px;
	padding: 8px;

	&:focus {
		outline: 0;
	}

	@media (max-width: ${BreakPoint.TabletTop}) {
		padding: 0;

		& > div {
			width: 37px;
			height: 24px;
		}
	}
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

export const Balance = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	min-height: 32px;
	padding: 8px;
	border-radius: 12px;
	background-color: ${({ theme }) => theme.components.header.balance.bgColor};

	& svg {
		flex-shrink: 0;

		path {
			fill: ${({ theme }) => theme.components.header.balance.iconColor};
		}
	}
`;

export const BalanceValue = styled(SText)`
	font-size: 14px;
	font-weight: 500;
`;

export const BalanceLoader = styled(DotsLoaderWithDelay)`
	height: 16px;
	padding: 2px 4px;
`;
