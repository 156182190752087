import { TFbStakingStatus } from 'store/api/fireblocks-api/types/staking/fb-staking-status.type';
import { TStakingTransactionStatus } from 'store/staking-reducers/staking-flow-reducer/types/staking-transaction-status.type';

export const categorizeStakingTransaction = (
	transactionStatus: TFbStakingStatus,
): TStakingTransactionStatus => {
	const stakingStatuses: TFbStakingStatus[] = [
		'STAKE_IN_PROGRESS',
		'APPROVE_IN_PROGRESS',
		'REQUESTED_INCREASE',
		'APPROVED',
		'INITIALIZED',
	];

	const withdrawingStatuses: TFbStakingStatus[] = ['UNSTAKE_IN_PROGRESS', 'UNSTAKE_INITIALIZED'];

	const successStatuses: TFbStakingStatus[] = ['STAKED', 'UNSTAKED'];

	const errorStatuses: TFbStakingStatus[] = ['ERROR'];

	if (stakingStatuses.includes(transactionStatus)) {
		return 'staking';
	}
	if (withdrawingStatuses.includes(transactionStatus)) {
		return 'withdrawing';
	}

	if (errorStatuses.includes(transactionStatus)) {
		return 'error';
	}

	if (successStatuses.includes(transactionStatus)) {
		return null;
	}

	return null;
};
