export enum StorageKey {
	AuthorizationToken = 'atn',
	EmailSendTime = 'est',
	ConnectorName = 'con',
	LastConnection = 'lc',
	OuterRoute = 'or',
	CurrentTheme = 'cth',
}

export const StorageUpdateTime = {
	DashboardUpdateTime: 'ldu',
	DaoUpdateTime: 'du',
	StatsUpdateTime: 'lsu',
	StakingOuterUpdateTime: 'sou',
	StakingInnerUpdateTime: 'siu',
	RewardsPageUpdateTime: 'rpu',
	PurchaseUpdateTime: 'lpu',
	RoiPageUpdateTime: 'lru',
	WalletUpdateTime: 'lwu',
} as const;

export const StorageBannerUpdateTime = {
	StakeTokensUpdateTime: 'stb',
} as const;

export type TStorageUpdateItemKey = keyof typeof StorageUpdateTime;
export type TStorageUpdateItemValue = (typeof StorageUpdateTime)[TStorageUpdateItemKey];
