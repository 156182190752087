import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { SText } from 'components/styled/SText';
import DotsLoader from 'components/ui/DotsLoader';

import { DEFAULT_LOADER_DELAY } from './DotsLoaderWithDelay.config';

import * as S from './DotsLoaderWithDelay.styled';

interface IProps {
	dependencies: boolean[];
	setIsContentReady: Dispatch<SetStateAction<boolean>>;
	text?: string;
	delay?: number;
	className?: string;
}

const DotsLoaderWithDelay: React.FC<IProps> = ({
	dependencies,
	setIsContentReady,
	delay = DEFAULT_LOADER_DELAY,
	text,
	className,
}) => {
	const [isTimeElapsed, setIsTimeElapsed] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => setIsTimeElapsed(true), delay);
		return () => clearTimeout(timer);
	}, [delay]);

	useEffect(() => {
		setIsContentReady(false);
		if (isTimeElapsed && dependencies.every((dep) => !dep)) {
			setIsContentReady(true);
		}
	}, [isTimeElapsed, dependencies, setIsContentReady]);

	return (
		<S.Wrapper className={className}>
			{text && <SText>{text}</SText>}
			<DotsLoader />
		</S.Wrapper>
	);
};

export default React.memo(DotsLoaderWithDelay);
