import coinsDesktopPng from 'assets/images/auth-pages/coins-desktop-bg.png';
import coinsDesktopWebp from 'assets/images/auth-pages/coins-desktop-bg.webp';
import coinsDesktopPng2x from 'assets/images/auth-pages/coins-desktop-bg@2x.png';
import coinsDesktopWebp2x from 'assets/images/auth-pages/coins-desktop-bg@2x.webp';
import coinsDesktopPng3x from 'assets/images/auth-pages/coins-desktop-bg@3x.png';
import coinsDesktopWebp3x from 'assets/images/auth-pages/coins-desktop-bg@3x.webp';
import coinsTabletPng from 'assets/images/auth-pages/coins-tablet-bg.png';
import coinsTabletWebp from 'assets/images/auth-pages/coins-tablet-bg.webp';
import coinsTabletPng2x from 'assets/images/auth-pages/coins-tablet-bg@2x.png';
import coinsTabletWebp2x from 'assets/images/auth-pages/coins-tablet-bg@2x.webp';
import coinsTabletPng3x from 'assets/images/auth-pages/coins-tablet-bg@3x.png';
import coinsTabletWebp3x from 'assets/images/auth-pages/coins-tablet-bg@3x.webp';
import styled, { css } from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoints';
import { ZIndex } from 'styles/style-variables/sizes';

export const Wrapper = styled.div<{ $hasBackground: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	flex-grow: 2;
	width: 100%;
	height: 100%;
	background-color: ${({ theme }) => theme.globals.outerBgColor};

	${({ $hasBackground }) =>
		$hasBackground &&
		css`
			@media (min-width: ${BreakPoint.TabletLow}) {
				background-image: image-set(
					url(${coinsTabletWebp}) 1x,
					url(${coinsTabletWebp2x}) 2x,
					url(${coinsTabletWebp3x}) 3x,
					url(${coinsTabletPng}) 1x,
					url(${coinsTabletPng2x}) 2x,
					url(${coinsTabletPng3x}) 3x
				);
				background-repeat: no-repeat;
				background-position: center;
				background-size: auto 100%;
			}

			@media (min-width: ${BreakPoint.DesktopLow}) {
				background-image: image-set(
					url(${coinsDesktopWebp}) 1x,
					url(${coinsDesktopWebp2x}) 2x,
					url(${coinsDesktopWebp3x}) 3x,
					url(${coinsDesktopPng}) 1x,
					url(${coinsDesktopPng2x}) 2x,
					url(${coinsDesktopPng3x}) 3x
				);
				background-size: auto 100%;
			}
		`}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-grow: 1;
	padding-top: 2vh;
	padding-bottom: 2vh;

	@media (max-width: ${BreakPoint.LaptopTop}) {
		padding-top: 24px;
	}
`;

export const Header = styled.header`
	z-index: ${ZIndex.Header};
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 16px;
	background-color: transparent;

	@media (min-width: ${BreakPoint.DesktopLow}) {
		padding: 24px;
	}
`;
