import { TFireblocksPaymentTransactionStatus } from 'types/fiat-payments/fireblocks-payment-transaction-status.type';
import { TMercuryoPaymentTransactionStatus } from 'types/fiat-payments/mercuryo-payment-transaction-status.type';
import { TStatusVariant } from 'types/status/status-variant.type';

export const getFiatPaymentStatus = ({
	mercuryoStatus,
	fireblocksStatus,
}: {
	mercuryoStatus: TMercuryoPaymentTransactionStatus;
	fireblocksStatus: TFireblocksPaymentTransactionStatus;
}): TStatusVariant => {
	switch (fireblocksStatus) {
		case 'SENT':
			return 'completed';
		case 'ERROR':
			return 'error';
		case 'INITIALIZED':
		case 'TRANSFER_IN_PROGRESS':
			return 'pending';
		default:
			switch (mercuryoStatus) {
				case 'order_scheduled':
				case 'order_verified_not_complete':
				case 'pending':
				case 'paid':
					return 'pending';
				case 'cancelled':
				case 'order_failed':
				case 'failed_exchange':
				case 'descriptor_failed':
					return 'error';
				default:
					return 'pending';
			}
	}
};
