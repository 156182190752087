export const HeaderHeight = {
	Desktop: '72px',
	Mobile: '64px',
};

export const MenuSize = {
	DesktopWidth: '96px',
	MobileHeight: '82px',
};

export const QrRectangleSize = {
	Small: 204,
	Medium: 248,
	Big: 272,
};

export const ZIndex = {
	DefaultSelect: '1',
	SelectButton: '2',
	WalletCreatedWrapper: '2',
	Header: '30',
	Menu: '30',
	Popup: '100',
	DatePicker: '20',
};

export const TransitionTime = {
	AccordeonFast: '0.3s',
	AccordeonSlow: '2.5s',
	Active: '0.5s',
	Disabled: '0.5s',
	Hover: '0.5s',
	Scale: '0.1s',
	ProgressBarWidth: '0.3s',
};

export const AnimationDuration = {
	Shake: '0.4s',
	PopupReveal: '0.3s',
};
