export const votesByProposalIdQuery = `
	query Votes($proposal: String!) {
		votes(
			where: { proposal: $proposal }
		) {
			voter
			choice
			vp
		}
	}	
`;
