import { FALLBACK_AUTH_ERROR_MESSAGE } from './constants/fallback-auth-error-message';

export const parseFirebaseAuthErrorCode = (rawMessage: string): string => {
	const [_, afterSlash] = rawMessage.split('/');

	if (!afterSlash) {
		return FALLBACK_AUTH_ERROR_MESSAGE;
	}

	return afterSlash?.charAt(0)?.toUpperCase() + afterSlash.slice(1).split('-').join(' ');
};
