import { createSlice } from '@reduxjs/toolkit';

import { TPopupMode } from './types/modal-reducer.types';

export interface IModalsState {
	popupMode: TPopupMode;
}

const initialState: IModalsState = {
	popupMode: 'disabled',
};

const modalsSlice = createSlice({
	name: 'modals',
	initialState,
	reducers: {
		openQrPopupAction: (state) => {
			state.popupMode = 'qr';
		},
		closeQrPopupAction: (state) => {
			state.popupMode = 'disabled';
		},
	},
});

export const { openQrPopupAction, closeQrPopupAction } = modalsSlice.actions;
export const modalsReducer = modalsSlice.reducer;
