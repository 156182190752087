import { createSelector } from '@reduxjs/toolkit';
import { IClaimState } from 'store/staking-reducers/web3-claim-reducer/web3-claim.reducer';
import { TRootState } from 'store/store';
import { TFetchStatus } from 'types/api/fetch-status.type';

const getClaimRewardState = (state: TRootState): IClaimState => state.web3ClaimReducer;

export const selectAvailableToClaimBtcb = createSelector(
	[getClaimRewardState],
	(state): number => state.availableToClaim ?? 0,
);

export const selectAvailableToClaimStatus = createSelector(
	[getClaimRewardState],
	(state): TFetchStatus => state.availableToClaimStatus,
);
