import axios from 'axios';
import { TNetworkName } from 'types/blockchain/contracts.types';

import { getSnapshotSpace } from 'utils/dao/get-snapshot-space.util';

import { delegateByDelegatorQuery } from './queries/delegate-by-delegator.query';
import { IDelegateResponse } from './types/response/delegate-response.interface';

export const SUBGRAPH_BASE_URL = 'https://subgrapher.snapshot.org/delegation/56';

export const requestDelegations = async ({
	address,
	chainName,
}: {
	address: string;
	chainName: TNetworkName;
}): Promise<IDelegateResponse> =>
	axios
		.post(SUBGRAPH_BASE_URL, {
			query: delegateByDelegatorQuery,
			variables: { space: getSnapshotSpace(chainName), delegator: address },
		})
		.then((response) => response?.data);
