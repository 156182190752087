import { createAsyncThunk } from '@reduxjs/toolkit';
import { isProductionFB } from 'store/api/env-config/is-production-fb.config';
import { castWeb2Vote } from 'store/api/fireblocks-api/services/fireblocks-dao.service';
import { TRootState } from 'store/store';
import { TAccountAddress } from 'types/blockchain/contracts.types';

import { getSnapshotSpace } from 'utils/dao/get-snapshot-space.util';
import { notifyError, notifySuccess } from 'utils/notify/notify.utils';
import { capitalizeString } from 'utils/strings/capitalize-string.util';

import { VoteMessage } from './constants/vote-message';
import { SnapshotRawBaseUrl } from './snapshot/types/snapshot-base-url.enum';
import { ISnapshotError } from './snapshot/types/snapshot-error.interface';
import { castWeb3Vote } from './snapshot/utils/cast-web3-vote.util';
import { refreshProposalAndVotesThunkAction } from './dao-read.thunk-actions';

export const castWeb3VoteThunkAction = createAsyncThunk(
	'dao/castWeb3Vote',
	async (
		{
			proposal,
			choice,
			accountAddress,
		}: {
			proposal: string;
			choice: number;
			accountAddress: TAccountAddress;
		},
		{ dispatch, getState },
	): Promise<void> => {
		const chainName = (getState() as TRootState).chainReducer.chain.network;

		const snapshotClientAddress =
			chainName === 'bsc' ? SnapshotRawBaseUrl.Main : SnapshotRawBaseUrl.Testnet;

		return castWeb3Vote({
			proposal,
			choice,
			accountAddress,
			snapshotClientAddress,
			space: getSnapshotSpace(chainName),
		})
			.then(() => {
				notifySuccess(VoteMessage.Success);
			})
			.catch((error) => {
				const typedErrorObject = error as ISnapshotError;

				if (typedErrorObject.error_description) {
					const errorMessage = typedErrorObject.error_description;
					notifyError(capitalizeString(errorMessage));
				} else {
					notifyError(VoteMessage.Error);
				}

				throw error;
			})
			.finally(() => {
				void dispatch(refreshProposalAndVotesThunkAction({ proposalId: proposal, chainName }));
			});
	},
);

export const castWeb2VoteThunkAction = createAsyncThunk(
	'dao/castWeb2Vote',
	async (
		{
			proposal,
			choice,
			space,
		}: {
			proposal: string;
			choice: number;
			space: string;
		},
		{ dispatch },
	): Promise<void> => {
		return castWeb2Vote({ proposal, space, choice })
			.then(() => {
				notifySuccess(VoteMessage.Success);
			})
			.catch(() => {
				notifyError(VoteMessage.Error);
			})
			.finally(() => {
				void dispatch(
					refreshProposalAndVotesThunkAction({
						proposalId: proposal,
						chainName: isProductionFB ? 'bsc' : 'bsc-testnet',
					}),
				);
			});
	},
);
