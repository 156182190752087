import { useSelector } from 'react-redux';
import { selectUserAuthStatus, selectWeb2WalletAddress } from 'store/auth-reducer/auth.selectors';
import { TAccountAddress } from 'types/blockchain/contracts.types';
import { useAccount } from 'wagmi';

export const useWalletAddress = (): TAccountAddress | null | undefined => {
	const userAuthStatus = useSelector(selectUserAuthStatus);
	const { address: web3WalletAddress } = useAccount();
	const web2WalletAddress = useSelector(selectWeb2WalletAddress);

	switch (userAuthStatus) {
		case 'crypto-wallet-connected':
			return web3WalletAddress;
		default:
			return web2WalletAddress;
	}
};
