import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestSendTransfer } from 'store/api/fireblocks-api/services/fireblocks-transfers.service';
import { ISendTransferRequestBody } from 'store/api/fireblocks-api/types/transfers/send/send-transfer-request-body.interface';

import {
	setSendFlowStepAction,
	setShouldCheckForPendingTransfersAction,
} from './transfers.reducer';

export const requestSendTransferThunkAction = createAsyncThunk(
	'transfers/send',
	async (body: ISendTransferRequestBody, { dispatch }) => {
		dispatch(setSendFlowStepAction('transaction-requested'));

		return requestSendTransfer(body)
			.then((data) => {
				dispatch(setSendFlowStepAction('transaction-pending'));
				dispatch(setShouldCheckForPendingTransfersAction(true));
				return data;
			})
			.catch((error) => {
				dispatch(setSendFlowStepAction('error'));
				throw error;
			});
	},
);
