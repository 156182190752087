import { createSelector } from '@reduxjs/toolkit';
import {
	selectBasicBalancesExact,
	selectBtcmtBalanceFloored,
	selectBtcmtBalanceStatus,
} from 'store/balance-reducer/balance.selectors';
import {
	selectBasicRatesExact,
	selectEtherToUsdtRateExact,
} from 'store/rates-reducer/rates.selectors';
import {
	selectWeb3AutofarmV2BalanceFloored,
	selectWeb3ManualStakingBalanceFloored,
	selectWeb3TotalStakingBalanceStatus,
} from 'store/staking-reducers/staking-balances-reducer/staking-balances.selectors';
import { selectAvailableToClaimBtcb } from 'store/staking-reducers/web3-claim-reducer/web3-claim.selectors';
import { TFetchStatus } from 'types/api/fetch-status.type';
import { IBasicBalancesWithRates } from 'types/assets/basic-balances.interface';

import {
	floorBnbValue,
	floorBtcmtValue,
	floorBtcValue,
	floorUsdtValue,
} from 'utils/formatters/formatters.utils';

export const selectBasicBalancesWithRates = createSelector(
	[selectBasicRatesExact, selectBasicBalancesExact, selectEtherToUsdtRateExact],
	(basicRates, basicBalances, etherRateExact): IBasicBalancesWithRates => {
		const btcmtBalance = floorBtcmtValue(basicBalances.btcmtBalance);
		const btcmtRate = floorBtcmtValue(basicRates.btcmtUsdtRate);
		const usdtBalance = floorUsdtValue(basicBalances.usdtBalance);
		const usdtRate = floorUsdtValue(basicRates.busdUsdtRate);
		const bnbBalance = floorBnbValue(basicBalances.bnbBalance);
		const bnbRate = floorBnbValue(basicRates.bnbUsdtRate);
		const btcbBalance = floorBtcValue(basicBalances.btcbBalance);
		const btcbRate = floorBtcValue(basicRates.btcbUsdtRate);
		const etherRate = floorBtcValue(etherRateExact);

		return {
			BTCMT: {
				balance: btcmtBalance,
				rateToUsdt: btcmtRate,
				balanceToUsdt: btcmtBalance * btcmtRate,
			},
			USDT: {
				balance: usdtBalance,
				rateToUsdt: usdtRate,
				balanceToUsdt: usdtBalance * usdtRate,
			},
			BNB: {
				balance: bnbBalance,
				rateToUsdt: bnbRate,
				balanceToUsdt: bnbBalance * bnbRate,
			},
			BTCB: {
				balance: btcbBalance,
				rateToUsdt: btcbRate,
				balanceToUsdt: btcbBalance * btcbRate,
			},
			ETHER: {
				balance: 0,
				rateToUsdt: etherRate,
				balanceToUsdt: 0,
			},
		};
	},
);

// used in dashboard's wallet section
export const selectTotalAssetsValue = createSelector(
	[selectBasicBalancesWithRates],
	({ BNB, BTCB, USDT, BTCMT }): number =>
		BNB.balanceToUsdt + BTCB.balanceToUsdt + USDT.balanceToUsdt + BTCMT.balanceToUsdt,
);

// used on wallet page
export const selectWalletTotalAssetsValue = createSelector(
	[
		selectBasicBalancesWithRates,
		selectWeb3AutofarmV2BalanceFloored,
		selectWeb3ManualStakingBalanceFloored,
		selectAvailableToClaimBtcb,
	],
	(
		{ BNB, BTCB, USDT, BTCMT },
		autofarmBalance,
		manualStakingBalance,
		availableToClaimBtcb,
	): number =>
		BNB.balanceToUsdt +
		BTCB.balanceToUsdt +
		USDT.balanceToUsdt +
		BTCMT.balanceToUsdt +
		autofarmBalance * BTCMT.rateToUsdt +
		manualStakingBalance * BTCMT.rateToUsdt +
		availableToClaimBtcb * BTCB.rateToUsdt,
);

export const selectTotalBtcmtOwned = createSelector(
	[
		selectBtcmtBalanceFloored,
		selectWeb3AutofarmV2BalanceFloored,
		selectWeb3ManualStakingBalanceFloored,
	],
	(btcmtBalanceFloored, autoFarmBalance, manualStakingBalance): number =>
		btcmtBalanceFloored + autoFarmBalance + manualStakingBalance,
);

export const selectTotalBtcmtOwnedStatus = createSelector(
	[selectWeb3TotalStakingBalanceStatus, selectBtcmtBalanceStatus],
	(web3TotalStakingBalanceStatus, btcmtBalanceStatus): TFetchStatus => {
		if (web3TotalStakingBalanceStatus === 'initial' && btcmtBalanceStatus === 'initial') {
			return 'initial';
		}
		if (web3TotalStakingBalanceStatus === 'fulfilled' && btcmtBalanceStatus === 'fulfilled') {
			return 'fulfilled';
		}
		if (web3TotalStakingBalanceStatus === 'rejected' || btcmtBalanceStatus === 'rejected') {
			return 'rejected';
		}
		if (web3TotalStakingBalanceStatus === 'pending' || btcmtBalanceStatus === 'pending') {
			return 'pending';
		}

		return 'pending';
	},
);
