import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestFbClaim } from 'store/api/fireblocks-api/services/fireblocks-claim.service';
import {
	setClaimFlowStepAction,
	setShouldCheckClaimTransactionsAction,
} from 'store/staking-reducers/staking-flow-reducer/staking-flow.reducer';
import { checkPendingClaimTransactionsThunkAction } from 'store/staking-reducers/staking-flow-reducer/staking-flow.thunk-actions';

export const requestWeb2ClaimThunkAction = createAsyncThunk(
	'web2-staking/stake',
	async (_, { dispatch }): Promise<void> => {
		dispatch(setClaimFlowStepAction('claim-requested'));

		requestFbClaim()
			.then(() => {
				dispatch(setShouldCheckClaimTransactionsAction(true));
				dispatch(setClaimFlowStepAction('claim-pending'));
				void dispatch(checkPendingClaimTransactionsThunkAction());
			})
			.catch(() => {
				dispatch(setClaimFlowStepAction('claim-error'));
			});
	},
);
