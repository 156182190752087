import React from 'react';

import { IButtonProps } from './Button.types';

import * as S from './Button.styled';

const Button = React.forwardRef<HTMLButtonElement, IButtonProps>(
	({ variant = 'primary', text = '', icon, isReversed = false, ...props }, ref) => {
		switch (variant) {
			case 'alter':
				return (
					<S.AlterButton {...props} ref={ref}>
						{isReversed ? (
							<>
								{text && text}
								{icon && icon}
							</>
						) : (
							<>
								{icon && icon}
								{text && text}
							</>
						)}
					</S.AlterButton>
				);
			case 'alter-light':
				return (
					<S.AlterLightButton {...props} ref={ref}>
						{isReversed ? (
							<>
								{text && text}
								{icon && icon}
							</>
						) : (
							<>
								{icon && icon}
								{text && text}
							</>
						)}
					</S.AlterLightButton>
				);
			case 'outlined':
				return (
					<S.OutlinedButton {...props} ref={ref}>
						{isReversed ? (
							<>
								{text && text}
								{icon && icon}
							</>
						) : (
							<>
								{icon && icon}
								{text && text}
							</>
						)}
					</S.OutlinedButton>
				);
			case 'borderless':
				return (
					<S.BorderlessButton {...props} ref={ref}>
						{isReversed ? (
							<>
								{text && text}
								{icon && icon}
							</>
						) : (
							<>
								{icon && icon}
								{text && text}
							</>
						)}
					</S.BorderlessButton>
				);
			case 'borderedIcon':
				return (
					<S.BorderedIcon {...props} ref={ref}>
						{icon && icon}
					</S.BorderedIcon>
				);
			case 'auth-option':
				return (
					<S.AuthOption {...props} ref={ref}>
						{isReversed ? (
							<>
								{text && text}
								{icon && icon}
							</>
						) : (
							<>
								{icon && icon}
								{text && text}
							</>
						)}
					</S.AuthOption>
				);
			default:
				return (
					<S.PrimaryButton {...props} ref={ref}>
						{isReversed ? (
							<>
								{text && text}
								{icon && icon}
							</>
						) : (
							<>
								{icon && icon}
								{text && text}
							</>
						)}
					</S.PrimaryButton>
				);
		}
	},
);

export default React.memo(Button);
