import { TFbClaimStatus } from 'store/api/fireblocks-api/types/claim/claim-status.type';
import { TClaimTransactionStatus } from 'store/staking-reducers/staking-flow-reducer/types/claim-transaction-status.type';

export const categorizeClaimTransaction = (status: TFbClaimStatus): TClaimTransactionStatus => {
	switch (status) {
		case 'INITIALIZED':
		case 'IN_PROGRESS':
			return 'claiming';
		case 'ERROR':
			return 'error';
		default:
			return null;
	}
};
