import { ReactComponent as IconLogoSVG } from 'assets/icons/minto.svg';
import { ReactComponent as FullLogoSVG } from 'assets/icons/minto-full.svg';
import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoints';

export const LogoWrapper = styled.div<{ $width: string; $height: string }>`
	display: inline-flex;
	flex-shrink: 0;
	width: ${({ $width }) => $width};
	height: ${({ $height }) => $height};

	svg {
		flex-shrink: 0;
		width: 100%;
		height: 100%;
	}
`;

export const FullLogo = styled(FullLogoSVG)`
	path {
		fill: ${({ theme }) => theme.components.mintoLogo.full.desktop};
	}
`;

export const IconLogo = styled(IconLogoSVG)`
	path {
		fill: ${({ theme }) => theme.components.mintoLogo.icon.desktop};
	}

	@media (max-width: ${BreakPoint.TabletTop}) {

		path {
			fill: ${({ theme }) => theme.components.mintoLogo.icon.mobile};
		}
	}
`;
