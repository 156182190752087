import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITransactionResponse } from 'types/blockchain/contracts.types';

import { TWeb3PurchaseFlowStep } from './types/web3-purchase-flow.step';
import { purchaseWeb3ThunkAction } from './web3-purchase.thunk-actions';

export interface IWeb3PurchaseState {
	web3PurchaseFlowStep: TWeb3PurchaseFlowStep | null;
	debugErrorMessage: string | null;
	purchaseResponse: ITransactionResponse | null;
}

const initialState: IWeb3PurchaseState = {
	web3PurchaseFlowStep: null,
	debugErrorMessage: null,
	purchaseResponse: null,
};

const web3PurchaseSlice = createSlice({
	name: 'web3-purchase',
	initialState,
	reducers: {
		setWeb3PurchaseFlowStepAction: (state, action: PayloadAction<TWeb3PurchaseFlowStep | null>) => {
			state.web3PurchaseFlowStep = action.payload;
		},
		resetWeb3PurchaseStateAction: (state) => {
			state.web3PurchaseFlowStep = null;
			state.debugErrorMessage = null;
			state.purchaseResponse = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(purchaseWeb3ThunkAction.pending, (state) => {
				state.debugErrorMessage = null;
			})
			.addCase(purchaseWeb3ThunkAction.fulfilled, (state, { payload }) => {
				state.purchaseResponse = payload;
			})
			.addCase(purchaseWeb3ThunkAction.rejected, (state, { error }) => {
				state.debugErrorMessage = error?.message || null;
			});
	},
});

export const { setWeb3PurchaseFlowStepAction, resetWeb3PurchaseStateAction } =
	web3PurchaseSlice.actions;
export const web3PurchaseReducer = web3PurchaseSlice.reducer;
