import { ContractName } from 'types/blockchain/contracts.types';

import { autofarmV1AbiBsc } from './abi/autofarmV1AbiBsc';
import { autofarmV2AbiBsc } from './abi/autofarmV2AbiBsc';
import { bnbAbiBsc } from './abi/bnbAbiBsc';
import { btcbAbiBsc } from './abi/btcbAbiBsc';
import { btcmtAbiBsc } from './abi/btcmtAbiBsc';
import { presaleAbiBsc } from './abi/presaleAbiBsc';
import { stakingAbiBsc } from './abi/stakingAbiBsc';
import { usdtAbiBsc } from './abi/usdtAbiBsc';

export const bscContractsConfig = {
  [ContractName.UsdtTokenBsc]: {
    name: 'USDT Token',
    chainName: 'bsc',
    address: '0x55d398326f99059fF775485246999027B3197955',
    abi: usdtAbiBsc,
  },
  [ContractName.BnbTokenBsc]: {
    name: 'BNB Token',
    chainName: 'bsc',
    address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    abi: bnbAbiBsc,
  },
  [ContractName.PresaleBsc]: {
    name: 'Presale BTCMT',
    chainName: 'bsc',
    address: '0x2b775b778f84b10877a41b78d6724634C7741827',
    abi: presaleAbiBsc,
  },
  [ContractName.BtcmtTokenBsc]: {
    name: 'BTCMT token',
    chainName: 'bsc',
    address: '0x410a56541bd912f9b60943fcb344f1e3d6f09567',
    abi: btcmtAbiBsc,
  },
  [ContractName.StakingBsc]: {
    name: 'Staking manual',
    chainName: 'bsc',
    address: '0x78ae303182FCA96A4629A78Ee13235e6525EbcFb',
    abi: stakingAbiBsc,
  },
  [ContractName.AutofarmV1Bsc]: {
    name: 'Staking auto V1',
    chainName: 'bsc',
    address: '0xe742FCE58484FF7be7835D95E350c23CE55A7E12',
    abi: autofarmV1AbiBsc,
  },
  [ContractName.AutofarmV2Bsc]: {
    name: 'Staking auto V2',
    chainName: 'bsc',
    address: '0xe5c6596A6c8E252Ae323191A4374b7098353105a',
    abi: autofarmV2AbiBsc,
  },
  [ContractName.BtcbTokenBsc]: {
    name: 'BTCB token',
    chainName: 'bsc',
    address: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    abi: btcbAbiBsc,
  },
} as const;

export const bscTestnetContractsConfig = {
  [ContractName.UsdtTokenBsc]: {
    name: 'USDT Token',
    chainName: 'bsc-testnet',
    address: '0x78867BbEeF44f2326bF8DDd1941a4439382EF2A7',
    abi: usdtAbiBsc,
  },
  [ContractName.BusdTokenBsc]: {
    name: 'BUSD Token',
    chainName: 'bsc-testnet',
    address: '0xB500bf7617AB1554eBc67bB4FBC1837996Dc2C9E',
    abi: usdtAbiBsc,
  },
  [ContractName.BnbTokenBsc]: {
    name: 'BNB Token',
    chainName: 'bsc-testnet',
    address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    abi: bnbAbiBsc,
  },
  [ContractName.PresaleBsc]: {
    name: 'Presale BTCMT',
    chainName: 'bsc-testnet',
    address: '0x7C55fca2a314e20eF48A71eCa63092C2562E3092',
    abi: presaleAbiBsc,
  },
  [ContractName.BtcmtTokenBsc]: {
    name: 'BTCMT token',
    chainName: 'bsc-testnet',
    address: '0x34238E292e112542c6d7143b2f536579922C1aEb',
    abi: btcmtAbiBsc,
  },
  [ContractName.StakingBsc]: {
    name: 'Staking manual',
    chainName: 'bsc-testnet',
    address: '0x402fbf519fa24edD97C50cb33c31cb8ea8B890FB',
    // old address is below
    // address: '0x9D11c4eD31774291cB36b566bc3d723c886f1Ca8',
    abi: stakingAbiBsc,
  },
  [ContractName.AutofarmV1Bsc]: {
    name: 'Staking auto V1',
    chainName: 'bsc-testnet',
    address: '0xc203852dde61b6A5727f7466D25Bf9332C14a323',
    abi: autofarmV1AbiBsc,
  },
  [ContractName.AutofarmV2Bsc]: {
    name: 'Staking auto V2',
    chainName: 'bsc-testnet',
    address: '0xC6F9039dc5603c79595e64D3A6FF72C46CF3BecE',
    abi: autofarmV2AbiBsc,
  },
  [ContractName.BtcbTokenBsc]: {
    name: 'BTCB token',
    chainName: 'bsc-testnet',
    address: '0x6A037D6F288c714ac33A3c5645C34c30D99A3a29',
    // old address is below
    // address: '0xAd3a0Ec0938b9315911D58361a16902c44732300',
    abi: btcbAbiBsc,
  },
} as const;
