import { createSlice } from '@reduxjs/toolkit';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { requestWeb2StakeThunkAction, requestWeb2WithdrawThunkAction } from './web2-stake.thunk-actions';

export interface IWeb2StakingState {
	debugErrorMessage: string | null;
	stakingFetchStatus: TFetchStatus;
	withdrawFetchStatus: TFetchStatus;
}

const initialState: IWeb2StakingState = {
	debugErrorMessage: null,
	stakingFetchStatus: 'initial',
	withdrawFetchStatus: 'initial',
};

const web2StakingSlice = createSlice({
	name: 'web2-staking',
	initialState,
	reducers: {
		resetWeb2StakingStateAction: (state) => {
			state.debugErrorMessage = null;
			state.stakingFetchStatus = 'initial';
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(requestWeb2StakeThunkAction.pending, (state) => {
				state.stakingFetchStatus = 'pending';
			})
			.addCase(requestWeb2StakeThunkAction.fulfilled, (state) => {
				state.stakingFetchStatus = 'fulfilled';
			})
			.addCase(requestWeb2StakeThunkAction.rejected, (state) => {
				state.stakingFetchStatus = 'rejected';
			})
			.addCase(requestWeb2WithdrawThunkAction.pending, (state) => {
				state.withdrawFetchStatus = 'pending';
			})
			.addCase(requestWeb2WithdrawThunkAction.fulfilled, (state) => {
				state.withdrawFetchStatus = 'fulfilled';
			})
			.addCase(requestWeb2WithdrawThunkAction.rejected, (state) => {
				state.withdrawFetchStatus = 'rejected';
			})
	},
});

export const { resetWeb2StakingStateAction } = web2StakingSlice.actions;
export const web2StakingReducer = web2StakingSlice.reducer;
