import { useEffect, useState } from 'react';

export const useNavigatorCapabilitiesCheck = (): {
	isCopyingAvailable: boolean;
	isSharingAvailable: boolean;
} => {
	const [isCopyingAvailable, setIsCopyingAvailable] = useState(false);
	const [isSharingAvailable, setIsSharingAvailable] = useState(false);

	useEffect(() => {
		if (navigator.clipboard && navigator.clipboard['writeText']) {
			setIsCopyingAvailable(true);
		}

		if (navigator['share']) {
			setIsSharingAvailable(true);
		}
	}, []);

	return { isCopyingAvailable, isSharingAvailable };
};
