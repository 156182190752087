import { createSelector } from '@reduxjs/toolkit';
import fromExponential from 'from-exponential';
import { TRootState } from 'store/store';
import { TFetchStatus } from 'types/api/fetch-status.type';

import {
	floorBnbValue,
	floorBtcmtValue,
	floorBtcValue,
	floorUsdtValue,
} from 'utils/formatters/formatters.utils';

import { IBalanceState } from './balance-reducer.types';

const getBalanceState = (state: TRootState): IBalanceState => state.balanceReducer;

export const selectBtcmtBalanceFloored = createSelector([getBalanceState], (state): number => {
	return floorBtcmtValue(state.btcmtBalance || 0);
});

export const selectBnbBalanceFloored = createSelector([getBalanceState], (state): number => {
	return floorBnbValue(state.bnbBalance || 0);
});

export const selectUsdtBalanceFloored = createSelector([getBalanceState], (state): number => {
	return floorUsdtValue(state.usdtBalance || 0);
});

export const selectBasicBalancesExact = createSelector(
	[getBalanceState],
	(
		state,
	): {
		bnbBalance: number;
		btcmtBalance: number;
		usdtBalance: number;
		btcbBalance: number;
	} => {
		return {
			btcmtBalance: Number(fromExponential(state.btcmtBalance || 0)),
			bnbBalance: Number(fromExponential(state.bnbBalance || 0)),
			usdtBalance: Number(fromExponential(state.usdtBalance || 0)),
			btcbBalance: Number(fromExponential(state.btcbBalance || 0)),
		};
	},
);

export const selectWeb3TotalBalanceStatus = createSelector(
	[getBalanceState],
	(state): TFetchStatus => {
		const { btcmtBalanceStatus, bnbBalanceStatus, usdtBalanceStatus, btcbBalanceStatus } = state;

		const dataStatuses = [
			btcmtBalanceStatus,
			bnbBalanceStatus,
			usdtBalanceStatus,
			btcbBalanceStatus,
		];

		if (dataStatuses.every((status) => status === 'initial')) {
			return 'initial';
		}

		if (dataStatuses.every((status) => status === 'fulfilled')) {
			return 'fulfilled';
		}

		if (dataStatuses.some((status) => status === 'rejected')) {
			return 'rejected';
		}

		if (dataStatuses.some((status) => status === 'pending')) {
			return 'pending';
		}

		return 'pending';
	},
);

export const selectBtcmtBalanceStatus = createSelector(
	[getBalanceState],
	(state): TFetchStatus => state.btcmtBalanceStatus,
);

export const selectUsdtBalanceStatus = createSelector(
	[getBalanceState],
	(state): TFetchStatus => state.usdtBalanceStatus,
);

export const selectBnbBalanceStatus = createSelector(
	[getBalanceState],
	(state): TFetchStatus => state.bnbBalanceStatus,
);

export const selectBtcbBalanceStatus = createSelector(
	[getBalanceState],
	(state): TFetchStatus => state.btcbBalanceStatus,
);
