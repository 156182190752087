import { CopyTextMessage } from 'constants/message/toast-message.const';

import { notifyError, notifySuccess } from 'utils/notify/notify.utils';

export const copyText = (value: string): void => {
	navigator?.clipboard
		?.writeText(value)
		.then(() => {
			notifySuccess(CopyTextMessage.Success);
		})
		.catch(() => {
			notifyError(CopyTextMessage.Error);
		});
};
