import { fireblocksApi } from 'store/api/fireblocks-api/fireblocks.api';
import { FireblocksApiRoute } from 'store/api/fireblocks-api/fireblocks-api-route';
import { IClaimHistoryResponse } from 'store/api/fireblocks-api/types/claim/claim-history-response.interface';
import { IClaimResponse } from 'store/api/fireblocks-api/types/claim/claim-response.interface';

export const requestFbClaim = async (): Promise<IClaimResponse> =>
	await fireblocksApi.post<IClaimResponse>(FireblocksApiRoute.Claim()).then((res) => res?.data);

export const requestFbClaimHistory = async (): Promise<IClaimHistoryResponse> =>
	await fireblocksApi
		.get<IClaimHistoryResponse>(FireblocksApiRoute.Claim())
		.then((res) => res?.data);
