import { AxiosResponse } from 'axios';
import { fireblocksApi } from 'store/api/fireblocks-api/fireblocks.api';
import { FireblocksApiRoute } from 'store/api/fireblocks-api/fireblocks-api-route';
import { ISendTransferRequestBody } from 'store/api/fireblocks-api/types/transfers/send/send-transfer-request-body.interface';
import { ISendTransferResponse } from 'store/api/fireblocks-api/types/transfers/send/send-transfer-response.interface';

export const requestSendTransfer = async (
	body: ISendTransferRequestBody,
): Promise<ISendTransferResponse> =>
	await fireblocksApi
		.post<
			ISendTransferResponse,
			AxiosResponse<ISendTransferResponse>,
			ISendTransferRequestBody
		>(FireblocksApiRoute.TransactionSend(), body)
		.then((res) => res?.data);
