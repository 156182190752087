import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { selectUserAuthStatus } from 'store/auth-reducer/auth.selectors';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from 'styles/GlobalStyles';
import { ResetStyles } from 'styles/ResetStyles';

import { useOnAuthStateChange } from 'components/logic/App/hooks/useOnAuthStateChange';
import InnerLayout from 'components/logic/InnerLayout';
import OuterLayout from 'components/logic/OuterLayout';
import { RedirectToOuterRoute } from 'components/route-wrappers/RedirectToOuterRoute';
import { AppRoute } from 'utils/routes/app-route';

import { useTheme } from './hooks/useTheme';
import { useViewportMetaContent } from './hooks/useViewportMetaContent';
import { useWalletAutoConnection } from './hooks/useWalletAutoConnection';

const PageBuyBtcmt = React.lazy(() => import('pages/PageBuyBtcmt'));
const PageDashboard = React.lazy(() => import('pages/PageDashboard'));
const PageDeleteAccount = React.lazy(() => import('pages/PageDeleteAccount'));
const PageLogin = React.lazy(() => import('pages/PageLogin'));
const PageMore = React.lazy(() => import('pages/PageMore'));
const PageNotFound = React.lazy(() => import('pages/PageNotFound'));
const PageReceive = React.lazy(() => import('pages/PageReceive'));
const PageRegistration = React.lazy(() => import('pages/PageRegistration'));
const PageResetPassword = React.lazy(() => import('pages/PageResetPassword'));
const PageRewards = React.lazy(() => import('pages/PageRewards'));
const PageRoi = React.lazy(() => import('pages/PageRoi'));
const PageSend = React.lazy(() => import('pages/PageSend'));
const PageSetupMfa = React.lazy(() => import('pages/PageSetupMfa'));
const PageStakingInner = React.lazy(() => import('pages/PageStakingInner'));
const PageStakingOuter = React.lazy(() => import('pages/PageStakingOuter'));
const PageStats = React.lazy(() => import('pages/PageStats'));
const PageSwap = React.lazy(() => import('pages/PageSwap'));
const PageVerification = React.lazy(() => import('pages/PageVerification'));
const PageWallet = React.lazy(() => import('pages/PageWallet'));
const PageDao = React.lazy(() => import('pages/PageDao'));
const PageDaoVote = React.lazy(() => import('pages/PageDaoVote'));

const App: React.FC = () => {
	const userAuthStatus = useSelector(selectUserAuthStatus);

	const theme = useTheme();

	useOnAuthStateChange(userAuthStatus);
	useWalletAutoConnection();
	useViewportMetaContent();

	switch (userAuthStatus) {
		case 'anonymous':
		case 'account-just-deleted':
		case 'otp-required':
			return (
				<ThemeProvider theme={theme}>
					<ResetStyles />
					<GlobalStyles />
					<ToastContainer
						position={'bottom-center'}
						autoClose={5000}
						hideProgressBar
						newestOnTop={false}
						closeOnClick={false}
						rtl={false}
						pauseOnFocusLoss={false}
						draggable={false}
						pauseOnHover
					/>
					<Routes>
						<Route path="*" element={<Navigate to={AppRoute.Home()} />} />
						<Route
							path={AppRoute.Home()}
							element={<OuterLayout hasBackground={userAuthStatus === 'anonymous'} />}
						>
							<Route index element={<Navigate to={AppRoute.Login()} />} />
							<Route path={AppRoute.Login()} element={<PageLogin />} />
							<Route path={AppRoute.Registration()} element={<PageRegistration />} />
						</Route>
					</Routes>
				</ThemeProvider>
			);

		case 'forgot-password':
		case 'reset-email-sent':
			return (
				<ThemeProvider theme={theme}>
					<ResetStyles />
					<GlobalStyles />
					<ToastContainer
						position={'bottom-center'}
						autoClose={5000}
						hideProgressBar
						newestOnTop={false}
						closeOnClick={false}
						rtl={false}
						pauseOnFocusLoss={false}
						draggable={false}
						pauseOnHover
					/>
					<Routes>
						<Route path="*" element={<Navigate to={AppRoute.PasswordReset()} />} />
						<Route path={AppRoute.PasswordReset()} element={<OuterLayout hasBackground={false} />}>
							<Route index element={<PageResetPassword userAuthStatus={userAuthStatus} />} />
						</Route>
					</Routes>
				</ThemeProvider>
			);

		case 'not-verified':
			return (
				<ThemeProvider theme={theme}>
					<ResetStyles />
					<GlobalStyles />
					<ToastContainer
						position={'bottom-center'}
						autoClose={5000}
						hideProgressBar
						newestOnTop={false}
						closeOnClick={false}
						rtl={false}
						pauseOnFocusLoss={false}
						draggable={false}
						pauseOnHover
					/>
					<Routes>
						<Route path="*" element={<Navigate to={AppRoute.Home()} />} />
						<Route path={AppRoute.Home()} element={<OuterLayout hasBackground={false} />}>
							<Route index element={<Navigate to={AppRoute.Verification()} />} />
							<Route path={AppRoute.Verification()} element={<PageVerification />} />
						</Route>
					</Routes>
				</ThemeProvider>
			);

		case 'verified-no-wallet':
		case 'wallet-just-created':
			return (
				<ThemeProvider theme={theme}>
					<ResetStyles />
					<GlobalStyles />
					<ToastContainer
						position={'bottom-center'}
						autoClose={5000}
						hideProgressBar
						newestOnTop={false}
						closeOnClick={false}
						rtl={false}
						pauseOnFocusLoss={false}
						draggable={false}
						pauseOnHover
					/>
					<Routes>
						<Route path="*" element={<Navigate to={AppRoute.Home()} />} />
						<Route path={AppRoute.Home()} element={<OuterLayout hasBackground={false} />}>
							<Route index element={<Navigate to={AppRoute.Verification()} />} />
							<Route path={AppRoute.Verification()} element={<PageVerification />} />
						</Route>
					</Routes>
				</ThemeProvider>
			);

		case 'verified-has-wallet':
		case 'crypto-wallet-connected':
			return (
				<ThemeProvider theme={theme}>
					<ResetStyles />
					<GlobalStyles />
					<ToastContainer
						position={'bottom-center'}
						autoClose={5000}
						hideProgressBar
						newestOnTop={false}
						closeOnClick={false}
						rtl={false}
						pauseOnFocusLoss={false}
						draggable={false}
						pauseOnHover
					/>
					<Routes>
						<Route
							path="*"
							element={
								<RedirectToOuterRoute>
									<Navigate to={AppRoute.Dashboard()} />
								</RedirectToOuterRoute>
							}
						/>
						<Route path={AppRoute.Dashboard()} element={<InnerLayout />}>
							<Route index element={<PageDashboard />} />
							<Route path={AppRoute.Staking()}>
								<Route index element={<PageStakingOuter />} />
								<Route
									path={AppRoute.StakingInner('auto')}
									element={<PageStakingInner stakingType="auto" />}
								/>
								<Route
									path={AppRoute.StakingInner('manual')}
									element={<PageStakingInner stakingType="manual" />}
								/>
							</Route>
							<Route path={AppRoute.Wallet()}>
								<Route index element={<PageWallet />} />
								<Route path={AppRoute.Send()} element={<PageSend />} />
								<Route path={AppRoute.Receive()} element={<PageReceive />} />
								<Route path={AppRoute.Swap()} element={<PageSwap />} />
							</Route>
							<Route path={AppRoute.More()} element={<PageMore />} />
							<Route path={AppRoute.Stats()} element={<PageStats />} />
							<Route path={AppRoute.SetupMfa()} element={<PageSetupMfa />} />
							<Route path={AppRoute.DeleteAccount()} element={<PageDeleteAccount />} />
							<Route path={AppRoute.Rewards()} element={<PageRewards />} />
							<Route path={AppRoute.BuyBtcmt()} element={<PageBuyBtcmt />} />
							<Route path={AppRoute.Calculator()} element={<PageRoi />} />
							{/*<Route path={AppRoute.Dao()} element={<PageDao />} />*/}
							{/*<Route path={AppRoute.DaoVote(':id')} element={<PageDaoVote />} />*/}
							<Route path={AppRoute.NotFound()} element={<PageNotFound />} />
						</Route>
					</Routes>
				</ThemeProvider>
			);
		default:
			return null;
	}
};

export default App;
