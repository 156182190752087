export const proposalsBySpaceQuery = `
	query Proposals($snapshotSpace: String!) {
		proposals(
			where: { space: $snapshotSpace }
			orderBy: "created"
			orderDirection: desc
		) {
			id
			title
			start
			end
			choices
			scores
			scores_total
			state
			snapshot
			body
		}
	}
`;
