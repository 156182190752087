import { TStakingType } from 'types/staking/staking-type.type';

export const AppRoute = {
	Home: (): string => '/',
	Dashboard: (): string => '/',
	// Dao: (): string => '/dao',
	DaoVote: (id: string): string => `/dao/vote/${id}`,
	DeleteAccount: (): string => '/delete-account',
	Login: (): string => '/login',
	Registration: (): string => '/registration',
	Verification: (): string => '/verification',
	Staking: (): string => '/staking',
	StakingInner: (type: TStakingType): string => `/staking/${type}`,
	Wallet: (): string => '/wallet',
	More: (): string => '/more',
	PasswordReset: (): string => '/password-reset',
	SetupMfa: (): string => '/setup-mfa',
	Rewards: (): string => '/history',
	Stats: (): string => '/stats',
	BuyBtcmt: (): string => '/purchase',
	Receive: (): string => '/wallet/receive',
	Swap: (): string => '/wallet/swap',
	Send: (): string => '/wallet/send',
	Calculator: (): string => '/calculator',
	NotFound: (): string => '/not-found',
} as const;
