export const delegateByDelegatorQuery = `
query Delegations($space: String!, $delegator: Bytes!) {
    delegations(
				first: 1, 
				where: {
						space: $space, 
						delegator: $delegator
						}
				) {
						delegate
				}
		}
`;
