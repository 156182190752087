import { createSelector } from '@reduxjs/toolkit';
import { IDexEstimation } from 'store/api/fireblocks-api/types/dex/dex-estimation.interface';
import { TRootState } from 'store/store';
import { TFetchStatus } from 'types/api/fetch-status.type';
import { TBuyFromDexFlowStep } from 'types/dex/buy-from-dex-flow-step.type';
import { formatEther } from 'viem';

const getWeb2DexState = (state: TRootState) => state.web2DexReducer;

export const selectDexEstimation = createSelector([getWeb2DexState], (state): IDexEstimation => {
	return {
		amount: Number(formatEther(BigInt(state.dexEstimation?.amount || 0))) || 0,
		gasEstimation: Number(formatEther(BigInt(state.dexEstimation?.gas_estimate || 0), 'gwei')) || 0,
	};
});

export const selectDexEstimationStatus = createSelector(
	[getWeb2DexState],
	(state): TFetchStatus => state.dexEstimationStatus,
);

export const selectIsLastDexTransactionPending = createSelector(
	[getWeb2DexState],
	(state): boolean => state.dexLastTransaction.isPending === true,
);

export const selectIsLastDexTransactionFulfilled = createSelector(
	[getWeb2DexState],
	(state): boolean => state.dexLastTransaction.isFulfilled === true,
);

export const selectIsLastDexTransactionFailed = createSelector(
	[getWeb2DexState],
	(state): boolean => state.dexLastTransaction.isFailed === true,
);

export const selectShouldCheckDexTransactions = createSelector(
	[getWeb2DexState],
	(state): boolean => state.shouldCheckDexTransactions,
);

export const selectDexTransactionsCheckStatus = createSelector(
	[getWeb2DexState],
	(state): TFetchStatus => state.transactionsCheckStatus,
);

export const selectBuyFromDexFlowStep = createSelector(
	[getWeb2DexState],
	(state): TBuyFromDexFlowStep => state.buyFromDexFlowStep,
);

export const selectReceivedBtcmtAmount = createSelector([getWeb2DexState], (state): string =>
	formatEther(BigInt(state.dexLastTransaction.data?.amount_income || 0)),
);
