import { createAsyncThunk } from '@reduxjs/toolkit';
import { reportUserSignIn } from 'store/api/bsc-api/bsc.service';
import { requestDeleteUser } from 'store/api/firebase-api/firebase.service';
import {
	createWallet,
	deleteWallet,
	requestUserProfile,
} from 'store/api/fireblocks-api/services/fireblocks-user.service';

import { notifyError } from 'utils/notify/notify.utils';

import { resetAuthStateAction } from './auth.reducer';

export const requestUserProfileThunkAction = createAsyncThunk('auth/user-profile', async () => {
	return await requestUserProfile().catch((error: Error) => {
		notifyError(error?.message);
		throw error;
	});
});

export const createWalletThunkAction = createAsyncThunk(
	'auth/create-wallet',
	async (promoCode: string) => {
		return await createWallet(promoCode)
			.then((user) => {
				const address = user?.address;
				if (address) {
					void reportUserSignIn(address);
				}
				return user;
			})
			.catch((error: Error) => {
				notifyError(error?.message);
				throw error;
			});
	},
);

export const deleteAccountThunkAction = createAsyncThunk(
	'auth/delete-wallet',
	async (walletAddress: string, { dispatch }): Promise<void> => {
		return await deleteWallet(walletAddress)
			.catch((error: Error) => {
				notifyError(error?.message);
				throw error;
			})
			.then(() => requestDeleteUser())
			.then(() => {
				dispatch(resetAuthStateAction());
			});
	},
);
