import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	requestRewards,
	requestTokenStats,
	requestUserAutoRewardsHistory,
	requestUserRewardsHistory,
} from 'store/api/bsc-api/bsc.service';
import { TUserRewardsHistoryNormalized } from 'store/api/bsc-api/types/bsc-response.types';
import { TAccountAddress } from 'types/blockchain/contracts.types';

export const requestUserTotalRewardsHistoryThunkAction = createAsyncThunk(
	'bsc/user-total-rewards-history',
	async (
		accountAddress: TAccountAddress,
	): Promise<{
		history: TUserRewardsHistoryNormalized;
		autoHistory: TUserRewardsHistoryNormalized;
	}> => {
		const historyPromise = requestUserRewardsHistory(accountAddress).then((data) =>
			data.map(({ amount, balance, staked_amount, multiplier, createdAt }) => {
				return {
					amount,
					amountBtcb: amount,
					balance,
					stakedAmount: staked_amount,
					multiplier,
					createdAt,
					isAuto: false,
				};
			}),
		);

		const autoHistoryPromise = requestUserAutoRewardsHistory(accountAddress).then((data) =>
			data.map(({ amount, balance, staked_amount, multiplier, createdAt, amount_btcb }) => {
				return {
					amount,
					amountBtcb: amount_btcb,
					balance,
					stakedAmount: staked_amount,
					multiplier,
					createdAt,
					isAuto: true,
				};
			}),
		);

		const [history, autoHistory] = await Promise.all([historyPromise, autoHistoryPromise]);

		return { history, autoHistory };
	},
);

export const requestTokenBSCThunkAction = createAsyncThunk('bsc/token', async () => {
	return requestTokenStats();
});

export const requestRewardsThunkAction = createAsyncThunk('bsc/apr-apy', async () => {
	return requestRewards();
});
